/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */

import { COLORS } from '../../../utils/enums/colors';
import { TemplateTypes } from '../../../utils/enums/types';
import { checkForV2 } from '../../../utils/helper';
import { canViewTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { createActorModel } from './actorsJson';
import { convertFieldType, convertMetaField, convertScope } from './helpers';
import '../../../index.scss';

export const templateBuilderJson = {
  name: 'ticket_template',
  label: 'Ticket Template',
  table_name: 'ticket_template',
  route: '/template-makers',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'ticket_template_sidebar',
      label: 'Ticket Template Sidebar',
      show_label: false,
      type: 'container',
      views: [
        {
          name: 'ticket_template_list_accordion',
          label: 'All Tickets Type',
          show_label: false,
          type: 'accordion',
          entity: 'Templates',
          filterKey: 'name',
          listKey: 'ticket_template_list_short_view',
          inputRightElementStyle: { height: '32px' },
          searchContainerStyle: {
            padding: '0 calc(var(--ck-space-4) - 0.5rem)',
          },
          views: [
            {
              name: 'ticket_template_list_short_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'nested_list',
              entity: 'ticket_template',
              columns: ['name'],
              nested: {
                column: 'parent_id',
                column_type: 'parent',
              },
              style: { maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' },
              apiReq: {
                reqCols: [
                  'id',
                  'name',
                  'slug',
                  'version',
                  'properties',
                  'metric',
                  'tenant_id',
                  'permissions',
                ],
                orderBy: 'created_on desc',
                endPoint: 'templates/tree',
              },
              childKey: 'children',
              filter: [
                {
                  AND: [
                    {
                      condition: 'is_active = true',
                    },
                    {
                      OR: [
                        {
                          condition: 'author_id = $user.id',
                        },
                        {
                          condition: 'collaborator_id = $user.id',
                        },
                      ],
                    },
                  ],
                },
              ],
              actions: [
                {
                  name: 'view_edit_details',
                  label: 'View/Edit',
                  show_label: false,
                  icon: 'edit.ico',
                  is_button: false,
                  action_type: 'get',
                  permission: 'view',
                  target_params: ['id', 'name'],
                  type: 'record',
                  target: '/template-makers/template/',
                  target_type: 'navigate',
                },
              ],
            },
            // {
            //   name: 'create_modal',
            //   label: 'create new template',
            //   type: 'modal_multi_tab',
            //   show_label: true,
            //   refreshListsAfterSave: ['ticket_template'],
            //   mandatoryFields: ['template_name', 'template_type'],
            //   tabIds: ['basic_tab_container', 'role_tab_container'],
            //   modal_id: 'custom_template_modal',
            //   views: [
            //     {
            //       name: 'namelabel',
            //       type: 'text',
            //       value: 'Name',
            //     },
            //     {
            //       name: 'typelabel',
            //       type: 'text',
            //       value: 'Type',
            //       visibility: false,
            //       condition: { stateName: 'templateName', stateValue: 'abc' },
            //     },
            //     {
            //       name: 'name',
            //       type: 'input',
            //       value: 'templateName',
            //       inputType: 'string',
            //     },
            //     {
            //       name: 'dummy',
            //       type: 'input',
            //       value: 'dummy',
            //       inputType: 'string',
            //     },
            //     {
            //       name: 'type',
            //       type: 'select',
            //       value: 'templateType',
            //       options: [{ label: 'dummy', value: 'Dummy' }],
            //     },
            //     {
            //       name: 'multi_type',
            //       type: 'multi_select',
            //       value: 'templateTypeMulti',
            //       options: [
            //         { label: 'dummy', value: 'Dummy' },
            //         { label: 'two', value: 'one' },
            //       ],
            //     },
            //   ],
            //   actions: [
            //     {
            //       name: 'continue',
            //       label: 'Continue',
            //       show_label: true,
            //       icon: 'edit.ico',
            //       is_button: true,
            //       action_type: 'get',
            //       permission: 'edit',
            //       view: 'details_view',
            //       target_type: 'api_call',
            //       type: 'button',
            //       is_modal_footer: true,
            //     },
            //     {
            //       name: 'cancel',
            //       label: 'Cancel',
            //       show_label: true,
            //       icon: 'edit.ico',
            //       is_button: true,
            //       action_type: 'get',
            //       permission: 'edit',
            //       view: 'details_view',
            //       target_type: 'state_change',
            //       type: 'button',
            //       is_modal_footer: true,
            //     },
            //   ],
            //   view_layout: {
            //     width: '100%',
            //     no_of_cols: 12,
            //     rows: [
            //       {
            //         i: 'typelabel',
            //         w: 12,
            //         h: 1,
            //       },
            //       {
            //         i: 'type',
            //         w: 6,
            //         h: 1,
            //       },
            //       {
            //         i: 'multi_type',
            //         w: 6,
            //         h: 1,
            //       },
            //       {
            //         i: 'cancel',
            //         w: 6,
            //         h: 1,
            //       },
            //       {
            //         i: 'continue',
            //         w: 6,
            //         h: 1,
            //       },
            //       {
            //         i: 'namelabel',
            //         w: 12,
            //         h: 1,
            //       },
            //       {
            //         i: 'name',
            //         w: 6,
            //         h: 1,
            //       },
            //       {
            //         i: 'dummy',
            //         w: 6,
            //         h: 1,
            //       },
            //     ],
            //   },
            // },
          ],
          actions: [
            {
              name: 'create_template',
              label: 'Create Ticket Type',
              show_label: true,
              icon: 'edit.ico',
              is_button: true,
              action_type: 'get',
              permission: 'edit',
              view: 'details_view',
              target_type: 'popup',
              target_modal_id: 'custom_template_modal',
              type: 'button',
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'create_template',
                w: 6,
                h: 1,
              },
              {
                i: 'ticket_template_list_short_view',
                w: 12,
                h: 1,
              },
              // {
              //   i: 'create_modal',
              //   w: 12,
              //   h: 1,
              // },
            ],
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'ticket_template_list_accordion',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
        ],
      },
    },

    {
      name: 'template_details_view',
      label: 'template_details',
      route: '/template-makers/template/:id',
      show_label: false,
      type: 'container',
      show: ({ viewState }) => {
        if (!viewState?.selectedTemplate) {
          return true;
        }
        return canViewTemplate({
          data: viewState?.selectedTemplate,
        });
      },
      style: {
        // height: 'calc(100vh - var(--ck-sizes-20))',
        height: 'calc(100vh - 1rem)',
      },
      // condition: {
      //   stateName: 'ticket_template_list_short_view',
      //   operator: '=',
      //   stateValue: 0,
      // },
      views: [
        {
          name: 'leftTop_view',
          label: 'Ticket Template List Short',
          show_label: false,
          type: 'container',
          views: [
            {
              name: 'left_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              style: {
                display: 'flex',
                alignItems: 'Center',
                height: '100%',
              },

              elements: [
                {
                  name: 'backBtn',
                  label: '',
                  type: 'button',
                  show_label: false,
                  target_type: 'route',
                  icon: 'back.ico',
                  navigatetUrl: '/template-cardlist',
                  classname: 'iconButton',
                  style: {
                    borderColor: '#2563EB',
                    // color: '#2563EB',
                    fontSize: '19px',
                    borderRadius: '100%',
                  },
                  styleDark: {
                    borderColor: '#2563EB',
                    // color: '#2563EB',
                    fontSize: '19px',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                  },
                },
                {
                  name: 'title',
                  type: 'dynamic_text',
                  value: '$name',
                  style: { fontSize: '20px', fontWeight: '600', display: 'flex', alignItems: 'center' },
                },
              ],
              actions: [
                // {
                //   name: 'edit',
                //   label: 'Edit',
                //   show_label: true,
                //   is_button: true,
                //   icon: '',
                //   action_type: 'get',
                //   permission: 'edit',
                //   view: 'edit_view',
                //   target_type: 'popup',
                //   target_modal_id: 'edit_custom_template_modal',
                //   type: 'button',
                //   classname: 'editbutton',
                //   style: {
                //     color: 'rgba(0, 0, 0, 0.85)',
                //     fontWeight: '600',
                //     height: '32px',
                //   },
                //   styleDark: {
                //     color: '#EDF2F7',
                //     fontWeight: '600',
                //     height: '32px',
                //   },
                // },
                // {
                //   name: 'copy',
                //   label: '',
                //   show_label: false,
                //   icon: 'copy.ico',
                //   is_button: true,
                //   target_type: 'popup',
                //   target_modal_id: 'crudTemplateCloneModal',
                //   method: 'post',
                //   target_url: '/templates/clone',
                //   type: 'button',
                //   listKey: 'ticket_template_list_short_view',
                //   classname: 'iconButton',
                //   style: {
                //     color: 'rgba(0, 0, 0, 0.85)',
                //     fontSize: '19px',
                //     width: '32px',
                //     height: '32px',
                //   },
                //   styleDark: {
                //     color: '#EDF2F7',
                //     fontSize: '19px',
                //     width: '32px',
                //     height: '32px',
                //   },
                //   tooltip: 'Copy Template',
                //   master_type: 'template',
                // },
                // {
                //   name: 'delete',
                //   label: '',
                //   type: 'button',
                //   show_label: false,
                //   target_type: 'popup',
                //   icon: 'delete.ico',
                //   target_modal_id: 'deleteTemplateModal',
                //   classname: 'iconButton',
                //   style: {
                //     borderColor: '#ED7474',
                //     color: '#ED7474',
                //     fontSize: '19px',
                //     width: '32px',
                //     height: '32px',
                //   },
                //   styleDark: {
                //     borderColor: '#ED7474',
                //     color: '#ED7474',
                //     fontSize: '19px',
                //     width: '32px',
                //     height: '32px',
                //   },
                //   tooltip: 'Delete Template',
                //   disabledCondition: {
                //     stateName: 'selectedTemplate',
                //     stateKey: 'properties.wasApproved',
                //     operator: '!=',
                //     stateValue: true,
                //   },
                // },
                // {
                //   name: 'view_json',
                //   label: '',
                //   show_label: true,
                //   is_button: true,
                //   icon: 'jsonfile.ico',
                //   action_type: 'get',
                //   permission: 'edit',
                //   view: 'edit_view',
                //   target_type: 'popup',
                //   target_modal_id: 'templateJsonModal',
                //   type: 'button',
                //   classname: 'iconButton',
                //   style: { color: 'rgba(0, 0, 0, 0.85)' },
                //   styleDark: { fill: '#fff' },
                //   tooltip: 'View JSON',
                // },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 11,
                rows: [
                  {
                    i: 'backBtn',
                    w: 0.5,
                    h: 1,
                  },
                  {
                    i: 'title',
                    w: 5,
                    h: 1,
                  },
                  // {
                  //   i: 'edit',
                  //   w: 2,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'copy',
                  //   w: 1,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'delete',
                  //   w: 1,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'view_json',
                  //   w: 1,
                  //   h: 1,
                  // },
                ],
              },
            },
            {
              name: 'right_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              classname: 'rightview',
              style: { display: 'flex', justifyContent: 'center' },
              actions: [
                // not required as of now
                // {
                //   name: 'preview',
                //   label: 'Preview',
                //   show_label: true,
                //   is_button: true,
                //   icon: 'view.ico',
                //   action_type: 'get',
                //   permission: 'edit',
                //   view: 'edit_view',
                //   navigation: 'popup',
                //   target_modal_id: 'create_template_modal',
                //   type: 'button',
                //   classname: 'previewbutton',
                //   style: { color: 'rgba(0, 0, 0, 0.85)' },
                // },
                {
                  name: 'empty_text',
                  type: 'text',
                  value: '',
                  style: {
                    fontSize: '16px',
                    fontWeight: '500',
                    marginTop: '1px',
                  },
                  show_label: false,
                },
                {
                  name: 'empty_text',
                  type: 'text',
                  value: '',
                  style: {
                    fontSize: '16px',
                    fontWeight: '500',
                    marginTop: '1px',
                  },
                  show_label: false,
                },
                {
                  name: 'empty_text',
                  type: 'text',
                  value: '',
                  style: {
                    fontSize: '16px',
                    fontWeight: '500',
                    marginTop: '1px',
                  },
                  show_label: false,
                },
                {
                  name: 'edit',
                  label: '',
                  show_label: false,
                  is_button: true,
                  icon: 'editWithOutMargin.ico',
                  action_type: 'get',
                  permission: 'edit',
                  view: 'edit_view',
                  dataTestId: 'selected-template-edit',
                  target_type: 'popup',
                  target_modal_id: 'edit_custom_template_modal',
                  type: 'button',
                  classname: 'iconButton calculatedHeight',
                  style: {
                    color: '#94A3B8',
                    fontSize: '19px',
                    width: '32px',
                    // height: '34px',
                  },
                  styleDark: {
                    color: '#EDF2F7',
                    fontSize: '19px',
                    width: '32px',
                    // height: '34px',
                  },
                  tooltip: 'Edit Template',
                },
                {
                  name: 'copy',
                  label: '',
                  show_label: false,
                  icon: 'copy.ico',
                  is_button: true,
                  dataTestId: 'selected-template-clone',
                  target_type: 'popup',
                  target_modal_id: 'crudTemplateCloneModal',
                  method: 'post',
                  target_url: '/templates/clone',
                  type: 'button',
                  listKey: 'ticket_template_list_short_view',
                  classname: 'iconButton calculatedHeight',
                  style: {
                    color: '#94A3B8',
                    fontSize: '19px',
                    width: '32px',
                    // height: '34px',
                  },
                  styleDark: {
                    color: '#EDF2F7',
                    fontSize: '19px',
                    width: '32px',
                    // height: '34px',
                  },
                  tooltip: 'Copy Template',
                  master_type: 'template',
                },
                {
                  name: 'delete',
                  label: '',
                  type: 'button',
                  show_label: false,
                  target_type: 'popup',
                  icon: 'delete.ico',
                  target_modal_id: 'deleteTemplateModal',
                  classname: 'iconButton calculatedHeight',
                  dataTestId: 'selected-template-delete',
                  style: {
                    borderColor: '#ED7474',
                    color: '#ED7474',
                    fontSize: '19px',
                    width: '32px',
                    // height: '34px',
                  },
                  styleDark: {
                    borderColor: '#ED7474',
                    color: '#ED7474',
                    fontSize: '19px',
                    width: '32px',
                    // height: '34px',
                  },
                  tooltip: 'Delete Template',
                  disabledCondition: {
                    stateName: 'selectedTemplate',
                    stateKey: 'properties.wasApproved',
                    operator: '!=',
                    stateValue: true,
                  },
                },
                {
                  name: 'view_json',
                  label: 'Export JSON',
                  show_label: true,
                  is_button: true,
                  icon: '',
                  action_type: 'get',
                  permission: 'edit',
                  view: 'edit_view',
                  target_type: 'popup',
                  target_modal_id: 'templateJsonModal',
                  type: 'button',
                  classname: 'editbutton calculatedHeight customHover',
                  // style: { color: 'rgba(0, 0, 0, 0.85)', height: '34px'},

                  styleDark: { fill: '#fff', },
                  // tooltip: 'View JSON',
                },
                {
                  name: 'dropdown',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'approve_reject_dropdown',
                  columns: ['status'],
                  classname: 'calculatedHeight',
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                },
                // {
                //   name: 'save',
                //   label: 'Save',
                //   show_label: true,
                //   icon: '',
                //   is_button: true,
                //   action_type: 'get',
                //   permission: 'edit',
                //   view: 'ticket_template_field_delete_view',
                //   navigation: 'popup',
                //   target_modal_id: 'create_template_modal',
                //   type: 'button',
                //   classname: 'savebutton',
                //   style: { background: '#2563EB', color: '#FFFFFF' },
                // },
                // {
                //   name: 'workflow',
                //   label: 'WorkFlow',
                //   show_label: true,
                //   is_button: true,
                //   icon: 'workflow.ico',
                //   action_type: 'get',
                //   permission: 'edit',
                //   target_modal_id: 'workflowConfigModal',
                //   type: 'workflow_linking',
                //   classname: 'previewbutton',
                //   style: { borderColor: '' },
                //   view: 'details_view',
                //   target_type: 'popup',
                // },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  // {
                  //   i: 'preview',
                  //   w: 2,
                  //   h: 1,
                  // },
                  {
                    i: 'empty_text',
                    w: 7,
                    h: 1,
                  },
                  {
                    i: 'edit',
                    w: 1,
                    h: 1,
                  },
                  {
                    i: 'copy',
                    w: 1,
                    h: 1,
                  },
                  {
                    i: 'delete',
                    w: 1,
                    h: 1,
                  },
                  {
                    i: 'view_json',
                    w: 1,
                    h: 1,
                  },
                  {
                    i: 'dropdown',
                    w: 1,
                    h: 1,
                  },
                  // {
                  //   i: 'save',
                  //   w: 1,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'empty_text',
                  //   w: 1,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'workflow',
                  //   w: 2,
                  //   h: 1,
                  // },
                ],
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 2,
            rows: [
              {
                i: 'left_view',
                w: 1,
                h: 1,
              },
              {
                i: 'right_view',
                w: 1,
                h: 1,
              },
            ],
          },
        },
        {
          name: 'ticket_template_tabs_view',
          label: 'Ticket Template Fields',
          show_label: false,
          type: 'tab_view',
          related_entity: 'ticket_template_fields',
          filter: [],
          views: [
            // field settings *****************************************************************
            {
              name: 'fieldsetting_tab',
              label: 'Fields',
              show_label: false,
              isBasicSettingFields: true,
              type: 'container',
              style: { paddingTop: '15px', boxSizing: 'borderBox' },
              filter: [],
              views: [
                {
                  name: 'field_search_container',
                  type: 'container',
                  elements: [
                    {
                      name: 'field_gab_fill_ele',
                      type: 'text',
                      value: 'Field Settings',
                      style: {
                        fontSize: '16px',
                        fontWeight: '600',
                        marginTop: '1px',
                      },
                      show_label: false,
                    },
                    {
                      name: 'search_fieldsetting_tab',
                      type: 'list_search',
                      placeholder: 'Search for a field',
                      listKey: 'ticket_template_fields_list_view',
                      filterKey: 'ticket_template_fields_filtered_list_view',
                      searchKey: 'label',
                      // style:{ height:'32px'},
                      classname: 'calculatedHeight',
                    },
                  ],
                  actions: [
                    {
                      name: 'add_field_from_master',
                      label: 'Add Field From Bank',
                      show_label: true,
                      // icon: 'add.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'popup',
                      target_modal_id: 'addFieldFromMaster',
                      type: 'button',
                      classname: 'calculatedHeight',
                      style: { 
                        borderColor: '#2563EB',
                      },
                    },
                    {
                      name: 'add_field',
                      label: 'Add  Field',
                      show_label: true,
                      // icon: 'add.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'popup',
                      target_modal_id: 'addFieldsModal',
                      type: 'button',
                      classname: 'calculatedHeight',
                      style: {
                        borderColor: '#2563EB',
                        backgroundColor: COLORS.LIGHT,
                        color: COLORS.WHITE,
                        // height:'32px'
                      },
                      styleDark: {
                        borderColor: '#2563EB',
                        backgroundColor: COLORS.LIGHT,
                        color: COLORS.WHITE,
                        // height:'32px'
                      }
                    },
                    // {
                    //   name: 'configure_view',
                    //   label: 'Configure the View',
                    //   show_label: true,
                    //   icon: 'config.ico',
                    //   is_button: true,
                    //   action_type: 'get',
                    //   permission: 'edit',
                    //   view: 'details_view',
                    //   target_type: 'navigate',
                    //   target_url: '/template-makers/configure-template-view/',
                    //   type: 'button',
                    //   is_modal_footer: true,
                    //   style: {
                    //     borderColor: 'rgb(0, 0, 0)',
                    //     color: 'rgb(0, 0, 0)',
                    //   },
                    //   styleDark: {
                    //     borderColor: '#3F444E',
                    //     color: '#EDF2F7',
                    //   },
                    // },
                  ],
                  view_layout: {
                    width: '100%',
                    no_of_cols: 12,
                    rows: [
                      {
                        i: 'field_gab_fill_ele',
                        w: 7,
                        h: 1,
                      },
                      {
                        i: 'search_fieldsetting_tab',
                        w: 3,
                        h: 1,
                      },
                      { i: 'add_field_from_master', w: 1, h: 1 },
                      {
                        i: 'add_field',
                        w: 1,
                        h: 1,
                      },
                      // {
                      //   i: 'configure_view',
                      //   w: 1,
                      //   h: 1,
                      // },
                    ],
                  },
                },
                {
                  name: 'ticket_template_fields_list_view',
                  filteredName: 'ticket_template_fields_filtered_list_view',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'list_exp',
                  title: 'Field',
                  apiReq: {
                    reqCols: ['fields'],
                    orderBy: 'created_on asc',
                    endPoint: 'templates/list',
                    isSingle: true,
                    filter: 'id',
                  },
                  related_entity: 'ticket_template_fields',
                  columns: [
                    {
                      col_name: 'label',
                      type: 'string',
                    },
                    {
                      col_name: 'Feature',
                      type: 'TemplateFieldsIconList',
                    },
                    {
                      col_name: 'type',
                      type: 'string',
                      cellRenderer: convertFieldType,
                    },
                    {
                      col_name: 'scope',
                      type: 'string',
                      cellRenderer: convertScope,
                    },
                    {
                      col_name: 'Meta Field',
                      type: 'string',
                      cellRenderer: convertMetaField,
                    },
                    {
                      col_name: 'actions',
                      type: 'actions',
                    },
                  ],
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                  actions: [
                    {
                      name: 'edit_fields',
                      icon: 'editIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'editFieldModal',
                    },
                    {
                      name: 'delete_fields',
                      icon: 'deleteIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'deleteModal',
                      master_type: 'fields', // this should be match keyValue in api for update
                      method: 'patch',
                      listKey: 'ticket_template_fields_list_view', // identify the redux status for update it
                      target_url: '/templates/update?$filter=id eq ${id}',
                      label_key: 'label', // Key name to be displayed in modal as label
                    },
                  ],
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'field_search_container',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'ticket_template_fields_list_view',
                    w: 12,
                    h: 1,
                  },
                ],
              },
            },

            // Child Templates
            !checkForV2() && {
              name: 'child_templates_tab',
              label: 'Child Templates',
              type: 'child_templates_tab',
              show_label: false,
              show: (state) =>
                state?.selectedTemplate?.properties?.template_type ===
                TemplateTypes.NESTED,
            },

            // role settings ******************************************************************
            // {
            //   name: 'role_settings_tab',
            //   label: 'Role Settings',
            //   show_label: false,
            //   type: 'container',
            //   loadInitialData: true,
            //   enpPoint:
            //     'templates/list?$top=8&$skip=0&$select=permissions&$orderby=created_on%20asc',
            //   methodType: 'get',
            //   viewStateName: 'role_settings_tab',
            //   responsePath: 'data.rows[0].permissions',
            //   style: { padding: '20px' },
            //   filter: [],
            //   views: [
            //     {
            //       name: 'role_settings_section',
            //       label: 'Role Settings',
            //       type: 'multi_select_tags',

            //       elements: [
            //         {
            //           name: 'viewers',
            //           type: 'text',
            //           value: 'Viewers',
            //           style: { fontWeight: '600' },
            //         },
            //         {
            //           name: 'viewers_select',
            //           type: 'select',
            //           options: [
            //             { label: 'viewer_1', value: 'viewer_1' },
            //             { label: 'viewer_2', value: 'viewer_2' },
            //             { label: 'viewer_3', value: 'viewer_3' },
            //             { label: 'viewer_4', value: 'viewer_4' },
            //           ],
            //           value: 'viewer',
            //         },
            //         {
            //           name: 'anyone_text',
            //           type: 'text',
            //           value: 'Anyone logged in from',
            //           style: { textAlign: 'center' },
            //         },
            //         {
            //           name: 'viewer_input',
            //           type: 'input',
            //           value: 'viewInput',
            //         },
            //         {
            //           name: 'editors',
            //           type: 'text',
            //           value: 'Editors',
            //           style: { fontWeight: '600' },
            //         },

            //         {
            //           name: 'editors_select',
            //           type: 'select',
            //           options: [
            //             { label: 'editor_1', value: 'editor_1' },
            //             { label: 'editor_2', value: 'editor_2' },
            //             { label: 'editor_2', value: 'editor_2' },
            //             { label: 'editor_3', value: 'editor_3' },
            //           ],
            //           value: 'editor',
            //         },
            //         {
            //           name: 'editor_subcategory',
            //           type: 'select',
            //           options: [
            //             { label: 'editor_sub_1', value: 'editor_sub_1' },
            //             { label: 'editor_sub_2', value: 'editor_sub_2' },
            //             { label: 'editor_sub_2', value: 'editor_sub_2' },
            //             { label: 'editor_sub_3', value: 'editor_sub_3' },
            //           ],
            //           value: 'editor_subcategory',
            //         },
            //         {
            //           name: 'expressions',
            //           type: 'text',
            //           value: 'Expressions',
            //           style: { fontWeight: '600' },
            //         },

            //         {
            //           name: 'expression_input_left',
            //           type: 'input',
            //           value: 'leftOperand',
            //         },
            //         {
            //           name: 'expression_select',
            //           type: 'select',
            //           options: [
            //             { label: '===', value: '===' },
            //             { label: '!==', value: '!==' },
            //             { label: '>=', value: '>=' },
            //           ],
            //           value: 'operator',
            //         },
            //         {
            //           name: 'expression_input_right',
            //           type: 'input',
            //           value: 'rightOperand',
            //         },
            //         {
            //           name: 'editors_div',
            //           type: 'multi_select_div',
            //           value: 'editors',
            //           target_state_key: 'role_settings_tab',
            //           // condition: { stateName: 'templateName', stateValue: 'abc' },
            //         },
            //         {
            //           name: 'viewers_div',
            //           type: 'multi_select_div',
            //           value: 'viewers',
            //           target_state_key: 'role_settings_tab',
            //           // condition: { stateName: 'templateName', stateValue: 'abc' },
            //         },
            //         {
            //           name: 'expression_div',
            //           type: 'expression_div',
            //           value: 'expression',
            //           // parent state were data will be stored
            //           target_state_key: 'role_settings_tab',
            //           lhs_key: 'leftOperand',
            //           op_key: 'operator',
            //           rhs_key: 'rightOperand',
            //           // condition: { stateName: 'templateName', stateValue: 'abc' },
            //         },
            //       ],
            //       actions: [
            //         {
            //           name: 'add_viewer',
            //           label: 'Add',
            //           is_button: true,
            //           type: 'button',
            //           target_key: 'viewers',
            //           target_obj_key: 'viewer',
            //           target_val_key: 'viewInput',
            //         },
            //         {
            //           name: 'add_editor',
            //           label: 'Add',
            //           is_button: true,
            //           type: 'button',
            //           target_key: 'editors',
            //           target_obj_key: 'editor',
            //           target_val_key: 'editor_subcategory',
            //         },
            //         {
            //           name: 'add_expression',
            //           label: 'Add',
            //           is_button: true,
            //           type: 'button',
            //           target_key: 'expression',
            //           lhs: 'leftOperand',
            //           op: 'operator',
            //           rhs: 'rightOperand',
            //         },
            //       ],
            //       view_layout: {
            //         width: '40%',
            //         no_of_cols: 12,
            //         rows: [
            //           // {
            //           //   i: 'option_popup',
            //           //   w: 10,
            //           //   h: 1,
            //           // },

            //           {
            //             i: 'viewers',
            //             w: 12,
            //             h: 1,
            //           },
            //           {
            //             i: 'viewers_select',
            //             w: 3,
            //             h: 1,
            //           },
            //           {
            //             i: 'anyone_text',
            //             w: 3,
            //             h: 1,
            //           },
            //           {
            //             i: 'viewer_input',
            //             w: 4,
            //             h: 1,
            //           },
            //           {
            //             i: 'add_viewer',
            //             w: 1,
            //             h: 1,
            //           },
            //           {
            //             i: 'viewers_div',
            //             w: 12,
            //             h: 1,
            //           },
            //           {
            //             i: 'editors',
            //             w: 12,
            //             h: 1,
            //           },
            //           {
            //             i: 'editors_select',
            //             w: 3,
            //             h: 1,
            //           },
            //           {
            //             i: 'editor_subcategory',
            //             w: 7,
            //             h: 1,
            //           },
            //           {
            //             i: 'add_editor',
            //             w: 1,
            //             h: 1,
            //           },
            //           {
            //             i: 'editors_div',
            //             w: 12,
            //             h: 1,
            //           },
            //           {
            //             i: 'expressions',
            //             w: 12,
            //             h: 1,
            //           },
            //           {
            //             i: 'expression_input_left',
            //             w: 3,
            //             h: 1,
            //           },
            //           {
            //             i: 'expression_select',
            //             w: 4,
            //             h: 1,
            //           },
            //           {
            //             i: 'expression_input_right',
            //             w: 3,
            //             h: 1,
            //           },

            //           {
            //             i: 'add_expression',
            //             w: 1,
            //             h: 1,
            //           },
            //           {
            //             i: 'expression_div',
            //             w: 12,
            //             h: 1,
            //           },
            //         ],
            //       },
            //     },
            //   ],
            //   actions: [
            //     {
            //       name: 'save_role_settings',
            //       label: 'Save',
            //       is_button: true,
            //       type: 'button',
            //       target_type: 'api_call',
            //       payloadKeysMaps: {
            //         permissions: {
            //           viewers: {},
            //           editors: {},
            //           expression: [],
            //         },
            //       },
            //       updatePayloadPath: 'permissions',
            //       payloadStateKey: 'role_settings_tab',
            //       method: 'patch',
            //       target_url: 'templates/update?',
            //       style: {
            //         background: '#2563EB',
            //         color: '#fff',
            //         marginTop: '20px',
            //       },
            //     },
            //   ],
            //   view_layout: {
            //     width: '100%',
            //     no_of_cols: 12,
            //     rows: [
            //       {
            //         i: 'role_settings_section',
            //         x: 0,
            //         y: 1,
            //         w: 12,
            //         maxH: 'Infinity',
            //       },
            //       {
            //         i: 'save_role_settings',
            //         x: 0,
            //         y: 1,
            //         w: 2,
            //         colEnd: 12,
            //         maxH: 'Infinity',
            //       },
            //     ],
            //   },
            // },

            // Field Defaults

            // Dependent Fields
            // checkForV2() && {
            //   name: 'dependent_fields_tab',
            //   label: 'Dependent fields',
            //   type: 'dependent_fields_tab',
            //   show_label: false,
            // },
            // Layouts
            {
              name: 'configure_layout_tab',
              label: 'Layouts',
              type: 'configure_layout_tab',
              isBasicSettingFields: true,
              show_label: false,
            },
            // View & Layout
            {
              name: 'layout_for_view_transition_tab',
              label: 'Linked Layouts',
              type: 'layout_for_view_transition_tab',
              isBasicSettingFields: true,
              show_label: false,
            },
            // Actors
            checkForV2() && {
              name: 'actors_tab',
              label: 'Actors',
              show_label: false,
              isBasicSettingFields: true,
              type: 'container',
              style: { paddingTop: '15px', boxSizing: 'borderBox' },
              filter: [],
              views: [
                {
                  name: 'actor_search_container',
                  type: 'container',
                  elements: [
                    {
                      name: 'actor_gab_fill_ele',
                      type: 'text',
                      value: 'Actors',
                      style: {
                        fontSize: '16px',
                        fontWeight: '600',
                        marginTop: '1px',
                      },
                      show_label: false,
                    },
                    {
                      name: 'actor_search_tab_ele',
                      type: 'list_search',
                      placeholder: 'Search for actor',
                      listKey: 'ticket_template_actors_list_view',
                      filterKey: 'ticket_template_actors_filtered_list_view',
                      searchKey: 'name',
                    },
                  ],
                  actions: [
                    {
                      name: 'add_actor',
                      label: 'Add Actor',
                      show_label: true,
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'popup',
                      target_modal_id: 'addActorInTemplateModal',
                      type: 'button',
                      classname: 'calculatedHeight',
                      style: {
                        borderColor: '#2563EB',
                        backgroundColor: COLORS.LIGHT,
                        color: COLORS.WHITE,
                        height: '32px'
                      },
                    },
                  ],
                  view_layout: {
                    width: '100%',
                    no_of_cols: 12,
                    rows: [
                      {
                        i: 'actor_gab_fill_ele',
                        w: 8,
                        h: 1,
                      },
                      {
                        i: 'actor_search_tab_ele',
                        w: 3,
                        h: 1,
                      },
                      {
                        i: 'add_actor',
                        w: 1,
                        h: 1,
                      },
                    ],
                  },
                },
                {
                  name: 'ticket_template_actors_list_view',
                  filteredName: 'ticket_template_actors_filtered_list_view',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'list_exp',
                  title: 'Actors',
                  apiReq: {
                    reqCols: ['actors'],
                    orderBy: 'created_on asc',
                    endPoint: 'templates/list',
                    isSingle: true,
                    filter: 'id',
                  },
                  related_entity: 'ticket_template_fields',
                  columns: [
                    {
                      col_name: 'name',
                      type: 'string',
                    },
                    {
                      col_name: 'associated_field', // ASSOCIATED FIELD
                      type: 'string',
                    },
                    {
                      col_name: 'log_field', // LOG FIELD as Queue
                      type: 'string',
                    },
                    {
                      col_name: 'actions',
                      type: 'actions',
                    },
                  ],
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                  actions: [
                    {
                      name: 'edit_fields',
                      icon: 'editIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'editActorInTemplateModal',
                    },
                    {
                      name: 'delete_fields',
                      icon: 'deleteIcon',
                      type: 'button',
                      target_type: 'popup',
                      label_key: 'name', // Key name to be displayed in modal as label
                      target_modal_id: 'deleteModal',
                      master_type: 'actors', // this should be match keyValue in api for update
                      method: 'patch',
                      listKey: 'ticket_template_actors_list_view', // identify the redux status for update it
                      target_url: '/templates/update?$filter=id eq ${id}',
                    },
                  ],
                },
                createActorModel
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'actor_search_container',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'ticket_template_actors_list_view',
                    w: 12,
                    h: 1,
                  },
                ],
              },
            },
            // Metrics & SLA
            {
              name: 'metrics_and_sla_tab',
              label: 'Metrics & SLA',
              type: 'metrics_and_sla_tab',
              show_label: false,
              isBasicSettingFields: true,
            },
            {
              name: 'metrics_and_sla_tab',
              label: 'Workflow',
              type: 'work_flow',
              show_label: false,
              isBasicSettingFields: true,
            },

            // Events
            {
              name: 'event_settings_tab',
              label: 'Events',
              type: 'event_settings_tab',
              isBasicSettingFields: false,
            },

            // Locales
            {
              name: 'locales_tab',
              label: 'Locales',
              type: 'locales_tab',
              show_label: false,
              isBasicSettingFields: false,
            },

            // Permissions
            checkForV2() && {
              name: 'permissions_tab',
              label: 'Permissions',
              type: 'permissions_tab',
              isBasicSettingFields: false,
            },

            // Dashboard
            {
              name: 'dashboard_tab',
              label: 'Dashboard',
              type: 'dashboard_tab',
              show_label: false,
              isBasicSettingFields: true,
            },
            // Offers
            {
              name: 'offers_tab',
              label: 'Offers',
              type: 'offers_tab',
              isBasicSettingFields: true,
              show_label: false,
            },

            // Field Defaults
            // checkForV2() && {
            //   name: 'field_default_tab',
            //   label: 'Field Defaults',
            //   type: 'field_default_tab',
            //   show_label: false,
            // },

            // Runtime Filter
            checkForV2() && {
              name: 'runtime_filter_tab',
              label: 'Filters',
              type: 'runtime_filter_tab',
              show_label: false,
              isBasicSettingFields: false,
            },

            // Templates
            checkForV2() && {
              name: 'templates_',
              label: 'Templates',
              type: 'templates_tab',
              isBasicSettingFields: false,
            },

            // Audience
            // checkForV2() && {
            //   name: 'audience_tab',
            //   label: 'Audience',
            //   type: 'audience_tab',
            //   show_label: false,
            // },

            // Relation
            checkForV2() && {
              name: 'relation_tab',
              label: 'Relations',
              type: 'relation_tab',
              isBasicSettingFields: false,
            },

            // Mapping (bot and email)
            // checkForV2() && {
            //   name: 'mapping_tab',
            //   label: 'Bot/Email Mapping',
            //   type: 'mapping_tab',
            // },

            // Routing
            checkForV2() && {
              name: 'ticket_routing_tab',
              label: 'Ticket Routing',
              type: 'ticket_routing_tab',
              show_label: false,
              isBasicSettingFields: false,
            },
            // Map Hierarchy
            checkForV2() && {
              name: 'map_hierarchy_tab',
              label: 'Map Hierarchy',
              type: 'map_hierarchy_tab',
              show_label: false,
              isBasicSettingFields: false,
            },
            // checkForV2() && {
            //   name: 'condition_com_tab',
            //   label: 'Condition Com',
            //   type: 'condition_com_tab',
            //   show_label: false,
            //   isBasicSettingFields: false,
            // },
            checkForV2() && {
              name: 'quick_action_tab',
              label: 'Quick Action',
              type: 'quick_action_tab',
              show_label: false,
              isBasicSettingFields: false,
            },
            checkForV2() && {
              name: 'special_fields_tab',
              label: 'Special Fields',
              type: 'special_fields_tab',
              show_label: false,
              isBasicSettingFields: false,
            },
          ],
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'leftTop_view',
            w: 12,
            h: 1,
          },
          {
            i: 'ticket_template_tabs_view',
            w: 12,
            h: 1,
          },
        ],
      },
    },
    // {
    //   name: 'empty_template_view',
    //   label: 'No Template Screen',
    //   type: 'empty_screen',
    //   icon: 'noTemplateIcon',
    //   title: 'You have not created a template',
    //   subTitle: 'Click ‘Create New Template’ to get started',
    //   btnText: 'Create New Template',
    //   target_modal_id: 'create_template_modal',
    //   condition: {
    //     stateName: 'ticket_template_list_short_view',
    //     operator: '>',
    //     stateValue: 0,
    //   },
    // },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      // {
      //   i: 'ticket_template_sidebar',
      //   x: 0,
      //   y: 0,
      //   w: 3,
      //   h: 1,
      // },
      // {
      //   i: 'empty_template_view',
      //   x: 0,
      //   y: 1,
      //   w: 10,
      //   maxH: 'Infinity',
      // },
      {
        i: 'template_details_view',
        x: 0,
        y: 1,
        w: 12,
        maxH: 'Infinity',
      },
    ],
  },
};

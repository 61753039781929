/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import SelectFieldFromTemplateWithDelete from '../../components/selectFieldFromTemplateWithDelete';
import { QUICK_ACTION_LABEL } from './quickAction.constants';

const WrapperForFieldChange = ({
  label,
  onDelete,
  values,
  onUpdatedValue,
  teamQueueMap,
  heirarchies,
  fieldHierarchyMap,
}) => {
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  return (
    <Box
      border="1px solid #D1D5DB"
      pl="16px"
      pr="16px"
      borderRadius="4px"
      mb="15px"
    >
      <Flex justifyContent="space-between" pt="16px">
        <Text className="SCLabel">{label}</Text>
        <DeleteIcon color="red" className="cursor-pointer" onClick={onDelete} />
      </Flex>
      <Box mt="20px">
        <SelectFieldFromTemplateWithDelete
          fieldList={selectedTemplate?.fields || []}
          value={values}
          workFlowStatusList={
            selectedTemplate?.workflow?.workflow_status
              ?.filter((item) => item.id !== 'start_1')
              .map((ele) => ({ value: ele.id, label: ele.data.label })) || []
          }
          onChange={(value) => {
            onUpdatedValue(value);
          }}
          isRequired
          preventFieldRepeat
          hideBorder
          hideHeadersOnEmpty
          fieldNameLabel={QUICK_ACTION_LABEL.FIELD}
          fieldValueLabel={QUICK_ACTION_LABEL.VALUE}
          teamQueueMap={teamQueueMap}
          actorAssociationMap={selectedTemplate?.actors || []}
          showMentionComponent={false}
          parentFields={selectedTemplate?.fields || []}
          targetTemplateName={'self'}
          sourceTemplateName={selectedTemplate.name}
        />
      </Box>
    </Box>
  );
};

export default WrapperForFieldChange;

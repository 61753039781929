import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import FilterEditDrawer from './FilterEditDrawer';
import TableLayout from './TableLayout';
import {
  BOOLEAN_OPTIONS,
  associateBy,
  createMap,
  generateOptions,
  getOperatorIdMapForAllOperators,
} from './CustomDynamicSelect/customDynamicSelect.helpers';
import { CATEGORY_LABELS, FALLBACK_OPERATORS, FORM_MAP, columns, getDefaultFilters } from './filters.constants';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { patchDataForTemplate } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { getTransformedTemplateFields } from './templateFilters.utils';
import { getHierarchies } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';

const TemplateLevelFilter = () => {
  const { selectedTemplate } = useSelector(
    (reduxState) => reduxState.viewState
  );

  const defaultFilters = useMemo(
    () => getDefaultFilters()?.map((i) => ({ ...i, label: i?.name })),
    []
  );

  const [transformedFields, setTransformedFields] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);

  useEffect(() => {
    const fetchTransformedFields = async () => {
      try {
        const result = await getTransformedTemplateFields({
          selectedTemplate,
          hierarchies,
        });
        setTransformedFields(result);
      } catch (error) {
        console.error('Error fetching transformed fields:', error);
      }
    };

    fetchTransformedFields();
  }, [selectedTemplate, hierarchies]);

  useEffect(() => {
    getHierarchies(setHierarchies);
  }, []);

  const fields = useMemo(
    () => {
      const modifiedDefaultFilters = defaultFilters.map((filter) => {
        if (filter?.id === 'status_category') {
          const wfCategories = selectedTemplate?.workflow?.workflow_category.map((cat) => ({
            label: cat.name,
            value: cat.name,
          })) || [
            {
              label: CATEGORY_LABELS.TODO_CATEGORY,
              value: CATEGORY_LABELS.TODO_CATEGORY,
            },
            {
              label: CATEGORY_LABELS.IN_PROGRESS_CATEGORY,
              value: CATEGORY_LABELS.IN_PROGRESS_CATEGORY,
            },
            {
              label: CATEGORY_LABELS.DONE_CATEGORY,
              value: CATEGORY_LABELS.DONE_CATEGORY,
            },
          ];

          return {
            ...filter,
            defaultOptions: wfCategories
          };
        }
        return filter;
      }

      );
      return [...modifiedDefaultFilters, ...transformedFields];
    },
    [defaultFilters, transformedFields]
  );

  const [filters, setFilters] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();

  const currentEditData = useRef(null);

  // To create a map for the operators
  const operatorIdMap = useMemo(() => {
    if (!Array.isArray(fields)) {
      return getOperatorIdMapForAllOperators();
    }

    const temp = [];
    fields?.forEach((f) => {
      if (Array.isArray(f?.operators)) {
        temp.push(...f.operators);
      }
    }, []);

    return getOperatorIdMapForAllOperators({ operators: [...temp.flat(), ...FALLBACK_OPERATORS] });
  }, [fields]);

  const defaultFiltersIdMap = useMemo(
    () =>
      associateBy({
        options: defaultFilters,
        key: 'id',
      }),
    [defaultFilters]
  );

  // To create a map for the left options
  const leftOptionMap = useMemo(() => {
    const leftOptions = generateOptions({
      options: fields,
      label: 'label',
    });
    return createMap({
      options: leftOptions,
    });
  }, [fields]);

  // To create a map for the right options
  const rightOptionMap = useMemo(() => {
    const map = {};

    BOOLEAN_OPTIONS.forEach((b) => {
      map[b.value] = b.label;
    });

    if (Array.isArray(fields)) {
      fields.forEach((f) => {
        if (Array.isArray(f?.defaultOptions)) {
          f.defaultOptions.forEach((op) => {
            map[op.value] = op.label;
          });
        }

        const optionMap = f?.optionMap || {};
        if (typeof optionMap === 'object') {
          const opMap = Object.values(optionMap)?.flat();
          if (Array.isArray(opMap)) {
            opMap.forEach((op) => {
              if (op) {
                map[op.value] = op.label;
              }
            });
          }
        }
      });
    }
    return map;
  }, [fields]);

  const onEditClick = useCallback(
    (rowData = {}) => {
      currentEditData.current = rowData;
      setEditMode(true);
    },
    [defaultFiltersIdMap]
  );

  const onCreateButtonClick = useCallback(() => {
    onEditClick();
  }, [onEditClick]);

  const updateSelectedTemplate = useCallback(
    (updatedTemplate) => {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: updatedTemplate,
        })
      );
    },
    [dispatch]
  );

  const onDeleteData = useCallback(
    async (rowData) => {
      const newFil = selectedTemplate?.filters?.filter(
        (i) => i?.id !== rowData?.id
      );

      const res = await patchDataForTemplate({
        id,
        data: newFil,
        key: 'filters',
        defaultErrorMessage: 'Failed to delete the Filter values',
        successMessage: 'Successfully deleted',
      });

      const updatedTemplate = {
        ...selectedTemplate,
        ...(res?.response?.data || {}),
        filters: newFil,
      };
      updateSelectedTemplate(updatedTemplate);
    },
    [selectedTemplate, updateSelectedTemplate]
  );

  const onEditOrSave = useCallback(
    async (filterData) => {
      const data = cloneDeep(filterData);

      if (!data?.[FORM_MAP.DESCRIPTION]) {
        delete data[FORM_MAP.DESCRIPTION];
      }

      let tempFilter = [];
      if (Array.isArray(data)) {
        data?.forEach((itm) => {
          const dId = itm.id;
          const temp = selectedTemplate?.filters?.find((f) => f?.id === dId);
          if (temp?.id) {
            tempFilter.push(itm);
          }
        });
      } else if (data?.id) {
        tempFilter = selectedTemplate?.filters?.map((f) => {
          if (f?.id === data?.id) {
            return filterData;
          }

          return f;
        });
      } else {
        tempFilter = [
          ...(selectedTemplate?.filters || []),
          { ...data, id: uuidv4() },
        ];
      }
      const res = await patchDataForTemplate({
        id,
        data: tempFilter,
        key: 'filters',
        defaultErrorMessage: 'Failed to set the filter values',
        successMessage: 'Successfully set the filters',
      });

      const updatedTemplate = {
        ...selectedTemplate,
        ...(res?.response?.data || {}),
        filters: tempFilter,
      };
      updateSelectedTemplate(updatedTemplate);

      setFilters(tempFilter);

      setEditMode(false);
    },
    [selectedTemplate, id, updateSelectedTemplate]
  );

  useEffect(() => {
    setFilters(selectedTemplate?.filters || []);
  }, [selectedTemplate?.filters]);

  const colDefs = useMemo(() => {
    const cols = columns({
      onEdit: onEditClick,
      onDelete: onDeleteData,
      operatorIdMap,
      leftOptionMap,
      rightOptionMap,
      onToggleDefault: {},
      fromTemplate: true,
    });

    return cols;
  }, [
    onEditClick,
    onDeleteData,
    operatorIdMap,
    leftOptionMap,
    rightOptionMap,
    selectedTemplate,
  ]);

  // sortable drag & drop configs start
  const [isFilterSortable, setIsFilterSortable] = useState(false);
  const [reOrderedList, setReOrderList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const onFilterOrderSave = useCallback(() => {
    setIsloading(true);
    onEditOrSave(reOrderedList);
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, [reOrderedList]);
  const sortableConfig = {
    isSortable: isFilterSortable,
    showReorder: Array.isArray(filters) && filters?.length > 0,
    onToggleSortable: setIsFilterSortable,
    onOrderChange: setReOrderList,
    onOrderSave: onFilterOrderSave,
    btnTitle: { toggleOff: 'Reorder', toggleOn: 'Save Order', isLoading },
  };
  // sortable drag & drop configs end

  return (
    <div>
      <div>
        <FilterEditDrawer
          data={currentEditData.current || {}}
          onSave={onEditOrSave}
          fields={fields}
          open={editMode}
          setOpen={setEditMode}
          key={editMode.toString()}
          fromTemplate
          workflowCategories={[]}
        />

        <TableLayout
          tableData={filters}
          title="Filters"
          addBtnText="Add Filter"
          sortableConfig={sortableConfig}
          columns={colDefs}
          onCreateButtonClick={onCreateButtonClick}
        />
      </div>
    </div>
  );
};

export default TemplateLevelFilter;

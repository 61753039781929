import axiosInstance from '../axios/axios';

import CustomToast from '../../components/Notification/Notification';

const { addToast } = CustomToast();

export const showToast = ({ title, message, type }) => {
  addToast({
    title,
    message,
    type,
  });
};

export const getData = async ({
  endpoint,
}) => {
  try {
    const res = await axiosInstance.get(endpoint);
    const data = res?.data?.rows;

    if (!Array.isArray(data)) {
      throw new Error('Something went wrong');
    }

    return data;
  } catch (err) {
    return [];
  }
};

export const createData = async ({ endpoint, payload, successMessage, errorMessage }) => {
  try {
    const res = await axiosInstance.post(endpoint, {
      data: payload,
    });

    if (res?.status !== 201) {
      throw new Error('Something went wrong');
    }

    showToast({
      title: 'Success',
      message: successMessage,
      type: 'success'
    });

    return res?.data;
  } catch (err) {
    showToast({
      type: 'error',
      message: errorMessage,
      title: 'Error',
    });

    return null;
  }
};

export const updateData = async ({ payload, endpoint, successMessage, errorMessage }) => {
  try {
    const res = await axiosInstance.patch(endpoint, {
      data: payload,
    });

    if (res?.data?.rows_updated !== 1) {
      throw new Error('Something went wrong');
    }

    showToast({
      title: 'Success',
      type: 'success',
      message: successMessage,
    });

    return res?.data?.data;
  } catch (err) {
    showToast({
      type: 'error',
      message: errorMessage,
      title: 'error',
    });

    return null;
  }
};

// bulkUpdata Offer

export const bulkUpdateData = async ({ payload, endpoint, successMessage, errorMessage }) => {
  try {
    const res = await axiosInstance.put(endpoint, {
      data: payload,
    });

    showToast({
      title: 'Success',
      type: 'success',
      message: successMessage,
    });

    return res?.data?.data;
  } catch (err) {
    showToast({
      type: 'error',
      message: errorMessage,
      title: 'error',
    });

    return null;
  }
};

export const deleteData = async ({ endpoint, successMessage, errorMessage }) => {
  try {
    const res = await axiosInstance.delete(endpoint);

    if (res?.data?.rows_deleted !== 1) {
      throw new Error('Something went wrong');
    }

    showToast({
      type: 'success',
      message: successMessage,
      title: 'Success',
    });

    return true;
  } catch (err) {
    showToast({
      type: 'error',
      message: errorMessage,
      title: 'Error',
    });

    return false;
  }
};

export const BASE_SETTINGS_KEYS = {
  LABEL: 'label',
  SLUG: 'slug',
  DESCRIPTION: 'description',
  DEFAULT_VALUE: 'default_value',
  FORMULA: 'formula',
  IS_EDIT_SLUG: 'isEditSlug',
  NAME: 'name',
  VALUES: 'values',
  MEDIA_SIZE: 'media_size',
  STATIC_VALUES: 'staticValues',
  DYNAMIC_VALUES: 'dynamicValues',
  DYNAMIC_VALUES_LOADER: 'dynamicValuesLoader',
  MAX_NUMBER_ATTACHMENT: 'maximum_number_attachment',
  DYNAMIC_CURL: 'dynamicCurl',
  SPECIAL_FIELD: 'specialField',
  API_URL: 'api_url',
  KEY: 'key',
  VALUE: 'value',
  MODULE_NAME: 'module_name',
  OFFSET_VALUE: 'offset_value',
  FIELD_NAME_MAPPING: 'field_name',
  IS_WHITELIST: 'isWhiteList',
  LIST: 'list',
  SCOPE: 'scope',
  ACTOR_TYPE: 'actor_type',
  MEDIA_TYPE: 'media_type',
  IS_MULTI: 'isMulti',
  DEFAULTFIELDBOT: 'defaultFieldBot',
  IS_ADD_ATTACHMENT: 'isAddAttachmentAllowed',
  IS_PRIMARY_MEDIA_FIELD: 'isPrimaryMediaField',
  IS_ALLOW_TIME_FIELD: 'isAllowTimeField',
  DEPENDENT_TYPE: 'dependentType',
  IS_RESTRICTED: 'isRestricted',
  VALIDATIONS: 'validations',
};

export const ADVANCED_SETTINGS_KEYS = {
  PLACEHOLDER: 'placeholder',
  HINT_TEXT: 'hint_text',
  REGEX_PATTERN: 'regex_pattern',
  NUMBER_FORMAT: 'number_format',
  MIN_LENGTH: 'min_length',
  MAX_LENGTH: 'max_length',
  REQUIRED: 'required',
  UNIQUE: 'unique_field',
  READ_ONLY: 'read_only',
  HIDDEN: 'hidden',
  SEARCHABLE: 'is_searchable',
  REPORTABLE: 'is_reportable',
  INCLUDE_IN_REPORT: 'include_in_report',
  METRICS: 'metrics',
  MEDIA_TYPE: 'media_type',
  DATE_FORMAT: 'date_format',
  LOCALE: 'locale',
  SELECT_LOCALE: 'select_locale',
  MIN_ROW: 'min_rows',
  MAX_ROW: 'max_rows',
};

export const TEMPLATE_ROLE_SETTINGS = {
  VIEWERS: 'viewers',
  EDITORS: 'editors',
  EXPRESSION: 'expression',
  RESTRICTREAD: 'restrictRead',
  ENABLED_EXPRESSION: 'enableExpression',
  REQUIRED_EXPRESSION: 'requiredExpression'
};

/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
/* eslint-disable array-callback-return */

import TENANT from "../constants/tenant.constant";

/* eslint-disable import/prefer-default-export */
const envs = ['dev', 'test', 'staging', 'stage', 'alpha', 'beta', 'uat', '91social-dev'];
const currentHostname =
  window.location.hostname === 'localhost'
    ? (TENANT.LOCALHOST || 'lakers.test.virtualpeople.ai')
    : window.location.hostname;
function addEnv1(str, rootDomain = 'apps.actionable-science.com') {
  if (!str) return;
  if (str && !str.includes('://')) str = 'https://' + str;
  let str1 = 'https://' + str.split('://')[1].split('/')[0];
  let path = str.split('://')[1].split('/')[1]
    ? '/' + str.split('://')[1].split('/').slice(1).join('/')
    : '';
  let retStr = str;
  if (str1 && !str1.includes('localhost')) {
    envs.some((e1) => {
      let e = `.${e1}.`;
      if (currentHostname.includes(e) && !str1.includes(e)) {
        if (!str1.includes('.')) {
          // append entire root here.
          retStr = `${str1}-${e.slice(1)}${rootDomain}${path || ''}`;
        } else {
          // insert e after prefix domain...
          let x = str1.split('.');
          x.splice(1, 0, e.slice(1, -1));
          retStr = x.join('.') + (path || '');
        }
        if (e.includes('staging')) {
          retStr = retStr.replace('staging', 'uat');
        }
        if (e.includes('stage')) {
          retStr = retStr.replace('stage', 'preprod');
        }
        return retStr;
      }
    });
    return retStr;
  } else return str;
}

export const config = {
  tsUrl: addEnv1(
    TENANT.TENANT_URL || process.env.REACT_APP_GLOBALTenant_SERVICE_URL || "https://apigateway-service/api/v1",
    'apps.actionable-science.com'
  ),
  domain: currentHostname
};

/* eslint-disable camelcase */
import React, { useReducer, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomToast from '../../../components/Notification/Notification';
import { COLORS } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import CustomButton from '../../../components/Button/SimpleButton';
import { reducerForState } from '../../TicketTemplateBuilder/AddEditActorModal/helper';
import { associateWorkflow, updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import { dummyEdge, dummyNode, dummyStatusCategories } from '../DummyData';
import CustomInput from '../../../components/InputBox/Input';
import { createNewWorkflowInMasterList, payloadCrater, updateWorkflow } from './helper';
import { getTenantData } from '../../../helpers/tenantUrl';

const AddEditWorkflowList = ({
  edit,
  isAddEditWorkflowModal,
  setIsAddEditWorkflowModal,
  initStateForWorkflowList,
}) => {
  const [isError, setIsError] = useState(false);
  const [workflowState, workflowDispatch] = useReducer(
    reducerForState,
    initStateForWorkflowList
  );
  const location = useLocation();
  const { workflow_expanded_list } = useSelector((state) => state.viewState);
  const { editWorkFlowInListModal } = useSelector((state) => state.viewState);
  const dispatch = useDispatch();
  const { addToast } = CustomToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const showApiCallToastMessage = useCallback(
    (toastTitle, type, toastDescription) => {
      addToast({
        title: toastTitle,
        message: toastDescription,
        type,
        duration: 5000,
        isClosable: true,
      });
    },
    []
  );

  const addNewWorkFlowInTemplate = async () => {
    const response = await createNewWorkflowInMasterList(
      {
        ...workflowState,
        tenant_id: getTenantData()?.id,
        version: '1',
        workflow_status: [...dummyNode],
        workflow_transitions: [...dummyEdge],
        workflow_category: [...dummyStatusCategories],
      },
      'Failed to add a workflow in master list'
    );

    if (!response.error) {
      if (!location.pathname.includes('template-makers')) {
        const newData = [
          ...workflow_expanded_list.data,
          {
            ...response?.response,
          },
        ];
        dispatch(
          updateViewState({
            stateKey: 'workflow_expanded_list',
            value: {
              ...workflow_expanded_list,
              data: newData,
            },
          })
        );
        const params = {
          sourceRoute: 'workflow-list',
          id: response?.response?.id,
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `/work-flow?${queryString}`;
        navigate(url);
      } else {
        dispatch(associateWorkflow(
          {
            data: payloadCrater(response?.response),
            id,
            key: 'workflow',
            successMessage: 'Workflow Configuration Successful',
            defaultErrorMessage: 'Failed to configure workflow',
          }
        ));
      }
    } else {
      showApiCallToastMessage('Something went wrong', 'error', response.error);
      setIsError(true);
      return;
    }
    setIsAddEditWorkflowModal(false);
    dispatch(setActiveModal({ targetModalId: undefined }));
  };
  const updateWorkflowInMasterList = async () => {
    const editedWorkflowIndex = workflow_expanded_list.data.findIndex(
      (workflow) => workflow.id === editWorkFlowInListModal.id
    );
    if (editedWorkflowIndex !== -1) {
      const updatedWorkflows = _cloneDeep([...workflow_expanded_list.data]);
      const updateWorkflowWithoutId = _omit(_cloneDeep(workflowState), [
        'id',
        'version',
        'slug',
        'cloned_from',
        'tenant_id',
        'status',
        'deleted_seq',
        'created_by',
        'updated_by',
        'created_on',
        'updated_on',
      ]);
      const response = await updateWorkflow(
        updateWorkflowWithoutId,
        workflowState.id,
        'Failed to update a workflow in master list'
      );

      if (!response.error) {
        updatedWorkflows[editedWorkflowIndex] = response?.response?.data;
        dispatch(
          updateViewState({
            stateKey: 'workflow_expanded_list',
            value: {
              ...workflow_expanded_list,
              data: updatedWorkflows,
            },
          })
        );
        showApiCallToastMessage(
          'Update Workflow',
          'success',
          'Updated a workflow in master list'
        );
        setIsAddEditWorkflowModal(false);
        dispatch(setActiveModal({ targetModalId: undefined }));
      } else {
        showApiCallToastMessage(
          'Something went wrong',
          'error',
          response.error
        );
        setIsError(true);
      }
    }
  };
  const handleWorkflowName = (e) => {
    workflowDispatch({
      type: 'UPDATE_STATE',
      name: 'name',
      value: e.target.value,
    });
  };
  const handleAddOrUpdateButton = () => {
    if (edit) {
      updateWorkflowInMasterList();
    } else {
      addNewWorkFlowInTemplate();
    }
  };
  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={() => {
        setIsAddEditWorkflowModal(!isAddEditWorkflowModal);
        dispatch(setActiveModal({ targetModalId: undefined }));
      }}
      isOpen={isAddEditWorkflowModal}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setIsAddEditWorkflowModal(!isAddEditWorkflowModal);
                dispatch(setActiveModal({ targetModalId: undefined }));
              }}
            />
            <Text fontSize="16px">
              {edit ? 'Edit Workflow' : 'Add Workflow'}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Grid mt="38px" data-testid="AddEditWorkflowListContentGrid">
            <GridItem w="100%" data-testid="AddEditWorkflowListContentGridItem">
              {/* <FormLabel className={styles.formLabelForActor}>
              Name
              <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
            </FormLabel> */}
              <CustomInput
                label="Name"
                type="text"
                value={workflowState?.name}
                onChange={(e) => {
                  setIsError(false);
                  handleWorkflowName(e);
                }}
                isError={isError}
                errorMessage="Workflow name is already exist"
                isMandatory
                id="AddEditWorkflowListCustomInput"
              />
            </GridItem>
          </Grid>
        </DrawerBody>
        <DrawerFooter>
          <Flex gap="8px" data-testid="AddEditWorkflowListFooterFlex">
            <CustomButton
              variant="outline"
              pl="25px"
              pr="25px"
              buttonColor="#868E96"
              buttonText={LABELS.ACTIONS.CANCEL}
              style={{ border: 'none' }}
              _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
              onClick={() => {
                setIsAddEditWorkflowModal(false);
                dispatch(setActiveModal({ targetModalId: undefined }));
              }}
              size="lg"
              id="AddEditWorkflowListFooterCanceltn"
            />
            <CustomButton
              variant="solid"
              pl="25px"
              pr="25px"
              buttonColor="customBlue"
              isDisabled={isEmpty(workflowState) || !workflowState.name}
              buttonText={edit ? 'Save' : 'Continue'}
              onClick={handleAddOrUpdateButton}
              size="lg"
              id="AddEditWorkflowListFooternamebtn"
            />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
AddEditWorkflowList.propTypes = {
  edit: PropTypes.bool,
  isAddEditWorkflowModal: PropTypes.bool.isRequired,
  setIsAddEditWorkflowModal: PropTypes.func.isRequired,
  initStateForWorkflowList: PropTypes.object,
};
AddEditWorkflowList.defaultProps = {
  edit: false,
  initStateForWorkflowList: {},
};
export default AddEditWorkflowList;

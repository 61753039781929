import React, { useCallback, useMemo, useRef } from 'react';

import PropTypes from 'prop-types';

import FilterRoleMappingsDrawer from './RoleMappingDrawer';
import TableLayout from '../TableLayout';

import {
  associateBy,
  createMap,
} from '../CustomDynamicSelect/customDynamicSelect.helpers';
import { FORM_MAP } from '../filters.constants';
import { columns, enrichSelectedData } from './rolemapping.constants';

const RoleMapping = ({
  filters,
  roles,
  filterRoleMappings,
  onSave,
  onDelete,
  isOpen,
  setIsOpen,
}) => {
  const currentData = useRef(null);

  const enrichedFilters = useMemo(() => {
    if (!Array.isArray(filters)) {
      return [];
    }
    return filters.map((f) => ({
      label: f?.[FORM_MAP.NAME],
      value: f?.id,
    }));
  }, [filters]);

  const filterMap = useMemo(
    () => createMap({ options: enrichedFilters }),
    [filters]
  );

  const roleMap = useMemo(() => createMap({ options: roles }), [roles]);

  const availableRoles = useMemo(() => {
    const roleMappings = associateBy({
      options: filterRoleMappings,
      key: FORM_MAP.ROLES,
    });
  
    // {r.value !==r.label check duplicate roles ticket_agent and Ticket Agent
    // ticket_agent label is converted to Ticket Agent but for duplicate label and value both are same}
    return roles?.filter((r) => !roleMappings[r?.value] && r.value !==r.label);
  }, [filterRoleMappings, roles]);

  const onEdit = useCallback((rowData = {}) => {
    currentData.current = enrichSelectedData({ rowData, roleMap, filterMap });
    setIsOpen(true);
  }, [roleMap, filterMap, setIsOpen]);

  const colDefs = useMemo(() => {
    const cols = columns({
      onEdit,
      onDelete,
      filterIdMap: filterMap,
      roleMap,
    });

    return cols;
  }, [onEdit, onDelete, filterMap, roleMap]);

  const customSearchFn = useCallback((tableData, searchKey) => {
    if (!Array.isArray(tableData)) {
      return [];
    }

    return tableData.filter((d) => {
      const role = roleMap[d?.[FORM_MAP.ROLES]] || d?.[FORM_MAP.ROLES] || '';
      return role?.toLowerCase()?.includes(searchKey?.toLowerCase());
    });
  }, [roleMap]);

  return (
    <>
      <FilterRoleMappingsDrawer
        data={currentData.current}
        onSave={onSave}
        roles={availableRoles}
        filters={enrichedFilters}
        open={isOpen}
        setOpen={setIsOpen}
        key={isOpen?.toString()}
      />

      <TableLayout
        tableData={filterRoleMappings}
        title="Filter Role Mappings"
        addBtnText="Add Filter Role Mapping"
        columns={colDefs}
        onCreateButtonClick={() => onEdit()}
        customSearchFn={customSearchFn}
      />
    </>
  );
};

RoleMapping.propTypes = {
  filters: PropTypes.array.isRequired,
  filterRoleMappings: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

RoleMapping.defaultProps = {};

export default RoleMapping;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const CommonAttributesDrawer = ({
  commonAttributesState,
  openDrawer,
  setOpenDrawer,
  title,
  setCommonAttributesState,
  checkBoxList,
  isColumn,
}) => {
  const [stateValue, setStateValue] = useState(() => {
    // Initialize state with default values for checkboxes
    const initialState = {
      ...commonAttributesState,
      attributes: {
        searchable: true,
        sortable: true,
        visible: true,
        movable: true,
        ...commonAttributesState.attributes,
      },
    };
    return initialState;
  });
const [islLoading,setIsLoading]=useState(false)
  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Grid templateColumns="repeat(2, 1fr)" gap="30px">
            {checkBoxList.map((item) => (
              <GridItem key={item.key}>
                <Checkbox
                  isChecked={
                    isColumn
                      ? stateValue?.attributes[item.key]
                      : stateValue?.[item.key]
                  }
                  onChange={(e) => {
                    if (isColumn) {
                      setStateValue((pre) => ({
                        ...pre,
                        attributes: {
                          ...pre.attributes,
                          [item.key]: e.target.checked,
                        },
                      }));
                    } else {
                      setStateValue((pre) => ({
                        ...pre,
                        [item.key]: e.target.checked,
                      }));
                    }
                  }}
                  data-testid="TicktFieeldContentDependentCheckbox"
                >
                  {item.name}
                </Checkbox>
              </GridItem>
            ))}
          </Grid>
        </DrawerBody>
        <DrawerFooter>
          <SecondaryCustomButton
            mr="15px"
            buttonText={LABELS.ACTIONS.DISCARD}
            onClick={() => {
              setOpenDrawer(false);
            }}
            size="lg"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={() => {
              setCommonAttributesState(stateValue);
              setIsLoading(true)
              setTimeout(() => {
                setIsLoading(false)
                setOpenDrawer(false);
              }, 1000);
              
            }}
            size="lg"
            isLoading={islLoading}
            data-testid="linkedMetaFieldButtonAdd"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

CommonAttributesDrawer.propTypes = {
  commonAttributesState: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setCommonAttributesState: PropTypes.func.isRequired,
  checkBoxList: PropTypes.array.isRequired,
  isColumn: PropTypes.bool,
};
CommonAttributesDrawer.defaultProps = {
  isColumn: false,
};
export default CommonAttributesDrawer;

import { SPECIAL_FIELD_TYPE } from '../../pages/TenantFilters/filters.constants';
import { FieldTypes } from './types';

export const FIELD_LABELS = {
  DEFAULT: 'Input',
  TEXT: 'Text',
  MAIL: 'Mail',
  NUMBER: 'Number',
  DATE: 'Date',
  RICH_TEXT: 'Rich Text',
  BOOLEAN: 'Boolean',
  FIELDSETS: 'Fieldsets',
  METRICS: 'Metrics',
  MEDIA: 'Media',
  TAGS: 'Tags',
  DROPDOWN: 'Select',
  RELATION: 'Relation',
  COMPOSITE: 'Composite Field',
  LABEL: 'Label',
  TEXT_AREA: 'Text Area',
};

export const FIELD_VS_INPUT_TYPE = {
  TEXT: 'input',
  LABEL: 'input',
  TEXT_AREA: 'input',
  EMAIL: 'input',
  RICH_TEXT: 'input',
  DROPDOWN: 'multi-select',
  TAGS: 'multi-select',
  NUMBER: 'input-number',
  DATE: 'date-picker',
  BOOLEAN: 'select',
  ACTOR: 'select',
};

export const FIELD_DESCRIPTION_TEXTS = {
  DEFAULT: 'Input',
  TEXT: 'Single Line text like title or name',
  MAIL: 'Email field with validation format',
  NUMBER: 'Number (Integer, Float, Decimal)',
  DATE: 'A date picker with format selection',
  RICH_TEXT: 'Multi line text like description ',
  BOOLEAN: 'Yes or No, 1 or 0, true or false',
  METRICS: 'Formula for Logic and Measurements',
  MEDIA: 'Files like image, videos, audio',
  TAGS: 'Labels used to categorize or identify content',
  DROPDOWN: 'Dropdown Menu with multiple options',
  RELATION: 'Relations',
  COMPOSITE: 'Composite Fields',
  FIELDSETS: 'Set of fields group together',
  CURL: 'cURL',
  LABEL: 'Read only text conveying information',
  TEXT_AREA: 'Area defined for text content',
  WORK_FLOW_LINK:
    // eslint-disable-next-line quotes
    "Note: To establish workflow connections, incorporate a dropdown field with the 'Special Field' type designated as 'Workflow State' within the template.",
  PARENT_CHILD_INFO:
    'Please ensure that all children are approved before approving this template',
};

export const FIELD_PROPERTY_LABELS = {
  NAME: 'Slug',
  LABEL: 'Label',
  RANK: 'Rank',
  DEFAULT_VALUE: 'Default Value',
  DESCRIPTION: 'Description',
  PLACEHOLDER: 'Placeholder',
  HINT_TEXT: 'Hint Text',
  REQUIRED: 'Required',
  READONLY: 'Read Only',
  HIDDEN: 'Hidden',
  SEARCHABLE: 'Searchable',
  METRICS: 'Metrics',
  FORMULA: 'Formula',
  MEDIA_TYPE: 'Media Type',
  SIZE: 'Size',
  MAX_NUMBER_ATTACHMENT: 'Max no of Attachments (0 for unlimited)',
  MAX_SIZE: 'Max Size (in MB)',
  REGEX: 'Regex Pattern',
  MIN_LENGTH: 'Min Length',
  MAX_LENGTH: 'Max Length',
  UNIQUE: 'Unique Field',
  REPORTABLE: 'Reportable',
  INCLUDE_IN_REPORT: 'Include in Reports',
  DATE_FORMAT: 'Date Format',
  NUMBER_FORMAT: 'Number Format',
  VALUES: 'Values',
  EMAIL: 'Email',
  DROPDOWN: 'Dropdown',
  STATIC: 'Static',
  DYNAMIC: 'Dynamic',
  DEPENDENT: 'Dependent',
  SPECIAL_FIELD: 'Special Field',
  SPECIAL_FIELD_LABEL: 'Special Field Type',
  MIN_NO_TAG: 'Min no of Tags',
  MAX_NO_TAG: 'Max no of Tags',
  DISPLAY_LOCALE: 'Display Locale',
  SELECT_LOCALE: 'Select Custom Locale',
  MIN_VALUE: 'Min Value',
  MAX_VALUE: 'Max Value',
  CURL: 'cURL',
  COLLECTION_LABEL: 'Collection',
  API_URL: 'API URL',
  KEY: 'Key',
  VALUE: 'Value',
  LABEL_NAME: 'Name',
  MODULE_NAME: 'Module Name',
  MIN_ROW: 'Min Row',
  MAX_ROW: 'Max Row',
  OFFSET_POSITIVE: 'Offset Positive',
  OFFSET_NEGATIVE: 'Offset Negative',
  STATIC_VALUES: 'Static Values',
  WHITELIST_QUEUE: 'Whitelist Queue',
  SCOPE: 'scope',
  ADD_ATTACHMENT: 'Allow Attachment',
  ADD_PRIMARY_MEDIA: 'Primary Media Field',
  ALLOW_TIME_FIELD: 'Allow Time Field',
  DEPENDENT_TYPE: 'Dependent Type',
  HIERARCHY: 'Hierarchy',
  SHOW_ON_MATCH: 'Make visible on matching these conditions',
  ENABLE_ON_MATCH: 'Make editable on matching these conditions',
  REQUIRED_ON_MATCH: 'Make required on matching these conditions',
};

export const SPECIAL_FIELDS_DROPDOWN = [
  { value: SPECIAL_FIELD_TYPE.WORKFLOW, label: 'Workflow State' },
  { value: SPECIAL_FIELD_TYPE.QUEUE, label: 'Queue' },
  { value: SPECIAL_FIELD_TYPE.ACTORS, label: 'Actor' },
  { value: SPECIAL_FIELD_TYPE.SOURCE, label: 'Source' },
];

export const SPECIAL_FIELDS_DROPDOWN_MAP = SPECIAL_FIELDS_DROPDOWN.reduce(
  (prev, curr) => {
    prev[curr.value] = curr.label;
    return prev;
  },
  {}
);

export const FIELD_PROPERTY_SUB_LABELS = {
  MEDIA_SIZE: ' (in MB)',
  VALUES_IN_LINE: ' (one line per value)',
};

export const TEMPLATE_PROPERTY_LABELS = {
  NAME: 'Name',
  LABEL: 'Label',
  DEFAULT_VALUE: 'Default Value',
  DESCRIPTION: 'Description',
  NESTED_TYPE: 'Nested Type',
  SINGLE_TYPE: 'Single Type',
  TEMPLATE_TYPE: 'Template Type',
};

export const FIELD_ACTION_LABELS = {
  EMAIL_EDIT: 'Edit Email Ticket Field',
  EMAIL_ADD: 'Add Email Ticket Field',
  NUMBER_EDIT: 'Edit Number Ticket Field',
  NUMBER_ADD: 'Add Number Ticket Field',
  DATE_EDIT: 'Edit Date Ticket Field',
  DATE_ADD: 'Add Date Ticket Field',
  TEXT_ADD: 'Add Text Ticket Field',
  TEXT_EDIT: 'Edit Text Ticket Field',
  RICH_TEXT_ADD: 'Add Rich Text Ticket Field',
  RICH_TEXT_EDIT: 'Edit Rich Text Ticket Field',
  BOOL_TEXT_ADD: 'Add Boolean Text Ticket Field',
  BOOL_TEXT_EDIT: 'Edit Boolean Text Ticket Field',
  METRIC_EDIT: 'Add Metrics Ticket Field',
  METRIC_ADD: 'Edit Metrics Ticket Field',
  MEDIA_EDIT: 'Edit Media Ticket Field',
  MEDIA_ADD: 'Add Media Ticket Field',
  TAG_EDIT: 'Edit Tag Ticket Field',
  TAG_ADD: 'Add Tag Ticket Field',
  DROPDOWN_TEXT_EDIT: 'Edit Dropdown Ticket Field',
  DROPDOWN_TEXT_ADD: 'Add Dropdown Ticket Field',
  FIELDSETS_EDIT: 'Edit Fieldsets',
  FIELDSETS_ADD: 'Add Fieldsets',
  LABEL_EDIT: 'Edit Label Ticket Field',
  LABEL_ADD: 'Add Label Ticket Field',
  TEXT_AREA_EDIT: 'Edit Text Area Ticket Field',
  TEXT_AREA_ADD: 'Add Text Area Ticket Field',
};

export const TITLE_LABELS = {
  BASIC_SETTINGS: 'Basic Settings',
  ROLE_SETTINGS: 'Role Settings',
  FIELD_SETTINGS: 'Field Settings',
  ADVANCED_SETTINGS: 'Advanced Settings',
  TEMPLATE_TYPE: 'Template Type',
  INFO: 'Information',
  WARNING: 'Warning',
  TICKET_SERVICE_CATALOGUE: 'Ticket Service Catalog',
  TEMPLATE_SERVICE_CATALOGUE: 'Template Service Catalog',
  ALL_TEMPLATES: 'All Templates',
  ALL_FIELDSETS: 'Fieldsets',
  ALL_COMPOSITE_FIELDS: 'Composite Fields',
  SAVED_SUCCESSFULLY: 'Saved Successfully',
  DELETED_SUCCESSFULLY: 'Deleted Successfully',
  FIELD_TABLE_NAME: 'Name',
  FIELD_TABLE_TYPE: 'Type',
  FIELD_TABLE_ACTIONS: 'Actions',
  NO_FIELDS_ADDED: 'You have not added a field in the template',
  NO_TEMPLATES_CREATED: 'You have not created a template',
  CLICK_ADD_NEW_FIELD: 'Click ‘Add New Field’ to get started',
  CLICK_ADD_NEW_TEMPLATE: 'Click ‘Create New Template’ to get started',
  INCIDENT_TICKET: 'Incident Ticket',
  SELECT_FIELD_FOR_TEMPLATE: 'Select a field for your ticket type',
  SELECT_FIELDSET_TO_ADD_IN_TEMPLATE: 'Select Fieldsets to add in Template',
  SELECT_FIELDS_TO_ADD_IN_TEMPLATE: 'Select Field’s to add in Template',
  PROFILE: 'Profile',
  ADMIN_USER: 'Admin',
  SETTINGS: 'Settings',
  SIGN_OUT: 'Sign Out',
  PAGE_NOT_FOUND_HERO_TEXT: 'Oops! Page not found',
  PAGE_NOT_FOUND_DESCRIPTION: 'The page you are looking for doesn’t exist.',
  PAGE_ERROR_HERO_TEXT: 'Oops! Something went wrong',
  PAGE_ERROR_DESCRIPTION:
    'The page didn’t load properly. Please reload the page or go back.',
  ACCESS_DENIED_HERO_TEXT: 'Access Denied!',
  ACCESS_DENIED_DESCRIPTION: 'You don’t have permission to access this page.',
  DELETE_TEMPLATE_WARNING: 'Are you sure you want to delete this template?',
  FAILED: 'Failed',
  COLLECTION_TYPE: 'Collection Type',
  DELETE_STATUS_WARNING: 'Are you sure you want to delete this status?',
  CATEGORY: 'Category',
  ADD_NEW_CATEGORY: 'Add New Category',
  EDIT_STATUS: 'Edit Status',
  NAME: 'Name',
  COLOR: 'Color',
  DESCRIPTION: 'Description',
  FOR_TRANSTION: 'For Transition',
  MOVE_TO_NEXT_AFTER: 'Move to next state after',
  ALL_APPROVALS: 'All approvals',
  N_APPROVALS: 'approval(s)',
  TRANSTION_PEOPLE_DESCRIPTION:
    'Only allow these people to move issue using this transition',
  LOGGED_FROM: 'Anyone logged in from',
  EXPRESSIONS: 'Expressions',
  CHECK_ISSUE_RULE: 'Check that the issue has been through',
  INCLUDE_ISSUE_STATUS: 'Include the issue’s current status',
  REVERSE_THE_RULE: 'Reverse the rule',
  TRANSITION_ISSUE_HAS_NOT:
    ' Only allow the transition if the issue hasn’t been through the selected statuses',
  DIFFERENT_TRANSITION_RULE:
    'To add a rule to a different transition, select the transition in your workflow diagram.',
  CHECK_PERMISSION: 'Check for this permission',
  FOR_THIS_FIELD: 'For this field',
  REVIEW_IT_VALUE: 'Review its value as',
  CHECK_IF_IT: 'Check if it',
  THIS_VALUE: 'This value',
  RESTRICT_TRANSTION: 'Restrict Transition',
  VALIDATE_DETAILS: 'Validate Details',
  PERFORM_ACTIONS: 'Perform Actions',
  ALL_RULES: 'All Rules',
  STATUS: 'Status',
  STATUS_CAPTURE: 'Statuses capture the stages of your working process.',
  STATUS_MUTLIPLE_WORKFLOW: 'This status is shared across multiple workflows.',
  CATEGORY_CAPITAL: 'CATEGORY',
  CATEGORY_GROUP: 'Categories group multiple statuses of the same kind.',
  TRANSITION_CONNECT:
    'Transitions connect statuses. They represent actions people take to move issues through your workflow.',
  TRANSITIONS: 'TRANSITIONS',
  DELETE_RULE_WARNING: 'Are you sure you want to delete this rule?',
  DELETE_EDGE_WARNING: 'Are you sure you want to delete this transition?',
  DELETE_TEMPLATE_LAYOUT: 'Are you sure you want to delete this layout ?',
  NO_LAYOUTS: 'No layouts are created for this template.',
  TICKET_TYPE: 'Ticket Type',
  TICKETS_TYPE: 'Tickets Type',
  SHOW_RECENTLY_VIEWED: 'Show Recently Viewed',
  CUSTOM_DELETE: 'Are you sure you want to delete this?',
  EDIT_CATEGORY: 'Edit Category',
  SCOPE_TYPE: 'Scope Type',
  TICKETS_TYPES: 'Ticket Types',
  RANK: 'Rank',
  BOT_INTEGRATION: 'Bot Integration',
};

export const DESCRIPTION_TEXTS = {
  CHANGE_NESTED_TEMPLATE_TO_SINGLE_WARNING:
    'Cannot change a nested template to a single type if the parent template contains any child templates.',
  FIELDSET_INFORMATION:
    'Fieldsets will get automatically injected to new templates created',
};

export const ACTION_LABELS = {
  EDIT: 'Edit',
  VIEW_JSON: 'View JSON',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  COPY: 'Copy',
  CLOSE: 'Close',
  FEEDBACK: 'Feedback',
  HELP: 'Help',
  CONTINUE: 'Continue',
  DOWNLOAD: 'Download',
  FINISH: 'Finish',
  EDIT_TEMPLATE: 'Edit Ticket Type',
  CREATE_NEW_TEMPLATE: 'Create New Ticket Type',
  CREATE_NEW_FIELDSET: 'Create New Fieldset',
  CREATE_NEW_COMPOSITE: 'Create New Composite Fields',
  CREATE_BUTTON_NEW_COMPOSITE: 'Create Composite Field',
  SEARCH_FOR_A_FIELD: 'Search for a field, type...',
  SEARCH_FOR_A_TEMPLATE: 'Search template..',
  ADD_A_FIELD: 'Add a Field',
  ADD_NEW_FIELD: 'Add New Ticket Field',
  CONFIGURE_VIEW: 'Configure the view',
  GO_HOME: 'Go Back to Home',
  GO_BACK: 'Go Back',
  RELOAD: 'Reload',
  LIGHT: 'Light',
  DARK: 'Dark',
  DELETE: 'Delete',
  ADD_STATES: 'Add states',
  ADD_TRANSITIONS: 'Add transitions',
  UPDATE_WORKFLOW: 'Update Workflow',
  DISCARD_CHANGES: 'Discard changes',
  ADD: 'Add',
  CREATE_TRANSITION: 'Create Transition',
  ADD_NEW_CATEGORY: 'Add New Category',
  MANAGE_CATEGORY: 'Manage Category ',
  ADD_RULE: 'Add Rule',
  EDIT_RULE: 'Edit Rule',
  EXECUTE: 'Execute',
  Notification: 'Notification',
  FETCH: 'Fetch',
  DISCARD: 'Discard',
  ADD_FIELD_IN_MASTER: 'Add Field in Master List',
  UPDATE: 'Update'
};

export const MEDIA_SIZES = {
  FILE_SIZE: 4,
  MAX_NUMBER_ATTACHMENT: 10,
};

export const COMPONENT_LABELS = {
  UPLOAD: 'Upload',
  DROP_IMAGES_HERE: 'Drop images here',
  OR_CLICK_UPLOAD: 'or click to upload',
};
export const FIELD_PROPERTY_ERROR = {
  NAME: 'Slug is required',
  LABEL: 'Label is required',
  RANK: 'Rank is required',
  SIZE: 'Size is required',
  VALUES: 'Values is required',
  EMAIL_FORMAT: 'Invalid email format',
  NUMBER_FORMAT: 'Value is Invalid',
  MAX_LENGTH: 'Value should be greater then min',
  MIN_LENGTH: 'Value should be less then max',
  VALUE_USED: 'Value is already used',
  VALUE_RANGE: 'Value should be in range',
  LABEL_NAME: 'Name is required',
  MAX_ROW: 'Value should be greater then min row',
  MIN_ROW: 'Value should be less then max row',
  OFFSET_POSITIVE: 'Value should be positive',
  OFFSET_NEGATIVE: 'Value should be negative',
  MEDIA_SIZE: 'Size should be between 0.1 and 4 MB',
  MAX_NUMBER_ATTACHMENT: 'Max no. of attachments can be zero or positive number',
  DYNAMIC_CURL: 'Invalid endpoint',
  DUPLICATE_FIELD_NAMES: `Duplicate field labels aren't allowed`,
  VALUE: 'Value is required',
  MEDIA_TYPE: 'Media type is required',
};
export const FIELD_VALIDATE_KEY = {
  SLUG: 'slug',
  LABEL: 'label',
  MEDIA_SIZE: 'media_size',
  VALUES: 'values',
  DEFAULT_VALUE: 'default_value',
  MAX_LENGTH: 'max_length',
  MIN_LENGTH: 'min_length',
  MIN_ROW: 'min_rows',
  MAX_ROW: 'max_rows',
  OFFSET_VALUE: 'offset_value',
  MAX_NUMBER_ATTACHMENT: 'maximum_number_attachment',
  DYNAMIC_CURL: 'dynamicCurl',
  MEDIA_TYPE: 'media_type',
};
export const TEMPLATE_VALIDATE_KEY = {
  NAME: 'name',
};
export const TEMPLATE_PROPERTIES_ERROR = {
  NAME: 'Value is required',
  VALUE_USED: 'Value is already used',
};

export const LAYOUT_PROPERTY_LABELS = {
  NAME: 'Name',
  LAYOUT_TYPE: 'Layout Type',
  VIEW_TYPE: 'View Type',
  MIN_WIDTH: 'Min Width',
  MAX_WIDTH: 'Max Width',
};

export const LAYOUT_FIELD_ERROR = {
  NAME_ERROR: 'Name is required',
  LAYOUT_TYPE_ERROR: 'Layout is required',
  MIN_WIDTH_ERROR: 'Min Width is required',
  MAX_WIDTH_ERROR: 'Max Width is required',
  PREFIX_ERROR: 'Prefix is Required',
  RUNNING_NUMBER_ERROR: 'Ticket Digits is required',
  DEFAULT_NAME_ERROR: `Name can't be 'Default'`,
  COLLABORATORS: 'Collaborators is required',
};

export const ADD_FIELD_FROM_BANK_ERROR = {
  FIELD: 'Field is required'
};

export const LABELS = {
  FIELDS: FIELD_LABELS,
  FIELD_PROPERTIES: FIELD_PROPERTY_LABELS,
  FIELD_ACTIONS: FIELD_ACTION_LABELS,
  FIELD_DESCRIPTIONS: FIELD_DESCRIPTION_TEXTS,
  TEMPLATE_PROPERTIES: TEMPLATE_PROPERTY_LABELS,
  LAYOUT_PROPERTIES: LAYOUT_PROPERTY_LABELS,
  TITLE: TITLE_LABELS,
  ACTIONS: ACTION_LABELS,
  DESCRIPTIONS: DESCRIPTION_TEXTS,
  COMPONENTS: COMPONENT_LABELS,
  ERROR: FIELD_PROPERTY_ERROR,
  TEMPLATE_ERROR: TEMPLATE_PROPERTIES_ERROR,
  LAYOUT_FIELD_ERROR,
  FIELD_ADD_ERROR: ADD_FIELD_FROM_BANK_ERROR,
};
export const SUB_LABELS = {
  FIELDS: FIELD_PROPERTY_SUB_LABELS,
};

export const FieldTypeToLabelMap = {
  [FieldTypes.DEFAULT]: FIELD_LABELS.DEFAULT,
  [FieldTypes.TEXT]: FIELD_LABELS.TEXT,
  [FieldTypes.EMAIL]: FIELD_LABELS.MAIL,
  [FieldTypes.NUMBER]: FIELD_LABELS.NUMBER,
  [FieldTypes.DATE]: FIELD_LABELS.DATE,
  [FieldTypes.RICH_TEXT]: FIELD_LABELS.RICH_TEXT,
  [FieldTypes.BOOLEAN]: FIELD_LABELS.BOOLEAN,
  [FieldTypes.METRICS]: FIELD_LABELS.METRICS,
  [FieldTypes.MEDIA]: FIELD_LABELS.MEDIA,
  [FieldTypes.TAGS]: FIELD_LABELS.TAGS,
  [FieldTypes.DROPDOWN]: FIELD_LABELS.DROPDOWN,
  [FieldTypes.RELATION]: FIELD_LABELS.RELATION,
  [FieldTypes.COMPOSITE]: FIELD_LABELS.COMPOSITE,
  [FieldTypes.FIELDSETS]: FIELD_LABELS.FIELDSETS,
  [FieldTypes.LABEL]: FIELD_LABELS.LABEL,
  [FieldTypes.TEXT_AREA]: FIELD_LABELS.TEXT_AREA,
};

export const getFieldLabel = (fieldType) =>
  FieldTypeToLabelMap[fieldType] || FIELD_LABELS.DEFAULT;

export const FIELD_SLUG = {
  FIELD_TAG: 'field_tag',
  FIELD_MEDIA: 'field_media',
  FIELD_BOOL: 'field_boolean',
  FIELD_EMAIL: 'field_mail',
  FIELD_NUM: 'field_number',
  FIELD_RICH_TEXT: 'field_rich_text',
  FIELD_TEXT: 'field_text',
  FIELD_DATE: 'field_date',
  FIELD_DROPDOWN: 'field_dropdown',
  FIELD_FIELDSET: 'field_fieldset',
  FIELD_LABEL: 'field_label',
  FIELD_TEXT_AREA: 'field_text_area',
};

export const DEFAULT_VALUE = {
  EMPTY_STRING: '',
};

export const WORK_FLOW_ACTION_LABEL = {
  TO_DO: 'To-do Status',
  IN_PROGRESS: 'In-progress Status',
  DONE: 'Done Status',
  TRANSITION: 'Transition',
  RULE: 'Rule',
  SOURCE: 'source',
  TARGET: 'target',
  SOURCE_HANDLE: 'sourceHandle',
  TARGET_HANDLE: 'targetHandle',
  CUSTOM_EDGES: 'customEdges',
};

export const DONE_MODAL = {
  TITLE: 'Add a done status',
  SUB_TITLE:
    'Statuses capture the stages of your working process. Issues in a done status represent completed work.',
};

export const IN_PROGRESS_MODAL = {
  TITLE: 'Add an in-progress status',
  SUB_TITLE:
    ' Statuses capture the stages of your working process. Issues in an in-progress status represent ongoing work.',
};

export const TO_DO_MODAL = {
  TITLE: 'Add a to-do status',
  SUB_TITLE:
    'Statuses capture the stages of your working process. Issues in a to-do status represent work that has not started yet.',
};

export const ADD_TRANSITION = {
  TITLE: 'Create Transition',
  SUB_TITLE: `Transitions connect statuses. They represent actions people take to move issues through your workflow. They also appear as drop zones when people move cards across your project's board. `,
};

export const CUSTUM_TYPE = {
  CUSTOM: 'custom',
};

export const ROLE_TITLE = {
  MOVE_ISSUE: 'Restrict who can move an issue',
  SPECIFIC_STATUS:
    'Restrict to when an issue has been through specific status ',
  SPECIFIC_VALUE: 'Restrict to when a field has a specific value',
  SPECIAL_PERMISSION: 'Validate that people have special permission',
  ASSIGN_ISSUE: 'Assign an issue',
  UPDATE_ISSUE: 'Update an issue field',
  RESTRICT_LINKED_TICKET: 'Restrict when linked tickets are in specific condition',
};
export const ROLE_DESCRIPTIONS = {
  MOVE_ISSUE:
    'Only allow certain people to move an issue using a particular transition',
  SPECIFIC_STATUS:
    'Only allow an issue to be moved using a particular transition if an issue has had a specific status',
  SPECIFIC_VALUE:
    'Only allow an issue to be moved using a particular transition when a field is a specific value or range of values',
  SPECIAL_PERMISSION:
    'Ensure people have a specific permission when moving an issue using a  particular transition',
  ASSIGN_ISSUE:
    'Automatically assign an issue to someone after moving the issue using a particular transition',
  UPDATE_ISSUE:
    'Automatically change the value of an issue field after moving the issue using a particular transition',
  RESTRICT_LINKED_TICKET: 'Only allow an issue to be moved using a particular transition when linked items of a specific relation type meet specified conditions.'
};
export const RULE_SPECIFIC_STATUS_KEY = {
  TRANSITION_ID: 'transition_id',
  STATUS_ID: 'status_id',
  INCLUDE_STATUS: 'include_status',
  REVERSE_RULE: 'reverse_rule',
};
export const RULE_MODAL_TYPE = {
  MOVE_ISSUE: 'moveIssue',
  SPECIFIC_STATUS: 'specificStatus',
  SPECIFIC_VALUE: 'specificValue',
  RESTRICT_LINKED_TICKET: 'restrictLinkedTicket'
};
export const DATE_DEFAULT_VALUE = {
  OFFSET_POSITIVE: 'POSITIVE_OFFSET',
  OFFSET_NEGATIVE: 'NEGATIVE_OFFSET',
};

export const HEADER_TOOLTIP_LABELS = {
  SWITCH: 'Switch between dark & light theme',
  FEEDBACK: 'Feedback',
  HELP: 'Help',
  PROFILE: 'Profile',
  NOTIFICATIONS: 'Notifications',
};

export const CARD_LISTS_TOOLTIP_LABELS = {
  EDIT: 'Edit',
  CLONE: 'Clone',
  DELETE: 'Delete',
  UNMARK_AS_FAVORITE: 'Unmark as favorite',
  MARK_AS_FAVORITE: 'Mark as favorite',
  SHOW_LIST_VIEW: 'Show List View',
  SHOW_CARD_VIEW: 'Show Card View',
  RESET: 'Refresh',
  QUICK_FILTERS: 'Quick Filters',
  ASCENDING: 'ascending',
  DESENDING: 'desending',
  SORT: 'Sort',
};

export const TEMPLATE_TOOLTIPS = {
  ONLY_OWNERS: 'Only owners can approve/reject template',
  EDIT_RESTRICTION: `You don't have access to Edit the template`,
};

export const QUICK_FILTERS_LABELS = {
  SAVED_FILTERS: 'Saved Filters',
  CREATE_FILTERS: 'Create Filters',
  CREATE_FILTER: 'Create Filter',
  REMOVE_DEFAULT: 'Remove Default',
  MARK_AS_DEFAULT: 'Mark As Default',
  APPLY_FILTER: 'Apply Filter',
  FILTER_NAME: 'Filter Name',
  STATUS: 'Status',
  FILTERS: 'Filters',
  CLEAR_FILTER: 'Clear Filter',
  APPLY: 'Apply',
  SAVE_AND_APPLY: 'Save & Apply Filter',
  DELETE_FILTER: 'Delete Filter',
  DELETE_CONFIRM_MESSAGE: `Are you sure? You can't undo this action afterwards.`,
};

export const COMMUNICATION_TEMPLATE_LABEL = {
  ERROR_TITLE: 'Error',
  MSG: `Unable to delete email communication because it's being used in a template.`,
  TOAST_TYPE: 'error',
};

export const MAIL_CONFIG = {
  REQUIRED_MAPPING_LABEL: 'Required Mappings',
  FIELD_MAPPING_LABEL: 'Mapping of Email Properties to Ticket Fields',
  OPTIONAL_MAPPING_LABEL: 'Optional Mappings',
};

export const DATE_VALIDATION_LABELS = {
  RESTRICTIONS_TOGGLE_LABEL: 'Restrictions',
  ADD_RESTRICTIONS_BUTTON: 'Add Restrictions',
  RESTRICTION_DRAWER_TITLE: 'Restrictions',
  DEFAULT_SECTION_HEADER_TITLE: 'Default',
  CONDITIONAL_SECTION_HEADER_TITLE: 'Condition',
  ADD_RULE_BUTTON: 'Add Rule',
  SAVE_RULE_BUTTON: 'Save Rule',
  RESTRICTION_RULES: 'Restriction Rules',
  APPLIED_CONDITIONS: 'Applied Conditions',
  RULES_APPLIED: 'Rules Applied',
  ADD_VALIDATION_CONDITION: 'Add Validation Condition',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  ADD_RULE_MODAL_TITLE: 'Add Rule',
  EDIT_RULE_MODAL_TITLE: 'Edit Rule',
  FROM_DATE_TOOLTIP: 'Keeping this blank would allow picking any date less than To Date',
  TO_DATE_TOOLTIP: 'Keeping this blank would allow picking any date greater than From Date',
  RESTRICTED: 'Restricted',
  ALLOW_DATES_UP_TO: 'Allow Dates Up To',
  FROM_DATE: 'From Date',
  TO_DATE: 'To Date',
  ADD_CONDITION: 'Add Condition',
  NUMBER_OF_DAYS: 'Number of Days',
  TIMEFRAME: 'Timeframe',
  REFERENCE_DATE: 'Reference Date',
  FUNCTION: 'Function',
  NUMBER_OF_DAYS_TOOLTIP: 'Number of days to compare with the reference date',
};

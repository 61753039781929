import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../components/Button/SimpleButton';
import CustomInput from '../../components/InputBox/Input';
import CustomSwitch from '../../components/Switch/Switch';
import { getTenantData } from '../../helpers/tenantUrl';
import { getIntentGroups, keys, setData } from '../../redux/FieldDefaults';
import { COLORS } from '../../utils/enums/colors';
import {
  getDataForIndeterminateCheckbox,
  getIntent,
} from '../CreateEditIntentBuilder/intent.service';
import { updatedDataForIntentGroup } from '../CreateEditIntentBuilder/utils';
import Cards from './Cards';
import styles from './servicePortalConfiguration.module.scss';
import {
  getConfiguration,
  getOffers,
  postImage,
  saveConfiguration,
  updateConfiguration,
} from './servicePortalConfiguration.service';
import Uploader from './Uploader';
import { convertIntentIdToPath, importAll, initialState } from './utils';

const fileTypes = ['JPG', 'PNG', 'GIF'];

const ServicePortalConfiguration = () => {
  const dispatch = useDispatch();
  const intentGroups = useSelector(getIntentGroups);
  const isUseEffectTriggered = useRef(false);
  const [config, setConfig] = useState(initialState);
  const [updateId, setUpdateId] = useState(0);
  const [offers, setOffers] = useState([]);
  const [listOfIcons, setListOfIcons] = useState([]);
  const [configError, setConfigError] = useState({});

  const intentOptions = useMemo(() => {
    const ig = intentGroups?.intent_groups;
    if (!Array.isArray(ig)) {
      return [];
    }

    const ed = getDataForIndeterminateCheckbox(ig);
    return ed;
  }, [intentGroups]);

  const offerOptions = useMemo(() => {
    if (!Array.isArray(offers)) {
      return [];
    }

    return offers.map((offer) => ({
      value: offer?.id,
      label: offer?.name,
    }));
  }, [offers]);

  const handleConfigChange = (key, value, cardId) => {
    if (cardId) {
      setConfig((prev) => ({
        ...prev,
        cards: prev.cards.map((card) => {
          if (card.id === cardId) {
            return {
              ...card,
              [key]: value,
            };
          }
          return card;
        }),
      }));
    } else {
      setConfig((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleFeatureLabelChange = (key, label) => {
    setConfig((prev) => {
      const newState = cloneDeep(prev);
      newState.rezolveFeatures[key].label = label;
      return newState;
    });
  };

  const handleIconUpload = (icon) => {
    if (config.icons.includes(icon)) {
      return;
    }
    setConfig((prev) => {
      const newState = cloneDeep(prev);
      newState.icons.push(icon);
      return newState;
    });
  };

  const handleIconRemove = (event, cardId, icon) => {
    event.stopPropagation();
    setConfig((prev) => {
      const newState = cloneDeep(prev);
      newState.icons = newState.icons.filter((ico) => icon !== ico);
      newState.cards = newState.cards.map((card) => {
        if (card.id === cardId) {
          return {
            ...card,
            icon: null,
          };
        }
        return card;
      });
      return newState;
    });
  };

  const onReset = () => {
    setConfig((prev) => ({
      ...initialState,
      icons: prev.icons,
    }));
  };

  // const onPreview = () => {};

  const onSave = () => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const payload = cloneDeep(config);

    let error = false;
    payload.cards = payload.cards.map((card) => {
      if (card.title === '') {
        card.titleError = true;
        error = true;
      } else {
        delete card.titleError;
      }
      if (card.description === '') {
        card.descriptionError = true;
        error = true;
      } else {
        delete card.descriptionError;
      }
      if (!card.type || card.type === '') {
        card.typeError = true;
        error = true;
      } else {
        delete card.typeError;
      }

      if (card.type === 'intent_hierarchy') {
        if (!card.intent_hierarchy || card.intent_hierarchy.length < 1) {
          card.intentHierarchyError = true;
          error = true;
        } else {
          delete card.intentHierarchyError;
        }
      } else if (card.type === 'offer') {
        if (!card.offer || card.offer === '') {
          card.offerError = true;
          error = true;
        } else {
          delete card.offerError;
        }
      } else if (card.type === 'static_link') {
        if (!card.static_link || card.static_link === '') {
          card.staticLinkError = true;
          error = true;
        } else {
          delete card.staticLinkError;
        }
      }
      return card;
    });

    if (error) {
      setConfig(payload);
      return;
    }

    if (payload.companyLogo === '' || payload.companyLogoBlobName === '') {
      setConfigError({
        companyLogo: 'Company Logo is required',
      });
      return;
    }

    setConfigError((prev) => ({
      ...prev,
      companyLogo: '',
    }));

    payload.cards = payload.cards.map((card) => {
      if (card.intent_hierarchy && card.intent_hierarchy !== '') {
        card.intent_hierarchy_url = convertIntentIdToPath(
          intentOptions,
          card.intent_hierarchy[0]
        );
      } else {
        card.intent_hierarchy_url = '';
      }
      return card;
    });

    if (updateId) {
      updateConfiguration(updateId, {
        service_portal_configuration: [{ ...payload }],
        tenant_id: tenantId,
      });
    } else {
      saveConfiguration({
        service_portal_configuration: [{ ...payload }],
        tenant_id: tenantId,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      getOffers().then((res) => {
        setOffers(res);
      });
      getConfiguration().then((res) => {
        if (
          res?.[0]?.service_portal_configuration &&
          res[0].service_portal_configuration.length > 0
        ) {
          setConfig(res[0].service_portal_configuration[0]);
          setUpdateId(res[0].id);
        }
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const list = importAll(
      require.context('./Icons/', false, /\.(png|jpe?g)$/)
    );
    setListOfIcons(list.map((icon) => icon.default));
  }, []);

  useEffect(() => {
    if (isUseEffectTriggered.current) {
      return;
    }

    isUseEffectTriggered.current = true;

    if (isEmpty(intentGroups)) {
      getIntent().then((res) => {
        if (res) {
          const updatedData = updatedDataForIntentGroup(res);
          dispatch(setData({ key: keys.INTENT_GROUPS, data: updatedData }));
        }
      });
    }
  }, [intentGroups]);

  return (
    <Box p="16px" data-testid="ServicePortalConfigContainerBox">
      <Flex
        justifyContent="space-between"
        data-testid="ServicePortalConfigContainerBoxFlex"
        className="border-b-1"
        paddingBottom="10px"
      >
        <Text
          fontSize="20px"
          fontWeight="600"
          lineHeight="normal"
          whiteSpace="nowrap"
        >
          Service Portal Configuration
        </Text>

        <Flex gap="15px" data-testid="ServicePortalConfigContainerFlex">
          <CustomButton
            buttonText="Reset"
            variant="outline"
            onClick={onReset}
          />
          {/* <CustomButton
            buttonText="Preview"
            variant="outline"
            onClick={onPreview}
          /> */}
          <CustomButton
            buttonText="Save"
            variant="solid"
            style={{
              backgroundColor: COLORS.LIGHT,
              color: COLORS.WHITE,
              borderColor: '#2563EB',
            }}
            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            onClick={onSave}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        gap="20px"
        my="16px"
        data-testid="ServicePortalConfigContentBox"
      >
        <Flex flexDirection="column" gap="25px" maxWidth="1050px">
          <Flex gap="50px">
            <Text fontSize="14px" fontWeight="500" minWidth="200px">
              Company Logo
              <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
            </Text>
            <Flex flex="1" flexDirection="column">
              <Uploader
                onChange={(file) => {
                  postImage(file).then((res) => {
                    const { blobName, imageUrl } = res;
                    handleConfigChange('companyLogo', imageUrl);
                    handleConfigChange('companyLogoBlobName', blobName);
                    setConfigError((prev) => ({
                      ...prev,
                      companyLogoError: '',
                    }));
                  });
                }}
                name="CompanyLogo"
                classes={styles['drop-zone']}
                maxSize={0.05}
                minSize={0}
                uploadSize="50 KB"
                fileTypes={fileTypes}
                onClearSelection={() => {
                  handleConfigChange('companyLogo', '');
                  handleConfigChange('companyLogoBlobName', '');
                }}
                selectedFile={config.companyLogo}
                selectedFileBlobName={config.companyLogoBlobName}
              />
              {configError?.companyLogo && (
                <span className="field-error">{configError.companyLogo}</span>
              )}
            </Flex>
          </Flex>
          <Flex gap="50px">
            <Text fontSize="14px" fontWeight="500" minWidth="200px">
              Banner Image
            </Text>
            <Flex flex="1">
              <Uploader
                onChange={(file) => {
                  postImage(file).then((res) => {
                    const { blobName, imageUrl } = res;
                    handleConfigChange('bannerImage', imageUrl);
                    handleConfigChange('bannerImageBlobName', blobName);
                  });
                }}
                name="BannerImage"
                classes={styles['drop-zone']}
                maxSize={5}
                minSize={0}
                uploadSize="5 MB"
                fileTypes={fileTypes}
                onClearSelection={() => {
                  handleConfigChange('bannerImage', '');
                  handleConfigChange('bannerImageBlobName', '');
                }}
                selectedFile={config.bannerImage}
                selectedFileBlobName={config.bannerImageBlobName}
              />
            </Flex>
          </Flex>
          <Flex gap="50px">
            <Text fontSize="14px" fontWeight="500" minWidth="200px">
              Banner Text
            </Text>
            <Box flex="1">
              <CustomInput
                value={config.bannerText}
                onChange={(event) =>
                  handleConfigChange('bannerText', event.target.value)
                }
              />
            </Box>
          </Flex>
          <Flex gap="50px">
            <Text fontSize="14px" fontWeight="500" minWidth="200px">
              Bot Greeting
            </Text>
            <Box flex="1">
              <CustomInput
                value={config.botGreeting}
                onChange={(event) =>
                  handleConfigChange('botGreeting', event.target.value)
                }
              />
            </Box>
          </Flex>
          <Flex gap="50px">
            <Text fontSize="14px" fontWeight="500" minWidth="200px">
              Cards
            </Text>
            <Flex flex="1">
              <Cards
                cards={config.cards}
                icons={config.icons}
                intents={intentOptions}
                offers={offerOptions}
                onChange={handleConfigChange}
                handleIconUpload={handleIconUpload}
                handleIconRemove={handleIconRemove}
                listOfIcons={listOfIcons}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="25px" maxWidth="1050px">
          <Flex gap="50px">
            <Text fontSize="14px" fontWeight="500" minWidth="200px">
              Rezolve Features
            </Text>
            <Flex flexDirection="column" flex="1" gap="15px">
              <CustomSwitch
                size="md"
                isChecked={config.isRezolveFeaturesEnabled}
                onChange={(e) =>
                  handleConfigChange(
                    'isRezolveFeaturesEnabled',
                    e.target.checked
                  )
                }
              />
              <Flex flexDirection="column" gap="15px">
                {Object.keys(config.rezolveFeatures).map((featureKey) => (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    key={config.rezolveFeatures[featureKey].title}
                  >
                    <Text
                      color={
                        config.isRezolveFeaturesEnabled ? '#6B7280' : '#b6b8c0'
                      }
                    >
                      {config.rezolveFeatures[featureKey].title}
                    </Text>
                    <Flex alignItems="center" gap="10px">
                      <Text
                        color={
                          config.isRezolveFeaturesEnabled
                            ? '#6B7280'
                            : '#b6b8c0'
                        }
                      >
                        Label
                      </Text>
                      <CustomInput
                        isDisabled={!config.isRezolveFeaturesEnabled}
                        value={config.rezolveFeatures[featureKey].label}
                        onChange={(event) =>
                          handleFeatureLabelChange(
                            featureKey,
                            event.target.value
                          )
                        }
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ServicePortalConfiguration;

import React, { useMemo, useState, useEffect } from 'react';
import { Box, Button, Avatar, Badge, Link } from '@chakra-ui/react';
import axiosInstance from '../../../utils/axios/axios';
import { config } from '../../../helpers/config';
import ConfigProcessor from './ConfigProcessor';

const redirectURL = `${window.location.origin}/agent-ui-v2`;
const SupportRequests = (props) => {
  const { identity } = props || null;
  const [data, setData] = useState([]);

  const tenantName = useMemo(() => identity?.realm, [identity?.realm]);

  const getSupportRequest = async () => {
    try {
      const dataFilter = { search: '',
        top: 100,
        offset: 0,
        sort: 'updatedAt',
        searchOrder: 'DESC',
        filter: [{
          op: 'EQ',
          column: 'summary',
          operand: `${tenantName}`,
        }] };
      const url = config.tsUrl.replace('v1', 'v2');
      const response = await axiosInstance.post(
        `${url}/ticketing/ticket/list`,
        JSON.stringify(dataFilter),
        {
          headers: {
            isSupportRequest: 'true',
          },
        }
      );
      if (response.data) {
        return {
          error: null,
          response: response.data,
        };
      }
    } catch (error) {
      return { error: 'something went wrong', response: null };
    }
    return { error: 'something went wrong', response: null };
  };

  useEffect(() => {
    getSupportRequest().then((res) => {
      if (res.response) {
        setData(res.response || []);
      }
    });
  }, []);
  const ticketList = useMemo(() => {
    if (data !== undefined && data?.list) {
      return ConfigProcessor.setTicketFormat(data, tenantName, identity);
    }
  }, [data, tenantName, identity?.profile]);

  return (
    <Box color="gray">
      <Box display="flex" pb={4}>
        <Box w="50%" color="black" fontSize="13px" fontWeight="bold">
          Support Ticket
        </Box>
        <Box w="50%" textAlign="right">
          <Button
            backgroundColor="#5887f0"
            onClick={() => window.open(`${redirectURL}/support`, '_blank')}
            color="white"
            fontSize="12px"
            borderRadius="3px"
            size="xs"
          >
            Create Ticket
          </Button>
        </Box>
      </Box>
      {/* Ticket Listing Starts Here */}
      <Box w="100%" maxH="560px" overflow="auto">
        {ticketList?.length === 0 && (
          <Box color="gray" textAlign="center" mt={10}>
            No Support Request Found!
          </Box>
        )}

        {ticketList?.map((item, id) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`list-${id}`}
            display="flex"
            justifyContent="space-between"
            fontSize="12px"
            mt={4}
            borderBottom="thin solid #e2e2e2"
            pb={3}
          >
            <Box w="60%" borderLeft="4px solid #a0d0d1" paddingLeft="10px">
              <Box display="flex" fontSize="12px">
                <Box>
                  <Avatar mr={1} size="xs" name={item.submittedby} />
                </Box>
                <Box fontSize="11px">
                  <Link
                    href={`${redirectURL}/support/${item.caseId}`}
                    isExternal
                  >
                    <Box color="#5887f0">{item.caseId}</Box>
                  </Link>
                  <Box>{item.subject}</Box>
                </Box>
              </Box>
            </Box>

            <Box w="50%" textAlign="end">
              <Box fontSize="9px">{item.submittedon}</Box>
              <Box>
                <Badge
                  backgroundColor="#dee8fc"
                  fontSize="9px"
                  color="#5887f0"
                  mr={1}
                >
                  {item.status}
                </Badge>
                <Badge backgroundColor="#dee8fc" fontSize="9px" color="#5887f0">
                  {item.priority}
                </Badge>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {/* Ticket Listing Starts Here */}
    </Box>
  );
};
export default SupportRequests;

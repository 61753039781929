/* eslint-disable import/prefer-default-export */
export const fieldsetBuilderJson = {
  name: 'fieldsets',
  label: 'Ticket Template',
  table_name: 'fieldsets',
  route: '/fieldsets/fieldsets-makers',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_fieldsets',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_fieldsets',
      join_column: 'parent_id',
      related_table: 'fieldsets',
      related_join_column: 'id',
    },
    {
      name: 'fieldsets_fields',
      join_column: 'id',
      related_table: 'fieldsets_fields',
      related_join_column: 'fieldsets_id',
    },
  ],
  views: [
    {
      name: 'fieldsets_sidebar',
      label: 'Ticket Template Sidebar',
      show_label: false,
      type: 'container',
      views: [
        {
          name: 'fieldsets_list_accordion',
          label: 'FIELDSETS',
          show_label: false,
          type: 'accordion',
          entity: 'Templates',
          filterKey: 'name',
          listKey: 'fieldsets_list_short_view',
          views: [
            {
              name: 'fieldsets_list_short_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'list_short',
              entity: 'fieldsets',
              columns: ['name'],
              nested: {
                column: 'parent_id',
                column_type: 'parent',
              },
              apiReq: {
                // reqCols: [
                //   'id',
                //   'name',
                //   'slug',
                //   'version',
                //   'properties',
                //   'metric',
                //   'tenant_id',
                // ],
                reqCols: ['*'],
                orderBy: 'created_on desc',
                endPoint: 'fieldsets/list',
              },
              filter: [
                {
                  AND: [
                    {
                      condition: 'is_active = true',
                    },
                    {
                      OR: [
                        {
                          condition: 'author_id = $user.id',
                        },
                        {
                          condition: 'collaborator_id = $user.id',
                        },
                      ],
                    },
                  ],
                },
              ],
              actions: [
                {
                  name: 'view_edit_details',
                  label: 'View/Edit',
                  show_label: false,
                  icon: 'edit.ico',
                  is_button: false,
                  action_type: 'get',
                  permission: 'view',
                  target_params: ['id', 'name'],
                  type: 'record',
                  target: 'fieldsets/fieldsets-makers/fieldset/',
                  target_type: 'navigate',
                },
              ],
            },
            {
              name: 'create_modal',
              type: 'modal',
              modal_id: 'create_template_modal',
              elements: [
                {
                  name: 'namelabel',
                  type: 'text',
                  value: 'Name',
                },
                {
                  name: 'typelabel',
                  type: 'text',
                  value: 'Type',
                  visibility: false,
                  condition: { stateName: 'templateName', stateValue: 'abc' },
                },
                {
                  name: 'name',
                  type: 'input',
                  value: 'templateName',
                  inputType: 'string',
                },
                {
                  name: 'dummy',
                  type: 'input',
                  value: 'dummy',
                  inputType: 'string',
                },
                {
                  name: 'type',
                  type: 'select',
                  value: 'templateType',
                  options: [{ label: 'dummy', value: 'Dummy' }],
                },
                {
                  name: 'multi_type',
                  type: 'multi_select',
                  value: 'templateTypeMulti',
                  options: [
                    { label: 'dummy', value: 'Dummy' },
                    { label: 'two', value: 'one' },
                  ],
                },
              ],
              actions: [
                {
                  name: 'continue',
                  label: 'Continue',
                  show_label: true,
                  icon: 'edit.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'details_view',
                  target_type: 'api_call',
                  type: 'button',
                  is_modal_footer: true,
                },
                {
                  name: 'cancel',
                  label: 'Cancel',
                  show_label: true,
                  icon: 'edit.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'details_view',
                  target_type: 'state_change',
                  type: 'button',
                  is_modal_footer: true,
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'typelabel',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'type',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'multi_type',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'cancel',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'continue',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'namelabel',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'name',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'dummy',
                    w: 6,
                    h: 1,
                  },
                ],
              },
            },
          ],
          actions: [
            {
              name: 'create_template',
              label: 'Create New Fieldset',
              show_label: true,
              icon: 'edit.ico',
              is_button: true,
              action_type: 'get',
              permission: 'edit',
              view: 'details_view',
              target_type: 'popup',
              target_modal_id: 'create_template_modal',
              type: 'button',
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'create_template',
                w: 6,
                h: 1,
              },
              {
                i: 'fieldsets_list_short_view',
                w: 12,
                h: 1,
              },
              {
                i: 'create_modal',
                w: 12,
                h: 1,
              },
            ],
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'fieldsets_list_accordion',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
        ],
      },
    },

    {
      name: 'template_details_view',
      label: 'template_details',
      route: '/fieldsets/fieldsets-makers/fieldset/:id',
      show_label: false,
      type: 'container',
      // condition: {
      //   stateName: 'fieldsets_list_short_view',
      //   operator: '=',
      //   stateValue: 0,
      // },
      views: [
        {
          name: 'leftTop_view',
          label: 'Ticket Template List Short',
          show_label: false,
          type: 'container',
          views: [
            {
              name: 'left_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              elements: [
                {
                  name: 'title',
                  type: 'dynamic_text',
                  value: '$name',
                  style: { fontSize: '29px', fontWeight: '600' },
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'title',
                    w: 5,
                    h: 1,
                  },
                ],
              },
            },
            {
              name: 'right_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              classname: 'rightview',
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'left_view',
                w: 6,
                h: 1,
              },
              {
                i: 'right_view',
                w: 6,
                h: 1,
              },
            ],
          },
        },
        {
          name: 'fieldsets_tabs_view',
          label: 'Ticket Template Fields',
          show_label: false,
          type: 'tab_view',
          related_entity: 'fieldsets_fields',
          filter: [],
          views: [
            {
              name: 'fieldsetting_tab',
              label: 'Field Settings',
              show_label: false,
              type: 'container',
              filter: [],
              views: [
                {
                  name: 'field_search_container',
                  type: 'container',
                  elements: [
                    {
                      name: 'search_fieldsetting_tab',
                      type: 'input',
                      placeholder: 'Search for a field, type...',
                    },
                  ],
                  actions: [
                    {
                      name: 'add_field',
                      label: 'Add New Field',
                      show_label: true,
                      icon: 'add.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'popup',
                      target_modal_id: 'addFieldsModal',
                      type: 'button',
                      style: { borderColor: '#2563EB' },
                    },
                  ],
                  view_layout: {
                    width: '100%',
                    no_of_cols: 12,
                    rows: [
                      {
                        i: 'search_fieldsetting_tab',
                        w: 10,
                        h: 1,
                      },
                      {
                        i: 'add_field',
                        w: 1,
                        h: 1,
                      },
                    ],
                  },
                },
                {
                  name: 'fieldsets_fields_list_view',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'list_exp',
                  apiReq: {
                    reqCols: ['fields'],
                    orderBy: 'created_on asc',
                    endPoint: 'fieldsets/list',
                    isSingle: true,
                    filter: 'id',
                  },
                  related_entity: 'fieldsets_fields',
                  columns: [
                    {
                      col_name: 'label',
                      type: 'string',
                    },
                    {
                      col_name: 'type',
                      type: 'string',
                    },
                    {
                      col_name: 'actions',
                      type: 'actions',
                    },
                  ],
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                  actions: [
                    {
                      name: 'edit_fields',
                      icon: 'editIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'editFieldModal',
                    },
                    {
                      name: 'delete_fields',
                      icon: 'deleteIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'deleteModal',
                      master_type: 'fields', // this should be match keyValue in api for update
                      method: 'patch',
                      listKey: 'fieldsets_fields_list_view', // identify the redux status for update it
                      // eslint-disable-next-line no-template-curly-in-string
                      target_url: '/fieldsets/update?$filter=id eq ${id}',
                      label_key: 'label', // Key name to be displayed in modal as label
                    },
                  ],
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'field_search_container',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'fieldsets_fields_list_view',
                    w: 12,
                    h: 1,
                  },
                ],
              },
            },

            // role settings
            {
              name: 'role_settings_tab',
              label: 'Role Settings',
              show_label: false,
              type: 'container',
              loadInitialData: true,
              enpPoint:
                'templates/list?$top=8&$skip=0&$select=permissions&$orderby=created_on%20asc',
              methodType: 'get',
              viewStateName: 'role_settings_tab',
              responsePath: 'data.rows[0].permissions',
              style: { padding: '20px' },
              filter: [],
              views: [
                {
                  name: 'role_settings_section',
                  label: 'Role Settings',
                  type: 'multi_select_tags',

                  elements: [
                    {
                      name: 'viewers',
                      type: 'text',
                      value: 'Viewers',
                      style: { fontWeight: '600' },
                    },
                    {
                      name: 'viewers_select',
                      type: 'select',
                      options: [
                        { label: 'viewer_1', value: 'viewer_1' },
                        { label: 'viewer_2', value: 'viewer_2' },
                        { label: 'viewer_3', value: 'viewer_3' },
                        { label: 'viewer_4', value: 'viewer_4' },
                      ],
                      value: 'viewer',
                    },
                    {
                      name: 'anyone_text',
                      type: 'text',
                      value: 'Anyone logged in from',
                      style: { textAlign: 'center' },
                    },
                    {
                      name: 'viewer_input',
                      type: 'input',
                      value: 'viewInput',
                    },
                    {
                      name: 'editors',
                      type: 'text',
                      value: 'Editors',
                      style: { fontWeight: '600' },
                    },

                    {
                      name: 'editors_select',
                      type: 'select',
                      options: [
                        { label: 'editor_1', value: 'editor_1' },
                        { label: 'editor_2', value: 'editor_2' },
                        { label: 'editor_2', value: 'editor_2' },
                        { label: 'editor_3', value: 'editor_3' },
                      ],
                      value: 'editor',
                    },
                    {
                      name: 'editor_subcategory',
                      type: 'select',
                      options: [
                        { label: 'editor_sub_1', value: 'editor_sub_1' },
                        { label: 'editor_sub_2', value: 'editor_sub_2' },
                        { label: 'editor_sub_2', value: 'editor_sub_2' },
                        { label: 'editor_sub_3', value: 'editor_sub_3' },
                      ],
                      value: 'editor_subcategory',
                    },
                    {
                      name: 'expressions',
                      type: 'text',
                      value: 'Expressions',
                      style: { fontWeight: '600' },
                    },

                    {
                      name: 'expression_input_left',
                      type: 'input',
                      value: 'leftOperand',
                    },
                    {
                      name: 'expression_select',
                      type: 'select',
                      options: [
                        { label: '===', value: '===' },
                        { label: '!==', value: '!==' },
                        { label: '>=', value: '>=' },
                      ],
                      value: 'operator',
                    },
                    {
                      name: 'expression_input_right',
                      type: 'input',
                      value: 'rightOperand',
                    },
                    {
                      name: 'editors_div',
                      type: 'multi_select_div',
                      value: 'editors',
                      target_state_key: 'role_settings_tab',
                      // condition: { stateName: 'templateName', stateValue: 'abc' },
                    },
                    {
                      name: 'viewers_div',
                      type: 'multi_select_div',
                      value: 'viewers',
                      target_state_key: 'role_settings_tab',
                      // condition: { stateName: 'templateName', stateValue: 'abc' },
                    },
                    {
                      name: 'expression_div',
                      type: 'expression_div',
                      value: 'expression',
                      target_state_key: 'role_settings_tab', /// parent state were data will be stored
                      lhs_key: 'leftOperand',
                      op_key: 'operator',
                      rhs_key: 'rightOperand',
                      // condition: { stateName: 'templateName', stateValue: 'abc' },
                    },
                  ],
                  actions: [
                    {
                      name: 'add_viewer',
                      label: 'Add',
                      is_button: true,
                      type: 'button',
                      target_key: 'viewers',
                      target_obj_key: 'viewer',
                      target_val_key: 'viewInput',
                    },
                    {
                      name: 'add_editor',
                      label: 'Add',
                      is_button: true,
                      type: 'button',
                      target_key: 'editors',
                      target_obj_key: 'editor',
                      target_val_key: 'editor_subcategory',
                    },
                    {
                      name: 'add_expression',
                      label: 'Add',
                      is_button: true,
                      type: 'button',
                      target_key: 'expression',
                      lhs: 'leftOperand',
                      op: 'operator',
                      rhs: 'rightOperand',
                    },
                  ],
                  view_layout: {
                    width: '40%',
                    no_of_cols: 12,
                    rows: [
                      // {
                      //   i: 'option_popup',
                      //   w: 10,
                      //   h: 1,
                      // },

                      {
                        i: 'viewers',
                        w: 12,
                        h: 1,
                      },
                      {
                        i: 'viewers_select',
                        w: 3,
                        h: 1,
                      },
                      {
                        i: 'anyone_text',
                        w: 3,
                        h: 1,
                      },
                      {
                        i: 'viewer_input',
                        w: 4,
                        h: 1,
                      },
                      {
                        i: 'add_viewer',
                        w: 1,
                        h: 1,
                      },
                      {
                        i: 'viewers_div',
                        w: 12,
                        h: 1,
                      },
                      {
                        i: 'editors',
                        w: 12,
                        h: 1,
                      },
                      {
                        i: 'editors_select',
                        w: 3,
                        h: 1,
                      },
                      {
                        i: 'editor_subcategory',
                        w: 7,
                        h: 1,
                      },
                      {
                        i: 'add_editor',
                        w: 1,
                        h: 1,
                      },
                      {
                        i: 'editors_div',
                        w: 12,
                        h: 1,
                      },
                      {
                        i: 'expressions',
                        w: 12,
                        h: 1,
                      },
                      {
                        i: 'expression_input_left',
                        w: 3,
                        h: 1,
                      },
                      {
                        i: 'expression_select',
                        w: 4,
                        h: 1,
                      },
                      {
                        i: 'expression_input_right',
                        w: 3,
                        h: 1,
                      },

                      {
                        i: 'add_expression',
                        w: 1,
                        h: 1,
                      },
                      {
                        i: 'expression_div',
                        w: 12,
                        h: 1,
                      },
                    ],
                  },
                },
              ],
              actions: [
                {
                  name: 'save_role_settings',
                  label: 'Save',
                  is_button: true,
                  type: 'button',
                  target_type: 'api_call',
                  payloadKeysMaps: {
                    permissions: {
                      viewers: {},
                      editors: {},
                      expression: [],
                    },
                  },
                  updatePayloadPath: 'permissions',
                  payloadStateKey: 'role_settings_tab',
                  method: 'patch',
                  target_url: 'templates/update?',
                  style: {
                    background: '#2563EB',
                    color: '#fff',
                    marginTop: '20px',
                  },
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'role_settings_section',
                    x: 0,
                    y: 1,
                    w: 12,
                    maxH: 'Infinity',
                  },
                  {
                    i: 'save_role_settings',
                    x: 0,
                    y: 1,
                    w: 2,
                    colEnd: 12,
                    maxH: 'Infinity',
                  },
                ],
              },
            },
          ],
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'leftTop_view',
            w: 12,
            h: 1,
          },
          {
            i: 'fieldsets_tabs_view',
            w: 12,
            h: 1,
          },
        ],
      },
    },
    // {
    //   name: 'empty_template_view',
    //   label: 'No Template Screen',
    //   type: 'empty_screen',
    //   icon: 'noTemplateIcon',
    //   title: 'You have not created a template',
    //   subTitle: 'Click ‘Create New Template’ to get started',
    //   btnText: 'Create New Template',
    //   target_modal_id: 'create_template_modal',
    //   condition: {
    //     stateName: 'fieldsets_list_short_view',
    //     operator: '>',
    //     stateValue: 0,
    //   },
    // },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'fieldsets_sidebar',
        x: 0,
        y: 0,
        w: 2,
        h: 1,
      },
      // {
      //   i: 'empty_template_view',
      //   x: 0,
      //   y: 1,
      //   w: 10,
      //   maxH: 'Infinity',
      // },
      {
        i: 'template_details_view',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
    ],
  },
};

/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  Flex,
  Text,
  useColorModeValue,
  Table,
  Tbody,
  Tr,
  TableContainer,
  Box,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import _set from 'lodash/set';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import { BLACK, COLORS, DARK, LIGHT, WHITE } from '../../utils/enums/colors';
import CustomOperatorButton from './CustomOperatorButton';
import styles from './expression-styles.module.scss';

import { conditionsExpressions } from './labels';
import DynamicExpression from './DynamicExpression';
import { AddOrIcon } from './Icons';
import axiosInstance from '../../utils/axios/axios';
import { parserForOldComponentToNewConditionComponent } from './dynamicExpressionWithTags.helper';
import {
  getHierarchyById,
  getTemplateFields,
  getTitlesHierarchy,
} from '../dynamicExpressionWithTags/dynamicExpressionWithTags.helper';

const ConditionsExpressions = ({
  templateId,
  testingId,
  maxWidth,
  value,
  onChange,
  label,
  showCount = false,
  showRelativeFilter = false,
}) => {
  const [conditionalExpression, setConditionalExpression] = useState({});

  const [andArray, setAndArray] = useState([]);

  const [fields, setFields] = useState([]);
  const workflowData = useRef(null);
  const [fieldHierarchyMap, setFieldHierarchyMap] = useState(null);
  const [templateHierarchy, setTemplateHierarchy] = useState(null);
  const [actors, setActors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const source = axios.CancelToken.source();
      try {
        const params = {
          $select: 'fields, workflow, field_hierarchy_map,actors',
          $filter: `id eq '${templateId}'`,
        };
        const response = await axiosInstance.get('templates/list', {
          params,
          cancelToken: source.token,
        });
        if (response?.data?.rows) {
          const TEMP_TEMPLATE_RESPONSE = response?.data?.rows[0] || [];
          const workFlowStates = (
            TEMP_TEMPLATE_RESPONSE?.workflow?.workflow_status || []
          )
            .filter((state) => state.id !== 'start_1')
            .map((state) => ({ label: state.data.label, value: state.id }));

          setActors(TEMP_TEMPLATE_RESPONSE?.actors || []);

          const workflowCategories = (
            TEMP_TEMPLATE_RESPONSE?.workflow?.workflow_category || []
          ).map((category) => ({
            label: category.name,
            value: category.id,
          }));

          if (TEMP_TEMPLATE_RESPONSE.field_hierarchy_map) {
            getHierarchyById(
              TEMP_TEMPLATE_RESPONSE?.field_hierarchy_map[0]?.heirachyListId,
              (hierarchy) => {
                setTemplateHierarchy(hierarchy);
                setFieldHierarchyMap(
                  TEMP_TEMPLATE_RESPONSE?.field_hierarchy_map[0]
                );
              }
            );
          }
          workflowData.current = {
            workFlowStates,
            workflowCategories,
          };
          const FILTER_FIELD = [
            ...(TEMP_TEMPLATE_RESPONSE?.fields || []),
          ].filter((item) => item.type !== 'MEDIA');

          FILTER_FIELD.push({
            id: 'status_category',
            label: 'Workflow Status Category',
            type: 'DROPDOWN',
            type_based_attributes: {
              collection: {
                collection_type: 'SPECIAL_FIELD',
                specialField: 'workflowCategory',
              },
            }
          });

          const finalData = showCount
            ? [
              {
                id: 'count',
                label: 'Count',
                type: 'COUNT',
                name: 'Count',
              },
            ]
            : FILTER_FIELD;
          setFields(finalData);

          // console.log(showCount, 'dataaaaaaa');

          if (!isEmpty(value.and)) {
            setAndArray([...value.and]);
          } else if (Array.isArray(value) && FILTER_FIELD) {
            setAndArray(
              parserForOldComponentToNewConditionComponent(value, FILTER_FIELD)
            );
          } else {
            setAndArray([
              {
                or: [
                  {
                    leftOperand: '',
                    operator: '',
                    rightOperand: '',
                    id: uuidv4(),
                  },
                ],
                id: uuidv4(),
              },
            ]);
          }
        }
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [templateId, showCount]);

  const addNewOrBlock = (andId) => {
    const localValue = {
      leftOperand: '',
      operator: '',
      rightOperand: '',
      id: uuidv4(),
    };

    const andBlock = andArray.find((item) => item.id === andId);

    if (
      andBlock.or.length === 0 ||
      andBlock.or[andBlock.or.length - 1].leftOperand !== ''
    ) {
      const currentOrBlockArray = [...andBlock.or, localValue];
      const newAndBlock = { ...andBlock, or: currentOrBlockArray };
      const newAndArray = andArray.map((item) =>
        item.id === andId ? newAndBlock : item
      );

      setAndArray(newAndArray);
    }
  };

  const isAddOrBlockButtonDisabled = (andId) => {
    const andBlock = andArray.find((item) => item.id === andId);

    if (
      andBlock.or.length === 0 ||
      andBlock.or[andBlock.or.length - 1].leftOperand !== '' ||
      andBlock.or[andBlock.or.length - 1].operator !== ''
    ) {
      if (showCount && andBlock.or.length === 1) {
        return true;
      }
      return false;
    }

    return true;
  };

  useEffect(() => {
    const newConditionalExpression = {
      and: andArray,
    };

    setConditionalExpression(newConditionalExpression);
  }, [andArray]);

  useEffect(() => {
    onChange(conditionalExpression);
  }, [conditionalExpression]);

  const isAddAndBlockButtonDisabled = () => {
    const lastAnd = andArray[andArray.length - 1];
    if (lastAnd && lastAnd.or) {
      const lastOr = lastAnd.or[lastAnd.or.length - 1];
      return !(lastOr && lastOr.leftOperand !== '');
    }
    return true;
  };

  const addNewAndBlock = () => {
    const defaultValue = {
      or: [
        {
          leftOperand: '',
          operator: '',
          rightOperand: '',
          id: uuidv4(),
        },
      ],
      id: uuidv4(),
    };
    const newAndArray = [...andArray, defaultValue];
    setAndArray(newAndArray);
  };

  const getIndex = (array, elementId) =>
    array.findIndex((item) => item.id === elementId);

  return (
    <Box
      className={styles.conditions__expressions__container}
      data-testid={testingId}
      maxW={maxWidth}
      // width={maxWidth ? '100%' : '780px'}
    >
      {andArray?.length > 0 && (
        <Flex direction="column">
          <Text
            className={styles.conditions__expressions__container__label}
            color={useColorModeValue(BLACK, WHITE)}
          >
            {label}
          </Text>
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                {andArray?.length > 0 &&
                  andArray?.map((andContainer, andElIndex) => (
                    <Tr key={andContainer.id}>
                      <Flex
                        key={andContainer.id}
                        direction="column"
                        className={styles.dynamic__expression__container}
                      >
                        <Text
                          className={
                            styles.conditions__expressions__sub__container__label
                          }
                          color={useColorModeValue(BLACK, WHITE)}
                        >
                          {getIndex(andArray, andContainer?.id) === 0
                            ? conditionsExpressions?.DYNAMIC_EXPRESSION_CONDITION_INITIAL_HEADER
                            : conditionsExpressions?.DYNAMIC_EXPRESSION_CONDITION_HEADER}
                        </Text>
                        {andContainer?.or?.length > 0 &&
                          andContainer.or.map((orContainer, orElIndex) => (
                            <Box key={orContainer?.id} marginBottom="18px">
                              <DynamicExpression
                                containerLabel={
                                  getIndex(andContainer?.or, orContainer?.id) >
                                  0
                                    ? 'or'
                                    : ''
                                }
                                fields={fields}
                                orBlock={orContainer}
                                andContainerId={andContainer?.id}
                                orContainerId={orContainer?.id}
                                andArray={andArray}
                                setAndArray={setAndArray}
                                workflowData={workflowData.current}
                                templateId={templateId}
                                fieldHierarchyMap={fieldHierarchyMap}
                                templateHierarchy={templateHierarchy}
                                orBlockList={andContainer.or}
                                actors={actors}
                                showRelativeFilter={showRelativeFilter}
                              />
                            </Box>
                          ))}
                        <Flex
                          justifyContent="flex-start"
                          marginLeft="26px"
                          marginTop="18px"
                        >
                          <CustomOperatorButton
                            testingId="add-or-button"
                            isButtonDisabled={isAddOrBlockButtonDisabled(
                              andContainer?.id
                            )}
                            // width="608px"
                            width="100%"
                            height="32px"
                            buttonLabel={
                              conditionsExpressions?.BUTTON_ADD_OR_OPERATION_TEXT
                            }
                            icon={
                              <AddOrIcon
                                color={useColorModeValue(
                                  COLORS.WHITE,
                                  COLORS.DARK
                                )}
                                stroke={useColorModeValue(
                                  COLORS.LIGHT,
                                  COLORS.BLACK
                                )}
                              />
                            }
                            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                            onClick={() => {
                              addNewOrBlock(andContainer?.id);
                            }}
                            borderColor={useColorModeValue(
                              COLORS.LIGHT,
                              COLORS.DARK
                            )}
                            _hover={{
                              backgroundColor: COLORS.LIGHT,
                              color: COLORS.WHITE,
                            }}
                          />
                        </Flex>
                      </Flex>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box>
            <Flex gap="20px" justify="flex-end" pb="10px">
              {!isEmpty(fields) && (
                <CustomOperatorButton
                  testingId="add-and-button"
                  // width="195px"
                  height="32px"
                  isButtonDisabled={
                    showCount ? true : isAddAndBlockButtonDisabled()
                  }
                  buttonLabel={
                    conditionsExpressions?.BUTTON_ADD_AND_CONDITION_TEXT
                  }
                  color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                  icon={
                    <AddIcon
                      width="10px"
                      height="10px"
                      stroke={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                    />
                  }
                  borderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                  onClick={() => {
                    addNewAndBlock();
                  }}
                  _hover={{
                    backgroundColor: COLORS.LIGHT,
                    color: COLORS.WHITE,
                  }}
                />
              )}
            </Flex>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ConditionsExpressions;

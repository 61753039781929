/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { Card, CardBody, CardHeader } from '@chakra-ui/card';
import { Box, Flex, Text, HStack, Grid } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Button } from '@chakra-ui/button';
import { Avatar, Tooltip } from '@chakra-ui/react';

import { ReactSVG } from 'react-svg';

import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import TextWithTooltip from '../../components/Text';

import { renderFavorite } from './TemplateTableView/utils';
// import { BLACK, WHITE } from '../../utils/enums/colors';
import { getColorForStatus } from './utils';
import { CARD_LISTS_TOOLTIP_LABELS } from '../../utils/enums/labels';

import TemplateIcon from '../../assets/icons/TemplateIcon.svg';
import CloneIcon from '../../assets/icons/CloneIcon.svg';
import EditIconSVG from '../../assets/icons/EditIcon.svg';
import DeleteIcon from '../../assets/icons/Delete.svg';
import TemplateDarkIcon from '../../assets/icons/TemplateDarkIcon.svg';
import CloneDarkIcon from '../../assets/icons/CloneDarkIcon.svg';
import EditDarkIconSVG from '../../assets/icons/EditDarkIcon.svg';
import DeleteDarkIcon from '../../assets/icons/DeleteDark.svg';

import styles from './cardLayout.module.scss';
import { getCreatedByInfo } from './helpers';
import { useLocation } from 'react-router';

const AllCardList = ({
  favoriteMap,
  onDelete,
  onEdit,
  onClone,
  toggleFavorite,
  filteredItems,
}) => (
  <div data-testid="AllCardListContainerDiv" className="pt-5" style={{ height: 'calc(100vh - 210px', overflow: 'auto' }}>
            <Grid
             templateColumns="repeat(4, 1fr)" 
             gap={3} 
             w="100%" 
             data-testid="AllCardListFlex"
         >
      {filteredItems.map((cardData) => (
        <Card
          mt={0}
          className={`${styles.cardStyle} ${styles.template__card}`}
          key={cardData.id}
          onClick={() => {
            onEdit(cardData.id, cardData.name);
          }}
          id={`template_${cardData.id}`}
          data-testid="AllCardListCard"
          width="98%"
        >
          <Box data-testid="AllCardListContainerBox">
            <CardHeader
              className={styles.cardHeaderStyle}
              data-testid="AllCardListCardHeader"
            >
              <Flex spacing="4" data-testid="AllCardListHeaderFlex">
                <Flex
                  flex="1"
                  gap="3"
                  alignItems="center"
                  flexWrap="wrap"
                  data-testid="AllCardListTemplateIconFlex"
                >
                  <ReactSVG
                    src={useColorModeValue(TemplateIcon, TemplateDarkIcon)}
                    className={styles.templateIcon}
                  />
                </Flex>
                <Flex alignItems="center" data-testid="AllCardListEditFlex">
                  <Tooltip
                    label={CARD_LISTS_TOOLTIP_LABELS.EDIT}
                    placement="top"
                    hasArrow
                    data-testid="AllCardListEditTooltip"
                  >
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit(cardData.id, cardData.name);
                      }}
                      data-testid="AllCardListEditButton"
                    >
                      <ReactSVG
                        src={useColorModeValue(EditIconSVG, EditDarkIconSVG)}
                        className={styles.menuIcons}
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    label={CARD_LISTS_TOOLTIP_LABELS.CLONE}
                    placement="top"
                    hasArrow
                    data-testid="AllCardListCloneTooltip"
                  >
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClone(cardData.id, cardData.name);
                      }}
                      data-testid="AllCardListCloneButton"
                    >
                      <ReactSVG
                        src={useColorModeValue(CloneIcon, CloneDarkIcon)}
                        className={styles.menuIcons}
                      />
                    </Button>
                  </Tooltip>

                  {!cardData?.properties?.wasApproved && (
                    <Tooltip
                      label={CARD_LISTS_TOOLTIP_LABELS.DELETE}
                      placement="top"
                      hasArrow
                      data-testid="AllCardListDeleteToolTip"
                    >
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(cardData.id);
                        }}
                        data-testid="AllCardListDeleteButton"
                      >
                        <ReactSVG
                          src={useColorModeValue(DeleteIcon, DeleteDarkIcon)}
                          className={styles.menuIcons}
                        />
                      </Button>
                    </Tooltip>
                  )}
                  {/* </Button> */}
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody
              className={styles.cardBodyStyle}
              data-testid="AllCardListCardBody"
            >
              <TextWithTooltip
                defaultClassName={useColorModeValue(
                  styles.templateNameLight,
                  styles.templateNameDark
                )}
                text={cardData.name}
                // color={useColorModeValue(BLACK, WHITE)}
                data-testid="AllCardListNameTextWithTooltip"
              />

              <TextWithTooltip
                defaultClassName={`${styles.templateDescription} ${styles.clipText}`}
                text={
                  cardData?.properties?.description ? (
                    cardData?.properties?.description
                  ) : (
                    <div style={{ height: '21px' }} />
                  )
                }
                data-testid="AllCardListDecsriptionTextWithTooltip"
              />

              <Flex spacing="4" data-testid="AllCardListBodyFlex">
                <Flex
                  flex="1"
                  gap="3"
                  alignItems="center"
                  flexWrap="wrap"
                  data-testid="AllCardListTextFlex"
                >
                  <Box data-testid="AllCardListStatusBox">
                    <Text
                      className={`${styles.statusStyle} ${getColorForStatus(
                        cardData.lifecycle_status
                      )}`}
                      data-testid="AllCardListStatusText"
                    >
                      {cardData.lifecycle_status.split('_').join(' ')}
                    </Text>
                  </Box>
                  <Box data-testid="AllCardListNestedBox">
                    {cardData?.properties?.parent_id && (
                      <Text
                        className={`${styles.statusStyle} ${useColorModeValue(
                          styles.nestedTemplateLight,
                          styles.nestedTemplateDark
                        )}`}
                        data-testid="AllCardListNestedText"
                      >
                        NESTED
                      </Text>
                    )}
                  </Box>
                </Flex>
                <HStack
                  alignItems="center"
                  data-testid="AllCardListCreatedByFlex"
                  spacing="10px"
                >
                  {renderFavorite({
                    isFavorite: favoriteMap[cardData.id],
                    toggleFavorite: () => toggleFavorite(cardData.id),
                  })}

                  <Tooltip
                    hasArrow
                    label={
                      <>
                        <span>
                          {`Created by: ${getCreatedByInfo(cardData)}`}
                        </span>
                        <br />
                        <span>
                          {cardData.created_on
                            ? dayjs(cardData.created_on).format(
                              'D MMM YYYY, h:mm A'
                            )
                            : ''}
                        </span>
                      </>
                    }
                    placement="bottom"
                    data-testid="AllCardListCreatedByTooltip"
                  >
                    <Avatar
                      name={
                        cardData?.created_by ||
                        cardData?.properties?.owners[0] ||
                        ''
                      }
                      size="xs"
                      data-testid="AllCardListAvatar"
                    />
                  </Tooltip>
                </HStack>
              </Flex>
            </CardBody>
          </Box>
        </Card>
      ))}
    </Grid>
  </div>
);

AllCardList.propTypes = {
  favoriteMap: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  filteredItems: PropTypes.array.isRequired,
};

AllCardList.defaultProps = {};

export default AllCardList;

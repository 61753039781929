import { cloneDeep } from 'lodash';

export const checkboxStatus = Object.freeze({
  unchecked: 0,
  checked: 1,
  indeterminate: -1,
});

// set the checkboxStatus property recursively for a tree of items
const setStatus = ({ rootObj = {}, status, parentSelectsChild }) => {
  rootObj.checkboxStatus = status;

  if (parentSelectsChild) {
    if (Array.isArray(rootObj?.items)) {
      rootObj.items.forEach((item) => {
        setStatus({ rootObj: item, status });
      });
    }
  }
};

export const getCheckedNodes = ({ items, parentSelectsChild }) => {
  const nodes = [];

  const generatePayload = (subData) => {
    if (!Array.isArray(subData) || subData.length === 0) {
      return;
    }

    for (let i = 0; i < subData.length; i++) {
      const status = subData?.[i]?.checkboxStatus;
      if (status === checkboxStatus.checked) {
        nodes.push(subData?.[i]?.id);
      }

      if (!parentSelectsChild || status !== checkboxStatus.checked) {
        generatePayload(subData?.[i]?.items);
      }
    }
  };

  generatePayload(items);

  return nodes;
};

export const getIdNameMap = (data) => {
  const obj = {};

  const generatePayload = (subData) => {
    if (!Array.isArray(subData) || subData.length === 0) {
      return;
    }

    for (let i = 0; i < subData.length; i++) {
      const title = subData?.[i]?.name;
      const key = subData?.[i]?.id;
      if (title && key) {
        obj[key] = title;
      }
      generatePayload(subData?.[i]?.items);
    }
  };

  generatePayload(data);
  return obj;
};

// To compute the overall checkbox status based on the checkboxStatus of items
export const computeStatus = (items) => {
  let checked = 0;
  let indeterminate = 0;

  items.forEach((item) => {
    if (item?.checkboxStatus === checkboxStatus.checked) {
      checked += 1;
    }
    if (
      item?.checkboxStatus === checkboxStatus.indeterminate
    ) {
      indeterminate += 1;
    }
  });

  if (checked === items.length) {
    return checkboxStatus.checked;
  }
  if (checked > 0 || indeterminate > 0) {
    return checkboxStatus.indeterminate;
  }

  return checkboxStatus.unchecked;
};

// To set checkboxStatus for a specific item
export const rootTraversal = ({ root, objId, status, parentSelectsChild }) => {
  const temp = cloneDeep(root);

  const triggerChange = (subRoot) => {
    if (!Array.isArray(subRoot) || subRoot.length === 0) {
      return;
    }

    for (let i = 0; i < subRoot.length; i++) {
      const id = subRoot?.[i]?.id;
      const items = subRoot?.[i]?.items;
      if (id === objId) {
        setStatus({ rootObj: subRoot[i], status, parentSelectsChild });
        return;
      }
      triggerChange(items);
    }
  };

  const recheckStatus = (subRoot) => {
    if (!Array.isArray(subRoot) || subRoot.length === 0) {
      return;
    }

    for (let i = 0; i < subRoot.length; i++) {
      const items = subRoot?.[i]?.items;
      recheckStatus(items);
      if (Array.isArray(items) && items.length > 0) {
        subRoot[i].checkboxStatus = computeStatus(items);
      }
    }
  };

  triggerChange(temp);
  if (parentSelectsChild) {
    recheckStatus(temp);
  }

  return temp;
};

export const uncheckAll = (root) => {
  const temp = cloneDeep(root);
  for (let i = 0; i < temp.length; i++) {
    temp[i].checkboxStatus = checkboxStatus.unchecked;
    if (Array.isArray(temp[i]?.items) && temp[i]?.items?.length > 0) {
      uncheckAll(temp[i].items);
    }
  }
  return temp;
};

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import {
  Progress,
  Box,
  Button,
  useToast,
  Flex,
  Text,
  Input,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image
} from '@chakra-ui/react';

import 'react-quill/dist/quill.snow.css';
import './quill-styles.css';
import './custom-styles.css';
import { isEmpty } from 'lodash';
import { config, configUrl } from './config';
import VideoBlot from './VideoBlot';
import 'quill-mention';
import CustomImageBlot from './ImageBlot';
import CustomModal from './modal';
import {
  addedUrlToIdentifier,
  uploadImageGetUrl,
  isValidImageUrl,
} from './helper';
import { postImage, getImageUrl } from '../AttachmentUpload/utils';
import { SecondaryCustomButton } from '../Button/PrimarySecondaryButton';

const icons = Quill.import('ui/icons');
icons.undo = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
<title>Undo</title>
</svg>`;
icons.redo = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
<title>Redo</title>
</svg>`;
// icons.undo = config.icons.undo
// icons.redo = config.icons.redo
icons.bold = config.icons.bold;
icons.italic = config.icons.italic;
icons.underline = config.icons.underline;
icons.strike = config.icons.strike;
icons.blockquote = config.icons.blockquote;
icons.list.ordered = config.icons.ordered;
icons.list.bullet = config.icons.bullet;
icons.script.sub = config.icons.sub;
icons.script.super = config.icons.super;
icons.color = config.icons.color;
icons.background = config.icons.background;
icons.image = config.icons.image;
icons.video = config.icons.video;
icons.link = config.icons.link;
icons.attachment = config.icons.attachment;
icons['code-block'] = config.icons.codeBlock;
icons.indent['-1'] = config.icons.indentBackward;
icons.indent['+1'] = config.icons.indentForward;

// Register Video Blot To Insert Video Tag For Video File //
VideoBlot.blotName = 'insertVideo';
VideoBlot.tagName = 'video';
Quill.register(VideoBlot);
ReactQuill.Quill.register('formats/image', CustomImageBlot);

// Main component function.

const QuillEditor = ({
  editorvalue,
  toolBarModules,
  readOnly,
  caseId,
  fieldId,
  errOnUpload,
  customEditorStyles,
  mentionObj,
  placeholder,
  usedOn,
  format,
  handleOnChange,
  customEditorContainerStyles,
  isMandatory,
  label,
  testId,
  focus,
  onBlur,
  showClearButton,
  onMentionAdd,
  isError,
  errorMessage,
  ...props
}) => {
  const toast = useToast();
  const quillRef = useRef(null);
  const [value, setValue] = useState(editorvalue);
  const [isLoadingEditor, setIsLoadingEditor] = useState(false);
  const [attachmentModel, setAttachmentModel] = useState(false);
  const [currentUploadType, setCurrentUploadType] = useState('');
  const [isAttachment, setIsAttachment] = useState(false);
  const [attachmentFileErr, setAttachmentFileErr] = useState('');
  const [attachmentFile, setAttachmentFile] = useState('');
  const [linkOfUploadFile, setLinkOfUploadFile] = useState('');

  // image states
  const imageModalRef = useRef(null);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showImageEmbaded, setShowImageEmbaded] = useState(false);
  const [imageModel, setImageModel] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageAlt, setImageAlt] = useState('');
  const [imageUrlText, setImageUrlText] = useState('');

  const [attachmentErrMsg, setAttachmentErrMsg] = useState('');

  useEffect(() => {
    if (focus) {
      quillRef.current?.focus();
    }
  }, [focus]);

  let defualtModules = config.defualtToolbar;
  defualtModules = !isEmpty(props?.toolBarModules)
    ? props?.toolBarModules
    : defualtModules;
  useEffect(() => {
    const str = editorvalue?.replace(/(\r\n|\r|\n)/g, '<div>');
    setValue(str);
  }, [editorvalue]);

  const showToast = useCallback(({ title, description, status }) => {
    toast({
      title,
      description: description || '',
      status: status || 'success',
      duration: 3000,
      position: 'top-right',
      isClosable: true,
    });
  }, []);

  const fileUploadWithCaseId = (fileObj) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const base64Data = e.target.result;
      const timestamp = Date.now().toString(36);
      const randomStr = Math.random().toString(36).substr(2, 5);
      const docNameUnique = `${fileObj.name}_${timestamp}${randomStr}_${fileObj.name}`;
      const newPatch = {
        id: fieldId,
        attachment: {
          $add: [
            {
              filename: docNameUnique,
              mediaType: fileObj.type,
              base64: base64Data,
              identifier:
                '87f31231-304d-4d40-bd4e-c459caa294b9-ticket-docs/CAB-0284%3AScreenshot%20from%202023-09-29%2010-49-00.png_lnk7ki3uv5y7r_Screenshot%20from%202023-09-29%2010-49-00.png?st=2023-10-10T10%3A48%3A01Z&se=2023-10-10T11%3A58%3A01Z&sp=r&sv=2018-03-28&sr=b&sig=qnttBGTLmOzv75bnmRe8KfzSlaF%2FhwFyL0ToiVQgmEY%3D',
            },
          ],
          $remove: [],
        },
        // these will remove once api added
        filename: docNameUnique,
        mediaType: fileObj.type,
        identifier:
          '87f31231-304d-4d40-bd4e-c459caa294b9-ticket-docs/CAB-0284%3AScreenshot%20from%202023-09-29%2010-49-00.png_lnk7ki3uv5y7r_Screenshot%20from%202023-09-29%2010-49-00.png?st=2023-10-10T10%3A48%3A01Z&se=2023-10-10T11%3A58%3A01Z&sp=r&sv=2018-03-28&sr=b&sig=qnttBGTLmOzv75bnmRe8KfzSlaF%2FhwFyL0ToiVQgmEY%3D',
      };
      const res = await uploadImageGetUrl(
        caseId ?? 'US-0001',
        fieldId,
        newPatch
      );
      if (typeof res === 'string') {
        setIsLoadingEditor(false);
        setIsAttachment(false);
        showToast({
          title: 'Something went wrong',
          status: 'error',
        });
        return;
      }
      const plm = addedUrlToIdentifier(res);

      setLinkOfUploadFile(plm);
    };

    reader.readAsDataURL(fileObj);
  };
  const iconList = {
    txt: `${configUrl?.cdnUri}/cdn/txt.png`,
    pdf: `${configUrl?.cdnUri}/cdn/pdf.png`,
    xls: `${configUrl?.cdnUri}/cdn/xlsx.png`,
    xlsx: `${configUrl?.cdnUri}/cdn/xlsx.png`,
    doc: `${configUrl?.cdnUri}/cdn/word.png`,
    docx: `${configUrl?.cdnUri}/cdn/word.png`,
    ppt: `${configUrl?.cdnUri}/cdn/ppt.png`,
    csv: `${configUrl?.cdnUri}/cdn/cvs.png`,
    tsv: `${configUrl?.cdnUri}/cdn/txt.png`,
    // defaultVideo:`${cdnUri}/cdn/thumbnail.png`,
    // defaultAttach: `${cdnUri}/cdn/txt.png`
  };

  document.onpaste = function (e) {
    return e;
    // if e.target is not a child of quill editor, then return
    // if (!e.target.closest('.ql-editor')) {
    //   return e;
    // }

    // const quill = quillRef?.current?.getEditor();
    // if (quill) {
    //   if (e && e.clipboardData) {
    //     const { items } = e.clipboardData;
    //     for (let i = 0; i < items.length; i++) {
    //       if (e.clipboardData.items[i].type === 'text/plain') {
    //         const textData = e.clipboardData.getData('text/plain');
    //         const str = textData?.replace(/(\r\n|\r|\n)/g, '<br>');
    //         setValue((pre) => {
    //           if (pre) {
    //             return pre + str;
    //           }
    //           return str;
    //         });
    //         setTimeout(() => {
    //           if (quill.getLength()) {
    //             const length = quill.getLength();
    //             quill.setSelection(length, 0);
    //           }
    //         }, 1);
    //       }
    //     }
    //     return false;
    //   }
    // }
    // return e;

    // if (getParentClassList(e)) {
    //   if (props?.usedOn === 'cannedRes') {
    //     const { items } = e.clipboardData;
    //     if (items[0].kind === 'file') {
    //       showToast({
    //         title: 'Please Upload the image once the ticket is created',
    //         status: 'error',
    //         description:
    //           'Pasting images is only supported in existing ticket / Pasting any Document is not allowed here',
    //       });
    //       return false;
    //     }
    //   } else if (editorvalue !== '') {
    //     const maxSizeBytes = 1048576; // 1mb
    //     const targetElement = e.target;
    //     const { className } = targetElement;

    //     if (className?.toLowerCase() === '') {
    //       const { items } = e.clipboardData;
    //       for (let i = 0; i < items.length; i++) {
    //         if (IMAGE_MIME_REGEX.test(items[i].type)) {
    //           setCurrentUploadType('image');
    //           setIsLoadingEditor(true);

    //           if (items[i].getAsFile().size > maxSizeBytes) {
    //             toast({
    //               title: 'File size exceeds the maximum allowed size.',
    //               description: 'Only 1MB file size allowed, please try again',
    //               status: 'error',
    //               duration: 3000,
    //               position: 'top-right',
    //               isClosable: true,
    //             });
    //             setIsLoadingEditor(false);
    //             return false;
    //           }
    //           fileUploadWithCaseId(items[i].getAsFile(), 'image');
    //           return false;
    //         }
    //         if (items[0].kind === 'file') {
    //           toast({
    //             title: 'File Not Supported',
    //             description:
    //               'Only png, jpeg and jpg format are supported, please try again',
    //             status: 'error',
    //             duration: 3000,
    //             position: 'top-right',
    //             isClosable: true,
    //           });
    //         }
    //       }
    //     }
    //   } else {
    //     // const targetElement = e.target;
    //     const { items } = e.clipboardData;
    //     if (items[0].kind === 'file') {
    //       toast({
    //         title: 'Please Upload the image once the ticket is created',
    //         description: 'Pasting images is only supported in existing ticket',
    //         status: 'error',
    //         duration: 3000,
    //         position: 'top-right',
    //         isClosable: true,
    //       });
    //       return false;
    //     }
    //   }
    // }

    // return false;
  };
  useEffect(() => {
    if (linkOfUploadFile !== '' && typeof linkOfUploadFile !== 'undefined') {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      const imageUrl = linkOfUploadFile?.identifier; // linkOfUploadFile?.doc;
      const docName = linkOfUploadFile?.filename; // linkOfUploadFile?.docName;
      const otherFileExtensions = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'bmp',
        'txt',
        'pdf',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'ppt',
        'csv',
        'tsv',
      ];
      if (isAttachment === false) {
        if (
          currentUploadType !== '' &&
          otherFileExtensions.includes(currentUploadType)
        ) {
          quill.insertText(range.index, '\u00A0', 'user');
          quill.insertEmbed(range.index, 'image', {
            src: imageUrl,
            myCustomAttribute: docName,
            height: 100,
            width: 100,
          });
        } else {
          quill.insertEmbed(
            range.index,
            'insertVideo',
            {
              url: imageUrl,
              controls: 'controls',
              width: '50%',
              height: '100%',
            },
            'user'
          );
        }
      } else if (isAttachment === true) {
        setAttachmentModel(false);
        if (Object.keys(iconList).includes(currentUploadType)) {
          quill.insertText(range.index, '\u00A0', 'user');
          quill.insertEmbed(range.index, 'image', {
            src: iconList[currentUploadType],
            myCustomAttribute: docName,
            height: 100,
            width: 100,
          });
        } else {
          quill.insertText(range.index, '\u00A0', 'user');
          quill.insertEmbed(range.index, 'image', {
            src: imageUrl,
            myCustomAttribute: docName,
            height: 100,
            width: 100,
          });
        }
      }
      setIsLoadingEditor(false);
      setIsAttachment(false);
    }
    if (errOnUpload) {
      showToast({
        title: 'error',
        status: 'error',
        description: errOnUpload,
      });
    }
    setIsLoadingEditor(false);
  }, [errOnUpload, linkOfUploadFile]);

  useEffect(() => {
    const handleClick = (targetClasses, excludeClass) => {
      targetClasses.forEach((className) => {
        const element = document.querySelector(`.${className}`);
        if (element && className !== excludeClass) {
          element.classList.remove('ql-expanded');
        }
      });
    };

    const handleElementClick = (className) => {
      const targetClasses = [
        'ql-color',
        'ql-background',
        'ql-align',
        'ql-size',
        'ql-font',
      ];
      handleClick(targetClasses, className);
    };

    const addEventListeners = () => {
      const elements = [
        'ql-color',
        'ql-background',
        'ql-align',
        'ql-size',
        'ql-font',
      ];

      elements.forEach((className) => {
        const element = document.querySelector(`.${className}`);
        if (element) {
          element.addEventListener('click', () =>
            handleElementClick(className)
          );
        }
      });
    };

    const removeEventListeners = () => {
      const elements = [
        'ql-color',
        'ql-background',
        'ql-align',
        'ql-size',
        'ql-font',
      ];

      elements.forEach((className) => {
        const element = document.querySelector(`.${className}`);
        if (element) {
          element.removeEventListener('click', () =>
            handleElementClick(className)
          );
        }
      });
    };

    const timer = setTimeout(() => {
      addEventListeners();
    }, 2000);

    return () => {
      clearTimeout(timer);
      removeEventListeners();
    };
  }, []);

  const handleFileChangeAttachment = (event) => {
    setIsAttachment(true);
    const fileType = event.target.files[0].name.split('.').pop().toLowerCase();
    setCurrentUploadType(fileType);
    setAttachmentFile(event.target.files[0]);
    setAttachmentFileErr('');
    fileUploadWithCaseId(event.target.files[0], fileType);
  };
  const handleAttachmentUploadCancel = () => {
    setAttachmentFileErr('');
    setAttachmentModel(false);
    setAttachmentFile('');
  };
  const handleAttachmentUpload = () => {
    const fileExtension = attachmentFile?.name
      ?.split('.')
      ?.pop()
      ?.toLowerCase();
    const otherFileExtensions = [
      'png',
      'jpg',
      'jpeg',
      'gif',
      'bmp',
      'txt',
      'pdf',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'ppt',
      'csv',
      'tsv',
    ];
    if (attachmentFile !== '' && otherFileExtensions.includes(fileExtension)) {
      setAttachmentModel(false);
      setIsLoadingEditor(true);
      const fileType = attachmentFile.name.split('.').pop().toLowerCase();
      fileUploadWithCaseId(attachmentFile, fileType);
    } else {
      setAttachmentFileErr('Please select a valid file to upload');
      setAttachmentFile('');
    }
  };

  // Image Upload Functions
  const openImageFileUpload = useCallback(() => {
    setShowImageUpload(true);
    setShowImageEmbaded(false);
    setAttachmentErrMsg('')
    setImageUrlText('')
  }, []);
  const showImageUploadModal = () => {
    setImageModel(true);
    openImageFileUpload();
  };
  const openImageUploadText = () => {
    setShowImageUpload(false);
    setShowImageEmbaded(true);
  };

  const handleUploadImage = () => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection(true);
    const imgTag = `<p><br/></p><img uniqueattr="${imageUrl?.blobName}" src="${
      imageUrl?.imgUrl || imageUrlText
    }" alt="${imageAlt}" ${
      imageHeight && imageWidth
        ? `style="height: ${imageHeight}px !important; width: ${imageWidth}px !important"`
        : ''
    } />`;
    quill.clipboard.dangerouslyPasteHTML(range.index, imgTag, 'user');
    setImageModel(false);
    setImageFile(null);
    setImageUrl(null);
    setImageAlt('');
    setImageHeight('');
    setImageWidth('');
  };

  const handleImageUrlChange = (e) => {
    setImageUrlText(e.target.value);
    const isValid = isValidImageUrl(e.target.value);
    return !isValid ? (setAttachmentErrMsg('Invalid image url. Please enter valid a secure url.'), false) : (setAttachmentErrMsg(''), true);
  };

  const handleCancel = () => {
    // Reset Image States
    setShowImageUpload(false);
    setShowImageEmbaded(false);
    setImageModel(false);
    setImageUrl(null);
    setImageFile(null);
    setAttachmentErrMsg('');
    setImageHeight(null);
    setImageWidth(null);
    setImageAlt('');
    setCurrentUploadType('');
    setIsLoadingEditor(false);
    setImageUrlText('');
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    if (event.dataTransfer.files[0]) {
      setIsLoadingEditor(true);
      setImageFile(event.dataTransfer.files[0]);
      // TODO: upload image
      // props.uploadImages(event.dataTransfer.files[0])
      //   .then((res) => {
      //     setIsLoadingEditor(false);
      //     setImageUrl(res.data.link);
      //   });
    }
  }, []);

  const handleFileInputChange = (event) => {
    if (event.target.files) {
      setIsLoadingEditor(true);
      setImageFile(event.target.files[0]);
      const inputAccept = ['gif', 'jpeg', 'jpg', 'png'];
      const maxUploadSize = 10;
      const fileFormat = event.target.files[0].name
        .split('.')
        .pop()
        .toLowerCase();
      if (!inputAccept.includes(fileFormat)) {
        setAttachmentErrMsg(
          `Please Upload only ${inputAccept.join(',')} extension File!`
        );
        setIsLoadingEditor(false);
        return;
      }
      const { size } = event.target.files[0];
      const fileSize = parseFloat(size / 1024 / 1024);
      if (fileSize > maxUploadSize) {
        setAttachmentErrMsg(`File Size must be less than ${maxUploadSize}MB`);
        setIsLoadingEditor(false);
      }

      postImage(event.target.files[0]).then((res) => {
        setIsLoadingEditor(false);

        if (res) {
          getImageUrl(res.blobName).then((imgRes) => {
            setImageUrl({
              imgUrl: imgRes,
              blobName: res.blobName,
            });
          });
        }
        setAttachmentErrMsg('');
      });
    }
  };

  const modules = useMemo(
    () => ({
      history: {
        delay: 2500,
        maxStack: 50,
        userOnly: false,
      },
      toolbar: {
        container: toolBarModules,
        handlers: {
          undo() {
            quillRef.current.getEditor()?.history?.undo();
          },
          redo() {
            quillRef.current.getEditor()?.history?.undo();
          },
          image: () => {
            setImageModel(true);
            showImageUploadModal();
          },
          video: () => {
            // setVideoModel(true);
            // openVideoFileUpload();
          },
          attachment: () => {
            setAttachmentModel(true);
            setAttachmentFile('');
          },
        },
      },
      mention: mentionObj,
    }),
    []
  );
  const editorStyles = {
    height: '154px',
  };
  const handleChangeEditor = (newValue) => {
    let updatedValue = newValue

    // If the editor content is completely cleared, it leaves behind "<p><br></p>", 
    // which indicates an empty rich text box. We reset it to 'undefined' to avoid 
    // sending unnecessary data when the rich text editor is empty.
    if(updatedValue === '<p><br></p>'){
      updatedValue = undefined
    }
    const quill = quillRef.current?.getEditor();
    const mentions = [];

    if (quill) {
      quill.getContents().ops.forEach((op) => {
        if (op.insert && op.insert.mention) {
          mentions.push(op.insert.mention);
        }
      });

      onMentionAdd(mentions);
    }

    const str = updatedValue?.replace(/(\r\n|\r|\n)/g, '<br>');
    setValue(str);
    handleOnChange(str, mentions);
  };
  useEffect(() => {
    if (readOnly && attachmentModel) {
      setAttachmentModel(false);
    }
  }, [attachmentModel, readOnly]);

  return (
    <Box style={customEditorStyles || { height: '248px' }} position="relative">
      <div style={label ? { marginBottom: '.5rem', display: 'flex' } : {}}>
        <Text className="SCLabel">{label}</Text>
        {isMandatory && (
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        )}
      </div>
      {isLoadingEditor && <Progress size="xl" height={2} isIndeterminate />}
      <ReactQuill
        value={value}
        ref={quillRef}
        modules={modules}
        placeholder={placeholder ?? 'Enter Description'}
        theme="snow"
        id="editor"
        onChange={handleChangeEditor}
        style={customEditorContainerStyles ?? editorStyles}
        readOnly={readOnly || false}
        data-testid={testId}
        onBlur={onBlur}
      />
      <Box marginTop="40px" height="10px">
        {isError && errorMessage && (
          <span className="field-error">{errorMessage}</span>
        )}
      </Box>
      {
        showClearButton && (
          <SecondaryCustomButton
            onClick={() => {
              setValue('');
              handleOnChange('');
              setTimeout(() => {
                setValue('');
                handleOnChange('');
              }, 100);
            }}
            style={{ marginTop: '10px' }}
            disabled={readOnly}
            buttonText="Clear"
          />
        )
      }
      {attachmentModel && (
        <CustomModal
          open={attachmentModel && !readOnly}
          setOpen={setAttachmentModel}
          id="attachmentModal01"
          modalTitle="Select File to Attach"
          size="sm"
          modalContent={
            <>
              {!attachmentFile && (
                <div>
                  <div>
                    {/* <h1 className="modelHeading">Select File to Attachment</h1> */}
                    <div className="custom_upload">
                      <input
                        className="videoFileInput"
                        type="file"
                        name="myFile"
                        onChange={handleFileChangeAttachment}
                      />
                    </div>
                  </div>
                </div>
              )}
              {attachmentFile && (
                <div style={{ marginTop: '10px' }}>
                  <span>
                    Selected File Name -
                    {attachmentFile.name}
                  </span>
                </div>
              )}
              {attachmentFileErr !== '' && (
                <div className="error">{attachmentFileErr}</div>
              )}
            </>
          }
          modalFooter={
            <Flex className="rdw-image-modal-btn-section btnupload">
              <Button
                className="rdw-image-modal-btn"
                onClick={handleAttachmentUpload}
                type="button"
              >
                Submit
              </Button>
              <Button
                className="rdw-image-modal-btn"
                style={{ marginLeft: '10px' }}
                onClick={handleAttachmentUploadCancel}
              >
                Cancel
              </Button>
            </Flex>
          }
        />
      )}
      {imageModel && (
        <Box ref={imageModalRef}>
          <Box
            style={{
              zIndex: '1000',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              backgroundColor: 'white',
              position: 'absolute',
              top: '29%',
              right: '1%',
              padding: '5px',
              width: '235px',
              height: 'auto',
            }}
          >
            <Box>
              <Tabs>
                <TabList display="flex" justifyContent="space-evenly">
                  <Tab onClick={openImageFileUpload}>File Upload</Tab>
                  <Tab onClick={openImageUploadText}>URL</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {showImageUpload && (
                      <Box>
                        {!imageUrl && (
                          <Box
                            onClick={() =>
                              document
                                .querySelector('input[type="file"]')
                                .click()
                            }
                            backgroundColor="#f1f1f1"
                            padding="5px"
                            marginBottom="10%"
                            position="relative"
                          >
                            {isLoadingEditor && (
                              <Spinner
                                position="absolute"
                                top="30%"
                                left="45%"
                                transform="translate(-50%, -50%)"
                                zIndex="1000"
                              />
                            )}
                            <Box
                              style={{
                                border: '2px dashed black',
                                padding: '10px 0px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                display: 'block',
                                marginTop: '1%',
                                width: '100%',
                              }}
                              onDrop={handleDrop}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              <Input
                                type="file"
                                accept="image/gif,image/jpeg,image/jpg,image/png"
                                style={{ display: 'none' }}
                                onChange={handleFileInputChange}
                                ref={(input) => input && (input.value = '')}
                              />
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  variant="link"
                                  color="black"
                                  marginRight="1%"
                                  sx={{
                                    textDecoration: 'none',
                                    fontWeight: 'normal',
                                    _hover: { textDecoration: 'none' },
                                    fontSize: '12px',
                                  }}
                                  isDisabled={isLoadingEditor}
                                >
                                  Drop the file or click to upload
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {imageUrl && (
                          <Box
                            padding="5px"
                            marginBottom="10%"
                            style={{
                              border: '2px dashed black',
                              padding: '10px 0px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              display: 'block',
                              marginTop: '1%',
                              width: '100%',
                            }}
                          >
                            <Box>
                            <Image src={imageUrl.imgUrl} className="!w-full !h-full" />
                            </Box>
                          </Box>
                        )}

                        {attachmentErrMsg && (
                          <Box className="error" color="red">
                            {attachmentErrMsg}
                          </Box>
                        )}
                        <Box marginBottom="10%">
                          <Box
                            style={{
                              display: 'flex',
                              fontSize: '12px',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              marginBottom: '10%',
                            }}
                          >
                            <Text style={{ marginRight: '5px' }}>
                              Alt Text
                            </Text>
                            <Box>
                              <Input
                                type="text"
                                name="alt"
                                placeholder="alt"
                                fontSize="12px"
                                height="13px"
                                padding={0}
                                style={{
                                  border: '1px solid #d3d3d3',
                                  width: '100%',
                                }}
                                onChange={(e) => setImageAlt(e.target.value)}
                                value={imageAlt}
                              />
                            </Box>
                          </Box>
                          {props?.pixels && (
                            <Box display="flex" alignItems="center">
                              ↕&nbsp;
                              <Input
                                type="number"
                                name="height"
                                placeholder="auto"
                                fontSize="12px"
                                height="13px"
                                padding={0}
                                value={imageHeight}
                                style={{ border: '1px solid #d3d3d3' }}
                                onChange={(e) => setImageHeight(e.target.value)}
                              />
                              &nbsp;
                              <Text style={{ color: 'red' }}>*</Text>
                              &nbsp;↔&nbsp;
                              <Input
                                type="number"
                                name="width"
                                placeholder="auto"
                                fontSize="12px"
                                height="13px"
                                padding={0}
                                value={imageWidth}
                                style={{ border: '1px solid #d3d3d3' }}
                                onChange={(e) => setImageWidth(e.target.value)}
                              />
                              &nbsp;
                              <Text style={{ color: 'red' }}>*</Text>
                            </Box>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            backgroundColor="white"
                            border="1px solid #d3d3d3"
                            onClick={handleUploadImage}
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid #d3d3d3',
                              fontWeight: 'normal',
                            }}
                            isDisabled={!imageFile || attachmentErrMsg}
                          >
                            Add
                          </Button>
                          <Button
                            backgroundColor="white"
                            border="1px solid #d3d3d3"
                            style={{
                              marginLeft: '10px',
                              backgroundColor: 'white',
                              border: '1px solid #d3d3d3',
                              fontWeight: 'normal',
                            }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {showImageEmbaded && (
                      <Box>
                        <Input
                          onChange={handleImageUrlChange}
                          type="text"
                          name="width"
                          placeholder=""
                          fontSize="16px"
                          padding={0}
                          value={imageUrlText}
                          style={{
                            border: '1px solid #d3d3d3',
                            marginBottom: '10%',
                            height: '40px',
                          }}
                        />
                        {attachmentErrMsg && imageUrlText && (
                          <div className="error">
                            Invalid image url. Please enter valid a secure url.
                          </div>
                        )}
                        <Box marginBottom="10%">
                          <Box
                            style={{
                              display: 'flex',
                              fontSize: '12px',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              marginBottom: '10%',
                            }}
                          >
                            <Text style={{ marginRight: '5px' }}>
                              Alt&nbsp;Text
                            </Text>
                            <Box>
                              <Input
                                type="text"
                                name="alt"
                                placeholder="alt"
                                fontSize="12px"
                                height="13px"
                                padding={0}
                                value={imageAlt}
                                style={{
                                  border: '1px solid #d3d3d3',
                                  width: '100%',
                                }}
                                onChange={(e) => setImageAlt(e.target.value)}
                              />
                            </Box>
                          </Box>
                          {props?.pixels && (
                            <Box display="flex" alignItems="center">
                              ↕&nbsp;
                              <Input
                                type="number"
                                name="height"
                                placeholder="auto"
                                fontSize="12px"
                                height="13px"
                                padding={0}
                                value={imageHeight}
                                style={{ border: '1px solid #d3d3d3' }}
                                onChange={(e) => setImageHeight(e.target.value)}
                              />
                              &nbsp;
                              <Text style={{ color: 'red' }}>*</Text>
                              &nbsp;↔&nbsp;
                              <Input
                                type="number"
                                name="width"
                                placeholder="auto"
                                fontSize="12px"
                                height="13px"
                                padding={0}
                                value={imageWidth}
                                style={{ border: '1px solid #d3d3d3' }}
                                onChange={(e) => setImageWidth(e.target.value)}
                              />
                              &nbsp;
                              <Text style={{ color: 'red' }}>*</Text>
                            </Box>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            backgroundColor="white"
                            border="1px solid #d3d3d3"
                            onClick={handleUploadImage}
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid #d3d3d3',
                              fontWeight: 'normal',
                            }}
                            isDisabled={!imageUrlText || attachmentErrMsg}
                          >
                            Add
                          </Button>
                          <Button
                            backgroundColor="white"
                            border="1px solid #d3d3d3"
                            style={{
                              marginLeft: '10px',
                              backgroundColor: 'white',
                              border: '1px solid #d3d3d3',
                              fontWeight: 'normal',
                            }}
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
QuillEditor.propTypes = {
  caseId: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  editorvalue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  usedOn: PropTypes.string.isRequired,
  customEditorStyles: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  toolBarModules: PropTypes.array,
  errOnUpload: PropTypes.string,
  readOnly: PropTypes.bool,
  mentionObj: PropTypes.object,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  format: PropTypes.string,
  customEditorContainerStyles: PropTypes.object,
  testId: PropTypes.string,
  focus: PropTypes.bool,
  onBlur: PropTypes.func,
  showClearButton: PropTypes.bool,
  onMentionAdd: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
};
QuillEditor.defaultProps = {
  toolBarModules: [],
  errOnUpload: '',
  readOnly: false,
  mentionObj: undefined,
  label: '',
  isMandatory: false,
  format: '',
  customEditorContainerStyles: {},
  testId: '',
  focus: false,
  onBlur: () => {},
  showClearButton: false,
  onMentionAdd: () => {},
  isError: false,
  errorMessage: '',
  onMentionAdd: () => {},
};
export default QuillEditor;

export const metaTableAttributeList = [
  {
    key: 'enable_search',
    name: 'Enable Search'
  },
  {
    key: 'enable_quick_search',
    name: 'Enable Quick Filter '
  },
  {
    key: 'enable_filter',
    name: 'Enable Filter'
  },
  {
    key: 'enable_reset',
    name: 'Enable Reset'
  },
  {
    key: 'enable_sort',
    name: 'Enable Sort',
  }
];
export const metaTableColumnAttributeList = [
  {
    name: 'Searchable',
    key: 'searchable'
  },
  // {
  //   name: 'Date Filter',
  //   key: 'date_filter '
  // },
  {
    name: 'Sortable',
    key: 'sortable'
  },
  // {
  //   name: 'Multi Valued',
  //   key: 'multi_valued '
  // },
  // {
  //   name: 'Visible',
  //   key: 'visible'
  // },
  // {
  //   name: 'Include in Global Search',
  //   key: 'include_in_global_search'
  // },
  {
    name: 'Movable',
    key: 'movable'
  }
];

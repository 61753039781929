import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomButton from '../../../components/Button/SimpleButton';
import { COLORS } from '../../../utils/enums/colors';
import CustomTextArea from '../../../components/Textarea/CustomTextArea';
import CustomToast from '../../../components/Notification/Notification';
import { StatusCategoriesColorOption } from '../../../utils/enums/selectOption';
import { findColorPalette } from '../helper';
// import styles from '../styles/CategoryList.module.scss';
import { ACTION_LABELS, TITLE_LABELS } from '../../../utils/enums/labels';
import StatusDeleteModal from './StatusDeleteModal';
import {
  addNewStateWorkFlowWithKeyValue,
  deleteCategoryFromWorkFlow,
  saveCategoryInWorkFlow,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { DangerCustomButton, PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const CreateEditCategory = ({ setAddingStatus, edit, setEditStatus }) => {
  const dispatch = useDispatch();
  const { selectedWorkFlow, selectedWorkFlowStatusCategory } = useSelector(
    (state) => state.workFlowMain
  );
  const [isEdited, setIsEdited] = useState(false);

  const { addToast } = CustomToast();

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const isValidName = (selectedWorkFlowStatusCategory.name || '').trim().length > 0;
  return (
    <Flex direction="column" data-testid="CreateEditCategoryFlex">
      <Box data-testid="CreateEditCategoryDetailBox">
        <h3
          className="text-[20px] semi-bold"
          data-testid="CreateEditCategoryH3"
        >
          {edit ? TITLE_LABELS.EDIT_CATEGORY : TITLE_LABELS.ADD_NEW_CATEGORY}
        </h3>
        <Box data-testid="CreateEditCategoryNameBox">
          <Text
            className="text-[14px] mt-[20px] mb-[4px] "
            data-testid="CreateEditCategoryNameText"
          >
            {TITLE_LABELS.NAME}
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </Text>
          <CustomInput
            onChange={(e) => {
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowStatusCategory',
                  value: {
                    ...selectedWorkFlowStatusCategory,
                    name: e.target.value,
                  },
                })
              );
              setIsEdited(true);
            }}
            value={selectedWorkFlowStatusCategory.name}
            placeholder=""
            helperText=" "
            errorMessage=""
            id="Categorynode_name"
          />
        </Box>
        <Box data-testid="CreateEditCategoryColorBox">
          <Text
            mt="20px"
            className="text-[14px]"
            data-testid="CreateEditCategoryColorText"
          >
            {TITLE_LABELS.COLOR}
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </Text>
          <Flex data-testid="CreateEditCategoryColorFlex">
            <Box w="100%" data-testid="CreateEditCategorySelectBox">
              <CustomSelectBox
                value={selectedWorkFlowStatusCategory.color}
                name="to status"
                options={StatusCategoriesColorOption}
                onChange={(e) => {
                  dispatch(
                    updateStateOfWorkFlowWithKey({
                      keyValue: 'selectedWorkFlowStatusCategory',
                      value: {
                        ...selectedWorkFlowStatusCategory,
                        color: e,
                      },
                    })
                  );
                  setIsEdited(true);
                }}
                id="CreateEditCategorySelectBox"
              />
            </Box>
            <Box
              p="4px"
              border="2px solid #E2E8F0"
              ml="5px"
              data-testid="CreateEditCategoryPalleteBox"
            >
              <Box
                h="28px"
                w="32px"
                bgColor={findColorPalette(
                  selectedWorkFlowStatusCategory?.color?.value
                )}
              />
            </Box>
          </Flex>
        </Box>
        <Box>
          <Text mt="20px" className="text-[14px]">
            {TITLE_LABELS.DESCRIPTION}
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </Text>
          <CustomTextArea
            onChange={(e) => {
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowStatusCategory',
                  value: {
                    ...selectedWorkFlowStatusCategory,
                    description: e.target.value,
                  },
                })
              );
              setIsEdited(true);
            }}
            value={selectedWorkFlowStatusCategory.description}
            placeholder=""
            isError={false}
            helperText=" "
            errorMessage=""
            height="221px"
            id="node_description"
          />
        </Box>
      </Box>
      {edit ? (
        <Box
          position="fixed"
          bottom="10px"
          data-testid="CreateEditCategoryEditBox"
          width="90%"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            data-testid="CreateEditCategoryDeleteButtonBox"
          >
            <DangerCustomButton
              id="CreateEditCategoryycreate-new-template-btn"
              buttonText={ACTION_LABELS.DELETE}
              onClick={() => {
                const isCategoryUsed = selectedWorkFlow.workflow_status.some(
                  (node) =>
                    node.data.categoryId === selectedWorkFlowStatusCategory.id
                );
                if (isCategoryUsed) {
                  addToast({
                    title: 'Unable to delete category',
                    message:
                      'The category can\'t be deleted as it is used in the canvas',
                    type: 'info',
                    duration: 5000,
                    isClosable: true,
                  });
                  return;
                }
                setIsDeleteModal(true);
              }}
            />

            <Box ml="25px" data-testid="CreateEditCategoryCancelButtonBox">
              <SecondaryCustomButton
                buttonText={ACTION_LABELS.CANCEL}
                onClick={() => {
                  dispatch(
                    updateStateOfWorkFlowWithKey({
                      keyValue: 'selectedWorkFlowStatusCategory',
                      value: {
                        name: '',
                        color: {},
                        description: '',
                      },
                    })
                  );
                  setEditStatus(false);
                  setAddingStatus(false);
                }}
                id="CreateEditCategoryycreate-Cancelate-btn"
              />
                <PrimaryCustomButton
                  isDisabled={
                    !(
                      isEdited &&
                      isValidName &&
                      selectedWorkFlowStatusCategory.color &&
                      selectedWorkFlowStatusCategory.description
                    )
                  }
                  buttonText={ACTION_LABELS.SAVE}
                  onClick={() => {
                    dispatch(
                      saveCategoryInWorkFlow({
                        value: selectedWorkFlowStatusCategory,
                        dispatch,
                      })
                    );
                    setEditStatus(false);
                    setAddingStatus(false);
                  }}
                  id="CreateEditCategoryycreate-nSavebtn"
                />
            </Box>
          </Box>
          <StatusDeleteModal
            statusName={selectedWorkFlowStatusCategory.name}
            isDeleteModal={isDeleteModal}
            setIsDeleteModal={setIsDeleteModal}
            onConfirmation={() => {
              setIsDeleteModal(false);
              setEditStatus(false);
              setAddingStatus(false);
              dispatch(
                deleteCategoryFromWorkFlow({
                  value: selectedWorkFlowStatusCategory,
                  dispatch,
                })
              );
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowStatusCategory',
                  value: {
                    name: '',
                    color: {},
                    description: '',
                  },
                })
              );
            }}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="flex-end"
          data-testid="CreateEditCategoryCancelBox"
        >
          <Box
            position="fixed"
            bottom="10px"
            data-testid="CreateEditCategoryButtonBox"
            gap="10px"
            display="flex"
          >
            <CustomButton
              variant="outline"
              pl="25px"
              pr="25px"
              buttonColor={COLORS.DEFAULT}
              buttonText={ACTION_LABELS.CANCEL}
              // style={{ border: 'none' }}
              _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
              onClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowStatusCategory',
                    value: {
                      name: '',
                      color: {},
                      description: '',
                    },
                  })
                );
                setAddingStatus(false);
              }}
              // size="md"
              classname="calculatedHeight"
              id="CreateEditCategoryycreate-new-Defaultt-btn"
            />
            <CustomButton
              isDisabled={
                !isValidName ||
                !selectedWorkFlowStatusCategory.color ||
                !selectedWorkFlowStatusCategory.description
              }
              buttonText={ACTION_LABELS.ADD}
              onClick={() => {
                dispatch(
                  addNewStateWorkFlowWithKeyValue({
                    keyValue: 'workflow_category',
                    value: {
                      ...selectedWorkFlowStatusCategory,
                      id: Date.now().toString(),
                    },
                    editTranstion: false,
                    dispatch,
                  })
                );
                setAddingStatus(false);
              }}
              variant="solid"
              pl="25px"
              pr="25px"
              buttonColor="customBlue"
              // size="md"
              classname="calculatedHeight"
              id="CreateEditCategoryycreate-new-Addtemp-btn"
            />
          </Box>
        </Box>
      )}
    </Flex>
  );
};
CreateEditCategory.propTypes = {
  setAddingStatus: PropTypes.func.isRequired,
  setEditStatus: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};
export default CreateEditCategory;

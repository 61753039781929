/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, SimpleGrid } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import SelectComponent from '../../../components/SelectBox/SelectComponent';
import {
  fieldColSpanOptions,
  fieldColorOptions,
  fieldFontOptions,
  slaOptions,
  layoutViewTypes,
  noteTypeOptions,
  badgeOptions,
} from '../../../utils/enums/selectOption';
import CustomInput from '../../../components/InputBox/Input';
import { LABELS } from '../../../utils/enums/labels';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import DynamicMutliSelectWithTags from '../../../components/dynamicSelectWithTags/DynamicSelectWithTags';
import { FieldTypes } from '../../../utils/enums/types';
import { CustomDrawer } from './AddEditTemplateLayoutModal';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import { getRelations } from '../../DynamicRenderer/Relation/relation.service';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { isEmpty } from 'lodash';

const getName = (fieldData) => {
  const name = fieldData?.name || fieldData?.label;
  if (fieldData?.type === 'SF_METRIC') {
    return `${name} (Metric)`;
  }
  if (fieldData?.type === 'SF_SLAS') {
    return `${name} (SLA)`;
  }
  return name;
};

const EditFieldLayoutModal = ({
  isOpen,
  setIsOpen,
  fieldData,
  onCancel,
  onConfirmation,
  viewType,
}) => {
  const [fieldColSpan, setfieldColSpan] = useState(fieldData?.width);
  const [isLoading, setIsloading] = useState(false);
  const [fieldRowSpan, setfieldRowSpan] = useState(fieldData?.height);
  const [error, setError] = useState('');
  const [fieldFontSize, setFieldFontSize] = useState(fieldData?.fieldFontSize);
  const [fieldFontColor, setFieldFontColor] = useState(
    fieldData?.fieldFontColor
  );
  const [slaViewType, setSlaViewType] = useState(fieldData?.slaViewType);
  // const [fieldReadOnly, setFieldReadOnly] = useState(
  //   fieldData?.type_based_attributes?.read_only || false
  // );
  const [fontWeight, setFontWeight] = useState(fieldData?.fieldFontWeight);
  const [noteTypes, setNoteTypes] = useState(fieldData?.noteTypes || []);
  const [isNoteRequired, setIsNoteRequired] = useState(
    fieldData?.isNoteRequired || false
  );
  const [isBadge, setIsBadge] = useState(fieldData?.isBadge || false);
  const [badgeColors, setBadgeColors] = useState(fieldData?.badgeColors);
  const [isPriority, setIsPriority] = useState(fieldData?.isPriority || false);
  const [fieldName, setFieldName] = useState(fieldData?.label || '');
  const [relationIds, setRelationIds] = useState(fieldData?.relationIds || []);
  const [relations, setRelations] = useState([]);
  const { selectedTemplate } = useSelector(
    (reduxState) => reduxState.viewState
  );

 const dependentActorFields = useMemo(() => {
    if (!isEmpty(selectedTemplate?.fields)) {
      const filterField = selectedTemplate?.fields?.filter(
        (item) =>
          item.type === 'DROPDOWN' &&
          (item.type_based_attributes.collection.collection_type === 'DEPENDENT' ||
          item.type_based_attributes.collection.specialField === 'source' ||
          item.type_based_attributes.collection?.specialField === 'actor' ||
          item.type_based_attributes.collection?.specialField === 'queue')
      );
      return [...(filterField || [])].map((item) => ({
        value: item.id,
        label: item.label,
      }));
    }
    return [];
  }, []);

  let restrictIspriority = dependentActorFields.find(
    (field) => field.value == fieldData?.id)


  const modalFooter = (
    <Box data-testid="EditFieldLayoutModalBox" className="flex gap-3">
      <SecondaryCustomButton
        onClick={() => {
          onCancel();
        }}
        buttonText={LABELS.ACTIONS.CANCEL}
        id="EditFieldLayoutCancenBtn"
      />
      <PrimaryCustomButton
        onClick={() => {
          setIsloading(true);
          const commonData = {
            ...fieldData,
            width: fieldColSpan,
            height: fieldRowSpan || 1,
            // read_only: fieldReadOnly,
            fieldFontSize,
            fieldFontColor,
            fieldFontWeight: fontWeight,
            slaViewType,
            noteTypes,
            isNoteRequired,
            isBadge,
            isPriority,
            badgeColors,
          };

          const extendedData =
            fieldData?.type === 'SF_LINKED_TICKETS_CUSTOM'
              ? {
                  ...commonData,
                  label: fieldName,
                  relationIds,
                }
              : commonData;
              setTimeout(() => {
                setIsloading(false);
                onConfirmation(extendedData);
              }, 500);       
            }}
        buttonText="Save"
        isLoading={isLoading}
        d="EditFieldLayoutSaveBtn"
      />
    </Box>
  );

  useEffect(() => {
    if (selectedTemplate) {
      getRelations(selectedTemplate.id).then((res) => {
        setRelations(res);
      });
    }
  }, [selectedTemplate]);

  const relationOptionsMemo = useMemo(() => {
    if (!relations?.length) return [];

    return relations.map((relation) => ({
      label: relation.name,
      value: relation.id,
    }));
  }, [relations]);

  const handleChange = (e) => {
    let { value } = e.target;
    value = value.replace(/-/g, '');
    if (/^\d+$/.test(value)) {
      setfieldRowSpan(value);
      setError('');
    } else if (value === '') {
      setfieldRowSpan('');
      setError('Please enter a positive value.');
    } else {
      setError('Please enter a positive value.');
    }
  };

  return (
    <CustomDrawer
      modalTitle="Edit Field Layout"
      id="delete-modal"
      drawerBody={
        <>
          {fieldData?.type === 'SF_LINKED_TICKETS_CUSTOM' ? (
            <CustomInput
              value={fieldName}
              onChange={(e) => {
                setFieldName(e.target.value);
              }}
              label="Name"
            />
          ) : (
            <div>{getName(fieldData)}</div>
          )}
          <SimpleGrid columns={2} spacing={4} mt="4">
            <FormControl data-testid="EditFieldLayoutModalFormControl">
              <FormLabel data-testid="EditFieldLayoutModalFormLabel">
                Colspan(s)
              </FormLabel>
              <SelectComponent
                options={fieldColSpanOptions}
                value={fieldColSpan}
                handleChange={(value) => setfieldColSpan(value)}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
              />
            </FormControl>
            <FormControl data-testid="EditFieldLayoutModalCIFormControl">
              <FormLabel>RowSpan(s)</FormLabel>
              <CustomInput
                value={fieldRowSpan}
                // onChange={(e) => {
                //   setfieldRowSpan(e.target.value);
                // }}
                onChange={handleChange}
                id="EditFieldCIDivCInput"
              />
              {error && (
                <Text color="red.500" fontSize="sm" mt="1">
                  {error}
                </Text>
              )}
            </FormControl>
            {fieldData?.type === 'SF_SLAS' && (
              <FormControl data-testid="EditFieldLayoutModalSLAType">
                <FormLabel>SLA View Type </FormLabel>
                <SelectComponent
                  options={slaOptions}
                  value={slaViewType}
                  handleChange={(value) => {
                    setSlaViewType(value);
                  }}
                  focusBorderColor={useColorModeValue(LIGHT, DARK)}
                />
              </FormControl>
            )}
            {fieldData?.type !== 'SF_SLAS' &&
              viewType === layoutViewTypes.INBOX && (
                <>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <FormLabel>Font Size</FormLabel>
                    <SelectComponent
                      options={fieldFontOptions}
                      value={fieldFontSize}
                      handleChange={(value) => {
                        setFieldFontSize(value);
                        const selectedOption = fieldFontOptions.find(
                          (option) => option.value === value
                        );
                        setFontWeight(
                          selectedOption ? selectedOption.fontWeight : ''
                        );
                      }}
                      focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    />
                  </FormControl>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <FormLabel>Font Color</FormLabel>
                    <SelectComponent
                      options={fieldColorOptions}
                      value={fieldFontColor}
                      isDisabled={isBadge}
                      handleChange={(value) => setFieldFontColor(value)}
                      focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    />
                  </FormControl>
                </>
              )}

            {/* For adding badge Types */}
            {fieldData?.type !== FieldTypes.DATE &&
              fieldData?.type !== FieldTypes.NUMBER &&
              fieldData?.type !== FieldTypes.TEXT &&
              fieldData?.type !== FieldTypes.TEXT_AREA &&
              fieldData?.type !== FieldTypes.RICH_TEXT &&
              fieldData?.type !== 'SF_SLAS' &&
              fieldData?.type !== 'SF_METRIC' &&
              fieldData?.type !== 'SF_NOTES' &&
              viewType === layoutViewTypes.INBOX && (
                <>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControlBadge">
                    <CustomCheckBox
                      onChange={() => setIsBadge(!isBadge)}
                      isChecked={isBadge}
                      checkBoxText="isBadge"
                    />
                  </FormControl>
                  {(restrictIspriority) ? (
                    ''
                  ): (
                  <FormControl data-testid="EditFieldLayoutModalCIFormControlBadgeColor">
                    <CustomCheckBox
                      onChange={() => setIsPriority(!isPriority)}
                      isChecked={isPriority}
                      checkBoxText="isPriority"
                      isDisabled={!isBadge}
                    />
                    {isPriority && (
                      <FormLabel mt={10}> Map Badge Color </FormLabel>
                    )}

                    {isPriority &&
                      fieldData?.type_based_attributes?.collection?.staticValues?.map(
                        (i) => (
                          <>
                            <FormLabel mt={4}>{i?.label}</FormLabel>
                            <SelectComponent
                              options={badgeOptions}
                              value={badgeColors?.[i?.label] || ''}
                              handleChange={(value) =>
                                setBadgeColors((prevBadgeColors) => ({
                                  ...prevBadgeColors,
                                  [i.label]: value,
                                }))
                              }
                              focusBorderColor={useColorModeValue(LIGHT, DARK)}
                            />
                          </>
                        )
                      )}
                  </FormControl>
                  )}
                </>
              )}
            {fieldData?.type === 'SF_NOTES' &&
              viewType === layoutViewTypes.TRANSITION && (
                <>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <DynamicMutliSelectWithTags
                      label="Note Type"
                      onChange={setNoteTypes}
                      selectOptions={noteTypeOptions}
                      values={noteTypes}
                      labelWeight="500"
                      runHandlerOnEmptyTags
                    />
                  </FormControl>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl" />
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <CustomCheckBox
                      onChange={() => setIsNoteRequired(!isNoteRequired)}
                      isChecked={isNoteRequired}
                      checkBoxText="Note Required for Transition?"
                    />
                  </FormControl>
                </>
              )}
            {/* <CustomCheckBox
              onChange={() => setFieldReadOnly(!fieldReadOnly)}
              isChecked={fieldReadOnly}
              checkBoxText="Read Only"
              isDisabled={false}
            /> */}
          </SimpleGrid>
          {fieldData?.type === 'SF_LINKED_TICKETS_CUSTOM' && (
            <Box>
              <CustomSelectBox
                options={relationOptionsMemo}
                placeholder="Select Relations"
                value={relationIds || ''}
                onChange={(value) => {
                  //   handleBasicErrorData('trigger');
                  setRelationIds(value);
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                label="Select Offer"
                isMulti
              />
            </Box>
          )}
        </>
      }
      drawerFooter={modalFooter}
      openDrawer={isOpen}
      setOpenDrawer={setIsOpen}
    />
  );
};

EditFieldLayoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fieldData: PropTypes.object.isRequired,
  viewType: PropTypes.string,
};

EditFieldLayoutModal.defaultProps = {
  viewType: '',
};
export default EditFieldLayoutModal;

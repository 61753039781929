import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomTable from '../../components/Table/Table';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';
import { outerRoutTableHight } from '../../utils/enums/constVariable';

const LinkedMetaFieldTableView = ({
  placeholder,
  columns,
  tableData,
  onRowClick,
  searchKey,
  addNewMetaField,
  setShowTableLayout,
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const filterData = debounce((e) => {
    const filteredFields = tableData.filter((field) =>
      field[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);

  useEffect(() => {
    setFilteredData([...tableData]);
  }, [tableData]);
  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  };
  return (
    <Box px="12px" data-testid="LinkedMetaFieldTableViewContainerBox">
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="LinkedMetaFieldTableViewContainerBoxFlex"
      >
        <Text style={{fontSize: '20px', fontWeight: '600' }}>Meta Fields</Text>
        <Flex
          gap="15px"
          flexWrap="wrap"
          data-testid="LinkedMetaFieldTableViewContainerFlex"
          width="50%"
          className='calculatedHeight'
        >
          <Box flex="1" data-testid="LinkedMetaFieldTableViewInnerBox" className='calculatedHeight'>
            <InputGroup size="l">
              <CustomInput
                placeholder={placeholder}
                type="text"
                size="sm"
                value={searchInputValue}
                onChange={(e) => {
                  setSearchInputValue(e.target.value);
                  filterData(e);
                }}
              />
                <InputRightElement
                  style={{ right: 12, cursor: 'pointer' }}
                  // eslint-disable-next-line react/no-children-prop
                  children={searchInputValue ? <CloseIcon color="gray.300" /> : <SearchIcon color="gray.300" />}
                  size="l"
                  onClick={handleClearInput}
                  className="calculatedHeight"
                />
            </InputGroup>
          </Box>
          <SecondaryCustomButton
            buttonText="Table Layout"
            onClick={() => {
              setShowTableLayout(true);
            }}
          />
          <PrimaryCustomButton
            buttonText="Add Meta Field"
            onClick={addNewMetaField}
          />
        </Flex>
      </Box>

      <Box my="16px" data-testid="LinkedMetaFieldTableViewTableContainerBox">
        <CustomTable
          columns={columns}
          tableData={filteredData}
          onRowClick={onRowClick}
          height={'calc(100vh - 25vh)'}
          title="Linked Meta Fields"
        />
      </Box>
    </Box>
  );
};

LinkedMetaFieldTableView.propTypes = {
  placeholder: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  addNewMetaField: PropTypes.func.isRequired,
  searchKey: PropTypes.string,
  setShowTableLayout: PropTypes.func,
};

LinkedMetaFieldTableView.defaultProps = {
  placeholder: 'Search',
  onRowClick: undefined,
  searchKey: 'label',
  setShowTableLayout: () => {},
};

export default LinkedMetaFieldTableView;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  Drawer,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  DrawerFooter,
  DrawerBody,
  Box,
  DrawerOverlay,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LABELS } from '../../utils/enums/labels';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import CustomInput from '../../components/InputBox/Input';
import CustomSelectBox from '../../components/SelectBox/Select';
import {
  STATUS_OPTION,
  getStatusObject,
  processErrors,
} from '../DynamicRenderer/AddEditSignatureDrawer/utils';
import QuillEditor from '../../components/QuillEditor';
import {
  COMMUNICATION_TEMPLATES,
  EMAIL_QUILL_TOOLBAR,
  CANNED_QUILL_TOOLBAR,
  EMAIL_TEMPLATE_KEY_MAP,
  genericCreateTemplatePayload,
  genericCreateTemplateValidation,
  parseAPIToCustomMention,
  parseAPIToRichTextMention,
  SEND_AS_OPTIONS,
  useMentionObj,
} from './utills';
import {
  patchDataForTemplateWithPath,
} from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import CustomMention from '../../components/Mention';
import HTMLPreviewModal from '../../components/PreviewModal/HTMLPreviewModal';
import { convertMentionToHTML } from '../../components/Mention/helpers';

const AddOrEditGenericCommunicationTemplateDrawer = ({
  setOpenDrawer,
  openDrawer,
  edit,
  selectedEmailTemplateValue,
  type,
  drawerTitle,
  defaultErrorMessage,
  successMessage,
  isHiddenNotificationLabel,
  testId,
}) => {
  const { mentionObj } = useMentionObj({ isEmailForm: true, isCannedNotes: type === 'CANNED_NOTES' });
  const [stateForEmail, setStateForEmail] = useState({});
  useEffect(() => {
    setStateForEmail({
      ...selectedEmailTemplateValue,
      [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]:
        selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] ?? 'text',
      [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]: selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
        ? parseAPIToCustomMention(selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || '')
        : parseAPIToRichTextMention(selectedEmailTemplateValue[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || ''),
    });
  }, [selectedEmailTemplateValue]);
  const [errorKeyList, setErrorKeyList] = useState({});
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { id: templateId } = useParams();

  const handleOnChange = (value, key) => {
    setStateForEmail((pre) => ({
      ...pre,
      [key]: value,
    }));
    setErrorKeyList((pre) => ({ ...pre, [key]: false }));
  };
  const addNewEmailTemplateInCommunicationTemplate = async (payload) => {
    setIsLoadingBtn(true);
    // const newData = [
    //   ...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || []),
    //   { ...payload },
    // ];

    const res = await patchDataForTemplateWithPath({
      id: templateId,
      data: {
        path: COMMUNICATION_TEMPLATES,
        operation: 'create',
        data: {
          ...payload,
        },
      },
      defaultErrorMessage,
      successMessage,
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
    setIsLoadingBtn(false);
    setOpenDrawer(false);
  };
  const updateEmailTemplateInCommunicationTemplate = async (payload) => {
    setIsLoadingBtn(true);
    const { id } = stateForEmail;

    const editedFieldIndex = [
      ...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || []),
    ].findIndex((item) => item.id === id);

    if (editedFieldIndex !== -1) {
      const updatedFields = [
        ...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || []),
      ];
      updatedFields[editedFieldIndex] = { ...payload };

      const res = await patchDataForTemplateWithPath({
        id: templateId,
        data: {
          path: COMMUNICATION_TEMPLATES,
          operation: 'update',
          id,
          data: {
            ...payload,
          },
        },
        defaultErrorMessage,
        successMessage,
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
      }
    }
    setIsLoadingBtn(false);
    setOpenDrawer(false);
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      data-testid={testId}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">{drawerTitle}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <CustomInput
            onChange={(e) => {
              handleOnChange(e.target.value, [EMAIL_TEMPLATE_KEY_MAP.NAME]);
            }}
            value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.NAME]}
            placeholder=""
            isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.NAME]}
            helperText=" "
            label="Name"
            isMandatory
            errorMessage={
              stateForEmail[EMAIL_TEMPLATE_KEY_MAP.NAME]
                ? 'Name is already exist'
                : 'Name is required'
            }
            data-testid="AddOrEditGenericCommunicationTemplateDrawerName"
            height="38px"
          />
          <Box mb={isHiddenNotificationLabel ? '0px' : '10px'}>
            <CustomSelectBox
              value={getStatusObject(
                stateForEmail[EMAIL_TEMPLATE_KEY_MAP.STATUS],
                STATUS_OPTION
              )}
              options={STATUS_OPTION}
              onChange={(e) => {
                handleOnChange(e.value, [EMAIL_TEMPLATE_KEY_MAP.STATUS]);
              }}
              label="Status"
              isMandatory
              isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.STATUS]}
              errorMessage="Status is required"
              aria-label="Status"
            />
          </Box>

          {isHiddenNotificationLabel === false && (
            <CustomInput
              onChange={(e) => {
                handleOnChange(e.target.value, [
                  EMAIL_TEMPLATE_KEY_MAP.NOTIFICATION_LABEL,
                ]);
              }}
              value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.NOTIFICATION_LABEL]}
              placeholder=""
              isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.NOTIFICATION_LABEL]}
              helperText=" "
              label="Notification Label"
              errorMessage="Notification label is required"
              data-testid="AddOrEditGenericCommunicationTemplateDrawerNotificationLabel"
              height="38px"
            />
          )}
          <Box mt="10px">
            <CustomSelectBox
              value={getStatusObject(
                stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS],
                SEND_AS_OPTIONS
              )}
              options={SEND_AS_OPTIONS}
              onChange={(e) => {
                handleOnChange(e.value, [EMAIL_TEMPLATE_KEY_MAP.SEND_AS]);
              }}
              label="Send as"
              isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.SEND_AS]}
              errorMessage="Send as is required"
              aria-label="send-as"
              isDisabled={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]}
            />
          </Box>
          {stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html' ? (
            <Box mt="20px" w="100%">
              <CustomMention
                label="Note Body"
                isMandatory
                value={stateForEmail[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]}
                onChange={(e) => {
                  handleOnChange(e, [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]);
                }}
                placeholder=""
                height="260px"
                isMulti
                multiLine
                isError={errorKeyList[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]}
                errorMessage="Note body is required"
                isCannedNotes={ type === 'CANNED_NOTES' ? true : false }
              />
            </Box>
          ) : (
            <Box mt="10px">
              <QuillEditor
                editorvalue={
                  stateForEmail[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY] || ''
                }
                toolBarModules={CANNED_QUILL_TOOLBAR}
                format=""
                customEditorContainerStyles={{ height: '120px' }}
                handleOnChange={(value) => {
                  handleOnChange(value, [EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]);
                }}
                label="Note Body"
                isMandatory
                mentionObj={mentionObj}
                showClearButton
                isError={!!errorKeyList[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY]}
                errorMessage="Note body is required"
              />
            </Box>
          )}

          <Box marginRight="10px" data-testid="AddEmailTemplateModal">
            <CustomButton
              buttonText="Preview"
              variant="outline"
              colorScheme="customBlue"
              style={{
                height: 30,
                marginTop: '8px',
              }}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={() => setIsModalOpen(true)}
              id="DynamicRendererSendMailPreviewBtnText"
            />
            <HTMLPreviewModal
              open={isModalOpen}
              closePreviewModal={() => setIsModalOpen(false)}
              title="Email Preview"
              content={isModalOpen ? [
                {
                  label: 'Note Body',
                  html: stateForEmail[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] === 'html'
                    ? convertMentionToHTML(
                      stateForEmail[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY],
                      selectedTemplate
                    )
                    : stateForEmail[EMAIL_TEMPLATE_KEY_MAP.RESPONSE_BODY],
                },
              ] : []}
            />
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: 'none',
              background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setOpenDrawer(false);
            }}
            size="lg"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={() => {
              setIsLoadingBtn(true);
              const checkNameUnique =
                [...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || [])]
                  ?.filter((item) => item.type === type)
                  .map((ins) => ins.name) || [];
              const payload = genericCreateTemplatePayload(
                stateForEmail,
                type,
                isHiddenNotificationLabel
              );
              const { error } = genericCreateTemplateValidation(
                edit ? [] : checkNameUnique,
                isHiddenNotificationLabel
              ).validate(payload, {
                abortEarly: false,
              });
              if (!isEmpty(error)) {
                setIsLoadingBtn(false);
                const tempError = processErrors(error.details);
                setErrorKeyList(tempError);
                return;
              }
              if (edit) {
                updateEmailTemplateInCommunicationTemplate(payload);
              } else {
                addNewEmailTemplateInCommunicationTemplate(payload);
              }
            }}
            size="lg"
            isLoading={isLoadingBtn}
            data-testid="AddOrEditGenericCommunicationTemplateDrawerButtonAdd"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

AddOrEditGenericCommunicationTemplateDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  selectedEmailTemplateValue: PropTypes.object,
  type: PropTypes.string.isRequired,
  drawerTitle: PropTypes.string.isRequired,
  defaultErrorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  isHiddenNotificationLabel: PropTypes.bool,
  testId: PropTypes.string,
};

AddOrEditGenericCommunicationTemplateDrawer.defaultProps = {
  selectedEmailTemplateValue: {},
  edit: false,
  isHiddenNotificationLabel: false,
  testId: '',
};

export default AddOrEditGenericCommunicationTemplateDrawer;

/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */

import { COLORS } from '../../../utils/enums/colors';
import { TemplateTypes } from '../../../utils/enums/types';
import { checkForV2 } from '../../../utils/helper';
import { canViewTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { createActorModel } from './actorsJson';
import { convertFieldType, convertMetaField, convertScope } from './helpers';
import '../../../index.scss';

export const botIntegrationJson = {
  name: 'bot-integration',
  label: 'Bot Integration',
  table_name: 'bot_integration',
  route: '/bot-integration',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'bot-integration',
      label: 'Bot Integration',
      // route: '/bot-integration/template/:id',
      show_label: false,
      type: 'container',
      views: [
        {
          name: 'leftTop_view',
          label: 'Ticket Template List Short',
          show_label: false,
          type: 'container',
          views: [
            {
              name: 'left_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              style: {
                display: 'flex',
                alignItems: 'Center',
                height: '100%',
              },

              elements: [
                {
                  name: 'backBtn',
                  label: '',
                  type: 'button',
                  show_label: false,
                  target_type: 'route',
                  icon: 'back.ico',
                  navigatetUrl: '/bot-integration',
                  classname: 'iconButton',
                  style: {
                    borderColor: '#2563EB',
                    // color: '#2563EB',
                    fontSize: '19px',
                    borderRadius: '100%',
                  },
                  styleDark: {
                    borderColor: '#2563EB',
                    // color: '#2563EB',
                    fontSize: '19px',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                  },
                },
                {
                  name: 'title',
                  type: 'dynamic_text',
                  value: '$name',
                  style: { fontSize: '20px', fontWeight: '600', display: 'flex', alignItems: 'center' },
                },
              ],
              actions: [
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 11,
                rows: [
                  {
                    i: 'backBtn',
                    w: 0.5,
                    h: 1,
                  },
                  {
                    i: 'title',
                    w: 5,
                    h: 1,
                  },
                  // {
                  //   i: 'edit',
                  //   w: 2,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'copy',
                  //   w: 1,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'delete',
                  //   w: 1,
                  //   h: 1,
                  // },
                  // {
                  //   i: 'view_json',
                  //   w: 1,
                  //   h: 1,
                  // },
                ],
              },
            }
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'left_view',
                w: 6,
                h: 1,
              },
            ],
          },
        },
        {
          name: 'ticket_template_tabs_view',
          label: 'Ticket Template Fields',
          show_label: false,
          type: 'tab_view',
          related_entity: 'ticket_template_fields',
          filter: [],
          style: {
            fontSize: '14px',
            color: '#6B7280'
          },
          views: [
            // ticket triggers *****************************************************************
            {
              name: 'ticket_triggers_tab',
              label: 'Ticket Triggers',
              show_label: false,
              isBasicSettingFields: true,
              isBotIntegration: true,
              type: 'container',
              style: { paddingTop: '15px', boxSizing: 'borderBox' },
              filter: [],
              views: [
                {
                  name: 'ticket_triggers_tabs_view',
                  label: 'Ticket Trigger Fields',
                  show_label: false,
                  type: 'tab_view',
                  related_entity: 'ticket_trigger_fields',
                  filter: [],
                  views: [
                    {
                      name: 'triggers_tab',
                      label: 'Triggers',
                      type: 'triggers_tab',
                      isBasicSettingFields: true,
                      isBotIntegration: true,
                      show_label: false,
                    },
                    {
                      name: 'bot_identification_fields_tab',
                      label: 'Bot Identification Fields',
                      type: 'bot_identification_fields_tab',
                      isBasicSettingFields: true,
                      isBotIntegration: true,
                      show_label: false,
                    },
                    {
                      name: 'silent_inputs_tab',
                      label: 'Silent Inputs',
                      type: 'silent_inputs_tab',
                      isBasicSettingFields: true,
                      isBotIntegration:true,
                      show_label: false,
                    },
                  ]
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'ticket_triggers_tabs_view',
                    w: 12,
                    h: 1,
                  }
                ],
              },
            },
// commented out for now as these are not ready yet. DRI-3457
            // card layout
            // {
            //   name: 'card_layouts_tab',
            //   label: 'Card Layouts',
            //   type: 'card_layouts_tab',
            //   isBasicSettingFields: true,
            //   isBotIntegration: true,
            //   show_label: false,
            //   // show: (state) =>
            //   //   state?.selectedTemplate?.properties?.template_type ===
            //   //   TemplateTypes.NESTED,
            // },
            // // link layout
            // {
            //   name: 'link_layout_tab',
            //   label: 'Link Layout',
            //   type: 'link_layout_tab',
            //   isBasicSettingFields: true,
            //   isBotIntegration: true,
            //   show_label: false,
            //   // show: (state) =>
            //   //   state?.selectedTemplate?.properties?.template_type ===
            //   //   TemplateTypes.NESTED,
            // },
            // // Bot user binding
            // {
            //   name: 'bot_user_binding_tab',
            //   label: 'Bot User Binding',
            //   type: 'bot_user_binding_tab',
            //   isBotIntegration: true,
            //   isBasicSettingFields: true,
            //   show_label: false,
            // },
          ],
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'leftTop_view',
            w: 12,
            h: 1,
          },
          {
            i: 'ticket_template_tabs_view',
            w: 12,
            h: 1,
          },
        ],
      },
    },
  ],
  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'bot-integration',
        w: 12,
        maxH: 'Infinity',
      },
    ],
  },
};

/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { stringToSlug } from '../TicketTemplateBuilder/utils/helper';

const TextAreaBox = ({
  name,
  placeholder,
  visibility,
  label,
  isError,
  isMandatory,
  parentName,
  rows,
  onChange,
  linkedSlugField,
  value,
  className,
  errorMessage,
  helperText
}) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
  <FormControl
    isInvalid={isError}
    isRequired={isMandatory}
    data-testid="TextAreaBoxFormControl"
  >
    {label && (
      <FormLabel className="SCLabel" data-testid="TextAreaBoxFormLabel">
        {label}
      </FormLabel>
    )}
    <Textarea
      value={parentName ? viewState[parentName]?.[value] ?? '' : value || ''}
      placeholder={placeholder}
      rows={rows}
      onChange={(e) => {
        dispatch(
          updateViewState({
            stateKey: parentName,
            value: {
              ...viewState[parentName],
              [value]: e.target.value,
              ...(linkedSlugField
                ? {
                    [linkedSlugField]: stringToSlug(
                      `${slugPrefix}_${e.target.value}`
                    ),
                  }
                : {}),
            },
          })
        );
        onChange(e);
      }}
      className={className}
      isRequired={isMandatory}
      data-testid="TextAreaBoxTextArea"
    />
            {!isError && helperText && <FormHelperText>{helperText}</FormHelperText>}
    {isError && errorMessage && (
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    )}
  </FormControl>
  );
};
TextAreaBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  visibility: PropTypes.bool.isRequired,
  label: PropTypes.string,
  isError: PropTypes.bool,
  isMandatory: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
};
TextAreaBox.defaultProps = {
  isError: false,
  label: '',
  isMandatory: false,
  rows: 5,
  className: '',
  helperText: '',
  errorMessage: '',
};
export default TextAreaBox;

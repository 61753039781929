import _ from 'lodash';
import { getDynamicValuesFromCurl, getQueueListForWholeTenant, getTitlesHierarchy } from '../../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { optionLabel } from '../../../utils/constants';
import { SCOPE_MAP } from '../../../utils/enums/selectOption';
import { FieldTypes } from '../../../utils/enums/types';
import { selectOptionsChannels } from '../customTemplateModal/CustomTemplateModalLayout';
import { availableUsersListInTenantLevel } from '../../../utils/helper/roleBasedAccess';
import { getSelectedFieldHierarchyMap, getSelectedHierarchy } from '../../../components/selectFieldFromTemplateWithDelete/utils';

export const makeRelationDefinitionOptions = (relationsList) => {
  const options = [];

  if (Array.isArray(relationsList)) {
    relationsList?.forEach((relation) => {
      options.push({
        label: relation?.string_pair_a,
        value: relation?.string_pair_a
      });
      options.push({
        label: relation?.string_pair_b,
        value: relation?.string_pair_b
      });
    });
  }

  return options;
};

export const makeRelationListOptions = (relationsList) => {
  if (Array.isArray(relationsList)) {
    return relationsList.map((relation) => ({
      label: `${relation?.string_pair_a}/${relation?.string_pair_b}`,
      value: relation?.id,
    }));
  }

  return [];
};
export const makeRelationDefinitionOptionValue = (id, relationDefinitions) => {
  const matchedField = relationDefinitions?.find((f) => f?.id === id);
  if (matchedField) {
    return {
      label: matchedField?.string_pair_a,
      value: matchedField?.id,
    };
  }
  return null;
};

export const findOtherRelationDefinitionString = (pairA, relationDefinitions) =>
  relationDefinitions?.find(
    (r) => r?.string_pair_a === pairA || r?.string_pair_b === pairA
  );

export const getPairedString = (pairA, relationDefinitions) => {
  const pair = findOtherRelationDefinitionString(pairA, relationDefinitions);

  if (pair?.string_pair_a === pairA) {
    return pair.string_pair_b;
  }
  return pair?.string_pair_a;
};

export const generateTemplateIdMap = (allTemplates) => {
  if (!Array.isArray(allTemplates)) {
    return {};
  }

  return allTemplates.reduce((acc, template) => {
    acc[template.id] = template;
    return acc;
  }, {});
};

export const generateTemplateOptions = (allTemplates) => {
  const templateOptions = [];
  if (!Array.isArray(allTemplates)) {
    return [];
  }

  allTemplates.forEach((template) => {
    templateOptions.push({
      value: template.id,
      label: template.name
    });
  }, []);

  return templateOptions;
};

export const generateOfferIdMap = (allOffers) => {
  if (!Array.isArray(allOffers)) {
    return {};
  }

  return allOffers.reduce((acc, offer) => {
    acc[offer.id] = offer;
    return acc;
  }, {});
};

export const generateOfferOptionsByTemplateId = (allOffers, allTemplates) => {
  const offers = allOffers || [];
  const templates = allTemplates || [];

  const offerByTemplateId = {
  };

  templates.forEach((template) => {
    const templateOffers = offers.filter((offer) => offer.item_type === template?.id);
    offerByTemplateId[template?.id] = [{
      value: 'none',
      label: 'None'
    }];
    templateOffers.forEach((offer) => {
      offerByTemplateId[template?.id].push({
        value: offer.id,
        label: offer.name
      });
    });
  });

  return offerByTemplateId;
};

export const generateFieldMap = (fields) => {
  if (!Array.isArray(fields)) {
    return {};
  }

  return fields.reduce((prev, curr) => {
    prev[curr?.id || curr?.value || ''] = curr;
    return prev;
  }, {});
};

export const getFieldsForSelect = (fields) => {
  if (!Array.isArray(fields)) {
    return [];
  }

  return fields.map((f) => ({
    label: f?.label,
    value: f?.id,
  }));
};

export const getRelationScopeFields = (fields, templateId) => {
  if (!Array.isArray(fields)) {
    return [];
  }

  return fields
    .filter((f) => f?.id && f?.scope === SCOPE_MAP.RELATION)
    .map((val) => ({
      template_id: templateId,
      label: val?.label,
      value: val?.id,
    }));
};

export const enrichDataForEdit = ({
  templateIdMap,
  data,
  currentTemplateId
}) => {
  const leftTemplate = templateIdMap[data?.template_id_left];
  const rightTemplate = templateIdMap[data?.template_id_right];

  const fieldsAIdMap = generateFieldMap(leftTemplate?.fields);
  const fieldsBIdMap = generateFieldMap(rightTemplate?.fields);

  const relationFieldsLeft = getRelationScopeFields(
    leftTemplate?.fields,
    data?.template_id_left,
  );

  const relationFieldsRight = getRelationScopeFields(
    rightTemplate?.fields,
    data?.template_id_right
  );

  const relationFieldsLeftIdMap = generateFieldMap(relationFieldsLeft);
  const relationFieldRightIdMap = generateFieldMap(relationFieldsRight);

  const relation = {
    name: data?.name?.replace(/\s+/g, ' ').trim(),
    id: data?.id,
    cardinality: data?.cardinality,
    template_id_left: data?.template_id_left || currentTemplateId,
    template_id_right: data?.template_id_right,
    offer_id_left: data?.offer_id_left || 'none',
    offer_id_right: data?.offer_id_right || 'none',
    relations_list_left: data?.relations_list_left || [],
    relations_list_right: data?.relations_list_right || [],
    default_relation: {
      left_to_right: data?.default_relation?.left_to_right || '',
      right_to_left: data?.default_relation?.right_to_left || ''
    },
  };

  relation.customAttributes = data
    ?.custom_attributes
    ?.map((attr) => {
      const matchedField = relationFieldsLeftIdMap[attr?.field_id]
        || relationFieldRightIdMap[attr?.field_id];

      if (!matchedField) {
        return null;
      }

      return {
        field: matchedField,
        mandatory: attr?.isRequired ? optionLabel.mandatory : optionLabel.optional,
      };
    })
    .filter((customAttributes) => !!customAttributes);
  relation.similarities = data?.similarity?.map((sim) => {
    const fieldOfA = fieldsAIdMap[sim?.left_field_id];
    const fieldOfB = fieldsBIdMap[sim?.right_field_id];
    return {
      fieldA: {
        value: fieldOfA?.id,
        label: fieldOfA?.label,
      },
      similar: '~',
      fieldB: {
        value: fieldOfB?.id,
        label: fieldOfB?.label,
      },
      mappings: sim?.mappings || []
    };
  });

  relation.similaritiesInverse = data?.similarity_inverse?.map((sim) => {
    const fieldOfA = fieldsBIdMap[sim?.left_field_id];
    const fieldOfB = fieldsAIdMap[sim?.right_field_id];
    return {
      fieldA: {
        value: fieldOfA?.id,
        label: fieldOfA?.label,
      },
      similar: '~',
      fieldB: {
        value: fieldOfB?.id,
        label: fieldOfB?.label,
      },
      mappings: sim?.mappings || []
    };
  });

  return relation;
};
export const fieldValueOption = async (template, workflowData, fieldId, hierarchies = []) => {
  const field = [...(template?.fields || [])]?.find((f) => f?.id === fieldId);
  let values;
  if (field?.type === FieldTypes.DROPDOWN || field?.type === FieldTypes.TAGS) {
    const collectionType = _.get(
      field,
      'type_based_attributes.collection.collection_type'
    );
    const specialFieldType = _.get(
      field,
      'type_based_attributes.collection.specialField'
    );
    if (collectionType === 'DYNAMIC') {
      values = _.get(field, 'type_based_attributes.collection.dynamicCurl'); // curlKey name is change
      return getDynamicValuesFromCurl(values) || [];
    }
    if (collectionType === 'STATIC') {
      values = _.get(field, 'type_based_attributes.collection.staticValues');
      if (typeof values === 'string') {
        values = values?.split(/\r?\n|\r|\n/g);
        const options = values?.map((v) => {
          if (!v) return null;
          return { value: v, label: v };
        });
        return options?.filter((x) => x);
      }
      return values?.filter((x) => x.value && x.label) || [];
    }
    if (collectionType === 'DEPENDENT') {
      if (template?.field_hierarchy_map) {
        const selectedMapping = getSelectedFieldHierarchyMap(
          template?.field_hierarchy_map, field.id
        );
        if (selectedMapping) {
          const selectedHierarchy = getSelectedHierarchy(
            hierarchies, selectedMapping.heirachyListId
          );

          const titles = getTitlesHierarchy(
            selectedHierarchy.hierarchy || [],
            selectedMapping
          );
          if (titles[field.id]) {
            return titles[field.id].map((item) => ({ value: item, label: item }));
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'queue') {
      const listFilter = _.get(field, 'type_based_attributes.collection.list');
      const isWhiteList = _.get(
        field,
        'type_based_attributes.collection.isWhiteList'
      );
      return getQueueListForWholeTenant(listFilter, isWhiteList);
    }
    if (
      collectionType === 'SPECIAL_FIELD' &&
      specialFieldType === 'workflowState'
    ) {
      return workflowData?.workFlowStates || [];
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'source') {
      return selectOptionsChannels;
    }

    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'actor') {
      const users = availableUsersListInTenantLevel();
      return users;
    }
  }
  return [];
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  Drawer,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  DrawerFooter,
  DrawerBody,
} from '@chakra-ui/react';
import { map, set, isEmpty, cloneDeep } from 'lodash';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { LABELS } from '../../utils/enums/labels';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import {
  DRAWER_TITLE,
  DRAWER_TITLE_EDIT,
  INITIAL_DEPENDENT_FIELD_DATA,
} from './utils';
import DependentFieldDynamicDropdown from './dependentFieldDynamicDropdown';

const EditDependentFieldDrawer = ({
  setOpenDrawer,
  openDrawer,
  selectedRowData,
  onSaveData,
}) => {
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    setRowData(cloneDeep(selectedRowData));
  }, []);

  const handleOnChange = (value, path) => {
    if (path === 'dependencies.id') {
      const newData = cloneDeep(INITIAL_DEPENDENT_FIELD_DATA);
      set(newData, path, value);
      setRowData(newData);
    } else {
      const newData = cloneDeep(rowData);
      set(newData, path, value);
      setRowData(newData);
    }
  };

  const onChangeDependentValues = (keyValue, value) => {
    const labelData = map(value, (item) => item.label);
    if (!isEmpty(keyValue)) {
      setRowData((prevData) => ({
        ...prevData,
        dependencies: {
          ...prevData.dependencies,
          values: {
            ...prevData.dependencies.values,
            [keyValue]: [...labelData],
          },
        },
      }));
    }
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      data-testid="EditQueueFieldDrawer"
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">
              {selectedRowData?.dependent_field_id
                ? DRAWER_TITLE_EDIT
                : DRAWER_TITLE}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <DependentFieldDynamicDropdown
            rowData={rowData}
            onChange={handleOnChange}
            onChangeDependentValues={(selectedLabel, tags) =>
              onChangeDependentValues(selectedLabel, tags)
            }
          />
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: 'none',
              background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setOpenDrawer(false);
            }}
            size="lg"
          />
          <CustomButton
            buttonText="Save"
            variant="solid"
            onClick={() => onSaveData(rowData)}
            style={{
              backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
              height: 40,
              color: '#fff',
            }}
            id="FilterEditModalButtonSaveBtn"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

EditDependentFieldDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  selectedRowData: PropTypes.object,
  onSaveData: PropTypes.func.isRequired,
};
EditDependentFieldDrawer.defaultProps = {
  selectedRowData: {},
};
export default EditDependentFieldDrawer;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { cloneDeep, isEmpty } from 'lodash';
import CustomToast from '../../components/Notification/Notification';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import CustomInput from '../../components/InputBox/Input';
import HierarchyTree from '../../components/HierarchyTree';
import { emptyHierarchy, getHierarchy, validateCreateHierarchy } from './utils';
import { processErrorWithMessage } from '../TemplateNewEventAction/helper';
import axiosInstance from '../../utils/axios/axios';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import { PrimaryCustomButton } from '../../components/Button/PrimarySecondaryButton';
import CustomCheckBox from '../../components/CheckBox/CheckBox';

const CreateEditHierarchyBuilder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [errorListWithKey, setErrorListWithKey] = useState({});
  const [hierarchyState, setHierarchyState] = useState({ ...emptyHierarchy });
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const { id } = useParams();

  const handleHierarchyCreation = async () => {
    try {
      setIsLoading(true);
      const { error } = validateCreateHierarchy.validate(hierarchyState, {
        abortEarly: false,
      });
      if (!isEmpty(error)) {
        setErrorListWithKey(processErrorWithMessage(error.details));
        setIsLoading(false);
        return;
      }
      const payload = cloneDeep(hierarchyState);
      payload.name = payload.name?.replace(/\s+/g, ' ').trim();
      const response = await axiosInstance.post('hierarchies/create', {
        data: hierarchyState,
      });
      if (response.data) {
        navigate('/hierarchy-builder');
        addToast({
          title: 'Success',
          message: 'Successfully created the hierarchy builder',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Error',
          message: 'Failed to create the hierarchy builder',
          type: 'error',
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      addToast({
        title: 'Something went wrong',
        message: 'Failed to create the hierarchy builder',
        type: 'error',
      });
      setIsLoading(false);
    }
  };
  const handleHierarchyUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = cloneDeep({
        name: hierarchyState.name?.replace(/\s+/g, ' ').trim(),
        description: hierarchyState.description,
        hierarchy: hierarchyState.hierarchy,
      });
      const { error } = validateCreateHierarchy.validate(payload, {
        abortEarly: false,
      });
      if (!isEmpty(error)) {
        setErrorListWithKey(processErrorWithMessage(error.details));
        setIsLoading(false);
        return;
      }
      const response = await axiosInstance.patch(
        `hierarchies/update?$filter=id eq ${id}`,
        {
          data: payload,
        }
      );
      if (response.data) {
        navigate('/hierarchy-builder');
        addToast({
          title: 'Success',
          message: 'Successfully updated the hierarchy builder',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Error',
          message: 'Failed to update the hierarchy builder',
          type: 'error',
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      addToast({
        title: 'Something went wrong',
        message: 'Failed to updated the hierarchy builder',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getHierarchy(id)
        .then((res) => {
          setEditLoader(true);
          if (res.error) {
            addToast({
              title: 'Error',
              message: 'Failed to create the hierarchy builder',
              type: 'error',
            });
            navigate('/hierarchy-builder');
          } else {
            const apiData = res?.response?.rows[0] || {};
            setHierarchyState({
              name: apiData.name,
              description: apiData.description,
              hierarchy: apiData.hierarchy,
              id: apiData.id,
              is_actor_hierarchy: apiData.is_actor_hierarchy,
            });
            setEditLoader(false);
          }
        })
        .catch(() => {
          addToast({
            title: 'Error',
            message: 'Failed to create the hierarchy builder',
            type: 'error',
          });
          navigate('/hierarchy-builder');
        });
    } else {
      setHierarchyState({ ...emptyHierarchy });
    }
    setEditLoader(false);
  }, [id]);

  if (editLoader) {
    return <Loader />;
  }
  return (
    <Box mx="12px">
      <Box borderBottom="1px solid #D1D5DB" pb="10px">
        <Text fontSize="20px" fontWeight="600" lineHeight="normal">
          Hierarchy Builder
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="CreateMailServer"
        mt="10px"
      >
        <Flex align="center">
          <CustomButton
            leftIcon={<ArrowBackIcon />}
            buttonText="Back"
            variant="outline"
            style={{ height: '32px' }}
            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            onClick={() => {
              navigate('/hierarchy-builder');
            }}
            mr="7px"
          />
          <Text style={{ fontWeight: 'bold' }}>
            {id ? 'Update Hierarchy' : 'Add New Hierarchy'}
          </Text>
        </Flex>
      </Box>
      <Flex
        mt="15px"
        justifyContent="flex-start"
        alignItems="center"
        gap="40px"
      >
        <div>
          <CustomInput
            label="Name"
            isMandatory
            value={hierarchyState.name || ''}
            onChange={(e) => {
              //  handleInputWithPath(e.target.value, 'host');
              setHierarchyState((pre) => ({ ...pre, name: e.target.value }));
              setErrorListWithKey((pre) => ({ ...pre, name: '' }));
            }}
            isError={errorListWithKey?.name || false}
            errorMessage={errorListWithKey?.name || ''}
            w="30vw"
            h="32px"
          />
        </div>
        <div>
          <CustomInput
            label="Description"
            isMandatory
            value={hierarchyState.description || ''}
            onChange={(e) => {
              setHierarchyState((pre) => ({
                ...pre,
                description: e.target.value,
              }));
              setErrorListWithKey((pre) => ({ ...pre, description: '' }));
            }}
            isError={errorListWithKey?.description || false}
            errorMessage={errorListWithKey?.description || ''}
            w="30vw"
            h="32px"
          />
        </div>
          <div>
            <CustomCheckBox
              isChecked={hierarchyState?.is_actor_hierarchy}
              size="md"
              value={hierarchyState?.is_actor_hierarchy || ''}
              onChange={(e) => {
                setHierarchyState((pre) => ({
                  ...pre,
                  is_actor_hierarchy: e.target.checked,
                }));
                setErrorListWithKey((pre) => ({
                  ...pre,
                  is_actor_hierarchy: '',
                }));
              }}
              isError={errorListWithKey?.is_actor_hierarchy || false}
              errorMessage={errorListWithKey?.is_actor_hierarchy || ''}
              w="30vw"
              h="32px"
            >
              Is Actor Hierarchy
            </CustomCheckBox>
          </div>
      </Flex>
      <Box
        mt="20px"
        border="1px solid #D1D5DB"
        borderRadius="5px"
        pt="10px"
        pl="10px"
        minH="60vh"
      >
        <HierarchyTree
          label="Add Main Node"
          isActorHierarchy={hierarchyState?.is_actor_hierarchy}
          onChange={(value) => {
            setHierarchyState((pre) => ({ ...pre, hierarchy: value }));

            setErrorListWithKey((pre) => ({ ...pre, hierarchy: '' }));
          }}
          value={hierarchyState.hierarchy}
          key={hierarchyState.id}
        />
        {errorListWithKey?.hierarchy && (
          <span className="field-error">{errorListWithKey?.hierarchy}</span>
        )}
      </Box>
      <Flex p="16px 10px" justify="flex-end">
        <PrimaryCustomButton
          onClick={id ? handleHierarchyUpdate : handleHierarchyCreation}
          buttonText="Save"
          isLoading={isLoading}
        />
      </Flex>
    </Box>
  );
};

CreateEditHierarchyBuilder.propTypes = {};

export default CreateEditHierarchyBuilder;

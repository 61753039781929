import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import {
  makeApiCallForWorkflowList,
  reducerForState,
} from '../AddEditActorModal/helper';
import { patchDataForTemplate } from '../utils/templateAPIUtils';
import { updateSelectedWorkFlow } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { ConfirmModel } from './ConfirmModel';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '200px',
  }),
  control: (provided) => ({
    ...provided,
    width: '200px',
    overflow: 'hidden',
    border: 'thin solid #e2e8f0'

  }),
  menu: (provided) => ({
    ...provided,
    width: '200px',
  }),
  singleValue: (provided) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};
const AssociateAnotherFlowBtn = ({ clearUndoRedoState, selectedTemplate }) => {
  const [workflowState, dispatchWorkflow] = useReducer(reducerForState, {});
  const [workflowMasterListOptions, setWorkflowMasterListOptions] = useState(
    []
  );
  const [selectedWokFlow, setSelectedWorkFlow] = useState({});
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchWorkflowListOption = async () => {
    setIsLoading(true)
    const response = await makeApiCallForWorkflowList(selectedTemplate.id);
    const options = response?.map((item) => ({
      value: item.id,
      label: item.name,
      cloned_from: item.cloned_from,
      template_id: item.template_id,
      workflow_status: item.workflow_status,
      workflow_category: item.workflow_category,
      workflow_transitions: item.workflow_transitions,
    }));
    setWorkflowMasterListOptions(options);
    setIsLoading(false)
  };
  useEffect(() => {
    fetchWorkflowListOption();
  }, [selectedTemplate]);

  const configWorkflowInTemplate = async (newObj) => {
    const workflowId = workflowState.id;
    delete workflowState.id;
    const newData = {
      ...newObj,
      // ...workflowState,
      workflow_rules: { list: [] },
      updated_on: new Date().toISOString(),
    };
    const res = await patchDataForTemplate({
      data: newData,
      id,
      key: 'workflow',
      successMessage: 'Workflow Configuration Successful',
      defaultErrorMessage: 'Failed to configure workflow',
    });
    if(res.error) return;
    newData.id = workflowId;
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: res?.response?.data,
      })
    );
    dispatch(updateSelectedWorkFlow(res?.response?.data?.workflow));
    dispatch(setActiveModal({ targetModalId: undefined }));
  };

  const handleWorkFlowSelect = async (e) => {
    const newObj = {
      id: e.value,
      name: e.label,
      cloned_from: e.cloned_from ? e.cloned_from : e.value,
      workflow_status: e.workflow_status,
      workflow_category: e.workflow_category,
      workflow_transitions: e.workflow_transitions,
    };
    clearUndoRedoState();
    dispatchWorkflow({
      type: 'SET_STATE',
      value: { ...newObj },
    });
    await configWorkflowInTemplate(newObj);
    //fetch dropdown list after changing work flow
    fetchWorkflowListOption();
  };

  const onConfirmOverWrite = (isOpen) => {
    handleWorkFlowSelect(selectedWokFlow);
    setOpenAlertModal(isOpen);
  };

  const onCancelOverWriteWorkflow =(isOpen)=>{
    setOpenAlertModal(isOpen)
    setSelectedWorkFlow({})
  }

  return (
    <>
      <CustomSelectBox
        styles={customStyles}
        options={workflowMasterListOptions ?? []}
        value={getWholeOption(workflowState?.id, workflowMasterListOptions)}
        isLoading={isLoading}
        onChange={(e) => {
          // if selected workflow already linked to template show alert for overwriting
          const clonedWorkflow = workflowMasterListOptions.find(
            (item) => item.cloned_from == e.value && item.template_id
          )
          if (
            clonedWorkflow
          ) {
            const event  = _cloneDeep(e)
            event.value = clonedWorkflow.value;
            event.label = `${clonedWorkflow.label}`
            setSelectedWorkFlow(event);
            setOpenAlertModal(true);
            return;
          }
          handleWorkFlowSelect(e);
        }}
        placeholder="Associate flow"
        id="WorkFlowConfigSelectBox2"
      />
      <ConfirmModel
        openAlertModal={openAlertModal}
        onCancelOverWriteWorkflow = {onCancelOverWriteWorkflow}
        onConfirmOverWrite={onConfirmOverWrite}
      />
    </>
  );
};
export default AssociateAnotherFlowBtn;

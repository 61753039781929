import React from "react"
import { Input ,Textarea,Select,Tooltip} from "@chakra-ui/react"
import { deleteRenderer } from "../../../DynamicRenderer/MetricsAndSLA/Metrics/utils"
import CustomSelectBox from "../../../../components/SelectBox/Select"
export const SILENT_INPUTS_TRIGGERS=({onEditDescription,onDelete,fields,offers,onChangeCrossReferencedFields,onChangeOffers})=>[
    {
        name: 'Field Name',
        label: 'Field Name',
        type: 'string',
        cellRenderer: ({ rowData }) =>{
            return (
                <Tooltip label={rowData?.label}><Input value={rowData?.label} disabled={true} /></Tooltip>
            )
        },
    },
    {
        name: 'Offers',
        label: 'Offers',
        type: 'string',
        cellRenderer: ({ rowData }) => {
            const handleChange = (selectedOption) => {
                onChangeOffers(rowData,selectedOption)
            }
            return(
                <CustomSelectBox
                    defaultValue={rowData?.selectedOffers||[]}
                    options={offers.map(item=>{
                        return {label:item.name,value:item.id}
                    })}
                    isMulti
                    onChange={handleChange}
                 />
            )
        }
            
    },
    {
        name: 'Cross Referenced Fields',
        label: 'Cross Referenced Fields',
        type: 'string',
        cellRenderer: ({ rowData }) => {
            const handleChange = (selectedOption) => {
                onChangeCrossReferencedFields(rowData,selectedOption)
            }
            return(
                <CustomSelectBox
                    defaultValue={rowData?.crossReferencedFields || []}
                    options={fields.filter(item=>item.label.toLowerCase()!==rowData?.label.toLowerCase()).map(item => {return{label:item.label,value:item.label}})}
                    isMulti
                    onChange={handleChange}
                 />
            )
            
        },
    },
    {
        name: 'Description for Bot',
        label: 'Description for Bot',
        type: 'string',
        cellRenderer: ({ rowData }) =>{
            const handleBlur = (event) => {
                const updatedDescription = event.target.value;
                if (updatedDescription !== rowData.botDescription) {
                onEditDescription(rowData, updatedDescription);
                }
            };
            return(
             <Textarea resize="none" overflowY="auto" placeholder="Enter description" defaultValue={rowData?.botDescription}  onBlur={handleBlur}/>
            )
        }
    },
    {
        name: 'Action',
        label: 'Action',
        type: 'string',
        cellRenderer: ({ rowData }) => (
            <div>
                {deleteRenderer({
                    title: 'Delete this field',
                    description: 'Are you sure you want to delete this field?',
                    onConfirm: () => {
                    onDelete(rowData);
                    },
                    dataTestId: `Fields-${rowData.name}-delete`
                })}
            </div>
        ),
    }
]
import Joi from 'joi';

import { stringRequiredValidation } from '../validationSchemas';

const RELATION_VALIDATIONS = Joi.object({
  name: stringRequiredValidation,
  template_id_left: stringRequiredValidation,
  template_id_right: stringRequiredValidation,
  offer_id_left: Joi.string().allow('', null),
  offer_id_right: Joi.string().allow('', null),
  relations_list_left: Joi.array().items(Joi.string()).min(1).required(),
  relations_list_right: Joi.array().items(Joi.string()).min(1).required(),
  default_relation: Joi.object({
    left_to_right: Joi.string().allow('', null),
    right_to_left: Joi.string().allow('', null)
  }).unknown().allow(null),
  cardinality: stringRequiredValidation,
}).unknown();

export default RELATION_VALIDATIONS;

export const LAYOUT_VIEW_LIST = [
  // {
  //   id: 'INBOX',
  //   label: 'Inbox',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  // {
  //   id: 'IPAD_1',
  //   label: 'iPad 1',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  {
    id: 'print',
    label: 'Print',
    type: 'VIEW',
    linked_layout: null,
  },
  {
    id: 'deferred',
    label: 'Deferred',
    type: 'VIEW',
    linked_layout: null,
  },
  {
    id: 'create',
    label: 'Create',
    type: 'VIEW',
    linked_layout: null,
  },
  {
    id: 'detail',
    label: 'Detail',
    type: 'VIEW',
    linked_layout: null,
  },
  // {
  //   id: 'search',
  //   label: 'Search',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  // {
  //   id: 'result',
  //   label: 'Result',
  //   type: 'VIEW',
  //   linked_layout: null,
  // },
  {
    id: 'card',
    label: 'Inbox',
    type: 'VIEW',
    linked_layout: null,
  },
];

export const updateArray = (firstArray, secondArray) => {
  const updatedArray = [...firstArray];
  // Create a copy of the first array to avoid modifying the original

  for (let i = 0; i < firstArray.length; i++) {
    const elementId = firstArray[i].id;

    for (let j = 0; j < secondArray.length; j++) {
      if (elementId === secondArray[j].view_id) {
        updatedArray[i].linked_layout = secondArray[j].layout_id;
        break; // Once a match is found, no need to continue searching
      }
    }
  }

  return updatedArray;
};
export const filterArrayByViewId = (firstArray, secondArray) =>
  firstArray.filter((item) =>
    secondArray.some((data) => data.id === item.view_id)
  );

export const findElementName = (jsonArray, searchId, keyValue) => {
  const foundElement = jsonArray.find((element) => element.id === searchId);
  return foundElement ? foundElement[keyValue] || '' : '';
};

import React, { useEffect } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  Tabs,
  TabPanel,
  TabPanels,
  Tab,
  TabList,
  Box,
  useBoolean
} from '@chakra-ui/react';

import { BiHelpCircle } from 'react-icons/bi';
import QuickHelp from './QuickHelp';
import SupportBot from './SupportBot';
import SupportRequests from './SupportRequests';

const HelpSupport = (props) => {
  const identity  = props?.config || null;
  const [flag, setFlag] = useBoolean(false);
  const { isOpen = false, onOpen = null, onClose = null } = props;
	useEffect(() => {
		if (identity?.realmAccess?.roles?.some((role) => ['tenant_admin-NA', 'Tenant Admin-NA'].includes(role))) {
			setFlag.on();
		} else {
			setFlag.off();
		}
	}, [identity, setFlag]);
  return (
    <Box fontSize="14px">
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Icon
              as={BiHelpCircle}
              color="blue"
              boxSize={6}
              data-testid="Bihelp"
              onClick={onOpen}
              cursor="pointer"
            />
          </DrawerHeader>

          <DrawerBody fontSize="14px">
            <Box fontWeight="bold">Help Center</Box>
            <Box fontSize="12px" color="gray" mb={2}>
              Find answers and get live support for your questions.
            </Box>
            <Tabs variant="unstyled">
              <TabList
                bg="rgb(242, 243, 244)"
                color="gray"
                p="4px"
                justifyContent="space-between"
              >
                <Tab
                  _selected={{ color: 'black', bg: 'white' }}
                  fontSize="12px"
                  borderRadius="6px"
                >
                  Quick Help
                </Tab>
                {flag && (<Tab
                  _selected={{ color: 'black', bg: 'white' }}
                  fontSize="12px"
                  borderRadius="6px"
                >
                  AI Chat Bot
                </Tab>)}
                {flag && (<Tab
                  _selected={{ color: 'black', bg: 'white' }}
                  fontSize="12px"
                  borderRadius="6px"
                >
                  Request Support
                </Tab>)}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <QuickHelp />
                </TabPanel>
                <TabPanel>
                  <SupportBot />
                </TabPanel>
                <TabPanel padding="2px" pt={2}>
                  <SupportRequests identity={props?.config || null} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default HelpSupport;


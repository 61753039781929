/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import SelectBoxWithAddButton from './SelectBoxWithAddButton';
import CustomSelectBox from '../../components/SelectBox/Select';
import {
  QUICK_ACTION_DATA_KEYS,
  QUICK_ACTION_LABEL,
} from './quickAction.constants';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';

const WrapperForCanned = ({
  label,
  optionList,
  onDelete,
  values,
  onUpdatedValue,
}) => {
  const abc = 10;
  return (
    <Box
      border="1px solid #D1D5DB"
      pl="16px"
      pr="16px"
      borderRadius="4px"
      mb="15px"
    >
      <Flex justifyContent="space-between" pt="16px">
        <Text className="SCLabel">{label}</Text>
        <DeleteIcon color="red" className="cursor-pointer" onClick={onDelete} />
      </Flex>
      <Box mb="15px">
        <CustomSelectBox
          value={getWholeOption(values, optionList)}
          options={optionList}
          label={QUICK_ACTION_LABEL.SELECT_NOTE}
          onChange={({ value }) => {
            onUpdatedValue(value);
          }}
          size="md"
          style={{ position: 'absolute', left: 30 }}
        />
      </Box>
    </Box>
  );
};

export default WrapperForCanned;

import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    Flex,
    Icon,
    Spinner,
  } from '@chakra-ui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const WarningTriggerModel = ({isOpen,onClose,handleDeleteModel,triggersLoader=false}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalBody>
       {triggersLoader? 
       <Flex alignItems={'center'} justifyContent={'center'} height={'136px'} flexDirection={'column'}>
       <Spinner width="30px" height="30px" />
       <Text mt={'3px'} pt={'5px'}>Checking For Triggers</Text>
       </Flex>
       : <>
       < Flex gap={2} cursor={'default'} >
        <Box width={"40px"} height={"40px"} padding={'10px'} borderRadius={'24px'}  backgroundColor={'#FEE2E2'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Icon as={ExclamationTriangleIcon} stroke={"2px"} height={'24px'} width={"24px"} top={"3px"} left={"3.07px"}color='#DC2626'/>
        </Box>
        <Flex>
            <Box>
            <Text pt={'2px'} pb={'10px'} fontWeight={'500'} lineHeight={'24px'} size={'18px'} color={'#111827'}>Warning !</Text>
            <Text fontWeight={'400'} lineHeight={'16.94px'} size={'14px'} color={'#6B7280'}>This action will permanently delete the associated trigger(s) in Bot Integrations. Are you sure you want to continue?</Text>
            </Box>
        </Flex>
        </Flex>
        <Flex justifyContent={'flex-end'} width={'100%'}  mt={'10px'}>
        <Box cursor={'pointer'} border={'1px solid #D1D5DB'} borderRadius={'6px'} py={'8px'} px={'17px'} mr={3} onClick={onClose}>
          <Text fontWeight={'500'} lineHeight={'20px'} fontSize={'14px'} color={'#374151'}>Cancel</Text>
        </Box>
        <Box cursor={'pointer'}  border={'1px solid #D1D5DB'} backgroundColor={'#DC2626'} borderRadius={'6px'} py={'8px'} px={'17px'} onClick={handleDeleteModel}>
          <Text fontWeight={'500'} lineHeight={'20px'} fontSize={'14px'} color={'#FFFFFF'}>Delete</Text>
        </Box>
        </Flex>
        </>}
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}

export default WarningTriggerModel
import { Box, FormLabel, Grid, GridItem, HStack, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import CustomSelectBox from '../../../components/SelectBox/Select';
// import CustomButton from '../../../components/Button/SimpleButton';
// import styles from '../styles/fieldRolePermission.module.scss';
import { COLORS } from '../../../utils/enums/colors';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import CreateActorInMasterList from './CreateActorInMasterList';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import {
  getActorFields,
  getQueueFields,
} from '../../../utils/helper/fieldHelper';
import CustomCreateSelectBox from '../../../components/SelectBox/CreateSelect';
import { createNewActorInMasterList } from './helper';
import { getTenantData } from '../../../helpers/tenantUrl';
import { stringToSlug } from '../utils/helper';
import { showToastMessage } from '../utils/templateAPIUtils';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';

const AddEditActorContainer = ({
  actorState,
  actorDispatch,
  actorOption,
  setActorOption,
  // fieldOption,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const viewState = useSelector((state) => state.viewState || {});

  const [openCreateActorModal, setOpenCreateActorModal] = useState(false);
  const handleActorSelect = (e, nameOne, nameTwo) => {
    actorDispatch({ type: 'UPDATE_STATE', name: nameOne, value: e.label });
    actorDispatch({ type: 'UPDATE_STATE', name: nameTwo, value: e.value });
  };
  const queueData = useCallback(async () => {
    dispatch(
      updateViewState({
        stateKey: 'queue',
        value: {
          id,
          data: [],
        },
      })
    );
  }, [id]);

  useEffect(() => {
    if (viewState?.queue?.id !== id) {
      queueData();
    }
  }, []);

  const makeOptions = (queueFields) =>
    queueFields?.map((d) => ({
      label: d?.label,
      value: d?.id,
    }));

    const makeOptionsForQueues = (queueFields) => [
      { label: 'None', value: '' },
      ...queueFields?.map((d) => ({
        label: d?.label,
        value: d?.id,
      })),
    ];

  const makeOptionValue = (queueFieldId, fields) => {
    const matchedField = fields?.find((f) => f?.id === queueFieldId);
    if (matchedField) {
      return {
        label: matchedField?.label,
        value: matchedField?.id,
      };
    }
    return null;
  };
  // const [createActorState, createDispatch] = useReducer(reducerForState, {});
  // const handleActorDispatch = (name, value) => {
  //   actorDispatch({
  //     type: 'UPDATE_STATE',
  //     name,
  //     value,
  //   });
  // };
  const addNewActorInMaster = async (value) => {
    const response = await createNewActorInMasterList({
      name: value,
      slug: stringToSlug(`actors_${value}`),
      tenant_id: getTenantData()?.id,
    });
    const errorMessage = response.error || 'Failed to create a new actor';
    if (!response.error) {
      const newOption = {
        value: response?.response.id,
        label: response?.response.name,
      };
      setActorOption((pre) => [newOption, ...pre]);
      handleActorSelect(newOption, 'name', 'actor_id');
      showToastMessage({
        title: 'Success',
        description: 'Actor is created at master list',
        status: 'success',
      });
    } else {
      setActorOption((pre) => [...pre]);
      showToastMessage({
        title: 'Something went wrong',
        description: errorMessage,
        status: 'error',
      });
    }
  };
  return (
    <>
      <Box
        gap="6px"
        mt="33px"
        data-testid="AddEditActorContainerGrid"
      >
        <Box data-testid="AddEditActorContainerFormGridItem">
          <FormLabel
            className="SCLabel"
            color={useColorModeValue(COLORS.TEXT_LIGHT, COLORS.TEXT_DARK)}
            data-testid="AddEditActorContainerFormLabel"
          >
            Actor Name
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </FormLabel>
          <CustomCreateSelectBox
            id="AddEditselect_actor_01"
            styles={customStyleFromRuleForSelect}
            options={actorOption ?? []}
            value={
              isEmpty(getWholeOption(actorState?.actor_id, actorOption))
                ? {}
                : getWholeOption(actorState?.actor_id, actorOption)
            }
            onChange={(e) => {
              handleActorSelect(e, 'name', 'actor_id');
            }}
            placeholder="Select Actor"
            aria-label="Select Actor"
            onCreateOption={(value) => {
              addNewActorInMaster(value);
            }}
            // eslint-disable-next-line no-unused-vars
            onInputChange={(e) => {}}
          />
        </Box>
        <HStack spacing="5px" mt="5px" display="flex" alignItems="center" justifyContent="end" color={`${COLORS.LIGHT}`}>
          <PlusCircleIcon width="16px" height="16px" />
          {/* eslint-disable-next-line react/button-has-type */}
          <button
            style={{ fontSize: '14px', fontWeight: '400', lineHeight: 'normal' }}
            onClick={() => {
              dispatch(setActiveModal({ targetModalId: 'create_actor_modal' }));
            }
          }
          >
Add New Actor
          </button>
        </HStack>
        {/* <GridItem colSpan={2} data-testid="AddEditActorContainerCreateGridItem">
          <CustomButton
            className={` border-[#2563EB]`}
            style={{
              border: '1px solid #2563EB',
              padding: '0 4px',
              height: '36px',
            }}
            color={useColorModeValue(LIGHT, DARK)}
            id="AddEditActorContainerCreatebtnrole"
            onClick={() => {
              setOpenCreateActorModal(true);
            }}
            buttonText="Create New Actor"
            variant="outline"
            mt="28px"
          />
        </GridItem> */}
      </Box>
      <Grid mt="33px" data-testid="AddEditActorContainerFieldGrid">
        <GridItem w="100%" data-testid="AddEditActorContainerFieldGridItem">
          <FormLabel
            className="SCLabel"
            color={useColorModeValue(COLORS.TEXT_LIGHT, COLORS.TEXT_DARK)}
            data-testid="AddEditActorContainerSCLabelFormLabel"
          >
            Ticket Field to link this Actor to
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </FormLabel>
          <CustomSelectBox
            styles={customStyleFromRuleForSelect}
            options={makeOptions(getActorFields(viewState?.selectedTemplate))}
            value={makeOptionValue(
              actorState?.associated_field_id,
              viewState?.selectedTemplate?.fields || []
            )}
            onChange={(e) => {
              handleActorSelect(e, 'associated_field', 'associated_field_id');
            }}
            placeholder="Select a actor field"
            aria-label="Select a actor field"
            id="AddEditActorSelectBox"
          />
        </GridItem>
      </Grid>
      <Grid mt="33px" data-testid="AddEditActorContainerQueueGrid">
        <GridItem w="100%" data-testid="AddEditActorContainerQueueGridItem">
          <FormLabel
            className="SCLabel"
            color={useColorModeValue(COLORS.TEXT_LIGHT, COLORS.TEXT_DARK)}
            data-testid="AddEditActorContainerQueueFormLabel"
          >
            Queue to pick the users from to fill this field
          </FormLabel>
          <CustomSelectBox
            styles={customStyleFromRuleForSelect}
            options={makeOptionsForQueues(getQueueFields(viewState?.selectedTemplate))}
            value={makeOptionValue(
              actorState?.log_field_id,
              viewState?.selectedTemplate?.fields || []
            )}
            onChange={(e) => {
              handleActorSelect(e, 'log_field', 'log_field_id');
            }}
            placeholder="Select a queue field"
            id="AddEditActorQueueSelectBox"
          />
        </GridItem>
      </Grid>
      <CreateActorInMasterList
        openCreateActorModal={openCreateActorModal}
        setOpenCreateActorModal={setOpenCreateActorModal}
        setActorOption={setActorOption}
        actorDispatch={actorDispatch}
      />
    </>
  );
};
AddEditActorContainer.propTypes = {
  actorDispatch: PropTypes.func.isRequired,
  actorState: PropTypes.object.isRequired,
  actorOption: PropTypes.array.isRequired,
  setActorOption: PropTypes.func.isRequired,
  // fieldOption: PropTypes.array.isRequired,
};
export default AddEditActorContainer;

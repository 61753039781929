import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const InsertFieldDrawer = ({
  openDrawer,
  setOpenDrawer,
  title,
  setCommonAttributesState,
  checkBoxList,
  fieldList,
}) => {
  const [checkList, setCheckList] = useState([]);
  const [stateValue, setStateValue] = useState([]);
  const [renderList, setRenderList] = useState([]);

  useEffect(() => {
    setRenderList(fieldList);
    const newCheckList = fieldList.map((element2) =>
      checkBoxList.some(
        (element) => element.id === element2.id
      )
    );
    setCheckList(newCheckList);
    const firstSet = new Set(fieldList.map((item) => item.id));
    const matchingElements = checkBoxList.filter((element) =>
      firstSet.has(element.id)
    );
    setStateValue(matchingElements);
  }, []);

  const handleOnChangeOfCheckBox = (value, index) => {
    if (value === true) {
      const BOOL_LIST = [...checkList];
      BOOL_LIST[index] = true;
      setCheckList(BOOL_LIST);

      const TEMP_STATE = [...stateValue];
      const INDEX = TEMP_STATE.findIndex(
        (item) => item.id === renderList[index].id
      );

      if (INDEX !== undefined && INDEX !== -1) {
        TEMP_STATE[INDEX] = renderList[index];
      } else {
        TEMP_STATE.push(renderList[index]);
      }

      setStateValue(TEMP_STATE);
    } else {
      const BOOL_LIST = [...checkList];
      BOOL_LIST[index] = false;
      setCheckList(BOOL_LIST);

      const TEMP_STATE = [...stateValue];
      const INDEX = TEMP_STATE.findIndex(
        (item) => item.id === renderList[index].id
      );

      if (INDEX !== undefined && INDEX !== -1) {
        TEMP_STATE.splice(INDEX, 1);
        setStateValue(TEMP_STATE);
      }
    }
  };
  const handleOnChangeForAll = (value) => {
    if (value) {
      setCheckList(Array(renderList.length).fill(true));
      setStateValue([...renderList]);
    } else {
      setCheckList(Array(renderList.length).fill(false));
      setStateValue([]);
    }
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Box mb="20px">
            <Checkbox
              isChecked={checkList.every((item) => item === true)}
              onChange={(e) => {
                handleOnChangeForAll(e.target.checked);
              }}
              data-testid="TicktFieeldContentDependentCheckbox"
            >
              All
            </Checkbox>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap="30px">
            {renderList.map((item, index) => (
              <GridItem>
                <Checkbox
                  isChecked={checkList[index] || false}
                  onChange={(e) => {
                    handleOnChangeOfCheckBox(e.target.checked, index);
                  }}
                  data-testid="TicktFieeldContentDependentCheckbox"
                >
                  {item.label}
                </Checkbox>
              </GridItem>
            ))}
          </Grid>
        </DrawerBody>
        <DrawerFooter>
          <Flex gap={3}>
            <SecondaryCustomButton
              buttonText={LABELS.ACTIONS.DISCARD}
              onClick={() => {
                setOpenDrawer(false);
              }}
            />
            <PrimaryCustomButton
              buttonText={LABELS.ACTIONS.ADD}
              onClick={() => {
                setCommonAttributesState(stateValue);
                setOpenDrawer(false);
              }}
              isLoading={false}
              data-testid="linkedMetaFieldButtonAdd"
            />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

InsertFieldDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setCommonAttributesState: PropTypes.func.isRequired,
  checkBoxList: PropTypes.array.isRequired,
  fieldList: PropTypes.array.isRequired,
};

export default InsertFieldDrawer;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';
import { TbBolt } from 'react-icons/tb';
import { useParams } from 'react-router';
import { DATE_VALIDATION_LABELS } from '../../../../../utils/enums/labels';
import RestrictionsSectionContainer from '../RestrictionsSectionContainer';
import { PrimaryCustomButton } from '../../../../../components/Button/PrimarySecondaryButton';
import DateAddRuleDrawer from './DateAddRuleDrawer';
import { getRulePreviewObject } from './utils/dateRestrictions.helper';
import RestrictionPreviewPill from '../RestrictionPreviewPill';
import styles from '../restrictions.module.scss';
import { FUNCTION_TYPES, RULE_TYPES, TIMEFRAME_TYPES } from './utils/dateRestrictions.constants';
import ConditionsExpressions from '../../../../../components/ConditionsExpressions/ConditionsExpressions';
import { isEmptyCondition } from '../../../../../utils/helper';

const {
  ADD_RULE_BUTTON,
  CONDITIONAL_SECTION_HEADER_TITLE,
  RULES_APPLIED,
  APPLIED_CONDITIONS,
} = DATE_VALIDATION_LABELS;

const DateConditionalRestrictions = ({
  conditionalValidation,
  onChangeConditionalValidation,
  templateFields,
  allowTime,
  onDeleteConditionalValidation,
  onMoveConditionalValidationUp,
  onMoveConditionalValidationDown,
  index,
  maxLength,
}) => {
  const {
    id,
    order,
  } = conditionalValidation;

  const rules = conditionalValidation.rules || [];
  const condition = conditionalValidation.condition || {};

  const [currentRule, setCurrentRule] = useState({});
  const [openAddRuleDrawer, setOpenAddRuleDrawer] = useState(false);
  const [openEditRuleDrawer, setOpenEditRuleDrawer] = useState(false);
  const { id: templateId } = useParams();

  const handleCloseAddRuleDrawer = () => {
    setOpenAddRuleDrawer(false);
    setOpenEditRuleDrawer(false);
  };

  const onChangeRule = (rule) => {
    if (openEditRuleDrawer) {
      // edit rule
      onChangeConditionalValidation(
        id,
        {
          ...conditionalValidation,
          rules: rules.map((r) => (r.id === rule.id ? rule : r)),
        }
      );
    } else {
      // add rule
      onChangeConditionalValidation(
        id,
        {
          ...conditionalValidation,
          rules: [...rules, rule],
        }
      );
    }
    handleCloseAddRuleDrawer();
  };

  const onDeleteRule = (ruleId) => {
    onChangeConditionalValidation(
      id,
      {
        ...conditionalValidation,
        rules: rules.filter((r) => r.id !== ruleId),
      }
    );
  };

  return (
    <RestrictionsSectionContainer
      title={`${CONDITIONAL_SECTION_HEADER_TITLE} ${order}`}
      onDelete={() => onDeleteConditionalValidation(id)}
      onMoveUp={() => {
        onMoveConditionalValidationUp(id);
      }}
      onMoveDown={() => {
        onMoveConditionalValidationDown(id);
      }}
      disableMoveUp={index === 0}
      disableMoveDown={index === maxLength - 1}
    >

      <Text fontWeight={500}>{APPLIED_CONDITIONS}</Text>

      <ConditionsExpressions
        templateId={templateId}
        value={condition}
        onChange={(value) => {
          const { and } = value || {};
          const updatedValue = isEmptyCondition(and) ? {} : value;
          onChangeConditionalValidation(id, {
            ...conditionalValidation,
            condition: updatedValue,
          });
        }}
      />

      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight={500}>Restriction Rules</Text>
        <PrimaryCustomButton
          buttonText={ADD_RULE_BUTTON}
          onClick={() => setOpenAddRuleDrawer(true)}
          leftIcon={<TbBolt size="18px" />}
        />
      </Flex>

      {
        openAddRuleDrawer || openEditRuleDrawer ? (
          <DateAddRuleDrawer
            rule={openEditRuleDrawer ? currentRule : null}
            open={openAddRuleDrawer || openEditRuleDrawer}
            isEditMode={openEditRuleDrawer}
            onModalClose={handleCloseAddRuleDrawer}
            templateFields={templateFields}
            allowTime={allowTime}
            onSaveRule={onChangeRule}
          />
        ) : null
      }

      {
        rules?.length ? (
          <>
            <Text fontWeight={500} fontSize={14}>{RULES_APPLIED}</Text>
            <div className={styles.restrictionPreviewPillContainer}>
              {
                rules.map((rule) => (
                  <RestrictionPreviewPill
                    key={rule.id}
                    id={rule.id}
                    onEdit={(ruleId) => {
                      setCurrentRule(rules.find((r) => r.id === ruleId));
                      setOpenEditRuleDrawer(true);
                    }}
                    onDelete={onDeleteRule}
                    previewObjects={getRulePreviewObject(rule, templateFields, allowTime)}
                    previewObjectJoiner="and"
                  />
                ))
              }
            </div>
          </>
        ) : null
      }

    </RestrictionsSectionContainer>
  );
};

DateConditionalRestrictions.propTypes = {
  conditionalValidation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    condition: PropTypes.object,
    rules: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        RULE_TYPES.RELATIVE,
        RULE_TYPES.ABSOLUTE
      ]).isRequired,
      offset: PropTypes.number,
      timeframe: PropTypes.oneOf([
        TIMEFRAME_TYPES.LESS_THAN,
        TIMEFRAME_TYPES.GREATER_THAN,
        TIMEFRAME_TYPES.RANGE,
      ]),
      reference: PropTypes.string,
      functionName: PropTypes.oneOf([
        FUNCTION_TYPES.CURRENT,
        FUNCTION_TYPES.START_OF_DAY,
        FUNCTION_TYPES.END_OF_DAY,
        FUNCTION_TYPES.START_OF_WEEK,
        FUNCTION_TYPES.END_OF_WEEK,
        FUNCTION_TYPES.START_OF_MONTH,
        FUNCTION_TYPES.END_OF_MONTH,
        FUNCTION_TYPES.START_OF_YEAR,
        FUNCTION_TYPES.END_OF_YEAR,
      ]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    })),
  }).isRequired,
  onChangeConditionalValidation: PropTypes.func.isRequired,
  onDeleteConditionalValidation: PropTypes.func.isRequired,
  onMoveConditionalValidationUp: PropTypes.func.isRequired,
  onMoveConditionalValidationDown: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  templateFields: PropTypes.array.isRequired,
  allowTime: PropTypes.bool,
};

DateConditionalRestrictions.defaultProps = {
  allowTime: false,
};

export default DateConditionalRestrictions;

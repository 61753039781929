/* eslint-disable import/prefer-default-export */
/* eslint-disable no-template-curly-in-string */

import { calculatedHight } from "../../../utils/enums/constVariable";

export const hierarchyBuilderJson = {
  name: 'hierarchy builder',
  label: 'Hierarchy Builder',
  table_name: 'hierarchy_builder',
  route: '/hierarchy-builder',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'hierarchy_builder_container',
      label: 'hierarchy_builder Container',
      show_label: false,
      type: 'container',
      style: { boxSizing: 'borderBox' },
      elements: [
        {
          name: 'hierarchy_builder_title',
          type: 'text',
          value: 'Hierarchy Builder',
          style: { fontSize: '20px', fontWeight: '600' },
        },
      ],
      views: [
        {
          name: 'hierarchy_builder_expanded_list',
          label: 'Hierarchy Builder List',
          type: 'list_exp',
          calculatedHight,
          calculatedHight,
          apiReq: {
            reqCols: ['*'],
            orderBy: 'created_on desc',
            endPoint: 'hierarchies/list',
          },
          related_entity: 'ticket_template_fields',
          columns: [
            {
              col_name: 'name',
              type: 'string',
            },
            // {
            //   col_name: 'description',
            //   type: 'string',
            // },
            {
              col_name: 'actions',
              type: 'actions',
            },
          ],
          filter: [
            {
              condition: 'is_active = true',
            },
          ],
          actions: [
            {
              name: 'edit_hierarchy-builder_server',
              type: 'button',
              label: 'Edit Hierarchy Builder',
              show_label: true,
              is_button: true,
              permission: 'edit',
              view: 'edit_view',
              icon: 'editIcon',

              navigatetUrl: '/hierarchy-builder/create', // For navigating urls to other pages
              isUrlParams: true,
              source_route: 'hierarchy-builder',
              target_type: 'on_click',
              target_modal_id: 'edit_mails_server',
            },

            {
              name: 'delete_hierarchy-builder_server',
              icon: 'deleteIcon',
              type: 'button',

              target_type: 'popup',
              label_key: 'name', // Key name to be displayed in modal as label
              target_modal_id: 'deleteModal',
              master_type: 'hierarchy',
              listKey: 'hierarchy_builder_expanded_list', // identify the redux status for update it
              target_url: '/hierarchies/delete?$filter=id eq ${id}',
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'edit_hierarchy-builder_server',
                x: 0,
                y: 1,
                w: 3,
                colEnd: -1,
                maxH: 'Infinity',
              },
            ],
          },
          filterKey: 'name',
          listKey: 'hierarchy_builder_expanded_list',
        },
        {
          name: 'action_container',
          label: 'Action Container',
          show_label: false,
          type: 'container',
          elements: [
            {
              name: 'search_mail_server_item',
              type: 'search',
              label: 'Search...',
              placeholder: 'Search...',
              entity: 'Hierarchies',
              listKey: 'hierarchy_builder_expanded_list',
              filterKey: 'name',
              classname: 'calculatedHeight'
            },
            {
              name: 'create_new_hierarchy_builder',
              label: 'Add New Hierarchy',
              show_label: true,
              is_button: true,
              icon: '',
              permission: 'edit',
              view: 'edit_view',
              navigatetUrl: '/hierarchy-builder/create', // For navigating urls to other pages
              isUrlParams: true,
              source_route: 'hierarchy-builder',
              target_type: 'route',
              type: 'button',
              classname: 'editbutton calculatedHeight',
              style: {
                color: '#FFFFFF',
                fontWeight: '600',
                backgroundColor: '#2563EB',
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'search_mail_server_item',
                x: 0,
                y: 1,
                w: 8,
                // maxH: 'Infinity',
              },
              {
                i: 'create_new_hierarchy_builder',
                x: 0,
                y: 1,
                w: 4,
                // colEnd: -1,
                // maxH: 'Infinity',
              },
            ],
          },
        },
      ],

      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'hierarchy_builder_title',
            x: 0,
            y: 1,
            w: 7,
            maxH: 'Infinity',
          },
          {
            i: 'action_container',
            x: 0,
            y: 0,
            w: 5,
            h: 1,
          },
          {
            i: 'hierarchy_builder_expanded_list',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'hierarchy_builder_container',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
      },
    ],
  },
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import LinkedMetaFieldDrawer from './LinkedMetaFieldDrawer';
import { META_FIELD_DEFAULT_STATE } from './LinkedMetaFieldDrawer/utils';
import LinkedMetaFieldTableView from './LinkedMetaFieldTableView';
import { columns, DeleteConfirmationModal } from './utils';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import {
  deleteMetaFields,
  getMetaFieldList,
  resetError,
  updateMultipleStatesOfMetaFieldList,
} from '../../redux/MetaFieldsSlice/MetaFieldsReducer';
import CustomToast from '../../components/Notification/Notification';
import MetaFieldLayout from './MetaFieldLayout';
import {
  PRE_FIELDS_NO_ASSOCIATED,
} from '../../redux/MetaFieldsSlice/MetaFieldsService';

const TicketMetaFields = () => {
  const { addToast } = CustomToast();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLinkOrUnLinkDrawer, setOpenLinkOrUnLinkDrawer] = useState(false);
  const [stateLinkOrUnlinkDrawer, setStateLinkOrUnlinkDrawer] = useState(null);
  const [showTableLayout, setShowTableLayout] = useState(false);
  const {
    loading,
    metaFieldList,
    error,
    metaColumnAttributes,
    metaTableAttribute,
    allMetaColumnAttributes,
  } = useSelector((state) => state.metaFields);

  const [isEditMetaFieldName, setIsEditMetaFieldName] = useState(false);
  const dispatch = useDispatch();
  const [deleteModalPayload, setDeleteModalPayload] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    dispatch(getMetaFieldList('some thing went wrong'));
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetError());
      }, 500);
    }
  }, [error]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return addToast({
      title: 'Something went wrong',
      message: 'Meta field api fail but predefine meta field will load',
      type: 'error',
    });
  }

  const showModal = (data) => {
    const arr = [...PRE_FIELDS_NO_ASSOCIATED];

    const TEMP_DATA = data?.rowData;
    setStateLinkOrUnlinkDrawer(TEMP_DATA);
    setOpenLinkOrUnLinkDrawer(true);
    const isDeleteButtonVisible = arr.every(
      (field) => field.meta_field_id !== data?.rowData?.meta_field_id
    );
    setIsEditMetaFieldName(isDeleteButtonVisible);
  };

  const onDelete = (data) => {
    const metaFieldIdToDelete = data?.rowData?.id;
    const metaIdTodelete = data?.rowData?.meta_field_id;
    if (!isEmpty(data?.rowData?.field_data)) {
      addToast({
        title: 'Failed to delete Meta Fields',
        message: 'As ticket type is linked to this meta field',
        type: 'error',
      });
      return;
    }
    dispatch(deleteMetaFields(metaFieldIdToDelete))
      .then(() => {
        dispatch(
          updateMultipleStatesOfMetaFieldList([
            {
              stateKey: 'allMetaColumnAttributes',
              value: allMetaColumnAttributes.filter((item) =>
                item.id !== metaFieldIdToDelete && item.meta_field_id !== metaIdTodelete
              ),
            },
            {
              stateKey: 'metaColumnAttributes',
              value: metaColumnAttributes.filter((item) =>
                item.id !== metaFieldIdToDelete && item.meta_field_id !== metaIdTodelete
              ),
            },
          ])
        );
        addToast({
          title: 'Success',
          message: 'Meta Fields successfully deleted',
          type: 'success',
        });
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        addToast({
          title: 'Something went wrong',
          message: 'Failed to delete Meta Fields',
          type: 'error',
        });
      });
  };

  const handleDeleteField = (payload) => {
    setDeleteModalPayload(payload);
    onOpen();
  }

  return (
    <>
      {showTableLayout && (
        <MetaFieldLayout
          isOpen={showTableLayout}
          setIsOpen={setShowTableLayout}
          setShowTableLayout={setShowTableLayout}
          metaFieldList={metaFieldList}
          metaColumnAttributes={metaColumnAttributes}
          metaTableAttribute={metaTableAttribute}
        />
      )}
      <LinkedMetaFieldTableView
        columns={columns({
          showModal,
          handleDeleteField
        })}
        tableData={metaFieldList}
        placeholder="Search meta field"
        searchKey="name"
        addNewMetaField={() => {
          setOpenDrawer(true);
        }}
        setShowTableLayout={setShowTableLayout}
      />

      {openDrawer && (
        <LinkedMetaFieldDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          selectedMetaFieldValue={META_FIELD_DEFAULT_STATE}
        />
      )}
      {stateLinkOrUnlinkDrawer && openLinkOrUnLinkDrawer && (
        <LinkedMetaFieldDrawer
          openDrawer={openLinkOrUnLinkDrawer}
          setOpenDrawer={setOpenLinkOrUnLinkDrawer}
          selectedMetaFieldValue={stateLinkOrUnlinkDrawer}
          isCreateMetaField={false}
          isEditMetaFieldName={isEditMetaFieldName}
        />
      )}
      {isOpen &&
        <DeleteConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={onDelete}
          deleteModalPayload={deleteModalPayload}
        />
      }
    </>
  );
};

export default TicketMetaFields;

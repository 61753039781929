/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
  Checkbox,
  Box,
  DrawerOverlay,
} from '@chakra-ui/react';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS, DARK, LIGHT } from '../../../utils/enums/colors';
import CustomInput from '../../../components/InputBox/Input';

const TemplateLocaleDrawer = ({
  selectedTemplate,
  openDrawer,
  setCloseDrawer,
  title,
  saveLocales,
  code,
}) => {
  const [locales, setLocales] = useState();

  useEffect(() => {
    if (!_isEmpty(selectedTemplate) && code) {
      const selectedLocale = selectedTemplate?.locales?.find(
        (item) => item.locale_code === code
      );
      let updatedTranslations = [];
      if (selectedLocale && selectedLocale?.translations) {
        updatedTranslations = _cloneDeep(selectedLocale?.translations);
      }
      selectedTemplate?.fields?.forEach((field) => {
        const exists = updatedTranslations?.some(
          (translation) => translation.label === field.label
        );

        if (!exists) {
          updatedTranslations.push({ label: field.label, value: '' });
        }
      });

      setLocales(updatedTranslations);
    }
  }, [selectedTemplate, code]);

  const handleInputChange = useCallback((index, value) => {
    setLocales((prevState) => {
      const newState = [...prevState];
      newState[index].value = value;
      return newState;
    });
  }, []);

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setCloseDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={setCloseDrawer}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Grid templateColumns="repeat(2, 1fr)" gap="30px">
            {locales?.map((item, index) => (
              <>
                <GridItem>
                  <Text>{item.label}</Text>
                </GridItem>
                <GridItem>
                  <CustomInput
                    label="Translation"
                    value={item.value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                </GridItem>
              </>
            ))}
          </Grid>
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            classname="calculatedHeight"
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: 'none',
              background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={setCloseDrawer}
            size="lg"
          />
          <CustomButton
            classname="calculatedHeight"
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={() => {
              saveLocales(locales, selectedTemplate?.id);
              setCloseDrawer(false);
            }}
            size="lg"
            isLoading={false}
            data-testid="LocaleButtonAdd"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

TemplateLocaleDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setCloseDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  saveLocales: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
};

export default TemplateLocaleDrawer;

/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
import React from 'react';
import axios from 'axios';
import { TriangleUpIcon } from '@chakra-ui/icons';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { filter } from 'lodash';
import { Radio, RadioGroup } from '@chakra-ui/react';
import {
  FIELD_SLUG,
  FIELD_VALIDATE_KEY,
  LABELS,
  SPECIAL_FIELDS_DROPDOWN,
} from '../../../utils/enums/labels';
import { COLORS, DARK, LIGHT } from '../../../utils/enums/colors';
import { RenderTypes } from '../../../utils/enums/types';
import { BASE_SETTINGS_KEYS, ADVANCED_SETTINGS_KEYS } from './constants';
import CustomButton from '../../../components/Button/SimpleButton';
import {
  renderFieldLabels,
  renderMaxLengthField,
  renderMinLengthField,
} from './renderFields';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import customStylesSelectWithHeight40 from '../../../context/CustomTheme/CustomStylesSelectWithHeight40';
import { getAgentDomain, getTenantData } from '../../../helpers/tenantUrl';
import { checkForV2 } from '../../../utils/helper';
import DropdownQueueSelectWithTags from './dropdownQueueSelectWithTags';
import { getOptionsForSpecialField } from './helper';
import { ACTOR_TYPE } from '../../../utils/enums/selectOption';
import radioStyles from '../CreateEditTemplate/createtemplate.module.scss';
import { DROPDOWN_TYPES } from '../../TenantFilters/filters.constants';

let specialFieldOptionValue = null;
let teamAndQueue = null;

const getLabel = (value, dropDownList, tempval = "") => {
  const [option] = filter(
    dropDownList,
    (item) => (item.value === value || item.label === value || item.value === tempval || item.label === tempval)
  );
  return option;
};

export const BasicDropdownSetting = ({
  data,
  setFields,
  setIsError,
  teamsDetail,
  setTeamsDetail,
  collectionData,
  setCollectionData,
  name,
  existingFields,
  specialWorkflowData,
}) => {
  let tempVal = '';
  specialFieldOptionValue = collectionData?.[BASE_SETTINGS_KEYS.SPECIAL_FIELD]
    ? getLabel(
        collectionData?.[BASE_SETTINGS_KEYS.SPECIAL_FIELD],
        SPECIAL_FIELDS_DROPDOWN
      )
    : specialWorkflowData?.id
    ? SPECIAL_FIELDS_DROPDOWN[1]
    : SPECIAL_FIELDS_DROPDOWN[0];
  teamAndQueue =teamsDetail;

  const dependentTypeOptions = [
    { label: 'Actor', value: 'actor'},
    { label: 'None', value: 'None' }]
  const row4 = [
    {
      label: LABELS.FIELD_PROPERTIES.STATIC,
      value: DROPDOWN_TYPES.STATIC,
    },
  ];

  if (checkForV2()) {
    row4.push({
      label: LABELS.FIELD_PROPERTIES.SPECIAL_FIELD,
      value: DROPDOWN_TYPES.SPECIAL_FIELD,
    });
    row4.push({
      label: LABELS.FIELD_PROPERTIES.DEPENDENT,
      value: DROPDOWN_TYPES.DEPENDENT,
    });
  } else {
    row4.push({
      label: LABELS.FIELD_PROPERTIES.DYNAMIC,
      value: DROPDOWN_TYPES.DYNAMIC,
    });
  }

  const row6 = [
    {
      label: '',
      value: `${data?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL] || ''}`,
      key: FIELD_VALIDATE_KEY.DYNAMIC_CURL,
      valueKey: BASE_SETTINGS_KEYS.DYNAMIC_CURL,
      placeholder: 'Endpoint',
      renderType: RenderTypes.TEXT_AREA,
      errorString: LABELS.ERROR.DYNAMIC_CURL,
      onChange: (value) => {
        setFields((prevState) => ({
          ...prevState,
          [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: value,
          apiEndpoint: data?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL],
        }));
        setIsError((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: !value,
        }));
      },
    },
  ];

  const row5 = [
    {
      label: '',
      value: '',
      placeholder: '',
      render: (newData) => (
        <CustomButton
          ml="10px"
          leftIcon={<TriangleUpIcon style={{ transform: 'rotate(90deg)' }} />}
          style={{ border: '1px solid #2563EB' }}
          color={useColorModeValue(LIGHT, DARK)}
          isLoading={
            newData?.[BASE_SETTINGS_KEYS.DYNAMIC_VALUES_LOADER] || false
          }
          onClick={async () => {
            setFields((pre) => ({
              ...pre,
              [BASE_SETTINGS_KEYS.DYNAMIC_VALUES_LOADER]: true,
            }));

            try {
              // const response = await axiosInstance.post('/curl/execute', {
              //   data: {
              //     curl: newData?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL] || '',
              //   },
              // });
              const baseURL = getAgentDomain();
              const tenantInformation = getTenantData();
              const tenantId = tenantInformation?.id;
              const ticketingEndPoint = '/ticketing/';
              const isEnCodeUrl = newData?.[
                BASE_SETTINGS_KEYS.DYNAMIC_CURL
              ].replaceAll('`', '');
              const response = await axios.get(
                `${baseURL}${ticketingEndPoint}${isEnCodeUrl}`,
                {
                  headers: {
                    authorization: `Bearer ${localStorage.getItem('kc_token')}`,
                    usecase: 'james',
                    'x-tenantid': tenantId,
                    itemtype: window.location.pathname.includes('/fields')
                      ? undefined
                      : name,
                  },
                }
              );
              setCollectionData((pre) => ({
                ...pre,
                [BASE_SETTINGS_KEYS.DYNAMIC_VALUES]: response?.data,
              }));
              setFields((pre) => ({
                ...pre,
                [BASE_SETTINGS_KEYS.DYNAMIC_VALUES]: response?.data,
                [BASE_SETTINGS_KEYS.DYNAMIC_VALUES_LOADER]: false,
                apiEndpoint: newData?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL],
              }));
              // if (response?.data && response?.data?.length > 0) {
              //   const optionFound = response?.data?.filter(
              //     (d) => d.value && d.label
              //   );
              //   if (optionFound?.length > 0) {
              //     setIsError((pre) => ({
              //       ...pre,
              //       [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: false,
              //     }));
              //   } else {
              //     setIsError((pre) => ({
              //       ...pre,
              //       [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: true,
              //     }));
              //   }
              // } else {
              //   setIsError((pre) => ({
              //     ...pre,
              //     [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: true,
              //   }));
              // }
            } catch (error) {
              setFields((pre) => ({
                ...pre,
                [BASE_SETTINGS_KEYS.DYNAMIC_VALUES_LOADER]: false,
              }));
              setIsError((pre) => ({
                ...pre,
                [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: true,
              }));
            }
          }}
          buttonText={LABELS.ACTIONS.FETCH}
          variant="outline"
          id="DropDownSettings-edit-template-save"
        />
      ),
    },
  ];

  return {
    row1: [
      renderFieldLabels({
        data,
        setFields,
        fieldSlug: FIELD_SLUG.FIELD_DROPDOWN,
        setIsError,
        existingFields,
      }),
      {
        label: LABELS.FIELD_PROPERTIES.NAME,
        value: `${data?.[BASE_SETTINGS_KEYS.SLUG] || ''}`,
        placeholder: '',
        required: true,
        key: FIELD_VALIDATE_KEY.SLUG,
        errorString: LABELS.ERROR.NAME,
        isReadOnly: true,
        valueKey: BASE_SETTINGS_KEYS.SLUG,
        onChange: (value) => {
          setFields((pre) => ({
            ...pre,
            [BASE_SETTINGS_KEYS.SLUG]: value,
            [BASE_SETTINGS_KEYS.IS_EDIT_SLUG]: true,
          }));
          setIsError((pre) => ({ ...pre, [BASE_SETTINGS_KEYS.SLUG]: !value }));
        },
      },
    ],
    row2: [
      {
        label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
        value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
        placeholder: '',
        key: BASE_SETTINGS_KEYS.DESCRIPTION,
        valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
        onChange: (value) =>
          setFields((pre) => ({
            ...pre,
            [BASE_SETTINGS_KEYS.DESCRIPTION]: value,
          })),
      },
    ],
    row7: [
      {
        label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
        value: `${collectionData?.[BASE_SETTINGS_KEYS.STATIC_VALUES] || ''}`,
        valueKey: BASE_SETTINGS_KEYS.STATIC_VALUES,
        onChange: (value) =>
          setCollectionData({
            ...collectionData,
            [BASE_SETTINGS_KEYS.STATIC_VALUES]: value,
          }),
        render: (newData) => {
          const localOptions = newData?.[BASE_SETTINGS_KEYS.STATIC_VALUES]
            ? typeof newData[BASE_SETTINGS_KEYS.STATIC_VALUES] === 'string'
              ? [...newData[BASE_SETTINGS_KEYS.STATIC_VALUES].split('\n')].map(
                  (value, index) => ({
                    value,
                    label: value,
                    rank: index + 1,
                  })
                )
              : newData[BASE_SETTINGS_KEYS.STATIC_VALUES]
            : [];
          return (
            <CustomSelectBox
              placeholder="Select Value"
              options={localOptions}
              value={
                getWholeOption(
                  newData?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE],
                  localOptions
                ) || ''
              } // {data?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE] || ''}
              onChange={(option) =>
                setFields((prevState) => ({
                  ...prevState,
                  [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: option?.value || null,
                }))
              }
              styles={customStylesSelectWithHeight40}
              isClearable
            />
          );
        },
      },
    ],

    row4,

    row5: checkForV2() ? [] : row5,

    row6: checkForV2() ? [] : row6,
    //   {
    //     label: LABELS.FIELD_PROPERTIES.COLLECTION_LABEL,
    //     placeholder: '',
    //     render: () => (
    //     <CustomInput
    //       style={{ border: '1px solid #E2E3E4', borderRadius: '4px' }}
    //       height="130px"
    //       value={JSON.stringify(collectionData) || ''}
    //       readOnly
    //     />
    //     ),
    //   },
    //   {
    //     label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
    //     value: `${collectionData?.[BASE_SETTINGS_KEYS.VALUES] || ''}`,
    //     onChange: (value) =>
    //       setCollectionData({
    //         ...collectionData,
    //         [BASE_SETTINGS_KEYS.VALUES]: value,
    //       }),
    //     render: () => (
    //       <SelectComponent
    //         placeholder="Select Value"
    //         options={
    //           data?.[BASE_SETTINGS_KEYS.VALUES]
    //             ? [...data[BASE_SETTINGS_KEYS.VALUES].split('\n')].map(
    //                 (value) => ({
    //                   value,
    //                   name: value,
    //                 })
    //               )
    //             : []
    //         }
    //         value={`${data?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE] || ''}`}
    //         handleChange={(value) =>
    //           setFields({ ...data, [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: value })
    //         }
    //         focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
    //       />
    //     ),
    //   },
    // ],

    row8: [
      {
        label: LABELS.FIELD_PROPERTIES.SPECIAL_FIELD_LABEL,
        render: (newCollectionData) => (
          <>
            <CustomSelectBox
              label={LABELS.FIELD_PROPERTIES.SPECIAL_FIELD_LABEL}
              isMandatory
              placeholder="Select special field"
              options={getOptionsForSpecialField(specialWorkflowData?.id)}
              value={specialFieldOptionValue}
              onChange={(e) => {
                specialFieldOptionValue = getLabel(
                  e.value,
                  SPECIAL_FIELDS_DROPDOWN
                );
                setCollectionData((prev) => {
                  const prevData = {
                    ...prev,
                    [BASE_SETTINGS_KEYS.SPECIAL_FIELD]: e.value,
                  };
                  return prevData;
                });
                setFields((prev) => ({
                  ...prev,
                  [BASE_SETTINGS_KEYS.SPECIAL_FIELD]: e.value,
                }));
              }}
              focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              id="BasicDropdownSettings1SelectBox"
            />
            {specialFieldOptionValue.value ===
            SPECIAL_FIELDS_DROPDOWN[1].value ? (
              <DropdownQueueSelectWithTags
                setTeamsDetail={setTeamsDetail}
                onChange={(e) => {
                  setCollectionData((prev) => (
                    {
                      ...prev,
                      [BASE_SETTINGS_KEYS.LIST]: e,
                    }
                  ));
                  setFields((prev) => ({
                    ...prev,
                    [BASE_SETTINGS_KEYS.LIST]: e,
                  }));
                }}
                teamAndQueue={ teamAndQueue }
                values={collectionData?.[BASE_SETTINGS_KEYS.LIST]}
                setCollectionData={setCollectionData}
                setFields={setFields}
                collectionData={collectionData}
              />
            ) : specialFieldOptionValue.value ===
              SPECIAL_FIELDS_DROPDOWN[2].value ? (
              <>
                <div className="mt-4">Actor Type</div>
                <div className="my-4">
                  <RadioGroup
                    className={`${radioStyles.radiobuttonM} ${radioStyles.flex}`}
                    value={
                      newCollectionData?.[BASE_SETTINGS_KEYS.ACTOR_TYPE] ||
                      ACTOR_TYPE[0].value
                    }
                    onChange={(e) => {
                      const selectedValue = e;
                      // if (!checkDisableForActorType(selectedValue)) {
                      // Update the state only if the selected option is not disabled
                      setFields((prev) => ({
                        ...prev,
                        [BASE_SETTINGS_KEYS.ACTOR_TYPE]: selectedValue,
                      }));
                      setCollectionData((prev) => ({
                        ...prev,
                        [BASE_SETTINGS_KEYS.ACTOR_TYPE]: selectedValue,
                      }));
                      // }
                    }}
                    data-testid="TicketFieldActorScopeRadioGrp"
                  >
                    {ACTOR_TYPE.map((item) => (
                      <Radio
                        value={item.value}
                        key={item.value}
                        data-testid="TicketFieldActorScopeRadioInfo"
                        // disabled={checkDisableForActorType(item?.value)}
                      >
                        {item.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                  {/* <CustomSelectBox
                    placeholder="Select Actor type"
                    options={ACTOR_TYPE.map((item) => ({
                      ...item,
                      isDisabled: checkDisableForActorType(item.value),
                    }))}
                    value={collectionData?.[BASE_SETTINGS_KEYS.ACTOR_TYPE]}
                    onChange={(e) => {
                      setFields((prev) => ({
                        ...prev,
                        [BASE_SETTINGS_KEYS.ACTOR_TYPE]: e.value,
                      }));
                      setCollectionData((prev) => ({
                        ...prev,
                        [BASE_SETTINGS_KEYS.ACTOR_TYPE]: e.value,
                      }));
                    }}
                    focusBorderColor={useColorModeValue(
                      COLORS.LIGHT,
                      COLORS.DARK
                    )}
                    id="BasicDropdownSettings1SelectBox"
                  /> */}
                </div>
              </>
            ) : null}
          </>
        ),
      },
    ],
    row12: [
      {
        label: LABELS.FIELD_PROPERTIES.SPECIAL_FIELD,
        render: (newCollectionData) => (
          <CustomSelectBox
            label={LABELS.FIELD_PROPERTIES.DEPENDENT_TYPE}
            placeholder="Select"
            options={dependentTypeOptions}
            value={
              tempVal === '' ? getLabel(
              collectionData?.[BASE_SETTINGS_KEYS.DEPENDENT_TYPE],
              SPECIAL_FIELDS_DROPDOWN,
              tempVal
            ) : dependentTypeOptions.find((x) => x.value === tempVal)
          }
            onChange={(e) => {
              tempVal = e.value;
              specialFieldOptionValue = getLabel(
                e.value,
                SPECIAL_FIELDS_DROPDOWN,
                tempVal
              );
              setCollectionData((prev) => {
                const prevData = {
                  ...prev,
                  [BASE_SETTINGS_KEYS.DEPENDENT_TYPE]: e.value,
                };
                return prevData;
              });
              setFields((prev) => ({
                ...prev,
                [BASE_SETTINGS_KEYS.DEPENDENT_TYPE]: e.value,
              }));
            }}
            focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            id="BasicDropdownSettings11SelectBox"
          />
        ),
      },
    ],
  };
};
export const AdvanceDropdownSeeting = (data, setFields, setIsError) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    },
  ],
  row2: [
    renderMinLengthField({
      data,
      setFields,
      setIsError,
      label: LABELS.FIELD_PROPERTIES.MIN_NO_TAG,
    }),
    renderMaxLengthField({
      data,
      setFields,
      setIsError,
      label: LABELS.FIELD_PROPERTIES.MAX_NO_TAG,
    }),
  ],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.REQUIRED,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.HIDDEN]: value,
    //     })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REPORTABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REPORTABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REPORTABLE]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.INCLUDE_IN_REPORT,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT]: value,
    //     })),
    // },
  ],
});

import { CustomToast } from '../../components/Notification/Notification';
import axiosInstance from '../../utils/axios/axios';

const { addToast } = CustomToast();

const showToast = ({ message, type = 'success' }) => {
  addToast({
    message,
    type,
    duration: 2000,
    isClosable: true,
  });
};

// To get Service Portal Configuration
export const getConfiguration = async () => {
  try {
    const res = await axiosInstance.get(
      'servicePortalConfiguration/list?$select=*'
    );
    if (!Array.isArray(res?.data?.rows)) {
      throw new Error('Something went wrong');
    }
    return res.data.rows;
  } catch (e) {
    return [];
  }
};

// To get Offers
export const getOffers = async () => {
  try {
    const res = await axiosInstance.get(
      `fieldDefaults/list?$select=*&$filter=offer_type ne 'email'`
    );
    if (!Array.isArray(res?.data?.rows)) {
      throw new Error('Something went wrong');
    }
    return res.data.rows;
  } catch (e) {
    return [];
  }
};

// To create Service Portal Configuration
export const saveConfiguration = async (payload) => {
  try {
    const res = await axiosInstance.post('servicePortalConfiguration/create', {
      data: payload,
    });

    if (res?.status !== 201) {
      throw new Error('Something went wrong');
    }

    showToast({
      message: 'Service Portal Configuration Added successfully',
    });

    return res?.data;
  } catch (err) {
    showToast({
      type: 'error',
      message: 'Failed to add Service Portal Configuration',
    });

    return null;
  }
};

// To update Service Portal Configuration
export const updateConfiguration = async (id, payload) => {
  try {
    const res = await axiosInstance.patch(
      `servicePortalConfiguration/update?$filter=id eq ${id}`,
      {
        data: payload,
      }
    );

    if (res?.data?.rows_updated !== 1) {
      throw new Error('Something went wrong');
    }

    showToast({
      message: 'Service Portal Configuration updated successfully',
    });

    return res?.data?.data;
  } catch (err) {
    showToast({
      type: 'error',
      message: 'Failed to update the Service Portal Configuration',
    });

    return null;
  }
};

export const getImage = async (blobName) => {
  try {
    const res = await axiosInstance.get(`/files/download?fileName=${blobName}`);

    const base64String = res?.data?.data;
    const mimeType = res?.data?.mimeType;
    if (typeof (base64String) !== 'string') {
      throw new Error('Something went wrong');
    }

    return {
      fileSrc: `data:${mimeType};base64,${base64String}`,
      fileMimeType: mimeType,
    };
  } catch (err) {
    return '';
  }
};

export const postImage = async (file) => {
  const fileName = file?.name;

  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await axiosInstance.post(`/files/upload?name=${fileName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (!res?.data?.fileUrl) {
      throw new Error('Something went wrong');
    }

    return {
      imageUrl: res?.data?.fileUrl,
      blobName: res?.data?.blobName,
    };
  } catch (err) {
    showToast({
      type: 'error',
      message: 'Failed to upload the image',
      title: 'Error',
    });

    return null;
  }
};

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../../components/Button/PrimarySecondaryButton';
import styles from './restrictions.module.scss';

const RestrictionsCustomDrawer = ({
  onSave,
  onCancelOrClose,
  open,
  isLoading,
  title,
  closeLabel,
  saveLabel,
  errorMessage,
  children,
  size,
}) => {
  const handleSave = useCallback(() => {
    if (typeof onSave === 'function') {
      onSave();
    }
  }, [onSave]);

  const closeDrawer = useCallback(() => {
    if (typeof onCancelOrClose === 'function') {
      onCancelOrClose();
    }
  }, [onCancelOrClose]);

  return (
    <Drawer size={size || 'xl'} placement="right" onClose={closeDrawer} isOpen={open}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <div className={styles.drawer__header}>
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              className={styles.drawer__close__button}
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={closeDrawer}
            />
            <p>{title}</p>
          </div>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        <DrawerFooter>
          <Flex>
            {errorMessage ? (
              <p className="field-error !mt-0">{errorMessage}</p>
            ) : null}

            <Spacer />
            <Flex gap={3}>
              <SecondaryCustomButton
                onClick={closeDrawer}
                buttonText={closeLabel}
                isDisabled={isLoading}
              />

              <PrimaryCustomButton
                onClick={handleSave}
                buttonText={saveLabel}
                isLoading={isLoading}
              />
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

RestrictionsCustomDrawer.propTypes = {
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancelOrClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  closeLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
};

RestrictionsCustomDrawer.defaultProps = {
  isLoading: false,
  closeLabel: 'Cancel',
  saveLabel: 'Save',
  errorMessage: '',
  children: null,
  size: 'xl',
};

export default RestrictionsCustomDrawer;

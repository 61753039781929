import { Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CustomSelectBox from '../../components/SelectBox/Select';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';
import CustomButton from '../../components/Button/SimpleButton';
import styles from './mapHierarchy.module.scss';
import HierarchyMapperRow from './HierarchyMapperRow';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import { getDefaultFieldOption, getHeaderPosition, LABELS_AND_FLEX_WIDTH_MAP } from './utils';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import { cloneDeep, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const DEFAULT_FIELD_OPTION = getDefaultFieldOption();

const HierarchyMapper = ({
  hierarchies,
  transformedHierarchies,
  hierarchyOptions,
  hierarchyMapState,
  index,
  // dependentFields,
  onSaveHierarchy,
  onDeleteHierarchy,
  showLoader,
  isLoadingHierarchySet,
  onSelectHierarchy,
  onAddRow,
  onDeleteRow,
  onFieldChange,
  onCheckboxChange,
  onDefaultValueChange,
  isAdminUser,
  setHierarchyMapState,
}) => {
  const { selectedTemplate, userRoles } = useSelector((state) => ({
    selectedTemplate: state?.viewState?.selectedTemplate ?? {},
    userRoles: state?.userInfoRole?.data?.data?.metaData?.roles ?? [],
  }));
  const [ isActorHierarchy, setIsActorHierarchy ] =useState(false)
  const hierarchyData = hierarchyMapState[index];

  const filteredHierarchyOptions = useMemo(() => {
    const allSelectedHierarchies = hierarchyMapState.map((item) => item.heirachyListId);
    const otherSelectedHierarchies = allSelectedHierarchies.filter(
      (item) => item !== hierarchyData.heirachyListId
    );

    return hierarchyOptions.filter((item) => !otherSelectedHierarchies.includes(item.value));
  }, [hierarchyOptions, hierarchyMapState, hierarchyData.heirachyListId]);

  const selectedHierarchyDepthMemo = useMemo(() => {
    if (!hierarchyData.heirachyListId) {
      return 0;
    }

    const selectedHierarchy = hierarchyOptions.find(
      (item) => item.value === hierarchyData.heirachyListId
    );
    return selectedHierarchy ? selectedHierarchy.depth : 0;
  }, [hierarchyOptions, hierarchyMapState, hierarchyData.heirachyListId]);

  const hierarchyLevelsMemo = useMemo(() => {
    if (!hierarchyData.heirachyListId || (!hierarchies || hierarchies.length === 0)) {
      return [];
    }

    return transformedHierarchies[hierarchyData.heirachyListId] || [];
  }, [hierarchies, hierarchyData.heirachyListId]);

  const isAnyCheckboxCheckedMemo = useMemo(
    () => Object.keys(hierarchyData.fieldOptions || {})
      .some((key) => {
        if (hierarchyData?.fieldOptions[key]?.showAllValues) {
          return true;
        }
        return false;
      }),
    [hierarchyData.fieldOptions]
  );

  const dependentFields = useMemo(() => {
    if (!isEmpty(selectedTemplate?.fields)) {
      const filterField = selectedTemplate?.fields?.filter(
        (item) =>
          item.type === 'DROPDOWN' &&
          item.type_based_attributes.collection.collection_type === 'DEPENDENT'
      );
      return [...(filterField || [])].map((item) => ({
        value: item.id,
        label: item.label,
      }));
    }
    return [];
  }, []);

  const dependentActorFields = useMemo(() => {
    if (!isEmpty(selectedTemplate?.fields)) {
      const filterField = selectedTemplate?.fields?.filter(
        (item) =>
          item.type === 'DROPDOWN' &&
          (item.type_based_attributes.collection.collection_type === 'DEPENDENT' ||
          item.type_based_attributes.collection?.specialField === 'actor' ||
          item.type_based_attributes.collection?.specialField === 'queue')
      );
      return [...(filterField || [])].map((item) => ({
        value: item.id,
        label: item.label,
      }));
    }
    return [];
  }, []);

  useEffect(()=>{
    if(hierarchyData) {
      let value = getWholeOption(hierarchyData?.heirachyListId, filteredHierarchyOptions)
      if (value) {
        let isActor = value.is_actor_hierarchy
        setIsActorHierarchy(isActor)
      }
    }
  },[])

  const handleSelectHierarchy = (index, value) => {
    setHierarchyMapState((prevState) => {
      const newState = cloneDeep(prevState);
      const prevFieldMap = newState[index]?.map || [];
      const prevFieldOptions = newState[index]?.fieldOptions;
      const newFieldOptions = {};
      const selectedHierarchy = hierarchyOptions.find(
        (item) => item.value === value
      );
      if (selectedHierarchy?.is_actor_hierarchy) setIsActorHierarchy(true);
      else setIsActorHierarchy(false);
      prevFieldMap.forEach((fieldId) => {
        newFieldOptions[fieldId] = {
          ...DEFAULT_FIELD_OPTION,
          showAllValues: prevFieldOptions?.[fieldId]?.showAllValues || false,
        };
      });

      let newMap = [...prevFieldMap];
      newState[index] = {
        heirachyListId: value,
        id: newState[index]?.id,
      };

      const maxLength = selectedHierarchy?.depth || 0;

      if (maxLength < prevFieldMap.length) {
        const idsToRemove = prevFieldMap.slice(maxLength);
        newMap = prevFieldMap.slice(0, maxLength);
        idsToRemove.forEach((id) => {
          delete newFieldOptions[id];
        });
      }
      newState[index].fieldOptions = newFieldOptions;
      newState[index].map = newMap;

      return newState;
    });
  };

  const handleFieldChange = (rowIndex, value) => {
    onFieldChange(index, rowIndex, value);
  };

  const handleCheckboxChange = (rowIndex) => {
    onCheckboxChange(index, rowIndex);
  };

  const handleDefaultValueChange = (rowIndex, value) => {
    onDefaultValueChange(index, rowIndex, value);
  };

  const handleAddRow = () => { onAddRow(index); };

  const handleDeleteRow = (rowIndex) => { onDeleteRow(index, rowIndex); };

  // const checkValuesDisbled = (obj) => {
  //   const fieldOptions = obj?.fieldOptions || null;
  //   if (fieldOptions) {
  //     const retValue = [];
  //     const keys = Object.keys(fieldOptions);
  //       for (const key of keys) {
  //           const option = fieldOptions[key];

  //           if (!option?.selectedValue && !option?.showAllValues) {
  //               retValue.push(false);
  //           } else {
  //               retValue.push(true);
  //       }
  //     }
  //     return retValue.includes(false);
  //   }
  //       return false;

  // };

  return (
    <div className={styles.hierarchyMapperContainer}>
      <div className={styles.hierarchyMapperTitle}>
        <div className={styles.hierarachyMapperTitleSelectBox}>
          <Text fontSize="sm" fontWeight="bold" className={styles.hierarachyMapperTitleSelectBoxLabel}>
            Select Hierarchy
          </Text>
          <CustomSelectBox
            isDisabled={showLoader || isLoadingHierarchySet}
            options={filteredHierarchyOptions}
            value={getWholeOption(hierarchyData?.heirachyListId, filteredHierarchyOptions)}
            onChange={({ value }) => {
              handleSelectHierarchy(index, value);
            }}
            placeholder="Select a Hierarchy set"
            styles={customStyleFromRuleForSelect}
          />
        </div>

        <div className={styles.hierarachyMapperTitleActionButtons}>
          <CustomButton
            buttonText="Delete"
            isDisabled={showLoader || isLoadingHierarchySet}
            onClick={() => {
              onDeleteHierarchy(index);
            }}
            variant="outline"
          />
          <CustomButton
            buttonText="Save"
            isDisabled={showLoader || isLoadingHierarchySet || !hierarchyData.heirachyListId || hierarchyData.map.some((item) => item === '')}
            onClick={() => {
              onSaveHierarchy(index);
            }}
            variant="outline"
            className={styles.hierachyMapperTitleActionButtonPrimary}
          />
        </div>
      </div>

      {
        showLoader
          ? <Loader height="300px" />
          : (
          <>
            <div className={styles.hierarchyMapperTableHeader}>
              {
                LABELS_AND_FLEX_WIDTH_MAP.map((item, labelsIndex) => (
                  <div
                    key={`${item.label}`}
                    className={styles.hierarchyMapperTableHeaderLabel}
                    style={{
                      flex: item.flex,
                      justifyContent: getHeaderPosition(labelsIndex),
                    }}
                  >
                    <Text fontSize="sm" fontWeight="bold">
                      {item.label}
                    </Text>
                  </div>
                ))
              }
            </div>

            {
              hierarchyData.map.map((_, rowIndex) => (
                <HierarchyMapperRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${hierarchyData.id}_${rowIndex}`}
                  index={index}
                  rowIndex={rowIndex}
                  selectedHierarchyDepth={selectedHierarchyDepthMemo}
                  hierarchyLevels={hierarchyLevelsMemo}
                  hierarchyMapState={hierarchyMapState}
                  dependentFields={isActorHierarchy ? dependentActorFields : dependentFields}
                  disableValueSelect={isAnyCheckboxCheckedMemo}
                  isLoadingHierarchySet={isLoadingHierarchySet}
                  isAdminUser={isAdminUser}
                  onAddRow={handleAddRow}
                  onDeleteRow={handleDeleteRow}
                  onFieldChange={handleFieldChange}
                  onCheckboxChange={handleCheckboxChange}
                  onDefaultValueChange={handleDefaultValueChange}
                />
              ))
            }
          </>
          )
      }
    </div>
  );
};

HierarchyMapper.propTypes = {
  index: PropTypes.number.isRequired,
  hierarchies: PropTypes.array.isRequired,
  transformedHierarchies: PropTypes.object.isRequired,
  hierarchyOptions: PropTypes.array.isRequired,
  hierarchyMapState: PropTypes.array.isRequired,
  dependentFields: PropTypes.array.isRequired,
  showLoader: PropTypes.bool.isRequired,
  isLoadingHierarchySet: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  onSaveHierarchy: PropTypes.func.isRequired,
  onDeleteHierarchy: PropTypes.func.isRequired,
  onSelectHierarchy: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onDefaultValueChange: PropTypes.func.isRequired,
};

export default HierarchyMapper;

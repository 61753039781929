/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  FormLabel,
  Wrap,
  WrapItem,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { map } from 'lodash';

import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelect from '../../../components/SelectBox/Select';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
// eslint-disable-next-line import/no-cycle
import { getTenantData } from '../../../helpers/tenantUrl';
import axiosInstance from '../../../utils/axios/axios';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import { BASE_SETTINGS_KEYS } from './constants';

const DropdownQueueSelectWithTags = ({
  onChange,
  teamAndQueue,
  setTeamsDetail,
  values,
  setCollectionData,
  setFields,
  collectionData,
}) => {
  const { selectedTeam, selectedQueue, tags } = teamAndQueue;

  const [teamOptions, setTeamOptions] = useState([]);
  const [queueOptions, setQueueOptions] = useState([]);
  const [whitelistQueue, setWhitelistQueue] = useState(false);

  const addTag = (teamData, queueData) => {
    const newTags = _cloneDeep(tags);
    if (
      newTags.findIndex(
        (tag) => tag.team === teamData.value && tag.queueId === queueData.value
      ) > -1
    ) { return; }
    newTags.push({
      team: teamData.value,
      queueId: queueData.value,
      queue_name: queueData.label,
    });
    teamAndQueue.tags = newTags;
    setTeamsDetail((prev) => ({ ...prev, tags: newTags }));
    const newSelectedTeamQueue = { team: teamData, queue: queueData, tags: newTags };
  };

  const removeTag = ({ index }) => {
    const newTags = _cloneDeep(tags);
    newTags.splice(index, 1);
    teamAndQueue.tags = newTags;
    setTeamsDetail((prev) => ({ ...prev, tags: newTags }));
  };

  const getTeam = async () => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const params = {
      tenant_id: tenantId,
    };
    const response = await axiosInstance.get('teams', { params });
    return response.data;
  };

  useEffect(() => {
    if (collectionData?.[BASE_SETTINGS_KEYS.IS_WHITELIST]) {
      setWhitelistQueue(collectionData[BASE_SETTINGS_KEYS.IS_WHITELIST]);
    }
  }, []);

  useEffect(() => {
    const getOptions = getTeam();
    getOptions.then((res) => {
      const data = res[0];
      const team = data?.length ? data[0].team : [];
      const options = map(team, (item) => ({
        label: item?.toUpperCase(),
        value: item,
      }));
      setTeamOptions(options);
    });
  }, []);

  useEffect(() => {
    if (!_isEmpty(tags)) {
      onChange(tags);
    } else {
      onChange([]);
    }
  }, [tags]);

  useEffect(() => {
    if (!_isEmpty(values)) {
      teamAndQueue.tags = values;
      // setTeamsDetail(prev=>({...prev, tags: values }))
    }
  }, []);

  const getQueue = async (team) => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const params = {
      tenant_id: tenantId,
      team_name: team.value,
    };
    const response = await axiosInstance.get('queueTypes/teams', { params });
    const data = response?.data[0]?.filter(item=>item.status==='Published');
    const selectedQueueIds = tags?.map(tag => tag?.queueId);
    const options = map(data, (item) => ({
      label: item.properties?.queueLabel || item.queue_name,
      value: item.id,
    })).filter(queue => !selectedQueueIds.includes(queue.value));
    setQueueOptions(options);
  };

  return (
    <>
      <CustomCheckBox
        isChecked={whitelistQueue}
        onChange={(e) => {
          setWhitelistQueue(e.target.checked);
          setCollectionData((prev) => {
            const prevData = {
              ...prev,
              [BASE_SETTINGS_KEYS.IS_WHITELIST]: e.target.checked,
            };
            return prevData;
          });
          setFields((prev) => ({
            ...prev,
            [BASE_SETTINGS_KEYS.IS_WHITELIST]: e.target.checked,
          }));
        }}
        checkBoxText={LABELS.FIELD_PROPERTIES.WHITELIST_QUEUE}
        isDisabled={false}
        style={{ marginTop: '30px' }}
      />
      <Box data-testid="DropdownQueueSelectWithTagsContainerBox">
        <FormLabel
          fontWeight={600}
          marginTop="20px"
          marginBottom="10px"
          data-testid="DropdownQueueSelectWithTagsFormLabel"
        >
          Team
        </FormLabel>
        <CustomSelect
          options={teamOptions}
          value={selectedTeam}
          onChange={(value) => {
            teamAndQueue.selectedQueue = '';
            teamAndQueue.selectedTeam = value;
            setTeamsDetail((prev) => ({ ...prev, selectedTeam: value }));
            getQueue(value);
          }}
          isDisabled={!whitelistQueue}
          placeholder="Select Team"
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(e) => (
            <Flex align="center" className="text-[14px]">
              {e.icon && (
                <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />
              )}
              <span className="ml-1">{e.label}</span>
            </Flex>
          )}
          id="DropdownQueueSelectWithTagsSelect"
        />
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="20px"
          mb="30px"
          mt="30px"
          data-testid="DropdownQueueSelectWithTagsFlex"
        >
          <CustomSelect
            options={queueOptions}
            value={selectedQueue}
            onChange={(value) => {
              teamAndQueue.selectedQueue = value;
              setTeamsDetail((prev) => ({ ...prev, selectedQueue: value }));
            }}
            placeholder="Select Queue"
            isDisabled={!whitelistQueue}
            // eslint-disable-next-line react/no-unstable-nested-components
            getOptionLabel={(e) => (
              <Flex align="center" className="text-[14px]">
                {e.icon && (
                  <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />
                )}
                <span className="ml-1">{e.label}</span>
              </Flex>
            )}
            id="DropdownQueueSelectWithTagsSelect"
          />
          <CustomButton
            className={` ml-4 border-[#2563EB] `}
            color={useColorModeValue(LIGHT, DARK)}
            id="button-addDynamicSelectWithTag-role"
            buttonText={LABELS.ACTIONS.ADD}
            variant="outline"
            height="32px"
            mt="0px"
            width="66px"
            padding="0px 30px"
            isDisabled={!whitelistQueue}
            onClick={() => {
              if (selectedTeam && selectedQueue) {
                addTag(selectedTeam, selectedQueue);
              }
              teamAndQueue.selectedTeam= ''; 
              teamAndQueue.selectedQueue= '' ;
              setTeamsDetail(prev=>({ ...prev, selectedTeam:'', selectedQueue:''}))
              setQueueOptions([]);
            }}
          />
        </Flex>
        {tags.length > 0 && (
          <Wrap
            spacing="15px"
            mt="22px"
            data-testid="DropdownQueueSelectWithTagsContainerWrap"
          >
            <Wrap
              spacing={2}
              pl="10px"
              pt="3px"
              pb="3px"
              border={`1px solid ${LIGHT}`}
              borderRadius="4px"
              key={uuidv4()}
              data-testid="DropdownQueueSelectWithTagsWrap"
            >
              <WrapItem data-testid="DropdownQueueSelectWithTagsWrapItem">
                <Wrap data-testid="DropdownQueueSelectWithTagsInnerWrap">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    data-testid="DropdownQueueSelectWithTagsInnerBox"
                  >
                    {`Team/Queue : `}
                  </Box>
                  {tags.map((value, index) => (
                    <Flex
                      key={value}
                      justifyContent="center"
                      alignItems="center"
                      bg="#EBEBEB"
                      borderRadius="4px"
                      fontWeight={500}
                      // height="28px"
                      // paddingLeft="1rem"
                      data-testid="DropdownQueueSelectWithTagsInnerMapFlex"
                    >
                      <Box data-testid="DropdownQueueSelectWithTagsLabelBox">
                        {`${value?.team} - ${value?.queue_name}`}
                      </Box>
                      <IconButton
                        background="ffffff"
                        icon={<SmallCloseIcon />}
                        onClick={() =>
                          removeTag({
                            index,
                            value,
                          })
                        }
                        data-testid="DropdownQueueSelectWithTagsIcomButton"
                      />
                    </Flex>
                  ))}
                </Wrap>
              </WrapItem>
            </Wrap>
          </Wrap>
        )}
      </Box>
    </>
  );
};

DropdownQueueSelectWithTags.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  setCollectionData: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  collectionData: PropTypes.object.isRequired,
};

DropdownQueueSelectWithTags.defaultProps = {};

export default DropdownQueueSelectWithTags;

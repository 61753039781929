import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';
import { TbBolt } from 'react-icons/tb';
import RestrictionsSectionContainer from '../RestrictionsSectionContainer';
import DateAddRuleDrawer from './DateAddRuleDrawer';
import { PrimaryCustomButton } from '../../../../../components/Button/PrimarySecondaryButton';
import { DATE_VALIDATION_LABELS } from '../../../../../utils/enums/labels';
import RestrictionPreviewPill from '../RestrictionPreviewPill';
import { FUNCTION_TYPES, RULE_TYPES, TIMEFRAME_TYPES } from './utils/dateRestrictions.constants';
import { getRulePreviewObject } from './utils/dateRestrictions.helper';
import styles from '../restrictions.module.scss';

const {
  ADD_RULE_BUTTON,
  DEFAULT_SECTION_HEADER_TITLE,
} = DATE_VALIDATION_LABELS;

const DateDefaultRestrictions = ({
  defaultValidations,
  onChangeDefaultRestrictions,
  templateFields,
  allowTime,
}) => {
  const rules = defaultValidations.rules || [];

  const [currentRule, setCurrentRule] = useState({});
  const [openAddRuleDrawer, setOpenAddRuleDrawer] = useState(false);
  const [openEditRuleDrawer, setOpenEditRuleDrawer] = useState(false);

  const handleCloseAddRuleDrawer = () => {
    setOpenAddRuleDrawer(false);
    setOpenEditRuleDrawer(false);
  };

  const onChangeRule = (rule) => {
    if (openEditRuleDrawer) {
      // edit rule
      onChangeDefaultRestrictions({
        rules: rules.map((r) => (r.id === rule.id ? rule : r)),
      });
    } else {
      // add rule
      onChangeDefaultRestrictions({
        rules: [...rules, rule],
      });
    }
    handleCloseAddRuleDrawer();
  };

  return (
    <RestrictionsSectionContainer title={DEFAULT_SECTION_HEADER_TITLE}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight={500}>Restriction Rules</Text>
        <PrimaryCustomButton
          buttonText={ADD_RULE_BUTTON}
          onClick={() => setOpenAddRuleDrawer(true)}
          leftIcon={<TbBolt size="18px" />}
        />
      </Flex>

      {
        openAddRuleDrawer || openEditRuleDrawer ? (
          <DateAddRuleDrawer
            rule={openEditRuleDrawer ? currentRule : null}
            open={openAddRuleDrawer || openEditRuleDrawer}
            isEditMode={openEditRuleDrawer}
            onModalClose={handleCloseAddRuleDrawer}
            templateFields={templateFields}
            allowTime={allowTime}
            onSaveRule={onChangeRule}
          />
        ) : null
      }

      {
        rules?.length ? (
          <div className={styles.restrictionPreviewPillContainer}>
            {
              rules.map((rule) => (
                <RestrictionPreviewPill
                  key={rule.id}
                  id={rule.id}
                  onEdit={(id) => {
                    setCurrentRule(rules.find((r) => r.id === id));
                    setOpenEditRuleDrawer(true);
                  }}
                  onDelete={(id) => {
                    onChangeDefaultRestrictions({
                      rules: rules.filter((r) => r.id !== id),
                    });
                  }}
                  previewObjects={getRulePreviewObject(rule, templateFields, allowTime)}
                  previewObjectJoiner="and"
                />
              ))
            }
          </div>
        ) : null
      }

    </RestrictionsSectionContainer>
  );
};

DateDefaultRestrictions.propTypes = {
  defaultValidations: PropTypes.shape({
    // array of objects with shap
    rules: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        RULE_TYPES.RELATIVE,
        RULE_TYPES.ABSOLUTE
      ]).isRequired,
      offset: PropTypes.number,
      timeframe: PropTypes.oneOf([
        TIMEFRAME_TYPES.LESS_THAN,
        TIMEFRAME_TYPES.GREATER_THAN,
        TIMEFRAME_TYPES.RANGE,
      ]),
      reference: PropTypes.string,
      functionName: PropTypes.oneOf([
        FUNCTION_TYPES.CURRENT,
        FUNCTION_TYPES.START_OF_DAY,
        FUNCTION_TYPES.END_OF_DAY,
        FUNCTION_TYPES.START_OF_WEEK,
        FUNCTION_TYPES.END_OF_WEEK,
        FUNCTION_TYPES.START_OF_MONTH,
        FUNCTION_TYPES.END_OF_MONTH,
        FUNCTION_TYPES.START_OF_YEAR,
        FUNCTION_TYPES.END_OF_YEAR,
      ]),
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    })),
  }),
  onChangeDefaultRestrictions: PropTypes.func.isRequired,
  templateFields: PropTypes.array.isRequired,
  allowTime: PropTypes.bool,
};

DateDefaultRestrictions.defaultProps = {
  defaultValidations: { rules: [] },
  allowTime: false,
};

export default DateDefaultRestrictions;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  useColorModeValue,
} from '@chakra-ui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { COLORS, DARK, LIGHT } from '../../utils/enums/colors';
import CustomButton from '../../components/Button/SimpleButton';
import { roleSettingSelectOpt } from '../../utils/enums/selectOption';
import DynamicMutliSelectWithInputTags from '../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import ConditionsExpressions from '../../components/ConditionsExpressions/ConditionsExpressions';
import { isEmptyCondition } from '../../utils/helper';
import { CustomDrawer } from '../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import { isEventConfigValid } from './helper';
import CustomAccordion from '../../components/Accordion/Accordion';
import ActionEventConfigRow from './ActionEventConfigRow';

const ActionCondition = ({
  eventOptions,
  actionState,
  handleChange,
  selectedTemplate,
  eventsByTemplateId,
  filteredRelatedTemplateOptions,
  onFinishSave,
  onAddEventConfigRow,
  onRemoveEventConfigRow,
  isLoading,
  errorKeyListWithMessage = null,
  relatedOffers,
}) => {
  const [isAddConditionDrawerOpen, setIsAddConditionDrawerOpen] = useState(false);
  const AccordionData = [
    {
      title: 'Ticket Conditions',
      content: (
        <ConditionsExpressions
          templateId={selectedTemplate?.id}
          value={actionState.listening_conditions || {}}
          onChange={(value) => {
            const { and } = value || {};
            const updatedValue = isEmptyCondition(and) ? { and: [] } : value;
            handleChange(updatedValue, `listening_conditions`);
          }}
          showRelativeFilter = {true}
          // label="Ticket Conditions"
        />
      ),
    },
    {
      title: 'User Condition',
      content: (
        <DynamicMutliSelectWithInputTags
          values={actionState.user_conditions || {}}
          // label="User Condition"
          onChange={(value) => {
            handleChange(value, 'user_conditions');
          }}
          templateId={selectedTemplate?.id}
          selectOptions={roleSettingSelectOpt}
        />
      ),
    },
  ];
  return (
  <TabPanel>
    <Box>
      {
        actionState.eventConfig.map((_, index) => (
          <ActionEventConfigRow
            index={index}
            eventOptions={eventOptions}
            actionState={actionState}
            handleChange={handleChange}
            eventsByTemplateId={eventsByTemplateId}
            filteredRelatedTemplateOptions={filteredRelatedTemplateOptions}
            selectedTemplate={selectedTemplate}
            errorKeyListWithMessage={errorKeyListWithMessage}
            onAddEventConfigRow={() => onAddEventConfigRow(index)}
            onRemoveEventConfigRow={() => onRemoveEventConfigRow(index)}
            relatedOffers={relatedOffers}
          />
        ))
      }

      {isEventConfigValid(actionState.eventConfig) && (
        <HStack
          spacing="5px"
          mt="5px"
          display="flex"
          cursor="pointer"
          alignItems="center"
          justifyContent="end"
          color={`${COLORS.LIGHT}`}
          onClick={() => setIsAddConditionDrawerOpen(true)}
        >
          <PlusCircleIcon width="24px" height="px" />
          {/* eslint-disable-next-line react/button-has-type */}
          <button style={{ fontSize: '14px', fontWeight: '400', lineHeight: 'normal' }}>Add Condition</button>
        </HStack>
      )}
      {isEventConfigValid(actionState.eventConfig) &&
          errorKeyListWithMessage?.user_conditions && (
            <p style={{ textAlign: 'right', fontSize: '12px', color: 'red' }}>
              Select at least one user condition
            </p>
        )}
      <CustomDrawer
        size="xl"
        drawerBody={<CustomAccordion accordionData={AccordionData} />}
        drawerFooter={
          <Flex p="16px 10px" justify="flex-end">
            <CustomButton
              color="white"
              onClick={() => {
                onFinishSave();
              }}
              buttonText="Save"
              variant="solid"
              bg={useColorModeValue(LIGHT, DARK)}
              isLoading={isLoading}
            />
          </Flex>
        }
          modalTitle="Action Conditions"
          openDrawer={isAddConditionDrawerOpen}
          setOpenDrawer={setIsAddConditionDrawerOpen}
        />
    </Box>
  </TabPanel>
  );
};

export default ActionCondition;

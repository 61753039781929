import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import FieldsTableViewWithSearch from './FieldsTableViewWithSearch';
import { BOT_IDENTICATION_FIELDS_TRIGGERS } from './utils';
import axiosInstance from '../../../../utils/axios/axios';

const BotIdentificationFields = () => {
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [dropdownFields, setDropdownFields] = useState([]);
  const [deletedFields, setDeletedFields] = useState([]);
  const templateId = useParams();
  const id = templateId['*'].match(/\d+/)[0];
  const fields=selectedTemplate[0]?.fields
  const getFields = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(
        `/templates/list?$top=1&$skip=0&$select=*&$filter=id%20eq%20${id}&history=true`
      );
      if (Array.isArray(response?.data?.rows)) {
        setSelectedTemplate(response?.data?.rows);
      }
      setIsFetching(false);
      return [];
    } catch (err) {
      setIsFetching(false);
      return [];
    }
  };
  useEffect(() => {
    getFields();
  }, []);
  const onEditDescription = (rowData, value) => {
    const updatedTableData = descriptionData.map((item) => {
      if (item.id === rowData.id) {
        return { ...item, botDescription: value };
      }
      return item;
    });
    setDescriptionData(updatedTableData);
  }
  const onChangeInformationRetrievalPreference=(rowData,value)=>{
    const updatedTableData = descriptionData.map(item => {
      if (item.id === rowData.id) {
        return { ...item, informationRetrievalPreference: value };
      }
      return item;
    })
    setDescriptionData(updatedTableData);
  }
  const onChangeCrossReferencedFields = (rowData, fieldValues) => {
    const updatedTableData = descriptionData.map(item => {
      if (item.id === rowData.id) {
        return { ...item, crossReferencedFields: fieldValues.map(item=>{
          return{label:item.value,value:item.value}
        }) };
      }
      return item;
    });
    setDescriptionData(updatedTableData);
  }
  useEffect(() => {
    // const nonBotUserFields=selectedTemplate[0]?.fields.filter(item=>!(item?.permissions?.viewers?.roles.includes("bot_user")))
    // const initialRequiredFields=allMandatoryFields.filter(item=>{
    //   if(Array.isArray(item?.permissions?.viewers?.roles)){
    //     const nonBotUserFields=!(item?.permissions?.viewers?.roles.includes("bot_user"))
    //     return nonBotUserFields.filter(element=>element.id===item.id)
    //   }
    // })
    // setDescriptionData(initialRequiredFields)

    // const initialFields=selectedTemplate[0]?.fields.filter((item)=>{

    //   if(Array.isArray(item?.permissions?.viewers?.roles) && item?.type_based_attributes?.required){
    //     const allMandatoryFields=item?.type_based_attributes?.required
    //     const requiredFieldsForBot=allMandatoryFields.filter(element=>{
    //      return !element?.permissions?.viewers?.roles.includes("bot_user")
    //     })
    //     console.log("req",requiredFieldsForBot)
    //     if((item?.permissions?.viewers?.roles.includes("bot_user") && item?.type_based_attributes?.required)||(item?.permissions?.viewers?.roles.includes("bot_user") && item?.requiredForBot)){return item}
    //   }
    // })
    if (Array.isArray(selectedTemplate[0]?.fields)) {
      const formatText = (input) => {
        let formattedText = input.replace(/_/g, ' ').toLowerCase();
        formattedText = formattedText.replace(/\b\w/g, (char) => char.toUpperCase());

        return formattedText;
      };
      const allMandatoryFields = selectedTemplate[0]?.fields.filter((item) => {
        if (item?.type_based_attributes?.required) {
          item.type = formatText(item.type);
          return true;
        } return false;
      });
      const allNonMandatoryFields = selectedTemplate[0]?.fields.filter((item) => {
        if (!item?.type_based_attributes?.required) {
          item.type = formatText(item.type);
          return true;
        } return false;
      });
      const nonBotUserMandatoryFields = allMandatoryFields.filter((item) => {
        if (Array.isArray(item?.permissions?.viewers?.roles)) {
          const { roles } = item.permissions.viewers;
          if (roles.find((ele) => ele === 'ticket_user')) {
            return null;
          } if (roles.find((element) => element === 'bot_user')) {
            return null;
          }
          return item;
        } if (!Array.isArray(item?.permissions?.viewers?.roles)) {
          return null;
        } return null;
      });
      const nonBotUserNonMandatoryFields = allNonMandatoryFields.filter((item) => {
        if (Array.isArray(item?.permissions?.viewers?.roles)) {
          const { roles } = item.permissions.viewers;
          if (roles.find((ele) => ele === 'ticket_user')) {
            return null;
          } if (roles.find((element) => element === 'bot_user')) {
            return null;
          }
          return item;
        } if (!Array.isArray(item?.permissions?.viewers?.roles)) {
          return null;
        } return null;
      });
      const botUserFields = allMandatoryFields.filter((item) => {
        if (item?.type_based_attributes?.required) {
          return !nonBotUserMandatoryFields.some((item2) => item2.id === item.id);
        }
      });
      const dropdownBotUserFields = allNonMandatoryFields.filter((item) => {
        if (!item?.type_based_attributes?.required) {
          return !nonBotUserNonMandatoryFields.some((item2) => item2.id === item.id);
        }
      });
      const additionalBotUserFields = allNonMandatoryFields.filter((item) => item?.requiredForBot);
      const fields = [...botUserFields, ...additionalBotUserFields];
      const mandatoryFields = fields.filter((item) => {
        if ((item?.type_based_attributes?.collection?.collection_type === 'SPECIAL_FIELD')) {
          return null;
        }
        return item;
      }).map((item) => ({
        ...item,
        requiredForBot: true
      }));
      const optionalFields = dropdownBotUserFields.filter((item) => {
        if ((item?.type_based_attributes?.collection?.collection_type === 'SPECIAL_FIELD')) {
          return null;
        }
        return item;
      });
      const filteredMandatoryFields=mandatoryFields.filter((item=>{
        const isExpression=item?.permissions?.expression?.and?.length>0 && item?.permissions?.expression?.and.find(ele=>ele.or)?.or.length>0
        const isEnableExpression=item?.permissions?.enableExpression?.and?.length>0 && item?.permissions?.enableExpression?.and.find(ele=>ele.or)?.or.length>0
        const isRequiredExpression=item?.permissions?.requiredExpression?.and?.length>0 && item?.permissions?.requiredExpression?.and.find(ele=>ele.or)?.or.length>0
        if(isExpression || isEnableExpression || isRequiredExpression) return false
        else return true
      }))
      const filteredOptionalFields=optionalFields.filter((item=>{
        const isExpression=item?.permissions?.expression?.and?.length>0 && item?.permissions?.expression?.and.find(ele=>ele.or)?.or.length>0
        const isEnableExpression=item?.permissions?.enableExpression?.and?.length>0 && item?.permissions?.enableExpression?.and.find(ele=>ele.or)?.or.length>0
        const isRequiredExpression=item?.permissions?.requiredExpression?.and?.length>0 && item?.permissions?.requiredExpression?.and.find(ele=>ele.or)?.or.length>0
        if(isExpression || isEnableExpression || isRequiredExpression) return false
        else return true
      }))
      setDescriptionData(filteredMandatoryFields);
      setDropdownFields(filteredOptionalFields);
    }
  }, [selectedTemplate]);
  const onDelete = async (fieldToBeDelete) => {
    const fields = descriptionData.filter((item) => item.id !== fieldToBeDelete?.id);
    setDescriptionData(fields);
    setDeletedFields(fieldToBeDelete);
  };
  return (
    <FieldsTableViewWithSearch
      columns={BOT_IDENTICATION_FIELDS_TRIGGERS({onEditDescription,onDelete,onChangeInformationRetrievalPreference,onChangeCrossReferencedFields,fields})}
      tableData={descriptionData}
      selectedTemplate={selectedTemplate}
      descriptionData={descriptionData}
      setDescriptionData={setDescriptionData}
      getFields={getFields}
      isFetching={isFetching}
      setDropdownFields={setDropdownFields}
      dropdownFields={dropdownFields}
      deletedFields={deletedFields}
      setDeletedFields={setDeletedFields}
    />
  );
};

export default BotIdentificationFields;

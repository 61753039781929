import React,{useState} from 'react';

import { Box,Flex,Text, useColorModeValue } from '@chakra-ui/react';

import PropTypes from 'prop-types';

import TextWithTooltip from '.';
import Styles from '../Table/table.module.scss';
import { CheckCircleIcon } from '@heroicons/react/24/outline';


const MAX_TABLE_COLUMN_WIDTH = '250px';

const TableColumnText = ({ text, showLength }) => (
  <Box maxWidth={MAX_TABLE_COLUMN_WIDTH} display={'flex'} alignItems={'center'}>
    <TextWithTooltip
      text={text || ''}
      defaultClassName={useColorModeValue(
        Styles.tableNestedContentLight,
        Styles.tableNestedContentDark
      )}
      showLength={showLength}
    />
  </Box>
)

TableColumnText.propTypes = {
  text: PropTypes.string,
  showLength: PropTypes.number,
};

TableColumnText.defaultProps = {
  text: '',
};

export default TableColumnText;

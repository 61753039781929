/* eslint-disable jsx-a11y/label-has-associated-control */
import { CloseIcon, DragHandleIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, useColorModeValue, FormLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BsUpload } from 'react-icons/bs';
import { HiOutlineTrash } from 'react-icons/hi2';
import CustomButton from '../../components/Button/SimpleButton';
import CustomInderminateCheckBox from '../../components/IndeterminateCheckbox';
import CustomInput from '../../components/InputBox/Input';
import CustomSelectBox from '../../components/SelectBox/Select';
import { COLORS } from '../../utils/enums/colors';
import styles from './servicePortalConfiguration.module.scss';
import { CARD_TYPES, getBase64 } from './utils';
import CustomSwitch from '../../components/Switch/Switch';

const Cards = ({
  cards,
  icons,
  intents,
  offers,
  listOfIcons,
  onChange,
  handleIconUpload,
  handleIconRemove,
}) => {
  const [items, setItems] = useState(cards);
  const [draggedItem, setDraggedItem] = useState(null);

  useEffect(() => {
    setItems(cards);
  }, [cards]);

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    if (!draggedItem) return;

    const currentIndex = items.indexOf(draggedItem);
    const targetIndex = items.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      items.splice(currentIndex, 1);
      items.splice(targetIndex, 0, draggedItem);
      onChange('cards', items);
    }
  };

  const handleNewCard = () => {
    const newCard = {
      id: items.length + 1,
      title: '',
      description: '',
      type: null,
      icon: null,
    };
    onChange('cards', [...items, newCard]);
  };

  const handleRemoveCard = (id) => {
    onChange(
      'cards',
      items.filter((item) => item.id !== id)
    );
  };

  const handleTypeChange = (val, id) => {
    onChange('type', val, id);
    onChange('static_link', '', id);
    if (val === 'static_link') {
      onChange('static_link_intent_is_root', true, id);
    } else {
      onChange('static_link_intent_is_root', null, id);
    }
    onChange('intent_hierarchy', [], id);
    onChange('offer', '', id);
  };

  const handleIntentChange = (value, id) => {
    onChange('intent_hierarchy', value, id);
    onChange('static_link', '', id);
    onChange('offer', '', id);
  };

  const handleStaticLinkChange = (value, id) => {
    onChange('static_link', value, id);
    onChange('intent_hierarchy', [], id);
    onChange('offer', '', id);
  };

  const handleStaticLinkLevelChange = (value, id, isRoot = false) => {
    onChange('static_link_intent_is_root', isRoot, id);
    onChange('static_link_intent', value[0], id);
  };

  const handleOfferChange = (value, id) => {
    onChange('offer', value, id);
    onChange('static_link', '', id);
    onChange('intent_hierarchy', [], id);
  };

  const onIconChange = (event) =>
    getBase64(event.target.files[0], (result) => {
      handleIconUpload(result);
    });
  return (
    <Flex flex="1" gap="10px" flexDirection="column">
      {cards.map((item) => (
        <Flex
          key={item.id}
          draggable="true"
          onDragStart={(e) => handleDragStart(e, item)}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, item)}
          borderRadius="10px"
          border="1px solid #D1D5DB"
        >
          <Box p="2px" borderRight="1px solid #D1D5DB">
            <DragHandleIcon height="100%" />
          </Box>
          <Flex
            position="relative"
            flex="1"
            flexDirection="column"
            gap="10px"
            p="10px"
          >
            <Box
              p="3px"
              position="absolute"
              right="5px"
              top="5px"
              cursor="pointer"
              zIndex="1"
              onClick={() => handleRemoveCard(item.id)}
            >
              <HiOutlineTrash color="#94A3B8" size={20} />
            </Box>
            <CustomInput
              isMandatory
              label="Title"
              value={item.title || ''}
              onChange={(event) =>
                onChange('title', event.target.value, item.id)
              }
              isError={item.titleError || false}
              errorMessage="Title is required"
            />
            <CustomInput
              isMandatory
              label="Description"
              value={item.description || ''}
              onChange={(event) =>
                onChange('description', event.target.value, item.id)
              }
              isError={item.descriptionError || false}
              errorMessage="Description is required"
            />
            <CustomSelectBox
              isMandatory
              label="Type"
              options={CARD_TYPES}
              onChange={(e) => handleTypeChange(e.value, item.id)}
              value={CARD_TYPES.find((type) => type.value === item.type)}
              isError={item.typeError || false}
              errorMessage="Type is required"
            />
            {item.type && item.type === 'static_link' && (
              <>
              <CustomInput
                isMandatory
                label="URL Link"
                value={item.static_link || ''}
                onChange={(event) => handleStaticLinkChange(event.target.value, item.id)}
                isError={item.staticLinkError || false}
                errorMessage="Static Link is required"
              />
              <FormLabel>Add To Root Level</FormLabel>
              <CustomSwitch
                isChecked={item.static_link_intent_is_root || false}
                onChange={(event) => handleStaticLinkLevelChange('', item.id, event.target.checked)}
              />
              {!item.static_link_intent_is_root && (
                <CustomInderminateCheckBox
                  isMandatory
                  isMulti={false}
                  label="Intent"
                  data={intents}
                  value={[item.static_link_intent] || []}
                  onChange={(value) => handleStaticLinkLevelChange(value, item.id)}
                  parentSelectsChild={false}
                />
              )}
              </>
            )}
            {item.type && item.type === 'intent_hierarchy' && (
              <CustomInderminateCheckBox
                isMandatory
                isMulti={false}
                label="Intent"
                data={intents}
                value={item.intent_hierarchy || []}
                onChange={(value) => handleIntentChange(value, item.id)}
                parentSelectsChild={false}
                errorMessage={item.staticLinkError ? 'Intent is required' : ''}
              />
            )}
            {item.type && item.type === 'offer' && (
              <CustomSelectBox
                isMandatory
                label="Offer"
                options={offers}
                value={offers.find((offer) => offer.value === item.offer)}
                onChange={(event) => handleOfferChange(event.value, item.id)}
                isError={item.offerError || false}
                errorMessage="Offer is required"
              />
            )}
            <Flex flexDirection="column" overflow="hidden">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="10px"
              >
                <Text fontSize="14px" fontWeight="500">
                  Choose An Icon
                </Text>
                <label>
                  <Flex
                    gap="10px"
                    alignItems="center"
                    color={COLORS.LIGHT}
                    cursor="pointer"
                  >
                    <BsUpload size={15} />
                    <Text fontSize="14px">Upload Icon</Text>
                    <input
                      type="file"
                      id="upload-icon"
                      name="upload-icon"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={onIconChange}
                      hidden
                    />
                  </Flex>
                </label>
              </Flex>
              <Flex className={styles.icon__container}>
                {listOfIcons.map((icon, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    cursor="pointer"
                    onClick={() => onChange('icon', icon, item.id)}
                    className={
                      item.icon === icon
                        ? `${styles.icon} ${styles.active}`
                        : styles.icon
                    }
                  >
                    <img
                      src={icon}
                      alt={`Icon ${index + 1}`}
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                ))}
                {icons.length > 0 &&
                  icons.map((icon, index) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      cursor="pointer"
                      onClick={() => onChange('icon', icon, item.id)}
                      className={
                        item.icon === icon
                          ? `${styles.icon} ${styles.active}`
                          : styles.icon
                      }
                    >
                      <img
                        src={icon}
                        alt={`User Icon ${index + 1}`}
                        style={{
                          width: '20px',
                          height: '20px',
                          objectFit: 'contain',
                        }}
                      />
                      <Flex
                        position="absolute"
                        width="16px"
                        height="16px"
                        right="-5px"
                        top="-5px"
                        borderRadius="50%"
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={COLORS.GREY_BACKGROUND}
                        onClick={(event) =>
                          handleIconRemove(event, item.id, icon)
                        }
                      >
                        <CloseIcon boxSize={2} color="gray.500" />
                      </Flex>
                    </Box>
                  ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ))}
      <Flex justifyContent="flex-end">
        <CustomButton
          buttonText="Add New Card"
          variant="solid"
          style={{
            backgroundColor: COLORS.LIGHT,
            color: COLORS.WHITE,
            borderColor: '#2563EB',
          }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={handleNewCard}
        />
      </Flex>
    </Flex>
  );
};

Cards.propTypes = {
  cards: PropTypes.array.isRequired,
  icons: PropTypes.array.isRequired,
  intents: PropTypes.array.isRequired,
  offers: PropTypes.array.isRequired,
  listOfIcons: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  handleIconUpload: PropTypes.func.isRequired,
  handleIconRemove: PropTypes.func.isRequired,
};

export default Cards;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios/axios';

export const fetchLanguages = createAsyncThunk(
  'languageList/fetchLanguages',
  async () => {
    try {
      const response = await axiosInstance.get('/allLocales');
      if (response) {
        const { data } = response;
        return data;
      }
    } catch (error) {
      throw new Error(error);
    }
    return [];
  }
);

export const fetchSelectedLanguages = createAsyncThunk(
  'languageList/fetchSelectedLanguages',
  async () => {
    try {
      const response = await axiosInstance.get(
        '/locales/list?$select=*&$filter=is_active eq true&$orderby=id asc'
      );
      if (response) {
        const { data } = response;
        return data.rows;
      }
    } catch (error) {
      throw new Error(error);
    }
    return [];
  }
);

const initialState = {
  languages: [],
  selectedLanguages: [],
  loading: false
};

const locales = createSlice({
  name: 'languageList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLanguages.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
    builder
    .addCase(fetchSelectedLanguages.pending, (state) => {
      state.loading= true;
    })
    .addCase(fetchSelectedLanguages.fulfilled, (state, action) => {
      state.selectedLanguages = action.payload;
      state.loading = false
    });
  },
});

export default locales.reducer;

import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../InputBox/Input';
import CustomButton from '../Button/SimpleButton';
import CustomTable from './Table';

import { COLORS } from '../../utils/enums/colors';

const TableViewWithSearch = ({
  placeholder,
  buttonText,
  onCreateNew,
  isTableLoading,
  columns,
  tableData,
  onRowClick,
  searchKey,
  // eslint-disable-next-line no-unused-vars
  showAddIcon,
  buttonVariant,
  filterTableData,
  showCreateNewButton,
  headerTitle,
  title,
  sortableConfig
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const searchText = useRef('');

  const filterTable = (inputValue = '') => {
    if (typeof filterTableData === 'function') {
      const filteredTempData = filterTableData(inputValue);
      setFilteredData([...filteredTempData]);
    } else {
      const filteredFields = tableData.filter((field) =>
        field[searchKey]?.toLowerCase().includes(inputValue?.toLowerCase())
      );
      setFilteredData([...filteredFields]);
    }
  };

  const filterData = debounce((inputValue) => {
    filterTable(inputValue);
  }, 500);

  useEffect(() => {
    searchText.current = searchInputValue;
  }, [searchInputValue]);

  useEffect(() => {
    filterTable(searchText.current);
  }, [tableData]);

  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  };

  return (
    <>
      {!isEmpty(headerTitle) ? (
        <Box
          display="flex"
          justifyContent="space-between"
          data-testid="LinkedMetaFieldTableViewContainerBoxFlex"
          alignItems="center"
          marginTop="10px"
          marginBottom="10px"
          borderBottom="1px solid #e2e3e4"
        >
          <Text style={{ fontWeight: 'bold' }}>{headerTitle}</Text>
        </Box>
      ) : null}
      <Flex
        flexWrap="wrap"
        alignItems="center"
        data-testid="TbleViewWithSearchFlex"
        justifyContent="space-between"
      >
        <Box fontSize="16px" fontWeight="600">
          {title}
        </Box>
        <Flex gap="10px">
          <Box flex="1" data-testid="TbleViewWithSearchBox">
            {/* <CustomInput placeholder={placeholder} onChange={filterData} /> */}
            <InputGroup size="l" data-testid="TbleViewWithSearchInputGrp">
              <CustomInput
                placeholder={placeholder}
                type="text"
                size="sm"
                value={searchInputValue}
                onChange={(e) => {
                  setSearchInputValue(e.target.value);
                  filterData(e.target.value);
                }}
                id="TableWithSearchCInput"
              />
              <InputRightElement
                style={{ right: 12, cursor: 'pointer' }}
                // eslint-disable-next-line react/no-children-prop
                children={
                  searchInputValue ? (
                    <CloseIcon color="gray.300" className="calculatedHeight" onClick={handleClearInput} />
                  ) : (
                    <SearchIcon color="gray.300" className="calculatedHeight" />
                  )
                }
                size="l"
              />
            </InputGroup>
          </Box>

          {sortableConfig?.showReorder && (
            <CustomButton
              classname="calculatedHeight"
              isLoading={sortableConfig?.btnTitle?.isLoading}
              isDisabled={sortableConfig?.btnTitle?.isLoading}
              buttonText={
                sortableConfig?.isSortable
                  ? sortableConfig?.btnTitle?.toggleOn
                  : sortableConfig?.btnTitle?.toggleOff
              }
              onClick={() => {
                if (sortableConfig?.isSortable) {
                  sortableConfig?.onOrderSave();
                  sortableConfig?.onToggleSortable(!sortableConfig?.isSortable);
                } else sortableConfig?.onToggleSortable(!sortableConfig?.isSortable);
              }}
              variant="solid"
              style={{
                backgroundColor: COLORS.LIGHT,
                color: COLORS.WHITE,
                borderColor: '#2563EB',
              }}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            />
          )}

          {showCreateNewButton ? (
            <CustomButton
              classname="calculatedHeight"
              buttonText={buttonText}
              variant={buttonVariant}
              colorScheme="customBlue"
              style={
                buttonVariant === 'outline'
                  ? {
                    // height: 35,
                    borderColor: '#2563EB',
                  }
                  : {
                    backgroundColor: COLORS.LIGHT,
                    // height: 35,
                    color: COLORS.WHITE,
                    borderColor: '#2563EB',
                  }
              }
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={onCreateNew}
              id="TableWithSearchButton"
            />
          ) : null}
        </Flex>
      </Flex>

      <Box my="5px" data-testid="TbleViewWithSearchTbleBox">
        <CustomTable
          columns={columns}
          sortableConfig={sortableConfig}
          isTableLoading={isTableLoading}
          tableData={filteredData}
          onRowClick={onRowClick}
          title={title}
        />
      </Box>
    </>
  );
};

TableViewWithSearch.propTypes = {
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
  isTableLoading: PropTypes.bool,
  onCreateNew: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  searchKey: PropTypes.string,
  showAddIcon: PropTypes.bool,
  buttonVariant: PropTypes.oneOf(['outline', 'solid']),
  filterTableData: PropTypes.func,
  showCreateNewButton: PropTypes.bool,
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  sortableConfig:
  PropTypes.shape({
    isSortable: PropTypes.bool.isRequired,
    showReorder: PropTypes.bool.isRequired,
    onToggleSortable: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onOrderSave: PropTypes.func.isRequired,
    btnTitle:
      PropTypes.shape({
        toggleOff: PropTypes.string.isRequired,
        toggleOn: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired
      }).isRequired
  })
};

TableViewWithSearch.defaultProps = {
  placeholder: 'Search',
  buttonText: 'Create new',
  isTableLoading: false,
  onRowClick: undefined,
  searchKey: 'name',
  showAddIcon: true,
  buttonVariant: 'outline',
  filterTableData: undefined,
  showCreateNewButton: true,
  headerTitle: '',
  title: '',
  sortableConfig:
  {
    isSortable: false,
    showReorder: false,
    onToggleSortable: () => {},
    onOrderChange: () => {},
    onOrderSave: () => {},
    btnTitle:
      {
        toggleOff: 'Reorder',
        toggleOn: 'Save Order',
        isLoading: false
      },
  },
};

export default TableViewWithSearch;

import _cloneDeep from 'lodash/cloneDeep';
import axiosInstance from '../../utils/axios/axios';

export const NO_ASSOCIATION_IDS = {
  objectId: {
    meta_field_id: 'objectId',
    name: 'Ticket ID',
    meta_field_type: 'TEXT',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  submitted_by: {
    meta_field_id: 'submitted_by',
    name: 'Submitted By',
    meta_field_type: 'DROPDOWN',
    meta_field_sub_type: 'SPECIAL_FIELD',
    meta_field_special_field_type: 'actor',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  updated_by: {
    meta_field_id: 'updated_by',
    name: 'Updated By',
    meta_field_type: 'DROPDOWN',
    meta_field_sub_type: 'SPECIAL_FIELD',
    meta_field_special_field_type: 'actor',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  last_viewed_by: {
    meta_field_id: 'last_viewed_by',
    name: 'Last Viewed By',
    meta_field_type: 'DROPDOWN',
    meta_field_sub_type: 'SPECIAL_FIELD',
    meta_field_special_field_type: 'actor',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  updated_at: {
    meta_field_id: 'updated_at',
    name: 'Updated At',
    meta_field_type: 'DATE',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  created_at: {
    meta_field_id: 'created_at',
    name: 'Created At',
    meta_field_type: 'DATE',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  offerId: {
    meta_field_id: 'offerId',
    name: 'Offer Type',
    meta_field_type: 'DROPDOWN',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  // team: {
  //   meta_field_id: 'team',
  //   name: 'Team',
  //   meta_field_type: 'TEXT',
  //   associated_to: '0',
  //   created_by: null,
  //   updated_by: '',
  //   created_on: '',
  //   updated_on: '',
  // },
  // ticket_type: {
  //   meta_field_id: 'ticket_type',
  //   name: 'Ticket Type',
  //   meta_field_type: 'TEXT',
  //   associated_to: '0',
  //   created_by: null,
  //   updated_by: '',
  //   created_on: '',
  //   updated_on: '',
  // },
  itemTypeId: {
    meta_field_id: 'itemTypeId',
    name: 'Ticket Type',
    meta_field_type: 'DROPDOWN',
    associated_to: '0',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
};

export const ASSOCIATION_ALLOWED_IDS = {
  'c4e49a66-25c7-4d02-b06d-4b9aee9c59a6': {
    meta_field_id: 'c4e49a66-25c7-4d02-b06d-4b9aee9c59a6',
    name: 'Summary',
    meta_field_type: 'TEXT',
    associated_to: '0',
    field_data: [],
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  '1e630d17-4c61-4f43-90e5-7eb00433d51f': {
    meta_field_id: '1e630d17-4c61-4f43-90e5-7eb00433d51f',
    name: 'Priority',
    meta_field_type: 'DROPDOWN',
    associated_to: '0',
    field_data: [],
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  '8a5014cd-9fe6-4e1f-9c74-ee7c95e0c61a': {
    meta_field_id: '8a5014cd-9fe6-4e1f-9c74-ee7c95e0c61a',
    name: 'Description',
    associated_to: '0',
    field_data: [],
    meta_field_type: 'TEXT',
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  'b7d54a0d-19e5-4a43-a13f-3f736deaa8c5': {
    meta_field_id: 'b7d54a0d-19e5-4a43-a13f-3f736deaa8c5',
    name: 'Assigned To',
    meta_field_type: 'DROPDOWN',
    associated_to: '0',
    field_data: [],
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
  'f5a199a8-22ef-4e08-aa7e-92b0f122cf8b': {
    meta_field_id: 'f5a199a8-22ef-4e08-aa7e-92b0f122cf8b',
    name: 'Status',
    meta_field_type: 'DROPDOWN',
    associated_to: '0',
    field_data: [],
    created_by: null,
    updated_by: '',
    created_on: '',
    updated_on: '',
  },
};

const createMetaFieldEntry = (metaFieldId, metaFieldObject) => ({
  meta_field_id: metaFieldId,
  meta_field_name: metaFieldObject[metaFieldId].name,
  attributes: {},
  id: metaFieldId,
  label: metaFieldObject[metaFieldId].name,
  name: metaFieldObject[metaFieldId].name,
});

export const PRE_FIELDS_NO_ASSOCIATED = Object.keys(NO_ASSOCIATION_IDS).map(
  (key) => createMetaFieldEntry(key, NO_ASSOCIATION_IDS)
);

export const PRE_FIELDS_WITH_ASSOCIATIONS = Object.keys(ASSOCIATION_ALLOWED_IDS).map(
  (key) => createMetaFieldEntry(key, ASSOCIATION_ALLOWED_IDS)
);
export const ensureObjectIdFirst = (arr) => {
  const index = arr.findIndex((item) => item.meta_field_id === 'objectId');

  if (index > 0) {
    const [objectIdItem] = arr.splice(index, 1);
    arr.unshift(objectIdItem);
  }

  return arr;
};
const mergeArrays = (predefineMetaFields, secondArray) => {
  // Clone the predefineMetaFields to avoid mutating the original array
  const firstArray = _cloneDeep(predefineMetaFields);

  // Create a Map for quick lookup of meta_field_id in secondArray
  const secondArrayMap = new Map(
    secondArray.map((item) => [item.meta_field_id, item])
  );

  // Iterate over firstArray and replace matching objects from secondArray
  firstArray.forEach((firstObj, index) => {
    if (secondArrayMap.has(firstObj.meta_field_id)) {
      firstArray[index] = secondArrayMap.get(firstObj.meta_field_id);
      secondArrayMap.delete(firstObj.meta_field_id); // Remove the matched item from the map
    }
  });

  // Add remaining elements from secondArray that were not in firstArray
  firstArray.push(...secondArrayMap.values());

  return firstArray;
};

const getMetaFieldList = async (defaultErrorMessage) => {
  try {
    const response = await axiosInstance.get('metaTables/list');

    const MERGE_META_FIELD_WITH_PREDEFINE = mergeArrays(
      [
        ...PRE_FIELDS_NO_ASSOCIATED,
      ],
      response?.data?.rows[0]?.metaFields || [],
    );
    const TABLE_ATTRIBUTES = response?.data?.rows[0]?.table_attributes || {};
    const KEY_MAP_FIELD_COLUMN = MERGE_META_FIELD_WITH_PREDEFINE
      .map((item) =>
        ({
          meta_field_id: item.meta_field_id,
          meta_field_name: item.name,
          attributes: {
            movable: true,
            searchable: true,
            sortable: true
          },
          id: item.meta_field_id,
          label: item.name
        }));
    const COLUMN_ATTRIBUTES =
    mergeArrays(response?.data?.rows[0]?.column_attributes || [], [])
      .map((item) => ({
        ...item,
        id: item.meta_field_id,
        label: item.meta_field_name
      }));
    return {
      metaFieldList: MERGE_META_FIELD_WITH_PREDEFINE,
      tableAttributes: TABLE_ATTRIBUTES,
      columnAttributes: ensureObjectIdFirst(COLUMN_ATTRIBUTES),
      allColumnAttributes: KEY_MAP_FIELD_COLUMN,
      error: null,
    };
  } catch (err) {
    return {
      error: err?.response?.data?.message || defaultErrorMessage,
      metaFieldList: [
        ...PRE_FIELDS_NO_ASSOCIATED,
        ...PRE_FIELDS_WITH_ASSOCIATIONS,
      ],
      columnAttributes: PRE_FIELDS_WITH_ASSOCIATIONS,
      tableAttributes: {},
      allColumnAttributes: PRE_FIELDS_WITH_ASSOCIATIONS,
    };
  }
};

const deleteMetaFields = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/metaFields/delete?$filter=id eq ${id}`
    );

    return {
      response: response?.data?.data || { id },
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message,
      response: null,
    };
  }
};

const MetaFieldsService = {
  getMetaFieldList,
  deleteMetaFields,
};

export default MetaFieldsService;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
  Checkbox,
  Box,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  RadioGroup,
  Radio,
  Input,
} from '@chakra-ui/react';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styles from './specialfield.module.scss';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import {
  COLORS,
  DARK,
  GREY_BACKGROUND,
  LIGHT,
} from '../../../utils/enums/colors';
import CustomInput from '../../../components/InputBox/Input';
import DynamicMutliSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import { roleSettingSelectOpt } from '../../../utils/enums/selectOption';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';
import { TEMPLATE_ROLE_SETTINGS } from '../../TicketTemplateBuilder/utils/constants';
import { isEmptyCondition } from '../../../utils/helper';
import { getUserAttributes } from '../../../components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.helper';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomToast from '../../../components/Notification/Notification';

const TABS = {
  ROLE_SETTING: 'Role Setting',
  OTHER_SETTING: 'Other Setting',
};
const NOTE_TYPE_OPTION = [
  { value: 'private', label: 'Private' },
  { value: 'public', label: 'Public' },
  { value: 'chase', label: 'Chase' },
  { value: 'resolution', label: 'Resolution' },
];

const RATING_OPTION = [
  { value: '1to2', label: '1 to 2' },
  { value: '1to3', label: '1 to 3' },
  { value: '1to4', label: '1 to 4' },
  { value: '1to5', label: '1 to 5' },
]
const SpecialFieldDrawer = ({
  openDrawer,
  setCloseDrawer,
  title,
  saveSpecialField,
  selectedTemplate,
  setSelectedSpecialField,
  selectedSpecialField,
}) => {
  const [errorMsg, setErrorMsg] = useState({});
  const [activeTab, setActiveTab] = useState(TABS.ROLE_SETTING);
  const [userAttributes, setUserAttributes] = useState([]);
  const handleInputChange = useCallback((index, value) => {}, []);
  const { addToast } = CustomToast();
  const initialLabels = [
    { id: 5, label: 'Very good' },
    { id: 4, label: 'Good' },
    { id: 3, label: 'Average' },
    { id: 2, label: 'Poor' },
    { id: 1, label: 'Very poor' }
  ]
  const [ratingLabels, setRatingLabels] = useState(initialLabels);
  const tabConfigurations = [
    {
      label: TABS.ROLE_SETTING,
      testId: 'EditSpecialFieldTab',
      onClick: () => setActiveTab(TABS.ROLE_SETTING),
      isActive: activeTab === TABS.ROLE_SETTING,
    },
    {
      label: TABS.OTHER_SETTING,
      testId: 'EditSpecialSettingTab',
      onClick: () => {
        setActiveTab(TABS.OTHER_SETTING);
      },
      isActive: activeTab === TABS.OTHER_SETTING,
      isVisible: [
        'SF_PROFILE',
        'SF_NOTES',
        'SF_WATCHERS',
        'SF_HISTORY',
        'SF_FEEDBACK_RATING',
        'SF_FEEDBACK_COMMENT',
      ].includes(selectedSpecialField.type),
    },
  ];
  useEffect(() => {
    getUserAttributes()
      .then((res) => {
        const apiOption = res.response.rows.map((item) => {
          const localOption =
            item.valueLabels?.map((data) => ({
              value: data,
              label: _startCase(_camelCase(data)),
            })) || [];

          return {
            value: item.key,
            label: _startCase(item.label),
            rightOptionType: item.validationRule.field,
          };
        });
        setUserAttributes(apiOption);
      })
      .catch(() => []);
  }, []);

  const permissionCheck = () => {
    const { permissions } = selectedSpecialField;
    const { VIEWERS, EDITORS } = TEMPLATE_ROLE_SETTINGS;
  
    const requiredRoles = [
      { role: VIEWERS, message: "Viewers are required" },
      { role: EDITORS, message: "Editors are required" },
    ];
  
    let hasError = false;
  
    requiredRoles.forEach(({ role, message }) => {
      if (Object.keys(permissions[role]).length === 0) {
        addToast({
          message,
          type: "error",
          duration: 2000,
          isClosable: true,
        });
        hasError = true;
      }
    });
  
    return hasError;
  };
  

  const handleDropdownChange = (e) => {
    const selectedValue = e; 
    const [min, max] = selectedValue?.value?.split('to').map(Number); 
    const updatedLabels = initialLabels.filter(label => label.id >= min && label.id <= max);
    setSelectedSpecialField(prev => ({
      ...prev,
      setting: [
        {
          ratingScale: [selectedValue], 
          ratingForm: updatedLabels     
        }
      ]
    }));
    setRatingLabels(updatedLabels); 
  };
  const handleRatingChange = (e, item) => {
    const updatedData = ratingLabels.map((label) => {
      if (label.id === item.id) {
        return { ...label, label: e.target.value };
      }
      return label;
    });
    
    setRatingLabels(updatedData);
    setSelectedSpecialField(prev => ({
      ...prev,
      name: 'feedbackRating',
      fieldType: 'JSONB',
      setting: prev.setting.map((s) => ({
        ...s,
        ratingForm: updatedData
      }))
    }));
  };

  const getFilteredLabels = () => {
    const ratingScaleObject = selectedSpecialField.setting.find(item => item.ratingScale);
    const scale = ratingScaleObject?.ratingScale[0].value || null;
    const savedData = ratingScaleObject?.ratingForm || [];
    const filteredLabels = ratingLabels.filter(item => {
      if (scale === '1to5') return item.id >= 1 && item.id <= 5;
      if (scale === '1to4') return item.id >= 1 && item.id <= 4;
      if (scale === '1to3') return item.id >= 1 && item.id <= 3;
      if (scale === '1to2') return item.id >= 1 && item.id <= 2;
      return false;
    });
    return filteredLabels.map(label => {
      const savedLabel = savedData.find(item => item.id === label.id);
      return savedLabel ? { ...label, label: savedLabel.label } : label;
    });
  };
  const ratingValue = selectedSpecialField?.setting?.find(item => item.ratingScale);
  const ratingScaleValue = ratingValue?.ratingScale[0] || null;
  useEffect(() => {
    if(selectedSpecialField.type === 'SF_FEEDBACK_RATING') {
      setSelectedSpecialField(prev => ({
        ...prev,
        name: 'feedbackRating',
        fieldType: 'JSONB',
      }))
    }
    if(selectedSpecialField.type === 'SF_FEEDBACK_COMMENT') {
      setSelectedSpecialField(prev => ({
        ...prev,
        name: 'feedbackComment',
        fieldType: 'JSONB',
        setting: prev.setting || [{value: 'optional'}]
      }))
    }
  }, []);

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setCloseDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setSelectedSpecialField({});
                setCloseDrawer(false);
              }}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Tabs
            index={tabConfigurations.findIndex(({ isActive }) => isActive)}
            data-testid="EditMetricsTabs"
          >
            <TabList
              padding="0px"
              data-testid="EditMetricsTabList"
              bg={useColorModeValue(GREY_BACKGROUND, '#4F5765')}
              className={styles.tab__container}
            >
              {tabConfigurations.map(
                ({ label, testId, onClick, isActive, isVisible = true }) =>
                  isVisible && (
                    <Tab
                      key={label}
                      onClick={onClick}
                      border="none"
                      borderBottom="0px"
                      padding="5px"
                      className={`${styles.tab}`}
                      data-testid={testId}
                    >
                      <Text
                        px="12px"
                        py="8px"
                        className={`${styles.tab__text} ${
                          isActive
                            ? useColorModeValue(
                                styles.tab__selected__light,
                                styles.tab__selected__dark
                              )
                            : ''
                        }`.trim()}
                      >
                        {label}
                      </Text>
                    </Tab>
                  )
              )}
            </TabList>
            <TabPanels data-testid="EditMetricsTabPanels">
              <TabPanel data-testid="EditMetricsTabPanelMetric" mt="10px">
                <DynamicMutliSelectWithInputTags
                  isMandatory
                  values={
                    selectedSpecialField?.permissions?.[
                      TEMPLATE_ROLE_SETTINGS.VIEWERS
                    ] || []
                  }
                  label="Viewers"
                  onChange={(value) => {
                    setSelectedSpecialField((prev) => ({
                      ...prev,
                      permissions: {
                        ...prev.permissions,
                        [TEMPLATE_ROLE_SETTINGS.VIEWERS]: value,
                      },
                    }));
                  }}
                  templateId={selectedTemplate.id}
                  selectOptions={roleSettingSelectOpt}
                />
                <Box
                  mt="10px"
                  mb="10px"
                  data-testid="TicktFieeldContentRow3DynamicBox"
                >
                  {selectedSpecialField?.type !== 'SF_SMARTSUMMARY' && (
                    <DynamicMutliSelectWithInputTags
                      isMandatory
                      values={
                        selectedSpecialField?.permissions?.[
                          TEMPLATE_ROLE_SETTINGS.EDITORS
                        ] || []
                      }
                      label="Editors"
                      onChange={(value) => {
                        setSelectedSpecialField((prev) => ({
                          ...prev,
                          permissions: {
                            ...prev.permissions,
                            [TEMPLATE_ROLE_SETTINGS.EDITORS]: value,
                          },
                        }));
                      }}
                      templateId={selectedTemplate.id}
                      selectOptions={roleSettingSelectOpt}
                    />
                  )}
                </Box>
                <ConditionsExpressions
                  templateId={selectedTemplate.id}
                  value={
                    selectedSpecialField?.permissions?.[
                      TEMPLATE_ROLE_SETTINGS.EXPRESSION
                    ] || {}
                  }
                  onChange={(value) => {
                    const { and } = value || {};
                    const updatedValue = isEmptyCondition(and)
                      ? { and: [] }
                      : value;
                    setSelectedSpecialField((prev) => ({
                      ...prev,
                      permissions: {
                        ...prev.permissions,
                        [TEMPLATE_ROLE_SETTINGS.EXPRESSION]: updatedValue,
                      },
                    }));
                  }}
                  label="Expressions"
                />
              </TabPanel>
              <TabPanel data-testid="EditMetricsTabPanelSla" mt="10px">
                {selectedSpecialField.type === 'SF_PROFILE' && (
                  <CustomSelectBox
                    label="Attributes To Hide"
                    options={userAttributes}
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e,
                      }));
                    }}
                    isMulti
                    id="EditSlaModalSLAMetColorInput"
                  />
                )}
                {selectedSpecialField.type === 'SF_NOTES' && (
                  <CustomSelectBox
                    label="Note Type Allow"
                    options={NOTE_TYPE_OPTION}
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e,
                      }));
                    }}
                    isMulti
                    id="EditSlaModalSLAMetColorInput"
                  />
                )}
                <>
                  {selectedSpecialField.type === 'SF_FEEDBACK_RATING' && (
                    <>
                      <CustomSelectBox
                        label="Rating Scale"
                        placeholder="Select Rating Scale"
                        options={RATING_OPTION}
                        value={ratingScaleValue} 
                        onChange={handleDropdownChange}
                        id="EditSlaModalSLAMetColorInput"
                      />
                      {selectedSpecialField.setting && (
                        <Box>
                        <Box mt="10px" border='1px solid #D1D5DB' borderRadius={'10px'} padding={'10px'}>
                          <Text fontSize="16px" fontWeight="bold" pb='10px' borderBottom={'1px solid #D1D5DB'}>
                            Rating Form
                          </Text>
                          <Grid templateColumns="1fr 2fr" gap={4} mt={2} color='#111827'>
                            <GridItem><Text>Rating</Text></GridItem>
                            <GridItem><Text>Label</Text></GridItem>
                            {getFilteredLabels().map((item, index) => (
                              <React.Fragment key={index}>
                                <GridItem>
                                  <Box
                                    p="9px 13px"
                                    border="1px solid #D1D5DB"
                                    backgroundColor="#F2F3F4"
                                    borderRadius="6px"
                                    textAlign="left"
                                  >
                                    {item.id}
                                  </Box>
                                </GridItem>
                                <GridItem>
                                  <Input
                                    w="100%"
                                    value={item.label}
                                    placeholder="Enter label"
                                    onChange={e => handleRatingChange(e, item)}
                                  />
                                </GridItem>
                              </React.Fragment>
                            ))}
                          </Grid>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </>
                {selectedSpecialField.type === 'SF_FEEDBACK_COMMENT' && (
                  <>
                    <Text fontSize="14px" className='SCLabel' mb='20px'>Comments</Text>
                    <RadioGroup
                      className='comments-RadioGroup'
                      value={selectedSpecialField.setting?.[0]?.value || 'optional'}
                      onChange={(e) => {
                        setSelectedSpecialField((prev) => {
                          const updatedSetting = prev.setting?.length > 0 ? [...prev.setting] : [{}];
                          updatedSetting[0] = {
                            ...updatedSetting[0],
                            value: e
                          };
                          return {
                            ...prev,
                            name: 'feedbackComment',
                            fieldType: 'JSONB',
                            setting: updatedSetting,
                          };
                        });
                      }}
                    >
                      <Radio value="optional">Optional</Radio>
                      <Radio value="mandatory">Mandatory</Radio>
                    </RadioGroup>
                  </>
                )}
                {selectedSpecialField.type === 'SF_WATCHERS' && (
                  <CustomInput
                    type="number"
                    label="Max no of Watcher"
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e.target.value,
                      }));
                      setErrorMsg((pre) => ({ ...pre, watcher: false }));
                    }}
                    isError={errorMsg?.watcher || false}
                    errorMessage={
                      errorMsg?.watcher ? 'Watch must be positive number' : ''
                    }
                  />
                )}
                {selectedSpecialField.type === 'SF_HISTORY' && (
                  <CustomInput
                    type="number"
                    label="Fold History"
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e.target.value,
                      }));
                      setErrorMsg((pre) => ({ ...pre, history: false }));
                    }}
                    isError={errorMsg?.history || false}
                    errorMessage={
                      errorMsg?.history
                        ? 'Fold history must be positive number'
                        : ''
                    }
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            classname="calculatedHeight"
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: 'none',
              background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setSelectedSpecialField({});
              setCloseDrawer(false);
            }}
            size="lg"
          />
          <CustomButton
            classname="calculatedHeight"
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.SAVE}
            onClick={() => {
              if (selectedSpecialField.type === 'SF_WATCHERS') {
                if (parseInt(selectedSpecialField.setting, 10) < 0) {
                  setErrorMsg((pre) => ({ ...pre, watcher: true }));
                  return;
                }
              }
              if (selectedSpecialField.type === 'SF_HISTORY') {
                if (parseInt(selectedSpecialField.setting, 10) < 0) {
                  setErrorMsg((pre) => ({ ...pre, history: true }));
                  return;
                }
              }
              const isError = permissionCheck();
              if (isError) return;
              saveSpecialField();
              setCloseDrawer(false);
            }}
            size="lg"
            isLoading={false}
            data-testid="LocaleButtonAdd"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

SpecialFieldDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setCloseDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  saveSpecialField: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  selectedSpecialField: PropTypes.object.isRequired,
  setSelectedSpecialField: PropTypes.func.isRequired,
};

export default SpecialFieldDrawer;

import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CustomModal from '../Modal/Modal';
import { TEXT_LIGHT } from '../../utils/enums/colors';
import CustomButton from '../Button/SimpleButton';

const getContent = (content) => content.map((item) => (
    <>
      <Text
        fontWeight="600"
        fontSize="16px"
        color={useColorModeValue(TEXT_LIGHT, '')}
        marginTop="20px"
        marginBottom="10px"
        data-testid="DynamicRendererSendMailBodyText"
      >
        {item.label}
      </Text>
      <Box
        border="1px"
        borderColor={useColorModeValue('gray.200', 'gray.700')}
        borderRadius="6px"
        p="2"
        marginBottom="10px"
        overflow="auto"
        data-testid="DynamicRendererSendMailBodyBox"
      >
        <span
          // replace(/\n/g, '<br>') to Replace \n with <br> for proper line breaks
          dangerouslySetInnerHTML={{
            __html: item.html
              .replace(/\\n{1,}/g, '<br>') // Replace escaped newlines
              .replace(/\n/g, '<br>')      // Replace literal newlines
          }}
          
        />
      </Box>
    </>
));
const HTMLPreviewModal = ({
  open,
  closePreviewModal,
  content = [],
  title = 'Email Preview',
}) => (
    <CustomModal
      open={open}
      setOpen={() => false}
      modalTitle={title}
      id="html-preview-modal"
    // eslint-disable-next-line react/no-danger
      modalContent={getContent(content)}
      modalFooter={
        <CustomButton
          variant="outline"
          buttonColor="customBlue"
          size="lg"
          buttonText="Close"
          onClick={closePreviewModal}
          id="DynamicRendererSendMailClostBtl"
        />
      }
      onModalClose={closePreviewModal}
    />
);

HTMLPreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closePreviewModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      html: PropTypes.string,
    })
  ),
};

HTMLPreviewModal.defaultProps = {
  content: [],
  title: 'Email Preview',
};

export default HTMLPreviewModal;

import React from 'react';

import TableColumnText from '../../components/Text/TableColumnText';

// eslint-disable-next-line import/prefer-default-export
export const columns = () => [
  {
    name: 'Name',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
  },
];

import { Box, Flex, useColorModeValue, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomInput from '../../../components/InputBox/Input';
import { COLORS } from '../../../utils/enums/colors';
import {
  createOrUpdateEmailSettings,
  getEmailSettings,
} from './emailSettings.service';

const EmailSettings = () => {
  const toast = useToast();
  const [emails, setEmails] = useState({
    alias: '',
    fromEmail: '',
    replyEmail: '',
  });
  const [error, setError] = useState({
    alias: '',
    fromEmail: '',
    replyEmail: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchEmailSettings = async () => {
      const emailSettings = await getEmailSettings();
      if (emailSettings) {
        setEmails({
          fromEmail: emailSettings?.from_email,
          replyEmail: emailSettings?.reply_email,
          alias: emailSettings?.properties?.alias
        });
      }
    };

    fetchEmailSettings();
  }, []);

  const validateEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const onSave = () => {
    if (isLoading) {
      return;
    }

    setError((prev) => ({
      ...prev,
      alias: '',
    }));

    if (emails.fromEmail.length > 0 && !validateEmail(emails.fromEmail)) {
      setError((prev) => ({
        ...prev,
        fromEmail: 'Please enter a valid email address',
      }));
      return;
    }
    setError((prev) => ({
      ...prev,
      fromEmail: '',
    }));

    if (emails.replyEmail.length > 0 && !validateEmail(emails.replyEmail)) {
      setError((prev) => ({
        ...prev,
        replyEmail: 'Please enter a valid email address',
      }));
      return;
    }
    setError((prev) => ({
      ...prev,
      replyEmail: '',
    }));

    setIsLoading(true);

    const data = {
      data: {
        from_email: emails.fromEmail,
        reply_email: emails.replyEmail,
      },
      properties: {
        alias: emails.alias,
      },
    };

    createOrUpdateEmailSettings({ data })
      .then(() => {
        toast({
          title: 'Email Settings updated successfully',
          status: 'success',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: 'Failed to update email settings',
          status: 'error',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <Box pt="20px" maxH="600px" maxW="400px" overflowX="scroll">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'scroll',
            paddingBottom: '20px',
          }}
          className="list-scroll"
        >
          <Flex direction="column" gap="20px">
              <Box>
                <CustomInput
                  label="From Alias"
                  value={emails?.alias || ''}
                  onChange={(e) =>
                    setEmails((prev) => ({
                      ...prev,
                      alias: e.target.value,
                    }))
                  }
                  isError={error.alias.length > 0}
                  errorMessage={error.alias}
                />
              </Box>
            <Box>
              <CustomInput
                label="From Email"
                value={emails?.fromEmail || ''}
                onChange={(e) =>
                  setEmails((prev) => ({ ...prev, fromEmail: e.target.value }))
                }
                isError={error.fromEmail.length > 0}
                errorMessage={error.fromEmail}
              />
            </Box>
            <Box>
              <CustomInput
                label="Reply Email"
                value={emails?.replyEmail || ''}
                onChange={(e) =>
                  setEmails((prev) => ({ ...prev, replyEmail: e.target.value }))
                }
                isError={error.replyEmail.length > 0}
                errorMessage={error.replyEmail}
              />
            </Box>
          </Flex>
        </div>
      </Box>
      <Flex
        justify="end"
        marginTop="20px"
        gap="10px"
        position="fixed"
        right="10"
        top={window.innerHeight - 130}
      >
        <CustomButton
          buttonText="Save"
          variant="outline"
          style={{ height: '32px' }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={onSave}
          isLoading={isLoading}
        />
      </Flex>
    </Box>
  );
};

export default EmailSettings;

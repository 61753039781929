import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CustomModal = ({
  id,
  open,
  setOpen,
  size,
  modalTitle,
  modalContent,
  modalFooter,
  onModalClose,
  applyMaxWidthForModalContent,
  height,
  showModalCloseButton,
  scrollOverflowFlag,
  ...props
}) => (
  <Modal
    size={size}
    isOpen={open}
    onClose={() => {
      setOpen(false);
      if (typeof onModalClose === 'function') {
        onModalClose();
      }
    }}
    data-test-id={id}
    {...(scrollOverflowFlag ? { scrollBehavior: 'inside', overflow: 'visible' } : {})}
    {...props}
  >
    <ModalOverlay />
    <ModalContent
      className={props.modalContentClassName || ''}
      {...(applyMaxWidthForModalContent
        ? { maxWidth: !size ? '871px' : '480px' }
        : {})}
      height={height}
    >
      {modalTitle ? <ModalHeader>{modalTitle}</ModalHeader>: null}
      {showModalCloseButton ? <Box paddingBottom="10px"><ModalCloseButton /></Box> : null}
      <ModalBody>{modalContent}</ModalBody>
      {modalFooter ? <ModalFooter>{modalFooter}</ModalFooter> : null}
    </ModalContent>
  </Modal>
);

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  modalTitle: PropTypes.node.isRequired,
  modalContent: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  modalFooter: PropTypes.node,
  setOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func,
  height: PropTypes.string,
  applyMaxWidthForModalContent: PropTypes.bool,
  showModalCloseButton: PropTypes.bool,
  scrollOverflowFlag: PropTypes.bool,
};

CustomModal.defaultProps = {
  size: '',
  modalFooter: '',
  applyMaxWidthForModalContent: true,
  onModalClose: () => {},
  height: '',
  showModalCloseButton: true,
  scrollOverflowFlag: true,
};

export default CustomModal;

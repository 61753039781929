/* eslint-disable indent */
import React from 'react';

import { EditIcon } from '@chakra-ui/icons';

import { cloneDeep, isArray,uniq } from 'lodash';
import dayjs from 'dayjs';

import { deleteRenderer } from '../DynamicRenderer/MetricsAndSLA/Metrics/utils';
import { renderCriteria } from '../DynamicRenderer/Audience/AudienceEditModal/audience.utls';
import { getUserInformation } from '../../utils/helper/roleBasedAccess';
import CustomSwitch from '../../components/Switch/Switch';

export const DEFAULT_FIELDS_MAP = {
  ACTORS: 'Actors',
  WATCHERS: 'Watchers',
  CC_WATCHERS: 'CC Watchers',
  TO_WATCHERS: 'To Watchers',
  QUEUES: 'Queues',
};

export const FORM_MAP = {
  NAME: 'name',
  CONDITIONS: 'condition',
  DESCRIPTION: 'description',
  IS_DEFAULT: 'is_default',

  ROLES: 'role',
  FILTERS: 'filters',
  DEFAULT_FILTER: 'default_filter',
};

export const SUPPORTED_FIELD_TYPES = {
  DROPDOWN: 'DROPDOWN',
  TEXT: 'TEXT',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  QUEUES: 'QUEUES',
  // SLA: 'SLA',
  METRICS: 'METRICS',
};

export const DROPDOWN_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
  DEPENDENT: 'DEPENDENT',
  SPECIAL_FIELD: 'SPECIAL_FIELD',
};

export const TAG_TYPES = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
};

export const SPECIAL_FIELD_TYPE = {
  ACTORS: 'actor',
  QUEUE: 'queue',
  WORKFLOW: 'workflowState',
  SOURCE: 'source',
};

const META_FIELD_TYPES = {
  DROPDOWN: 'DROPDOWN',
  DATE: 'DATE',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXT_AREA',
  TAGS: 'TAGS',
  EMAIL: 'EMAIL',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  SLA: 'SLA',
  METRICS: 'METRICS',
  READ_ONLY: 'READ_ONLY'
};
export const META_FIELD_TYPE_MAP = {
  [META_FIELD_TYPES.TEXT]: SUPPORTED_FIELD_TYPES.TEXT,
  [META_FIELD_TYPES.TEXTAREA]: SUPPORTED_FIELD_TYPES.TEXT,
  [META_FIELD_TYPES.EMAIL]: SUPPORTED_FIELD_TYPES.TEXT,

  [META_FIELD_TYPES.NUMBER]: SUPPORTED_FIELD_TYPES.NUMBER,

  [META_FIELD_TYPES.BOOLEAN]: SUPPORTED_FIELD_TYPES.BOOLEAN,

  [META_FIELD_TYPES.DROPDOWN]: SUPPORTED_FIELD_TYPES.DROPDOWN,
  [META_FIELD_TYPES.TAGS]: SUPPORTED_FIELD_TYPES.DROPDOWN,

  [META_FIELD_TYPES.DATE]: SUPPORTED_FIELD_TYPES.DATE,
  // [META_FIELD_TYPES.SLA]: SUPPORTED_FIELD_TYPES.SLA,
  [META_FIELD_TYPES.METRICS]: SUPPORTED_FIELD_TYPES.METRICS,
};

const defaultFilterIdMap = {
  // ALL_TICKETS: 'all__tickets',
  ACTORS: 'actors',
  SUBMITTED_BY: 'submittedBy',
  CREATED_ON: 'createdAt',
  WATCHERS: 'watchers',
  // STATUS: 'status',
  CC_WATCHERS: 'ccWatchers',
  TO_WATCHERS: 'toWatchers',
  DELETED_BY: 'deleted_seq',
  WORKFLOW_STATUS_CATEGORY: 'status_category',
};

export const ACTOROPERATORS = {
  IS_EMPTY: 'IS_EMPTY',
  IS_NOT_EMPTY: 'IS_NOT_EMPTY',
};

export const FALLBACK_OPERATORS = [
  { label: 'VALUE IS ONE_OF', value: 'is_one_of' },
  { label: 'VALUE IS NOT EMPTY:', value: 'is_not_empty' },
  { label: 'VALUE IS EMPTY', value: 'is_empty' },
  { label: 'VALUE EQUALS', value: 'is_equal_to' },
  { label: 'VALUE NOT EQUALS', value: 'not_equal_to' },
  { label: 'VALUE NOT IN LIST', value: 'not_in' },
  { label: 'VALUE IS', value: 'is' },
];

export const OPERATORS_MAP = {
  EQ: 'EQ',
  NEQ: 'NEQ',
  IS_EMPTY: 'is_empty',
  IS_NOT_EMPTY: 'is_not_empty',
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  GREATER_THAN: 'GT',
  LESS_THAN: 'LT',
  GREATER_THAN_EQUAL_TO: 'GE',
  LESS_THAN_EQUAL_TO: 'LE',
};

export const CATEGORY_LABELS = {
  TODO_CATEGORY: 'To-do Category',
  IN_PROGRESS_CATEGORY: 'In-progress Category',
  DONE_CATEGORY: 'Done Category',
};

const OPTIONS_MAP = {
  CURRENT_USER: '{{current_user}}',
  DONE: 'Done',
  [CATEGORY_LABELS.TODO_CATEGORY]: 'categores_1',
  [CATEGORY_LABELS.IN_PROGRESS_CATEGORY]: 'categores_3',
  [CATEGORY_LABELS.DONE_CATEGORY]: 'categores_4',
};

export const getOperators = (type) => {
  switch (type) {
    case SUPPORTED_FIELD_TYPES.TEXT:
    case SUPPORTED_FIELD_TYPES.BOOLEAN:
      return [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
      ];

    case SUPPORTED_FIELD_TYPES.DROPDOWN:
      return [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
        {
          label: 'NOT EQUAL TO',
          value: OPERATORS_MAP.NEQ,
        },
        {
          label: 'IN',
          value: OPERATORS_MAP.IN,
        },
        {
          label: 'NOT IN',
          value: OPERATORS_MAP.NOT_IN,
        },
        // {
        //   label: 'IS NOT EMPTY',
        //   value: OPERATORS_MAP.IS_NOT_EMPTY,
        // },
        // {
        //   label: 'IS EMPTY',
        //   value: OPERATORS_MAP.IS_EMPTY,
        // },
      ];

    case SUPPORTED_FIELD_TYPES.SLA:
    case SUPPORTED_FIELD_TYPES.METRICS:
      return [
        {
          label: 'GREATER THAN',
          value: OPERATORS_MAP.GREATER_THAN,
        },
        {
          label: 'GREATER THAN OR EQUAL TO',
          value: OPERATORS_MAP.GREATER_THAN_EQUAL_TO,
        },
        {
          label: 'LESS THAN',
          value: OPERATORS_MAP.LESS_THAN,
        },
        {
          label: 'LESS THAN OR EQUAL TO',
          value: OPERATORS_MAP.LESS_THAN_EQUAL_TO,
        },
      ];

    case SUPPORTED_FIELD_TYPES.NUMBER:
      return [
        {
          label: 'GREATER THAN',
          value: OPERATORS_MAP.GREATER_THAN,
        },
        {
          label: 'GREATER THAN OR EQUAL TO',
          value: OPERATORS_MAP.GREATER_THAN_EQUAL_TO,
        },
        {
          label: 'LESS THAN',
          value: OPERATORS_MAP.LESS_THAN,
        },
        {
          label: 'LESS THAN OR EQUAL TO',
          value: OPERATORS_MAP.LESS_THAN_EQUAL_TO,
        },
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
      ];
    case SUPPORTED_FIELD_TYPES.DATE:
      return [
        {
          label: 'GREATER THAN',
          value: OPERATORS_MAP.GREATER_THAN,
        },
        {
          label: 'GREATER THAN OR EQUAL TO',
          value: OPERATORS_MAP.GREATER_THAN_EQUAL_TO,
        },
        {
          label: 'LESS THAN',
          value: OPERATORS_MAP.LESS_THAN,
        },
        {
          label: 'LESS THAN OR EQUAL TO',
          value: OPERATORS_MAP.LESS_THAN_EQUAL_TO,
        }
      ];

    default:
      return null;
  }
};

export const convertSelectedFilters = (filters) => {
  if (!Array.isArray(filters)) {
    return [];
  }

  const transformRightOperand = (val) => {
    if (
      typeof val === 'string' ||
      typeof val === 'number' ||
      typeof val === 'boolean'
    ) {
      return [val];
    }
    if (Array.isArray(val)) {
      return val;
    }
    if (val?.start && val?.end) {
      return [
        new Date(val?.start)?.toISOString(),
        new Date(val?.end)?.toISOString(),
      ];
    }

    return [new Date(val)?.toISOString()];
  };

  return filters.map((f) => {
    const rightOperand = f?.operand && transformRightOperand(f?.operand);
    return {
      id: f?.id,
      operator: f?.op || ' ',
      leftOperand: f?.column,
      rightOperand: rightOperand || [],
      isSLA: f?.isSLA,
    };
  });
};

export const enrichSelectedFilters = ({
  filters,
  defaultFiltersIdMap = {},
  metaFieldsIdMap = {},
  fieldType = '',
}) => {
  if (!Array.isArray(filters)) {
    return [];
  }

  const enrichDate = (f) => {
    const date = {
      id: f?.id,
      op: f?.operator?.trim(),
      column: f?.leftOperand,
      operand: f?.rightOperand?.[0],
    };

    return date;
  };

  const enrichForSingle = (f) => {
    if (f?.leftOperand === defaultFilterIdMap.CREATED_ON) {
      return enrichDate(f);
    }

    if (f?.leftOperand === defaultFilterIdMap.WORKFLOW_STATUS_CATEGORY) {
      return {
        id: f?.id,
        op: f?.operator,
        column: f?.leftOperand,
        operand: f?.rightOperand,
      };
    }

    return {
      id: f?.id,
      op: f?.operator,
      column: f?.leftOperand,
      operand: f?.rightOperand?.[0],
    };
  };

  return filters
    .map((f) => {
      const defaultField = defaultFiltersIdMap[f?.leftOperand];

      if (defaultField) {
        return enrichForSingle(f);
      }

      const metaFieldType =
        metaFieldsIdMap[f?.leftOperand]?.meta_field_type || fieldType;
      const type = META_FIELD_TYPE_MAP?.[metaFieldType] || fieldType;

      switch (type) {
        case SUPPORTED_FIELD_TYPES.TEXT:
        case SUPPORTED_FIELD_TYPES.NUMBER:
        case SUPPORTED_FIELD_TYPES.BOOLEAN:
          return enrichForSingle(f);

        case SUPPORTED_FIELD_TYPES.METRICS:
          return enrichForSingle(f, false);

        case SUPPORTED_FIELD_TYPES.DATE:
          return enrichDate(f);

        case SUPPORTED_FIELD_TYPES.DROPDOWN:
          return {
            id: f?.id,
            op: f?.operator,
            column: f?.leftOperand,
            operand: f?.rightOperand,
          };

        default:
          return null;
      }
    })
    .filter((f) => f?.id);
};

export const getTransformedMetaFields = ({ metaData, metaFilterData, templates, offers }) => {
  const tempData = [];

  if (Array.isArray(metaData)) {
    metaData.forEach((m) => {
      if (!m) {
        return;
      }

      const type = META_FIELD_TYPE_MAP?.[m?.meta_field_type || ''];
      if (!type) {
        return;
      }

      let payload = {
        id: m?.meta_field_id || '',
        name: m?.name || '',
        type,
        operators: getOperators(type),
      };

      if (m.meta_field_special_field_type === 'actor' && m.meta_field_sub_type === 'SPECIAL_FIELD' && m.meta_field_type === 'DROPDOWN') {
        payload = {
          ...payload,
          operators: [...payload.operators, {
            label: 'IS NOT EMPTY',
            value: OPERATORS_MAP.IS_NOT_EMPTY,
          },
          {
            label: 'IS EMPTY',
            value: OPERATORS_MAP.IS_EMPTY,
          }]
        };
      }

      if (m.meta_field_special_field_type === 'queue' && m.meta_field_sub_type === 'SPECIAL_FIELD' && m.meta_field_type === 'DROPDOWN') {
        payload = {
          ...payload,
          operators: [...payload.operators, {
            label: 'IS NOT EMPTY',
            value: OPERATORS_MAP.IS_NOT_EMPTY,
          },
          {
            label: 'IS EMPTY',
            value: OPERATORS_MAP.IS_EMPTY,
          }]
        };
      }

      if (
        payload.type === SUPPORTED_FIELD_TYPES.METRICS ||
        payload.type === SUPPORTED_FIELD_TYPES.SLA
      ) {
        payload.type = SUPPORTED_FIELD_TYPES.METRICS;
      }
      if (payload.type === META_FIELD_TYPES.DROPDOWN) {
        let options = [];

        if (m?.meta_field_sub_type === DROPDOWN_TYPES.SPECIAL_FIELD) {
          const specialFieldType = m?.meta_field_special_field_type;
          if (!specialFieldType) {
            return;
          }

          let workflowStatus = {};

          switch (specialFieldType) {
            // If the special field is queue then use the current user queue
            case SPECIAL_FIELD_TYPE.QUEUE:
              payload.type = SUPPORTED_FIELD_TYPES.QUEUES;
              break;

            // If special field is of type actors then use the user list
            case SPECIAL_FIELD_TYPE.ACTORS:
              options = getUserInformation()?.availableUsers;
              if (Array.isArray(options)) {
                options = options
                  .map((op) => ({
                    label: op?.user,
                    value: op?.email,
                  }))
                  .filter((op) => op?.label && op?.value);
              }
              break;

            // If the special field is of type workflow then
            // take the options available in filterData
            case SPECIAL_FIELD_TYPE.WORKFLOW:
              workflowStatus = metaFilterData[m?.id || ''];
              if (
                workflowStatus?.collection_type ===
                  DROPDOWN_TYPES.SPECIAL_FIELD &&
                workflowStatus?.special_field_type ===
                  SPECIAL_FIELD_TYPE.WORKFLOW
              ) {
                if (Array.isArray(workflowStatus?.dropdown_options)) {
                  options = workflowStatus.dropdown_options;
                }
              }
              break;
            case SPECIAL_FIELD_TYPE.SOURCE:
              const sourceMetaField = metaFilterData[m?.id || ''];
              if (Array.isArray(sourceMetaField?.dropdown_options)) {
                options = sourceMetaField.dropdown_options;
              }
              break;
            default:
              break;
          }
        }

        const dropdownFilterData = metaFilterData[m?.id || ''];

        if (dropdownFilterData?.collection_type === DROPDOWN_TYPES.STATIC) {
          if (Array.isArray(dropdownFilterData?.dropdown_options)) {
            options = dropdownFilterData.dropdown_options;
          }
        }

        if (
          m?.isSystemField && m.meta_field_id === 'itemTypeId' &&
          templates?.length
        ) {
          const templateOptions = templates.map((t) => ({ label: t.name, value: t.id }));
          options = templateOptions;
        }

        if (
          m?.isSystemField && m.meta_field_id === 'offerId' &&
          offers?.length
        ) {
          const offerOptions = offers.map((o) => ({ label: o.name, value: o.id }));
          options = offerOptions;
        }

        if (!Array.isArray(options)) {
          options = [];
        }

        Object.assign(payload, {
          defaultOptions: options,
        });
      }

      tempData.push(payload);
    });
  }

  return tempData;
};

export const getDefaultFilters = () => {
  const defaultFilters = [
    {
      id: defaultFilterIdMap.ACTORS,
      name: 'Actors',
      type: META_FIELD_TYPES.DROPDOWN,
      isMulti: false,
      isDefault: true,
      operators: [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
        {
          label: 'IS NOT EMPTY',
          value: OPERATORS_MAP.IS_NOT_EMPTY,
        },
        {
          label: 'IS EMPTY',
          value: OPERATORS_MAP.IS_EMPTY,
        },
      ],
      optionMap: {
        // All Assigned Tickets
        [OPERATORS_MAP.IS_NOT_EMPTY]: null,

        // Unassigned Tickets
        [OPERATORS_MAP.IS_EMPTY]: null,
      },
      // My Assigned Tickets
      defaultOptions: [
        {
          label: 'Current User',
          value: OPTIONS_MAP.CURRENT_USER,
        },
      ],
    },
    {
      id: defaultFilterIdMap.SUBMITTED_BY,
      name: 'Submitted By',
      type: META_FIELD_TYPES.DROPDOWN,
      isMulti: false,
      isDefault: true,
      // Submitted By Me
      operators: [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
        {
          label: 'IS NOT EMPTY',
          value: OPERATORS_MAP.IS_NOT_EMPTY,
        },
        {
          label: 'IS EMPTY',
          value: OPERATORS_MAP.IS_EMPTY,
        },
      ],
      defaultOptions: [
        {
          label: 'Current User',
          value: OPTIONS_MAP.CURRENT_USER,
        },
      ],
    },
    {
      id: defaultFilterIdMap.WATCHERS,
      name: 'Watchers',
      type: META_FIELD_TYPES.DROPDOWN,
      isMulti: false,
      isDefault: true,
      operators: [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
      ],
      defaultOptions: [
        {
          label: 'Current User',
          value: OPTIONS_MAP.CURRENT_USER,
        },
      ],
    },
    {
      id: defaultFilterIdMap.CC_WATCHERS,
      name: 'CC Watchers',
      type: META_FIELD_TYPES.DROPDOWN,
      isMulti: false,
      isDefault: true,
      operators: [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
      ],
      defaultOptions: [
        {
          label: 'Current User',
          value: OPTIONS_MAP.CURRENT_USER,
        },
      ],
    },
    {
      id: defaultFilterIdMap.TO_WATCHERS,
      type: META_FIELD_TYPES.DROPDOWN,
      name: 'To Watchers',
      isMulti: false,
      isDefault: true,
      operators: [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
      ],
      defaultOptions: [
        {
          label: 'Current User',
          value: OPTIONS_MAP.CURRENT_USER,
        },
      ],
    },
    // {
    //   id: defaultFilterIdMap.STATUS,
    //   name: 'Status',
    //   type: META_FIELD_TYPES.DROPDOWN,
    //   isMulti: false,
    //   isDefault: true,
    //   operators: [
    //     {
    //       label: 'NOT EQUAL TO',
    //       value: OPERATORS_MAP.NEQ,
    //     }
    //   ],
    //   defaultOptions: [
    //     {
    //       label: 'Done',
    //       value: OPTIONS_MAP.DONE,
    //     }
    //   ],
    // },
    {
      id: defaultFilterIdMap.CREATED_ON,
      name: 'Created At',
      type: META_FIELD_TYPES.DATE,
      isDefault: true,
      operators: [
        {
          label: 'GREATER THAN',
          value: OPERATORS_MAP.GREATER_THAN,
        },
        {
          label: 'GREATER THAN OR EQUAL TO',
          value: OPERATORS_MAP.GREATER_THAN_EQUAL_TO,
        },
        {
          label: 'LESS THAN',
          value: OPERATORS_MAP.LESS_THAN,
        },
        {
          label: 'LESS THAN OR EQUAL TO',
          value: OPERATORS_MAP.LESS_THAN_EQUAL_TO,
        }
      ],
    },
    {
      id: defaultFilterIdMap.WORKFLOW_STATUS_CATEGORY,
      name: 'Workflow Status Category',
      type: META_FIELD_TYPES.DROPDOWN,
      isDefault: true,
      operators: [
        {
          label: 'EQUALS',
          value: OPERATORS_MAP.EQ,
        },
        {
          label: 'NOT EQUAL TO',
          value: OPERATORS_MAP.NEQ,
        },
        {
          label: 'IN',
          value: OPERATORS_MAP.IN,
        },
        {
          label: 'NOT IN',
          value: OPERATORS_MAP.NOT_IN,
        },
      ],
      defaultOptions: [
        {
          label: CATEGORY_LABELS.TODO_CATEGORY,
          value: CATEGORY_LABELS.TODO_CATEGORY,
        },
        {
          label: CATEGORY_LABELS.IN_PROGRESS_CATEGORY,
          value: CATEGORY_LABELS.IN_PROGRESS_CATEGORY,
        },
        {
          label: CATEGORY_LABELS.DONE_CATEGORY,
          value: CATEGORY_LABELS.DONE_CATEGORY,
        },
      ]
    }
  ];

  return defaultFilters;
};

const renderConditions = ({
  conditions,
  operatorIdMap,
  leftOptionMap,
  rightOptionMap,
}) => {
  const formatLeftOperand = (l, op) => {
    const val = leftOptionMap?.[l] || l;
    if (op?.trim()) {
      return val;
    }

    return `${val}: `;
  };

  const formatRightOperand = (r) => {
    const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
    if (dateRegex.test(r?.[0])) {
      return `${dayjs(r?.[0]).format('MMM DD, YYYY')} - ${dayjs(r?.[1]).format(
        'MMM DD, YYYY'
      )}`;
    }

    if (isArray(r)) {
      return r?.map((oprVal) => {
        if (oprVal.toString().length === 13) { // check if the value is a timestamp
          if (dayjs(oprVal).isValid()) { // check if the timestamp is valid
            return dayjs(oprVal).format('MMM DD, YYYY');
          }
        }

        return rightOptionMap?.[oprVal] || oprVal || '';
      }).join(', ');
    }
    return r.map((v) => v.toString()).join(', ');
  };

  const tempData = cloneDeep(conditions);
  tempData[0].leftOperand = formatLeftOperand(
    tempData[0].leftOperand,
    tempData[0].operator
  );
  tempData[0].operator =
    operatorIdMap?.[tempData[0].operator?.trim() || ''] ||
    tempData[0].operator?.trim();
  tempData[0].rightOperand = formatRightOperand(tempData[0].rightOperand);

  return renderCriteria(tempData);
};

const renderConditionsTemplate = ({
  conditions,
  operatorIdMap,
  leftOptionMap,
  rightOptionMap,
}) => {
  const formatLeftOperand = (l, op) => {
    const val = leftOptionMap?.[l] || l;
    if (op?.trim()) {
      return val;
    }

    return `${val}: `;
  };

  const formatRightOperand = (r) => {
    const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
    if (dateRegex.test(r?.[0])) {
      return `${dayjs(r?.[0]).format('MMM DD, YYYY')} - ${dayjs(r?.[1]).format(
        'MMM DD, YYYY'
      )}`;
    }
    if (r?.toString().length === 13) { // check if the value is a timestamp
      if (dayjs(r).isValid()) { // check if the timestamp is valid
        return dayjs(r).format('MMM DD, YYYY');
      }
    }

    return Array.isArray(r)
      ? uniq(r?.map((v) => rightOptionMap?.[v] || v || '')).join(', ')
      : r?.toString();
  };

  const tempData = cloneDeep(conditions);
  tempData[0].leftOperand = formatLeftOperand(
    tempData[0].column,
    tempData[0].op
  );
  tempData[0].operator =
    operatorIdMap?.[tempData[0].op?.trim() || ''] || tempData[0].op?.trim();
  tempData[0].rightOperand = formatRightOperand(tempData[0].operand);

  return renderCriteria(tempData);
};

export const columns = ({
  onEdit,
  onDelete,
  operatorIdMap,
  leftOptionMap,
  rightOptionMap,
  onToggleDefault,
  fromTemplate = false,
}) => {
  const cols = [
    {
      name: FORM_MAP.NAME,
      col_name: FORM_MAP.NAME,
      type: 'string',
    },
    {
      name: FORM_MAP.CONDITIONS,
      col_name: FORM_MAP.CONDITIONS,
      cellRenderer: ({ rowData }) => {
        const conditions = rowData?.[FORM_MAP.CONDITIONS];
        if (!Array.isArray(conditions) || conditions.length === 0) {
          return null;
        }

        return fromTemplate
          ? renderConditionsTemplate({
              conditions,
              operatorIdMap,
              leftOptionMap,
              rightOptionMap,
            })
          : renderConditions({
              conditions,
              operatorIdMap,
              leftOptionMap,
              rightOptionMap,
            });
      },
    },
    !fromTemplate && {
      name: 'IS DEFAULT',
      col_name: FORM_MAP.IS_DEFAULT,
      cellRenderer: ({ rowData }) => {
        const isDefault = rowData?.[FORM_MAP.IS_DEFAULT] || false;

        return (
          <div className="text-center" style={{ textAlign: 'left' }}>
            <CustomSwitch
              id={`filter-${rowData.name}-toggle`}
              isChecked={isDefault}
              size="md"
              onChange={(e) => {
                onToggleDefault({
                  id: rowData?.id,
                  isDefault: e.target.checked,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      col_name: 'actions',
      cellRenderer: ({ rowData }) => (
        <div className="flex gap-[10px] justify-center">
          <EditIcon
            data-testid={`filter-${rowData.name}-edit`}
            className="cursor-pointer"
            onClick={() => onEdit(rowData)}
          />
          {deleteRenderer({
            title: 'Delete this filter',
            description: 'Are you sure you want to delete this filter?',
            onConfirm: () => onDelete(rowData),
            dataTestId: `filter-${rowData.name}-delete`
          })}
        </div>
      ),
    },
  ];

  return cols;
};
export const DELETE_FILTER_DEFAULT = {
  id: defaultFilterIdMap.DELETED_BY,
  name: 'Delete By',
  type: META_FIELD_TYPES.READ_ONLY,
  isMulti: false,
  isDefault: true,
  operators: [
    {
      label: 'NOT EQUAL TO',
      value: OPERATORS_MAP.NEQ,
    },
  ],
};

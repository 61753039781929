import React from 'react';
import { Box, FormLabel, useColorModeValue } from '@chakra-ui/react';
import { COLORS } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import SelectFieldFromTemplateWithDelete from '../../../components/selectFieldFromTemplateWithDelete';

const renderCreateIssuesForm = (
  relatedTemplates,
  relatedOffers,
  actionToPerform,
  handleChange,
  handleChanges,
  actionToPerformSelectedIndex,
  actionErrorData,
  teamQueueMap,
  fieldDefaultDataForCreateIssue,
  relationsData,
  selectedTemplate,
  actionState,
  hierarchies,
  fieldHierarchyMaps
) => {
  const offerOptions = [{
    label: 'None',
    value: '',
  }];

  relatedOffers.forEach((offer) => {
    if (offer?.item_type === actionToPerform.issue_type?.value) {
      offerOptions.push({
        label: offer.name,
        value: offer.id,
      });
    }
  });

  //actionToPerform.issue_type?.value.
  const targetTemplateName = () => {
    if (actionToPerform.issue_type?.value) {
      const template = relatedTemplates.find(
        (template_) => template_.id === actionToPerform.issue_type?.value
      );
      return template?.name;
    }
    return '';
  };
  const getSimilaritiesField = () => {
    const value =
      relationsData.find(
        (relation) => relation.template_id_right === actionState.linked_item_type
      ) ?? null;
    if (!value) return [];
    const { similarity } = value ?? {};
    if (!fieldDefaultDataForCreateIssue?.fieldList) {
      return [];
    }
    const fieldLabelMap = {};
    selectedTemplate?.fields.forEach((field) => {
      fieldLabelMap[field.id] = field.label;
    });

    const result = {};
    similarity.forEach((similarField) => {
      const leftFieldId = similarField?.left_field_id;
      const rightFieldId = similarField?.right_field_id;
      if (fieldLabelMap[leftFieldId]) {
        result[rightFieldId] = fieldLabelMap[leftFieldId];
      }
    });
    return result;
  };

  return (
    <Box marginTop="1rem" data-testid="DynamicRendererSlackRecordsBox">
      <CustomSelectBox
        label="Issue Type"
        options={relatedTemplates.map((template) => ({
          label: template.id === selectedTemplate?.id ? 'Self' : template.name,
          value: template.id,
        }))}
        placeholder="Select an Issue Type"
        value={actionToPerform.issue_type}
        onChange={(value) => {
          const events = [
            {
              value,
              path: `action_to_perform[${actionToPerformSelectedIndex}].issue_type`,
            },
            {
              value: '',
              path: `action_to_perform[${actionToPerformSelectedIndex}].offer_id`,
            },
            {
              value: [],
              path: `action_to_perform[${actionToPerformSelectedIndex}].create_issue`,
            },
          ];
          handleChanges(events);
        }}
        focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
        id="DynamicRendererUpdateCreateCSelect"
      />
      <CustomSelectBox
        label="Offer (Optional)"
        options={offerOptions}
        placeholder="None"
        value={offerOptions.find((option) => option.value === actionToPerform.offer_id)}
        onChange={(value) => {
          handleChange(
            value.value,
            `action_to_perform[${actionToPerformSelectedIndex}].offer_id`
          );
        }}
        focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
        id="DynamicRendererUpdateCreateCSelect"
      />
      {actionToPerform?.issue_type && (
        <>
          <FormLabel
            fontWeight={600}
            mt="1rem"
            data-testid="DynamicRendererUpdateCreateFormLabel"
          >
            Create Issue
            <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
          </FormLabel>
          <SelectFieldFromTemplateWithDelete
            key={actionToPerform?.issue_type?.value}
            fieldList={fieldDefaultDataForCreateIssue?.fieldList || []}
            teamQueueMap={teamQueueMap}
            //value={actionToPerform?.create_issue || []}
            value = {actionToPerform?.id ? actionToPerform?.create_issue || [] : actionToPerform?.create_issue.filter(item => 
              fieldDefaultDataForCreateIssue?.fieldList?.some(field => field.id === item.field.value)
            ) || []}
            workFlowStatusList={
              fieldDefaultDataForCreateIssue?.workflowStatusList || []
            }
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].create_issue`
              );
            }}
            isRequired
            preventFieldRepeat
            hierarchies={hierarchies}
            fieldHierarchyMap={fieldHierarchyMaps}
            similaritiesField={getSimilaritiesField()}
            parentFields={selectedTemplate?.fields || []}
            targetTemplateName={targetTemplateName()}
            sourceTemplateName={selectedTemplate.name}
            actorAssociationMap={fieldDefaultDataForCreateIssue.actors || []}
            createIssue={true}
          />
        </>
      )}

      {actionErrorData?.create_issue ? (
        <span className="field-error">
          Field name & value can&apos;t be empty
        </span>
      ) : null}
    </Box>
  );
};

export default renderCreateIssuesForm;

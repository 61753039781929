const RULE_TYPES = {
  RELATIVE: 'relative',
  ABSOLUTE: 'absolute',
};

const RULE_TYPE_LABELS = {
  [RULE_TYPES.RELATIVE]: 'Relative',
  [RULE_TYPES.ABSOLUTE]: 'Absolute',
};

const CURRENT_DATE_PLACEHOLDER = '{{currentDate}}';

const CURRENT_DATE_PLACEHOLDER_LABEL = 'Current Date';

const FUNCTION_TYPES = {
  CURRENT: 'currentDateTime',
  START_OF_DAY: 'startOfDay',
  END_OF_DAY: 'endOfDay',
  START_OF_WEEK: 'startOfWeek',
  END_OF_WEEK: 'endOfWeek',
  START_OF_MONTH: 'startOfMonth',
  END_OF_MONTH: 'endOfMonth',
  START_OF_YEAR: 'startOfYear',
  END_OF_YEAR: 'endOfYear',
};

const FUNCTION_LABELS = {
  [FUNCTION_TYPES.CURRENT]: 'Current Date',
  [FUNCTION_TYPES.START_OF_DAY]: 'Start of Day',
  [FUNCTION_TYPES.END_OF_DAY]: 'End of Day',
  [FUNCTION_TYPES.START_OF_WEEK]: 'Start of Week',
  [FUNCTION_TYPES.END_OF_WEEK]: 'End of Week',
  [FUNCTION_TYPES.START_OF_MONTH]: 'Start of Month',
  [FUNCTION_TYPES.END_OF_MONTH]: 'End of Month',
  [FUNCTION_TYPES.START_OF_YEAR]: 'Start of Year',
  [FUNCTION_TYPES.END_OF_YEAR]: 'End of Year',
};

const FUNCTION_OPTIONS = [
  { label: FUNCTION_LABELS[FUNCTION_TYPES.CURRENT], value: FUNCTION_TYPES.CURRENT },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.START_OF_DAY], value: FUNCTION_TYPES.START_OF_DAY },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.END_OF_DAY], value: FUNCTION_TYPES.END_OF_DAY },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.START_OF_WEEK], value: FUNCTION_TYPES.START_OF_WEEK },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.END_OF_WEEK], value: FUNCTION_TYPES.END_OF_WEEK },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.START_OF_MONTH], value: FUNCTION_TYPES.START_OF_MONTH },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.END_OF_MONTH], value: FUNCTION_TYPES.END_OF_MONTH },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.START_OF_YEAR], value: FUNCTION_TYPES.START_OF_YEAR },
  { label: FUNCTION_LABELS[FUNCTION_TYPES.END_OF_YEAR], value: FUNCTION_TYPES.END_OF_YEAR },
];

const TIMEFRAME_TYPES = {
  LESS_THAN: 'LT',
  GREATER_THAN: 'GT',
  RANGE: 'RNG',
};

const TIMEFRAME_LABELS = {
  [TIMEFRAME_TYPES.LESS_THAN]: 'Less Than',
  [TIMEFRAME_TYPES.GREATER_THAN]: 'Greater Than',
  [TIMEFRAME_TYPES.RANGE]: 'Range',
};

const TIMEFRAME_OPTIONS = [
  { label: TIMEFRAME_LABELS[TIMEFRAME_TYPES.LESS_THAN], value: TIMEFRAME_TYPES.LESS_THAN },
  { label: TIMEFRAME_LABELS[TIMEFRAME_TYPES.GREATER_THAN], value: TIMEFRAME_TYPES.GREATER_THAN },
  { label: TIMEFRAME_LABELS[TIMEFRAME_TYPES.RANGE], value: TIMEFRAME_TYPES.RANGE },
];

export {
  RULE_TYPES,
  RULE_TYPE_LABELS,
  FUNCTION_TYPES,
  FUNCTION_LABELS,
  FUNCTION_OPTIONS,
  CURRENT_DATE_PLACEHOLDER,
  CURRENT_DATE_PLACEHOLDER_LABEL,
  TIMEFRAME_TYPES,
  TIMEFRAME_LABELS,
  TIMEFRAME_OPTIONS,
};

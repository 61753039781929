import React from 'react';

import PropTypes from 'prop-types';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack, IconButton } from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons';
import styles from './restrictions.module.scss';

const RestrictionsSectionContainer = ({
  title,
  children,
  onMoveUp,
  onMoveDown,
  onDelete,
  disableMoveUp,
  disableMoveDown,
  disableDelete,
}) => (
    <div className={styles.dateRestrictionSectionContainer}>
      <Accordion defaultIndex={0} allowToggle>
        <AccordionItem>
          <div className={styles.sectionHeader}>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                className={styles.sectionHeaderText}
              >
                {title}
              </Box>
              <HStack>
                {
                  onDelete && (
                    <IconButton
                      colorScheme="red"
                      aria-label="Delete Condition"
                      size="sm"
                      className={styles.deleteRestrictionButton}
                      variant="unstyled"
                      isDisabled={disableDelete}
                      icon={<DeleteIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                      }}
                    />
                  )
                }
                {
                  onMoveUp && (
                    <IconButton
                      colorScheme="blue"
                      aria-label="Move Condition Up"
                      size="sm"
                      className={styles.moveRestrictionButton}
                      variant="unstyled"
                      isDisabled={disableMoveUp}
                      icon={<ArrowUpIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onMoveUp();
                      }}
                    />
                  )
                }
                {
                  onMoveDown && (
                    <IconButton
                      colorScheme="blue"
                      aria-label="Move Condition Down"
                      size="sm"
                      className={styles.moveRestrictionButton}
                      variant="unstyled"
                      isDisabled={disableMoveDown}
                      icon={<ArrowDownIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onMoveDown();
                      }}
                    />
                  )
                }
              </HStack>
              <AccordionIcon />
            </AccordionButton>
          </div>
          <AccordionPanel>
            {children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
);

RestrictionsSectionContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onDelete: PropTypes.func,
  disableMoveUp: PropTypes.bool,
  disableMoveDown: PropTypes.bool,
  disableDelete: PropTypes.bool,
};

RestrictionsSectionContainer.defaultProps = {
  onMoveUp: null,
  onMoveDown: null,
  onDelete: null,
  disableMoveUp: false,
  disableMoveDown: false,
  disableDelete: false,
};

export default RestrictionsSectionContainer;

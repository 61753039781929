/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../components/Modal/Modal';
import CustomButton from '../../components/Button/SimpleButton';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import axiosInstance from '../../utils/axios/axios';
import { RULE_CARD_BORDER } from '../../utils/enums/colors';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomToast from '../../components/Notification/Notification';

const ModalView = ({
  name,
  isOpen,
  setIsOpen,
  isEdit,
  viewLayout,
  modalTitle,
  children,
  onModalClose,
  mandatoryFields = [],
}) => {
  const [modalItems, setModalItems] = useState([]);
  const [modalFooter, setModalFooter] = useState('');
  const [footerItems, setFooterItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMSg] = useState('');
  const viewState = useSelector((state) => state.viewState);
  const { activeModal = false } = useSelector((state) => state.allModals);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = CustomToast();

  // eslint-disable-next-line no-shadow
  const sortByViewLayout = (items, viewLayout) => {
    if (JSON.stringify(viewLayout) === JSON.stringify({})) return items;
    let merged = [];
    const foot = [];
    for (let i = 0; i < viewLayout?.rows?.length; i++) {
      merged.push({
        ...viewLayout?.rows[i],
        ...items.find(
          (itmInner) => itmInner?.props?.name === viewLayout?.rows[i].i
        ),
      });
    }

    merged = merged.filter((i) => {
      if (
        JSON.stringify(i) !== JSON.stringify({}) &&
        !i?.props?.isModalFooter
      ) {
        return i;
      }
      foot.push(i);
    });

    setFooterItems(foot);
    return merged;
  };

  const makeApiRequest = async (method, targetUrl, data) => {
    if (method === 'get') {
      await axiosInstance.get(targetUrl, { data });
    } else if (method === 'post') {
      setIsLoading(true);
      try {
        const response = await axiosInstance.post(targetUrl, { data });
        setIsLoading(false)
        return response.data;
      } catch (error) {
        setIsLoading(false)
        console.error('Response data:', error.response.data);
      }
    } else if (method === 'patch') {
      setIsLoading(true);
      try {
        const response = await axiosInstance.patch(targetUrl, { data });
        if (response.data) {
          setIsLoading(false)
          return response.data;
        }
      } catch (error) {
        setIsLoading(false)
        console.error('Response data:', error.response.data);
      }
    }
  };

  const getUpdatedUrl = (str, obj) => {
    let updatedStr = str;
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
      updatedStr = updatedStr.replaceAll(`\${${key}}`, obj[key]);
    }
    return updatedStr;
  };

  // eslint-disable-next-line no-shadow
  const updateList = (listKey, rowdata, isEdit, listIdentifier) => {
    if (listKey) {
      const updatedList = _.cloneDeep(viewState[listKey]);
      if (isEdit) {
        const editIndex = _.findIndex(updatedList.data, {
          [listIdentifier]: rowdata[listIdentifier],
        });
        if (editIndex !== undefined && editIndex !== -1) {
          updatedList.data[editIndex] = rowdata;
        }
      } else {
        updatedList.data = [rowdata, ...updatedList.data];
      }
      dispatch(
        updateViewState({
          stateKey: listKey,
          value: updatedList,
        })
      );
    }
  };
  const validateForm = (formData = {}) => {
    const errorObj = {};
    for (const mandatorykey of mandatoryFields) {
      if (_.isEmpty(formData[mandatorykey]?.trim())) {
        errorObj[mandatorykey] = `${mandatorykey} is required field`;
      }
    }
    return errorObj;
  };
  const modalFooterActions = async (val) => {
    setErrorMSg('');
    if (val.props.name === 'cancel') {
      if (val.props?.payloadKeysMaps) {
        Object.keys(val.props.payloadKeysMaps).map((i) =>
          dispatch(
            updateViewState({
              stateKey: i,
              value: undefined,
            })
          )
        );
      }
      dispatch(setActiveModal({ targetModalId: undefined }));
      dispatch(
        updateViewState({
          stateKey: activeModal,
          value: undefined,
        })
      );
    }
    if (val.props.name === 'continue') {
      const viewStateModalData = viewState[activeModal];
      const errorObj = validateForm(viewStateModalData);
      if (!_.isEmpty(errorObj)) {
        const errorMessages = Object.entries(errorObj)
        .map(([key, value]) => `${value}`)
        .join('\n');

        dispatch(
          updateViewState({
            stateKey: activeModal,
            value: { ...viewStateModalData, errorObj },
          })
        );
        setErrorMSg(errorMessages);
        return false;
      }
      if (activeModal === 'create_actor_modal') {
        if (viewState?.actors_expanded_list?.count > 0) {
          const checkName = _.filter(
            viewState?.actors_expanded_list?.data,
            (item) => item.name?.toLowerCase()  === viewState?.create_actor_modal?.name?.toLowerCase() 
          );
          if (checkName.length) {
            setErrorMSg('Name already exists');
            return false;
          }
        }
      }
      const apiData = _.reduce(
        _.keys(val.props.payloadKeysMaps),
        (acc, key) => ({
          ...acc,
          [key]: viewStateModalData[key] ?? val.props.payloadKeysMaps[key],
        }),
        {}
      );
      const rowdata = await makeApiRequest(
        val?.props?.method,
        getUpdatedUrl(val?.props?.targetUrl, viewStateModalData),
        apiData
      );

      if (rowdata) {
        if (val?.props?.method === 'patch') {
          addToast({
            title: 'Success',
            message: 'Successfully updated the Actors',
            type: 'success',
            duration: 2000,
            isClosable: true,
          });
        } else if (val?.props?.method === 'post') {
          addToast({
            title: 'Success',
            message: 'Successfully created the Actors',
            type: 'success',
            duration: 2000,
            isClosable: true,
          });
        }
      }

      updateList(
        val?.props?.listKey,
        isEdit ? viewStateModalData : rowdata,
        isEdit,
        val?.props?.listIdentifier
      );
      dispatch(setActiveModal({ targetModalId: undefined }));
      dispatch(
        updateViewState({
          stateKey: activeModal,
          value: undefined,
        })
      );
      // if target_route and source_route is present then we have to redirect to that target_route
      if (val?.props?.targetRoute && val?.props?.sourceRoute) {
        const params = {
          sourceRoute: val?.props?.sourceRoute,
          id: rowdata.id,
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${val?.props?.targetRoute}?${queryString}`;
        navigate(url);
      }
    }
  };

  useEffect(() => {
    setErrorMSg('');
    setModalItems(sortByViewLayout(children, viewLayout));
  }, [children]);

  useEffect(() => {
    if (footerItems?.length > 0) {
      setModalFooter(
        footerItems.map((i) => (
          <CustomButton
            onClick={() => modalFooterActions(i)}
            buttonText={i.props.buttonText}
            classname={'calculatedHeight'}
            variant={i.props.name === 'cancel' ? 'outline' : 'solid'}
            buttonColor={i.props.name === 'cancel' ? '' : 'customBlue'}
            _hover={i.props.name === 'cancel' ? 'none' : ''}
            ml="2"
            style={i.props.style}
            data-testid="ModalViewFooterBtn"
            isLoading ={i.props.name === 'cancel' ? '' : isLoading}
          />
        ))
      );
    }
  }, [footerItems, viewState, isLoading]);

  const checkVisibility = (element) => {
    if (element?.props?.condition) {
      return (
        element?.props?.condition?.stateValue ===
        (viewState[name]
          ? viewState[name][element?.props?.condition?.stateName]
          : '')
      );
    }
    return true;
  };

  const modalContent = (
    <>
      <Grid
        templateColumns={`repeat(${viewLayout?.no_of_cols}, 1fr)`}
        gap={3}
        data-testid="ModalViewContentGrid"
      >
        {modalItems?.map((i) => (
          <GridItem key={i.id} colSpan={i.w}>
            {checkVisibility(i) ? i : ''}
          </GridItem>
        ))}
      </Grid>
      {!_.isEmpty(errorMsg) && <Text color="red" style={{ whiteSpace: "pre-wrap" }}>{errorMsg}</Text>}
    </>
  );

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={() => {
        setIsOpen(false);
        onModalClose();
      }}
      isOpen={isOpen}
      id="DrawerViewZModal"
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex
            alignItems="center"
            gap="10px"
            borderBottom={`1px solid ${RULE_CARD_BORDER}`}
            pb="10px"
          >
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setIsOpen(false);
                onModalClose();
              }}
            />
            <Text fontSize="16px">{modalTitle}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>{modalContent}</DrawerBody>
        <DrawerFooter>{modalFooter}</DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  modalTitle: PropTypes.string,
  viewLayout: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onModalClose: PropTypes.func,
  mandatoryFields: PropTypes.array,
};

ModalView.defaultProps = {
  isEdit: false,
  modalTitle: '',
  onModalClose: () => {},
  mandatoryFields: [],
};

export default ModalView;

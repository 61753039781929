import React from 'react';
import PropTypes from 'prop-types';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { DeleteIcon, DragHandleIcon, EditIcon } from '@chakra-ui/icons';
import { BORDER_DARK } from '../../../utils/enums/colors';
import CustomToolTip from '../../../components/Tooltip/Tooltip';
import LAYOUT_FIELD_COLORS from './utils/colors';
import CountShow from '../../../components/CountShow';

const calculateBg = (type, scope) => {
  if (scope === 'WORKFLOW') {
    return LAYOUT_FIELD_COLORS.WORKFLOW_SCOPE;
  }
  if (scope === 'RELATION') {
    return LAYOUT_FIELD_COLORS.RELATION_SCOPE;
  }

  if (type === 'SF_METRIC') {
    return LAYOUT_FIELD_COLORS.SF_METRIC_TYPE;
  }

  if (type === 'SF_SLAS') {
    return LAYOUT_FIELD_COLORS.SF_SLAS_TYPE;
  }

  if (type?.includes('SF_')) {
    return LAYOUT_FIELD_COLORS.SF_TYPE;
  }
  return LAYOUT_FIELD_COLORS.TICKET_SCOPE;
};

const DragDiv = ({
  draggableItemName,
  onEdit,
  onDelete,
  type,
  isStatic,
  scope,
  showColSpan,
  colSpanCount,
  onChangeColSpan,
  isEditable,
  isDeletable,
  isDraggable,
}) => (
  <>
    <Box
      height="100%"
      borderRight="1px"
      borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
      py="1"
      px="2"
      bg={calculateBg(type, scope)}
      color={isStatic && '#2563EB'}
      data-testid="DragDivBox"
      style={{ cursor: isDraggable ? 'grab' : 'not-allowed' }}
    >
      <DragHandleIcon height="100%" />
    </Box>
    <Box
      className="ellipsis"
      ml="2"
      color={isStatic && '#2563EB'}
      data-testid="DragDivToolTipBox"
    >
      <CustomToolTip toolTipText={draggableItemName} id={draggableItemName}>
        <span>{draggableItemName}</span>
      </CustomToolTip>
    </Box>
    {showColSpan && (
      <Box
        w="55px"
        mr={colSpanCount && draggableItemName === 'Ticket ID' ? '55px' : '12px'}
      >
        <CountShow
          value={colSpanCount || 0}
          onChange={(value) => {
            onChangeColSpan(value);
          }}
        />
      </Box>
    )}
    {colSpanCount && draggableItemName === 'Ticket ID' ? null : (
      <Box
        w="55px"
        minWidth="50px"
        display="flex"
        justifyContent="space-around"
        color={isStatic && '#2563EB'}
        data-testid="DragDivBoxIconBox"
      >
        {isEditable && <EditIcon onClick={() => !isStatic && onEdit()} />}
        {isDeletable && <DeleteIcon ml="2" mr="2" onClick={() => !isStatic && onDelete()} />}
      </Box>
    )}
  </>
);

DragDiv.propTypes = {
  draggableItemName: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isStatic: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  showColSpan: PropTypes.bool,
  colSpanCount: PropTypes.number,
  onChangeColSpan: PropTypes.func,
  isEditable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isDraggable: PropTypes.bool,
};
DragDiv.defaultProps = {
  showColSpan: false,
  colSpanCount: 0,
  onChangeColSpan: () => {},
  isEditable: true,
  isDeletable: true,
  isDraggable: true,
};
export default DragDiv;

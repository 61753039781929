import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import TriggersTableViewWithSearch from './TriggersTableViewWithSearch';
import EditTriggersDrawer from './EditTriggersDrawer';
import { COLUMNS_TRIGGERS } from './utils';
import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../../../utils/axios/axios';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';

const Triggers = () => {
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedTriggerField, setSelectedTriggerField] = useState({});
  const [showTriggersDrawer, setShowTriggersDrawer] = useState(false);
  const [triggersOffersData, setTriggersOffersData] = useState([]);
  const [triggerKeyValues, setTriggerKeyValues] = useState([]);
  const [triggerContext, setTriggerContext] = useState('');
  const [qnaRecords, setQnaRecords] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [offers, setOffers] = useState([]);
  const [updateQnaRecords, setUpdateQnaRecords] = useState({ deleteQna: false, updateQna: false });
  const [isPublishing, setIsPublishing] = useState(false);
  const [botConfigRecords, setBotConfigRecords] = useState([])
  const [associateFlow, setAssociateFlow] = useState('')
  const [defaultSettingRecords, setDefaultSettingRecords] = useState([])

  const tenantId = JSON.parse(localStorage.getItem('tenant'))?.id;
  const templateId = useParams();
  const id = templateId['*'].match(/\d+/)[0];
  const flowName = selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

  const TOASTER = {
    DELETE_ERROR: 'Error in deleting trigger',
    DELETE_SUCCESS: 'Trigger deleted successfully',
    PUBLISH_SUCCESS: 'Trigger published successfully',
    PUBLISH_ERROR: 'Error in publishing trigger',
    UPLOAD_SUCCESS: 'Trigger uploaded successfully',
    UPLOAD_ERROR: 'Error in uploading trigger',
    UPDATE_SUCCESS: 'Trigger updated successfully',
    UPDATE_ERROR: 'Error in updating trigger',
    FLOW_NOT_FOUND: 'Published flow not found',
  };
  const getOffers = async () => {
    try {
      const response = await axiosInstance.get(
        `/fieldDefaults/list?$select=*&$filter=item_type%20eq%20%27${selectedTemplate[0]?.id}%27%20and%20offer_type%20ne%20%27email%27`
      );
      if (Array.isArray(response?.data?.rows)) {
        setOffers(response.data.rows);
        return response.data.rows;
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  const getQnaRecords = async () => {
    try {
      setIsFetching(true);
      const response = await axiosInstanceWithoutBaseUrl.get(
        `/botmgmt-service/qna/qnaRecords?limit=2000&sortName=1&sortOrder=desc&type=flow&answer=NA&botIntegration=True`
      );
      if (Array.isArray(response?.data?.data)) {
        setIsFetching(false);
        setQnaRecords(response?.data?.data);
      }
      return [];
    } catch (err) {
      setIsFetching(false);
    } finally {
      setIsFetching(false);
      return [];
    }
  };
  const getBotConfigRecords = async () => {
     try {
      const response = await axiosInstanceWithoutBaseUrl.get(
        `/botmgmt-service/qna/getflow`
      );
      if (Array.isArray(response?.data)) {
        setBotConfigRecords(response?.data);
      }
      return [];
    } catch (err) {
      return []
    }
  };
   const getDefaultSettingRecords = async () => {
     try {
      const response = await axiosInstanceWithoutBaseUrl.get(
        `botConfig?$filter=configType eq 'defaultSetting' and status eq 'Published'`
      );
      if (Array.isArray(response?.data)) {
        setDefaultSettingRecords(response?.data);
      }
      return [];
    } catch (err) {
      return []
    }
  };
  const getTemplateData = async () => {
    try {
      const response = await axiosInstance.get(
        `/templates/list?$top=1&$skip=0&$select=*&$filter=id%20eq%20${id}&history=true`
      );
      if (Array.isArray(response?.data?.rows)) {
        setSelectedTemplate(response?.data?.rows);
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getQnaRecords();
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [updateQnaRecords.deleteQna, updateQnaRecords.updateQna]);
  useEffect(() => {
    getTemplateData();
  }, []);
  useEffect(() => {
    if (selectedTemplate.length > 0) {
      getOffers();
      getBotConfigRecords();
      getDefaultSettingRecords();
    }
  }, [selectedTemplate]);

  function formatDateToIST(dateString) {
    const date = new Date(new Date(dateString).getTime());
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options).replace(',', '').replace(/, (\d+)/, ' $1');

    return formattedDate;
  }
  useEffect(() => {
    const updatedData = offers.map((item) => {
      const trigger=qnaRecords.find(element => {
        if(Array.isArray(element.metadata.find(item=>item.name==='offers')?.value) && element.metadata.find(item=>item.name==='offers')?.value.length>0 && element.metadata.find(item=>item.name==='offers')?.value[0].name === item.name && element.status!=='Deleted' && element.service_status!=='Deleted'){
          return true
      }
        return false  
      });
      if(trigger){
      const meta=trigger.metadata.find(ele=>ele.name==='offers')?.value[0]
        return{keys: trigger.questions, context:meta?.context , name: item?.name, id: item?.id, created: formatDateToIST(trigger?.created_at), status: trigger?.status, description: item?.description, fields: item?.field_attributes, audience: item?.audiences,associateFlow:meta?.associateFlow}
      }

      return { keys: [], context: '', name: item?.name, id: item?.id, created: '', status: '', description: item?.description, fields: item?.field_attributes, audience: item?.audiences,associateFlow:'' };
    });
    setTriggersOffersData(updatedData);
  }, [qnaRecords, updateQnaRecords, selectedTemplate, offers]);
  const onEdit = (rowData) => {
    setSelectedTriggerField(rowData);
    setOpenEditDrawer(true);
  };
  const deleteQna = async (selectedTriggerField) => {
    let triggerResponse
    triggerResponse = qnaRecords.find((item) => {
      const offersMetadata = item.metadata.find((meta) => meta.name === 'offers');
      if(offersMetadata && Array.isArray(offersMetadata.value) && offersMetadata.value.length > 0){
        if(item.metadata.find((meta) => meta.name === 'offers')?.value[0].name === selectedTriggerField?.name && (item.metadata.find((meta) => meta.name === 'flow')?.value === `sc_${flowName}` || item.metadata.find((meta) => meta.name === 'flow')?.value === flowName)) {
          return true
        }
        else{ 
          return false
        }
      }
      else{
        return false
      }
    });
    if (triggerResponse!==undefined) {
      const triggerIntent = `${associateFlow}-${selectedTriggerField?.id}`;
      try {
        const response=await axiosInstanceWithoutBaseUrl.delete(`botmgmt-service/qna/${triggerResponse?.id}?trigger=${triggerIntent}&kbId=null`)
        if (response) {
          setUpdateQnaRecords({ ...updateQnaRecords, updateQna: !updateQnaRecords.updateQna });
          setOpenEditDrawer(false);
          setSelectedTriggerField({});
          setTriggerContext('');
          setAssociateFlow('');
          setTriggerKeyValues([]);
          setErrorMessage({});
          showToastMessage({
            title: 'Success',
            description: TOASTER.DELETE_SUCCESS,
            status: 'success',
          });
          return response;
        }
        return [];
      }
      catch(err){
        showToastMessage({
          title: 'Error',
          description: TOASTER.DELETE_ERROR,
          status: 'error',
        });
      }
    } 
    else {
      showToastMessage({
        title: 'Error',
        description: TOASTER.DELETE_ERROR,
        status: 'error',
      });
    }
  };
  const defaultSettingRecord=defaultSettingRecords?.length>0 ? defaultSettingRecords.find((item)=>item?.properties?.default===`sc_${flowName}`) :undefined
  const publishDefaultSetting=async(botConfigPayload)=>{
    try {
      await axiosInstanceWithoutBaseUrl.post('botConfig/publish',botConfigPayload);
    } catch (error) {
      return []
    }
  }
  const createOrUpdateDefaultSetting=async(defaultSettingRecord)=>{
    if(!defaultSettingRecord){
      const defaultFlow = associateFlow !== `sc_${flowName}` ? true : false
      const updatedProperties=!defaultFlow ? {default:`sc_${flowName}`,offers:[]} : {default:`sc_${flowName}`,offers:[{offerName: selectedTriggerField?.name,associateFlow: associateFlow,offerId: selectedTriggerField?.id,}]}
      const botConfigPayload ={
          configType: "defaultSetting",
          fullName:`${selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()}_flowsetting`,
          properties:updatedProperties,
          status: "Published",
      }
      publishDefaultSetting(botConfigPayload)
    }
    else{
      const isAssociated=defaultSettingRecord?.properties?.offers.find(item=>item.offerId===selectedTriggerField?.id)
      const defaultFlow = associateFlow !== `sc_${flowName}` ? true : false
      if(!isAssociated && defaultFlow){
        const updatedOffers = [
          ...defaultSettingRecord?.properties?.offers,
          {
            offerName: selectedTriggerField?.name,
            associateFlow: associateFlow,
            offerId: selectedTriggerField?.id,
          }
        ]
        const botConfigPayload = {
          ...defaultSettingRecord,
          properties: {
            ...defaultSettingRecord?.properties,
            offers: updatedOffers
          }
        };
        publishDefaultSetting(botConfigPayload)
      }
    }
  }

  const upsertQna = async (isPublish,botConfigResponse) => {
    const errors = {};
    if (triggerContext === '') errors.context = 'Trigger context is required';
    if (triggerKeyValues.length == 0) errors.keys = 'Trigger keys are required';
    if (associateFlow == '') errors.associateFlow = 'Associate flow is required';
    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors);
      return;
    }
    setErrorMessage({});
    const timestamp = Date.now();
    const date = new Date(timestamp);
    const flowName = selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const formattedDate = date.toISOString().replace('T', ' ').substring(0, 19);
    const triggerIntent = `${flowName}-${selectedTriggerField?.id}`;
    const formattedTriggerIntent = triggerIntent.toLowerCase().replace(/\s+/g, '');
    const fields = selectedTemplate[0]?.fields.filter((item) => item?.requiredForBot);
    const fieldsData = fields.filter((item) => {
      if (item?.permissions?.expression && Array.isArray(item?.permissions?.expression.and)) {
        const conditions = item?.permissions.expression.and;
        if (conditions.find((item) => item.or.length > 0)) {
          return null;
        }
        return item;
      }
      return item;
    })
      .map((element) => {
        let infoSource = element?.informationRetrievalPreference;
        if (infoSource === 'May Ask Leading Questions') {
          infoSource = 'UserInput';
        } else if (infoSource === 'Fetch from Fields') {
          infoSource = 'Inference';
        } else if (infoSource === 'Fetch From Conversation') {
          infoSource = 'SystemGeneration';
        }
        return {
        field_name: element.label,
        description: element.description,
        mandatory: !!element?.type_based_attributes?.required,
        type: element?.type,
        valid_options: Array.isArray(element?.type_based_attributes?.list) ? element?.type_based_attributes?.list.map((item) => item.queue_name) : (Array.isArray(element?.type_based_attributes?.collection?.staticValues) ? element?.type_based_attributes?.collection?.staticValues.map((item) => item.value) : []),
          example: 'example',
          info_source: infoSource,
        }
      });
     const offerFieldValues=offers.find(item=>item.name===selectedTriggerField?.name)?.field_attributes
      const requiredFields=selectedTemplate[0]?.fields.filter(item=>item?.type_based_attributes?.required)
      let offerValues
      if(Array.isArray(offerFieldValues) && offerFieldValues.length>0){
        offerValues = offerFieldValues.reduce((result, item) => {
          result[item.field.label] = item.value;
          return result;
          },
          {offerId: selectedTriggerField?.id}
       );
      }
      else{
         offerValues = requiredFields.reduce((result, item) => {
          result[item.label] = item?.default_value || '';
          return result;
          },
          {offerId: selectedTriggerField?.id}
       );
      }
    setIsPublishing(true);
    let triggerResponse
    triggerResponse =  qnaRecords.find((item) => {
      if(Array.isArray(item.metadata.find((meta) => meta.name === 'offers')?.value) && item.metadata.find((meta) => meta.name === 'offers')?.value?.length>0){
        if(item.metadata.find((meta) => meta.name === 'offers')?.value[0].name === selectedTriggerField?.name && (item.metadata.find((meta) => meta.name === 'flow')?.value === `sc_${flowName}`||item.metadata.find((meta) => meta.name === 'flow')?.value === flowName)) {
          return true
        }
        else{ 
          return false
        }
      }
      else{
        return false
      }
    });
     const defaultSettingRecord=defaultSettingRecords?.length>0 ? defaultSettingRecords.find((item)=>item?.properties?.flowName===flowName) :false
     const pineconePayload = {
        ticket_forms: [{
          trigger_intent: formattedTriggerIntent,
          title: selectedTemplate[0]?.name,
          description: triggerContext,
          trigger_meta: {
            answer: formattedTriggerIntent,
            trigger_name: associateFlow,
            max_questions: 1,
            description: triggerContext,
            type: 'flow',
            timestamp: formattedDate,
            audience: Array.isArray(selectedTriggerField?.audience) && selectedTriggerField?.audience.length > 0 ? selectedTriggerField?.audience : ['all'],
            question: triggerKeyValues,
            fields: fieldsData,
            offer_defaults:offerValues
          },
        }],
          tenantid: tenantId
      };
      const updatedOffers = [{
          name: selectedTriggerField.name,
          context: triggerContext,
          associateFlow:associateFlow,
          offerId:selectedTriggerField?.id
      }];
    if (triggerResponse!==undefined && botConfigResponse) {
      let allKeys=[]
      const uniqueQuestions = [...new Set([...allKeys, ...triggerKeyValues])];
      const response=triggerResponse
      const teamValue=response && response?.metadata.find((item) => item.name == 'team')?.value
      const metadata = response?.metadata.map((item) => {
        if (item.name === 'offers') {
          return { ...item, value: updatedOffers };
        }
        return item;
      }) || [];
      if (!metadata.some((item) => item.name === 'offers')) {
        metadata.push({ name: 'offers', value: updatedOffers });
      }
      const payload = {
        questions: uniqueQuestions,
        metadata
      };
      if (isPublish) {
        const qnaRecordId = triggerResponse;
        const publish = {
          publish: true,
          ids: [qnaRecordId?.id],
          type: 'flow',
          updatedBy: tenantId,
          serviceCatalog: true,
          pinecone: pineconePayload,
          workspace: teamValue ? `{"team":"${teamValue}"}` :null
        }
        payload.publishData = publish;
      }
      const updateQnaRecord = async () => {
        const qnaRecordId = triggerResponse;
        try {
          const response = await axiosInstanceWithoutBaseUrl.put(
            `/botmgmt-service/qna/update/${qnaRecordId?.id}`, payload);
          if (response) {
            setUpdateQnaRecords({ ...updateQnaRecords, updateQna: !updateQnaRecords.updateQna });
            setOpenEditDrawer(false);
            setSelectedTriggerField({});
            setTriggerContext('');
            setAssociateFlow('');
            setTriggerKeyValues([]);
            setErrorMessage({});
            setIsPublishing(false);
            // updateDefaultSetting(defaultSettingRecord);
            showToastMessage({
              title: 'Success',
              description: isPublish ? TOASTER.PUBLISH_SUCCESS : TOASTER.UPDATE_SUCCESS,
              status: 'success',
            });
            return response;
          }
          return [];
        } catch (err) {
          setIsPublishing(false);
          showToastMessage({
            title: 'Error',
            description: isPublish ? TOASTER.PUBLISH_ERROR : TOASTER.UPDATE_ERROR,
            status: 'error',
          });
          setOpenEditDrawer(false);
          setSelectedTriggerField({});
          setTriggerContext('');
          setAssociateFlow('');
          setTriggerKeyValues([]);
          setErrorMessage({});
          return [];
        } finally {
          setIsPublishing(false);
        }
      };
      await updateQnaRecord();
    } 
    else {
      const updatedMetadata = botConfigResponse.data.metadata.map((item) => {
        if (item.name === "offers") {
          return { ...item, value: updatedOffers };
        }
        return item;
      });
      const offersExists = updatedMetadata.some((item) => item.name === "offers");
      if (!offersExists) {
        updatedMetadata.push({ name: "offers", value: updatedOffers },{"name": "triggerName","value": formattedTriggerIntent,"valueLabel": "true"});
      }
      const createPayload={
        questions:triggerKeyValues,
        metadata:updatedMetadata
      }
      if(isPublish){
      const teamValue= botConfigResponse.data.metadata.find((item)=>item.name==='team')?.value
      const publishPayload={
         publish: true,
         type: "flow",
         workspace: teamValue ? `{"team":"${teamValue}"}` :null,
         updatedBy: tenantId,
         serviceCatalog: true,
         pinecone: pineconePayload
      }
      createPayload.publishData=publishPayload
      }
       try {
          const response = await axiosInstanceWithoutBaseUrl.post(
            `/botmgmt-service/qna/createTrigger`, createPayload);
          if (response) {
            setUpdateQnaRecords({ ...updateQnaRecords, updateQna: !updateQnaRecords.updateQna });
            setOpenEditDrawer(false);
            setSelectedTriggerField({});
            setTriggerContext('');
            setAssociateFlow('');
            setTriggerKeyValues([]);
            setErrorMessage({});
            setIsPublishing(false);
            !defaultSettingRecord && createOrUpdateDefaultSetting(defaultSettingRecord);
            showToastMessage({
              title: 'Success',
              description: isPublish ? TOASTER.PUBLISH_SUCCESS : TOASTER.UPDATE_SUCCESS,
              status: 'success',
            });
            return response;
          }
          return [];
        } catch (err) {
          setIsPublishing(false);
          showToastMessage({
            title: 'Error',
            description: isPublish ? TOASTER.PUBLISH_ERROR : TOASTER.UPDATE_ERROR,
            status: 'error',
          });
          setOpenEditDrawer(false);
          setSelectedTriggerField({});
          setTriggerContext('');
          setAssociateFlow('');
          setTriggerKeyValues([]);
          setErrorMessage({});
          return [];
        } finally {
          setIsPublishing(false);
        }
    }
  };
  const saveTrigger = async (isPublish) => {
    const botConfigResponse = botConfigRecords.find((item) => item.value === associateFlow);
    if(botConfigResponse?.id !== undefined){
      await upsertQna(isPublish,botConfigResponse);
    }
    else{
      showToastMessage({
        title: 'Error',
        description: TOASTER.FLOW_NOT_FOUND,
        status: 'error',
      });
    }
    
  };
  const onDelete = async (selectedTriggerField) => {
    await deleteQna(selectedTriggerField);
  };
  return (
    <>
        <TriggersTableViewWithSearch
          columns={COLUMNS_TRIGGERS({ onEdit, onDelete })}
          tableData={triggersOffersData}
          isFetching={isFetching}
        />
        {openEditDrawer && (
          <EditTriggersDrawer
            openDrawer={openEditDrawer}
            setOpenEditDrawer={setOpenEditDrawer}
            selectedTriggerField={selectedTriggerField}
            setSelectedTriggerField={setSelectedTriggerField}
            setShowTriggersDrawer={setShowTriggersDrawer}
            saveTrigger={saveTrigger}
            triggerKeyValues={triggerKeyValues}
            setTriggerKeyValues={setTriggerKeyValues}
            triggerContext={triggerContext}
            setTriggerContext={setTriggerContext}
            triggersOffersData={triggersOffersData}
            errorMessage={errorMessage}
            isPublishing={isPublishing}
            botConfigRecords={botConfigRecords}
            associateFlow={associateFlow}
            setAssociateFlow={setAssociateFlow}
            selectedTemplate={selectedTemplate}
          />
        )}
    </>
  );
};

export default Triggers;

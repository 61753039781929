import React from 'react';
import { Switch } from '@chakra-ui/react';
import {
  FIELD_SLUG,
  FIELD_VALIDATE_KEY,
  LABELS,
} from '../../../utils/enums/labels';
import {
  dateDefaultValueList,
  // dateFormatSelectOptTwo,
  // displayLocaleOptTwo,
  // selectLocalDateOptTwo,
} from '../../../utils/enums/selectOption';
import { BASE_SETTINGS_KEYS, ADVANCED_SETTINGS_KEYS } from './constants';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import customStylesSelectWithHeight40 from '../../../context/CustomTheme/CustomStylesSelectWithHeight40';
import {
  renderFieldLabels,
  renderOffsetNegativeField,
  renderOffsetPositiveField,
} from './renderFields';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import { PrimaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

export const DateBaseSettings = ({
  data,
  setFields,
  setIsError,
  existingFields,
  templateFieldsArray,
  onOpenDateValidationDrawer,
}) => ({
  row1: [
    renderFieldLabels({
      data,
      setFields,
      fieldSlug: FIELD_SLUG.FIELD_DATE,
      setIsError,
      existingFields,
    }),
    {
      label: LABELS.FIELD_PROPERTIES.NAME,
      value: `${data?.[BASE_SETTINGS_KEYS.SLUG] || ''}`,
      required: true,
      key: FIELD_VALIDATE_KEY.SLUG,
      errorString: LABELS.ERROR.NAME,
      placeholder: '',
      isReadOnly: true,
      valueKey: BASE_SETTINGS_KEYS.SLUG,
      onChange: (value) => {
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.SLUG]: value,
          [BASE_SETTINGS_KEYS.IS_EDIT_SLUG]: true,
        }));
        setIsError((pre) => ({ ...pre, [BASE_SETTINGS_KEYS.SLUG]: !value }));
      },
    },
  ],
  row2: [
    {
      label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
      value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DESCRIPTION]: value,
        })),
    },
    {
      label: '',
      render: (newData) => {
        const selectedOptions =
          newData[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD] || false;
        return (
          <CustomCheckBox
            isChecked={selectedOptions}
            onChange={(e) => {
              setFields((prev) => ({
                ...prev,
                [BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD]: e.target.checked,
              }));
            }}
            checkBoxText={LABELS.FIELD_PROPERTIES.ALLOW_TIME_FIELD}
            isDisabled={false}
          />
        );
      },
    },
  ],
  row9: [
    {
      label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
      value: `${data[BASE_SETTINGS_KEYS.DEFAULT_VALUE] || ''}`,
      placeholder: '',
      key: FIELD_VALIDATE_KEY.DEFAULT_VALUE,
      valueKey: BASE_SETTINGS_KEYS.DEFAULT_VALUE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: value,
        })),
      render: (newData) => (
        <CustomSelectBox
          options={dateDefaultValueList}
          value={
            getWholeOption(
              newData[BASE_SETTINGS_KEYS.DEFAULT_VALUE],
              dateDefaultValueList
            ) || ''
          }
          onChange={(option) => {
            setFields((pre) => ({
              ...pre,
              [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: option?.value,
            }));
            if (option?.value === dateDefaultValueList[0].value) {
              setFields((pre) => ({
                ...pre,
                [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: option?.value,
                [BASE_SETTINGS_KEYS.OFFSET_VALUE]: '0',
              }));
            }
          }}
          styles={customStylesSelectWithHeight40}
          id="DAteBaseSettingsSelectBox"
        />
      ),
    },
    renderOffsetPositiveField({ data, setFields, setIsError }),
    renderOffsetNegativeField({ data, setFields, setIsError }),
  ],
  row3: [
    {
      label: '',
      render: (fieldData) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
              marginTop: '10px',
              marginBottom: '10px',
              gap: '20px',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              Restricted
              <Switch
                isChecked={fieldData[BASE_SETTINGS_KEYS.IS_RESTRICTED] || false}
                onChange={(e) => {
                  setFields((prev) => ({
                    ...prev,
                    [BASE_SETTINGS_KEYS.IS_RESTRICTED]: e.target.checked,
                  }));
                }}
              />
            </div>
            <PrimaryCustomButton
              buttonText="Restriction Rules"
              isDisabled={!fieldData[BASE_SETTINGS_KEYS.IS_RESTRICTED]}
              onClick={() => {
                if (typeof onOpenDateValidationDrawer === 'function') {
                  onOpenDateValidationDrawer({
                    fieldData,
                    fieldDataInDB: data,
                    setFieldData: setFields,
                    templateFields: templateFieldsArray || [],
                  });
                }
              }}
            />
          </div>
      ),
    },
  ]
});

export const AdvanceDateFields = (data, setFields) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REGEX,
      value: `${data[ADVANCED_SETTINGS_KEYS.REGEX_PATTERN] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.REGEX_PATTERN,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REGEX_PATTERN]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.DATE_FORMAT,
    //   value: `${data[ADVANCED_SETTINGS_KEYS.DATE_FORMAT] || ''}`,
    //   placeholder: 'hs',
    //   onChange: (value) =>
    //     setFields({ ...data, [ADVANCED_SETTINGS_KEYS.DATE_FORMAT]: value }),
    //   render: () => (
    //     <CustomSelectBox
    //       options={dateFormatSelectOptTwo}
    //       value={
    //         getWholeOption(
    //           data[ADVANCED_SETTINGS_KEYS.DATE_FORMAT],
    //           dateFormatSelectOptTwo
    //         ) || ''
    //       }
    //       onChange={(option) =>
    //         setFields({
    //           ...data,
    //           [ADVANCED_SETTINGS_KEYS.DATE_FORMAT]: option?.value,
    //         })
    //       }
    //       styles={customStylesSelectWithHeight40}
    //     />
    //   ),
    // },
  ],
  row2: [],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.REQUIRED,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.UNIQUE,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.UNIQUE] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.UNIQUE,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.UNIQUE]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.HIDDEN]: value,
    //     })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REPORTABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REPORTABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REPORTABLE]: value,
        })),
    },
  ],
  row4: [
    // {
    //   label: LABELS.FIELD_PROPERTIES.METRICS,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.METRICS] || ''}`,
    //   placeholder: '',
    //   onChange: (value) =>
    //     setFields({ ...data, [ADVANCED_SETTINGS_KEYS.METRICS]: value }),
    // },
  ],
  row5: [
    // {
    //   label: LABELS.FIELD_PROPERTIES.DISPLAY_LOCALE,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.LOCALE] || ''}`,
    //   placeholder: '',
    //   onChange: (value) =>
    //     setFields({ ...data, [ADVANCED_SETTINGS_KEYS.LOCALE]: value }),
    //   render: () => (
    //     <CustomSelectBox
    //       options={displayLocaleOptTwo}
    //       value={getWholeOption(
    //         data[ADVANCED_SETTINGS_KEYS.LOCALE],
    //         displayLocaleOptTwo
    //       )}
    //       onChange={(option) =>
    //         setFields({
    //           ...data,
    //           [ADVANCED_SETTINGS_KEYS.LOCALE]: option?.value,
    //         })
    //       }
    //       styles={customStylesSelectWithHeight40}
    //     />
    //   ),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.SELECT_LOCALE,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.SELECT_LOCALE] || ''}`,
    //   placeholder: '',
    //   onChange: (value) =>
    //     setFields({ ...data, [ADVANCED_SETTINGS_KEYS.SELECT_LOCALE]: value }),
    //   render: () => (
    //     <CustomSelectBox
    //       options={selectLocalDateOptTwo}
    //       value={getWholeOption(
    //         data[ADVANCED_SETTINGS_KEYS.SELECT_LOCALE],
    //         selectLocalDateOptTwo
    //       )}
    //       onChange={(option) =>
    //         setFields({
    //           ...data,
    //           [ADVANCED_SETTINGS_KEYS.SELECT_LOCALE]: option?.value,
    //         })
    //       }
    //       styles={customStylesSelectWithHeight40}
    //     />
    //   ),
    // },
  ],
});

import { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import { cloneDeep, uniq } from 'lodash';

import { getTenantData } from '../../../helpers/tenantUrl';

import { ACTOROPERATORS, OPERATORS_MAP, SUPPORTED_FIELD_TYPES } from '../filters.constants';
import {
  ruleCheckForTextNumberOption,
  ruleCheckForSelectOption,
  ruleCheckForTextOption,
} from '../../../utils/enums/selectOption';
import axiosInstance from '../../../utils/axios/axios';

export const associateBy = ({ options, key }) => {
  if (!Array.isArray(options)) {
    return {};
  }

  return options.reduce((prev, curr) => {
    const val = curr?.[key];
    if (val) {
      prev[val] = curr;
    }
    return prev;
  }, {});
};

export const createMap = ({ options, key = 'value', value = 'label' }) => {
  if (!Array.isArray(options)) {
    return {};
  }

  return options.reduce((prev, curr) => {
    const val = curr?.[key];
    if (val) {
      prev[val] = curr[value];
    }
    return prev;
  }, {});
};

export const generateOptions = ({ options, label = 'name', value = 'id' }) => {
  if (!Array.isArray(options)) {
    return [];
  }

  return options.map((o) => ({
    label: o?.[label],
    value: o?.[value],
    isDefault: o?.isDefault || false,
  }));
};

export const getOperators = ({ field, type }) => {
  if (!field) {
    return null;
  }

  // If the custom operators are provided then use it
  const providedOperators = field?.operators;
  if (Array.isArray(providedOperators) || providedOperators === null) {
    return providedOperators;
  }

  switch (type) {
    case SUPPORTED_FIELD_TYPES.DROPDOWN:
      return ruleCheckForSelectOption;

    case SUPPORTED_FIELD_TYPES.TEXT:
      return ruleCheckForTextOption;

    case SUPPORTED_FIELD_TYPES.NUMBER:
      return ruleCheckForTextNumberOption;

    case SUPPORTED_FIELD_TYPES.BOOLEAN:
      return ruleCheckForTextNumberOption;

    case SUPPORTED_FIELD_TYPES.DATE:
      return [
        {
          label: 'GREATER THAN',
          value: OPERATORS_MAP.GREATER_THAN,
        },
        {
          label: 'GREATER THAN OR EQUAL TO',
          value: OPERATORS_MAP.GREATER_THAN_EQUAL_TO,
        },
        {
          label: 'LESS THAN',
          value: OPERATORS_MAP.LESS_THAN,
        },
        {
          label: 'LESS THAN OR EQUAL TO',
          value: OPERATORS_MAP.LESS_THAN_EQUAL_TO,
        }
      ];

    default:
      return null;
  }
};

export const getOperatorIdMapForAllOperators = ({ operators }) => {
  const ruleCheckForSelectOptionMap = createMap({
    options: ruleCheckForSelectOption,
  });

  const ruleCheckForTextOptionMap = createMap({
    options: ruleCheckForTextOption,
  });

  const ruleCheckForTextNumberOptionMap = createMap({
    options: ruleCheckForTextNumberOption,
  });

  const temp = createMap({
    options: operators,
  });

  return {
    ...ruleCheckForSelectOptionMap,
    ...ruleCheckForTextOptionMap,
    ...ruleCheckForTextNumberOptionMap,
    ...temp,
  };
};

export const BOOLEAN_OPTIONS = [
  {
    label: 'True',
    value: 'true',
  },
  {
    label: 'False',
    value: 'false',
  },
];

export const getRightOptionsForSelectedField = ({ field, operator }) => {
  if (!field) {
    return null;
  }

  if (operator === OPERATORS_MAP.IS_NOT_EMPTY || operator === OPERATORS_MAP.IS_EMPTY) {
    return null;
  }

  if (operator === ACTOROPERATORS.IS_NOT_EMPTY || operator === ACTOROPERATORS.IS_EMPTY) {
    return null;
  }
  // To get the options based on the selected operator
  const options = field?.optionMap?.[operator];
  if (Array.isArray(options) || options === null) {
    return options;
  }

  // Get the default options
  const defaultOptions = field?.defaultOptions;
  if (Array.isArray(defaultOptions) || defaultOptions === null) {
    return defaultOptions;
  }

  return [];
};

export const getRightOperandValue = ({ value, rightOptionMap, type }) => {
  if (type === SUPPORTED_FIELD_TYPES.DATE) {
    const day = dayjs(value);

    if (day.isValid()) {
      return day.format('MMM DD, YYYY');
    }
  }

  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    if (type === SUPPORTED_FIELD_TYPES.DROPDOWN || typeof value === 'boolean') {
      return rightOptionMap?.[value];
    }

    return value.toString();
  }

  if (Array.isArray(value)) {
    return uniq(value.map((v) => rightOptionMap?.[v] || v || '')).join(', ');
  }

  if (value instanceof Date) {
    return value?.toString();
  }

  if (value) {
    return rightOptionMap?.[value] || value;
  }

  return '';
};

export const getLeftLabel = ({ value, leftOptionMap }) => {
  let str = leftOptionMap?.[value?.column || ''] || '';
  if (!value?.op) {
    str += ': ';
  }
  return str;
};

function isDeletedSeqPresentAndValid(conditions) {
  return conditions.some((condition) =>
    condition.column === 'deleted_seq'
  );
}
export const addValue = ({ existingValues, newValue, optionsIdMap }) => {
  const type = optionsIdMap?.[newValue?.column]?.type;
  const tempValues = Array.isArray(existingValues)
    ? cloneDeep(existingValues)
    : [];

  let isAvailable;
  switch (type) {
    case SUPPORTED_FIELD_TYPES.QUEUES:
      isAvailable = tempValues.findIndex(
        (v) => v?.column === newValue?.column && v?.op === newValue?.op
      );
      if (isAvailable < 0) {
        tempValues.push(newValue);
      } else {
        const values = tempValues[isAvailable]?.operand;
        const updatedValues = new Set([
          ...values,
          ...(newValue?.operand?.flat() || []),
        ]);
        tempValues[isAvailable].operand = Array.from(updatedValues);
      }
      break;
    case 'READ_ONLY':
      if (isDeletedSeqPresentAndValid(tempValues)) {
        break;
      } else {
        tempValues.push(newValue);
        break;
      }
    default:
      tempValues.push(newValue);
  }

  return tempValues;
};

const teamOptions = {
  current: null,
};
const queueOptions = {
  current: {},
};

export const useQueue = () => {
  const [teamOptionsState, setTeamOptionsState] = useState([]);
  const [queueOptionState, setQueueOptionState] = useState({});

  const getTeam = useCallback(async () => {
    if (teamOptions.current) {
      return teamOptions.current;
    }

    try {
      const tenantInformation = getTenantData();
      const tenantId = tenantInformation?.id;

      const params = {
        tenant_id: tenantId,
      };

      const res = await axiosInstance.get('teams', { params });
      const teams = res?.data?.[0]?.[0]?.team;
      if (!Array.isArray(teams)) {
        throw new Error('Something went wrong');
      }

      return teams;
    } catch (err) {
      return [];
    }
  }, []);

  const getQueue = useCallback(async (team) => {
    if (queueOptions.current[team]) {
      return queueOptions.current[team];
    }

    try {
      const tenantInformation = getTenantData();
      const tenantId = tenantInformation?.id;
      const params = {
        tenant_id: tenantId,
        team_name: team,
      };

      const res = await axiosInstance.get('queueTypes/teams', { params });
      const queue = res?.data?.[0];
      if (!Array.isArray(queue)) {
        throw new Error('Something went wrong');
      }

      const enrichedOptions = queue.map((q) => ({
        label: q?.properties?.queueLabel || q?.queue_name,
        value: q?.queue_name,
      }));

      return enrichedOptions;
    } catch (err) {
      return [];
    }
  }, []);

  const getAllQueueData = useCallback(
    (teams) => {
      const queuePromises = teams.map((t) => getQueue(t));
      Promise.all(queuePromises)
        .then((res) => {
          res.forEach((r, i) => {
            queueOptions.current[teams[i]] = r;
          });
        })
        .finally(() => {
          setQueueOptionState(queueOptions.current);
        });
    },
    [getQueue]
  );

  const getTeamAndQueueInfo = useCallback(() => {
    const options = getTeam();
    options.then((res) => {
      const formattedOptions = res.map((t) => ({
        label: t,
        value: t,
      }));

      teamOptions.current = res;
      setTeamOptionsState(formattedOptions);
      getAllQueueData(res);
    });
  }, [getTeam, getAllQueueData]);

  return { teamOptionsState, queueOptionState, getTeamAndQueueInfo };
};

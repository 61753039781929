import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';

import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import CustomSelectBox from '../../../components/SelectBox/Select';
import TableWithAction from '../../../components/Table/TableWithAction';

import {
  generateFieldMap,
  getFieldsForSelect,
  fieldValueOption,
} from './relation.helper';
import styles from './Relation.module.scss';
import {
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import {
  removeMatchingOption,
} from './relation.service';

const EditSimilarity = ({
  templateIdMap,
  hierarchies,
  label,
  similarities,
  templateIdLeft,
  templateIdRight,
  onChange,
  setIsError,
}) => {
  const [similarityState, setSimilarityState] = useState({
    similarities
  });

  const leftTemplate = useMemo(() => {
    if (!templateIdLeft) {
      return {};
    }
    return templateIdMap?.[templateIdLeft];
  }, [templateIdLeft, templateIdMap]);

  const rightTemplate = useMemo(() => {
    if (!templateIdRight) {
      return {};
    }
    return templateIdMap?.[templateIdRight];
  }, [templateIdRight, templateIdMap]);

  const leftTemplateFieldsMemo = useMemo(
    () =>
      generateFieldMap(
        [...(leftTemplate?.fields || [])].filter(
          (item) => item.type === 'DROPDOWN'
        )
      ),
    [leftTemplate]
  );

  const rightTemplateFieldsMemo = useMemo(
    () =>
      generateFieldMap(
        [...(rightTemplate?.fields || [])].filter(
          (item) => item.type === 'DROPDOWN'
        )
      ),
    [rightTemplate]
  );

  const enrichedLeftFieldsMemo = useMemo(
    () => getFieldsForSelect(Object.values(leftTemplateFieldsMemo)),
    [leftTemplateFieldsMemo]
  );

  const enrichedRightFieldsMemo = useMemo(
    () => getFieldsForSelect(Object.values(rightTemplateFieldsMemo)),
    [rightTemplateFieldsMemo]
  );

  useEffect(() => {
    onChange(similarityState?.similarities);
  }, [similarityState?.similarities]);

  const handleChange = (key, value) => {
    setSimilarityState((prev) => ({
      ...prev,
      [key]: value,
    }));

    setIsError(false);
  };

  const getDifferenceWithFormat = (arr1, arr2, checkKey = 'label') => {
    const arr2Set = new Set(arr2.map((item) => item[checkKey]));
    const difference = arr1.filter((item) => arr2Set.has(item[checkKey]));
    const formattedDifference = difference.map((item) => ({
      left: {
        value: item.value,
        label: item.label,
      },
      right: {
        value: item.value,
        label: item.label,
      },
    }));
    return formattedDifference;
  };

  const makeSimilaritiesByFieldType = async (fieldLeft, fieldRight) => {
    let leftFieldValueMapOption = [];
    let rightFieldValueMapOption = [];

    const getFieldData = (template, fieldId) => {
      const field =
        (template?.fields || []).find((f) => f?.id === fieldId) || {};
      const workflowStates = (template?.workflow?.workflow_status || [])
        .filter((state) => state.id !== 'start_1')
        .map((state) => ({ label: state.data.label, value: state.id }));
      return { field, workflowStates };
    };

    const leftData = getFieldData(leftTemplate, fieldLeft);
    const rightData = getFieldData(rightTemplate, fieldRight);

    const leftOptions = await fieldValueOption(
      leftTemplate,
      { workFlowStates: leftData.workflowStates },
      fieldLeft,
      hierarchies
    );
    if (!isEmpty(leftOptions)) {
      leftFieldValueMapOption = [...leftOptions];
    }

    const rightOptions = await fieldValueOption(
      rightTemplate,
      { workFlowStates: rightData.workflowStates },
      fieldRight,
      hierarchies
    );
    if (!isEmpty(rightOptions)) {
      rightFieldValueMapOption = [...rightOptions];
    }

    const leftTypeAttributes =
      leftData.field.type_based_attributes?.collection || {};
    const rightTypeAttributes =
      rightData.field.type_based_attributes?.collection || {};

    const isStaticCollection =
      leftTypeAttributes.collection_type === 'STATIC' &&
      rightTypeAttributes.collection_type === 'STATIC';

    const isSameDropdownType =
      leftData.field.type === 'DROPDOWN' && rightData.field.type === 'DROPDOWN';

    const isDependentCollection =
      leftTypeAttributes.collection_type === 'DEPENDENT' &&
      rightTypeAttributes.collection_type === 'DEPENDENT';

    const specialFieldsToCheck = ['source', 'queue'];
    const specialFieldsToCheckLabel = ['workflowState', 'actor'];
    if (isSameDropdownType && isStaticCollection) {
      return getDifferenceWithFormat(
        leftFieldValueMapOption,
        rightFieldValueMapOption
      );
    }

    if (isSameDropdownType) {
      if (isDependentCollection) {
        return getDifferenceWithFormat(
          leftFieldValueMapOption,
          rightFieldValueMapOption
        );
      }

      if (
        specialFieldsToCheck.includes(leftTypeAttributes.specialField) &&
        specialFieldsToCheck.includes(rightTypeAttributes.specialField)
      ) {
        return getDifferenceWithFormat(
          leftFieldValueMapOption,
          rightFieldValueMapOption,
          'value'
        );
      }
      if (
        specialFieldsToCheckLabel.includes(leftTypeAttributes.specialField) &&
        specialFieldsToCheckLabel.includes(rightTypeAttributes.specialField)
      ) {
        return getDifferenceWithFormat(
          leftFieldValueMapOption,
          rightFieldValueMapOption
        );
      }
    }

    return [];
  };

  // Add Similar Fields
  const addSimilarities = useCallback(async () => {
    setSimilarityState((prev) => {
      if (!prev?.fieldA || !prev?.fieldB) {
        return prev;
      }
      return prev;
    });

    const prevState = similarityState;
    if (prevState?.fieldA && prevState?.fieldB) {
      const data = await makeSimilaritiesByFieldType(
        prevState.fieldA.value,
        prevState.fieldB.value
      );

      const tempData = cloneDeep(prevState.similarities || []);
      tempData.push({
        fieldA: prevState.fieldA,
        similar: '~',
        fieldB: prevState.fieldB,
        mappings: data || [],
      });
      setSimilarityState({
        ...prevState,
        similarities: tempData,
        fieldA: null,
        fieldB: null,
      });
    }
  }, [similarityState]);

  const addValueMapInSimilarities = useCallback((similarIndex) => {
    setSimilarityState((prev) => {
      if (!prev[`valueA-${similarIndex}`] || !prev[`valueB-${similarIndex}`]) {
        return {
          ...prev,
          [`valueA-${similarIndex}`]: null,
          [`valueB-${similarIndex}`]: null,
        };
      }

      const tempData = cloneDeep(prev?.similarities || []);
      const updatedMappings = [
        ...(tempData[similarIndex]?.mappings || []),
        {
          left: prev[`valueA-${similarIndex}`],
          right: prev[`valueB-${similarIndex}`],
        },
      ];

      tempData[similarIndex] = {
        ...tempData[similarIndex],
        mappings: updatedMappings,
      };
      return {
        ...prev,
        similarities: tempData,
        [`valueA-${similarIndex}`]: null,
        [`valueB-${similarIndex}`]: null,
      };
    });
  }, []);

  // Delete Similar Fields
  const deleteSimilarity = useCallback((rowNo) => {
    setSimilarityState((prev) => {
      const tempData = cloneDeep(prev?.similarities);
      tempData.splice(rowNo, 1);
      return {
        ...prev,
        similarities: tempData,
      };
    });
  }, []);
  // delete similar mapping
  const deleteMapValueFromSimilarity = useCallback(
    (similarityRowNo, mappingRowNo) => {
      setSimilarityState((prev) => {
        const tempData = cloneDeep(prev?.similarities);
        if (tempData && tempData[similarityRowNo]) {
          const updatedMappings = [
            ...(tempData[similarityRowNo]?.mappings || []),
          ];
          updatedMappings.splice(mappingRowNo, 1);
          tempData[similarityRowNo] = {
            ...tempData[similarityRowNo],
            mappings: updatedMappings,
          };
        }
        return {
          ...prev,
          similarities: tempData,
        };
      });
    },
    []
  );

  return (
    <div>
      {/* Similarity Section */}
      <div className={styles.title}>{label}</div>
      <Box className={styles.relationBox}>
        <CustomSelectBox
          options={removeMatchingOption(
            enrichedLeftFieldsMemo,
            [...(similarities || [])],
            'fieldA'
          )}
          onChange={(e) => handleChange('fieldA', e)}
          value={similarityState?.fieldA || null}
          placeholder="Select Field"
        />

        <CustomSelectBox
          options={removeMatchingOption(
            enrichedRightFieldsMemo,
            [...(similarities || [])],
            'fieldB'
          )}
          onChange={(e) => handleChange('fieldB', e)}
          value={similarityState?.fieldB || null}
          placeholder="Select Field"
        />

        <SecondaryCustomButton
          buttonText="Add"
          isDisabled={!similarityState?.fieldA || !similarityState?.fieldB}
          onClick={addSimilarities}
        />
      </Box>

      {similarities?.length ? (
        <TableWithAction
          className={styles.table}
          columns={[]}
          rowData={similarities}
          keys={['fieldA.label', 'similar', 'fieldB.label']}
          action="delete"
          onClick={deleteSimilarity}
          leftTemplate={leftTemplate}
          rightTemplate={rightTemplate}
          addValueMapInSimilarities={addValueMapInSimilarities}
          handleChangeForSelectBox={handleChange}
          details={similarityState}
          deleteMapValueFromSimilarity={deleteMapValueFromSimilarity}
          showValueMapField
          hierarchies={hierarchies}
        />
      ) : null}
    </div>
  );
};

EditSimilarity.propTypes = {
  templateIdMap: PropTypes.object.isRequired,
  hierarchies: PropTypes.array,
  label: PropTypes.string,
  similarities: PropTypes.array.isRequired,
  templateIdLeft: PropTypes.string.isRequired,
  templateIdRight: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setIsError: PropTypes.func.isRequired,
};

EditSimilarity.defaultProps = {
  hierarchies: [],
  label: 'Similarity'
};

export default EditSimilarity;

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import AttachmentUpload from '../AttachmentUpload';
import CustomDrawer from '../../pages/TenantFilters/CustomDrawer';
import { mediaFormatSelectOpt2FileType } from '../../utils/enums/selectOption';
import { DARK, LIGHT } from '../../utils/enums/colors';

const HierarchyEditImageDrawer = ({
  open,
  setOpen,
  imageUrlInfo,
  onSave,
  description,
  setDescription,
  title,
}) => {
  const [tempUrlInfo, setTempUrlInfo] = useState({ ...imageUrlInfo });

  const onCancel = useCallback(() => setOpen(false), [setOpen]);

  const handleSave = useCallback(() => {
    onSave(tempUrlInfo, description);
    onCancel();
  }, [onSave, tempUrlInfo, description, onCancel]);

  const onImageDelete = useCallback(() => {
    setTempUrlInfo({ blobName: '', imageUrl: '' });
  }, []);

  const onImageUpload = useCallback((imageRes) => {
    setTempUrlInfo({ blobName: imageRes[imageRes.length - 1], imageUrl: '' });
  }, []);

  const AttachmentBody = useMemo(
    () => (
      <AttachmentUpload
        blobName={[tempUrlInfo?.blobName]}
        onImageUpload={onImageUpload}
        onImageDelete={onImageDelete}
        maxFileSize={4 * 1024 * 1024}
        maxNoOfFileAllow={1}
        fileSupportForUpload={mediaFormatSelectOpt2FileType.images}
      />
    ),
    [imageUrlInfo, onImageUpload, onImageDelete]
  );

  const drawerBody = useMemo(
    () => (
      <Flex flexDirection="column" gap="10px">
        {AttachmentBody}
        <Flex flexDirection="column" gap="8px">
          <Text
            as="label"
            className="SCLabel"
            data-testid="LabelForIntentDescription"
          >
            Description
          </Text>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            size="lg"
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            minH="2.5rem"
            data-testid="DescriptionForIntent"
          />
        </Flex>
      </Flex>
    ),
    [description]
  );

  return (
    <CustomDrawer
      title={title}
      open={open}
      closeDrawer={onCancel}
      drawerBody={drawerBody}
      handleSave={handleSave}
    />
  );
};

HierarchyEditImageDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  imageUrlInfo: PropTypes.object,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
};

HierarchyEditImageDrawer.defaultProps = {
  imageUrlInfo: {},
  description: ''
};

export default HierarchyEditImageDrawer;

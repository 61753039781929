import React from 'react';
import { Box, Checkbox, Switch } from '@chakra-ui/react';
const renderKnowledgeArticleForm = (
    actionToPerform,
    handleChange,
    actionToPerformSelectedIndex,
) => {
    //const selectedValue = actionToPerform.notify_on && actionToPerform.notify_on.length > 0 && actionToPerform.notify_on?.map((item) => { return { label: item, value: item } })
    return (
        <>
            {/* <Box data-testid="DynamicRendererNoteFormBox3" marginTop="32px">
                <Checkbox
                    isChecked={actionToPerform.knowledge_article}
                    onChange={() => {
                        handleChange(
                            !actionToPerform.knowledge_article,
                            `action_to_perform[${actionToPerformSelectedIndex}].knowledge_article`
                        );
                    }}
                    mb="15px"
                    data-testid="AddFromMasterModalSelectAllCheckbox"
                >
                    Knowledge Article
                </Checkbox>
            </Box> */}
            <Box data-testid="DynamicRendererNoteFormBox4" marginTop="32px">
                <Switch
                    isChecked={actionToPerform.restrict_to_agent}
                    onChange={() => {
                        handleChange(
                            !actionToPerform.restrict_to_agent,
                            `action_to_perform[${actionToPerformSelectedIndex}].restrict_to_agent`
                        );
                    }}
                    mb="15px"
                    data-testid="AddFromMasterModalSelectAllSwitch"
                >
                    Restrict to Agent
                </Switch>
            </Box>
        </>
    );
}
export default renderKnowledgeArticleForm;
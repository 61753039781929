import React, { useMemo, useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios/axios';
import { config } from '../../../helpers/config';

const QuickHelp = () => {
  const defaultHelpContent = `<p>We’re here to provide you with the assistance and resources you need to effectively manage and maintain your employee support operations using [Rezolve.ai](https://www.rezolve.ai) bot application.</p> \n\n </br> <div style="border-left: 4px solid blue; height: 18px; padding-left: 10px; border-radius: 3px; margin-left: 0px"> <strong style="margin-left: 5px">Frequently Asked Questions (FAQs):</strong> </div>  \nFor any frequently asked questions, we request you to refer to our [Help-Center](https://www.rezolve.ai/help-center) that we keep updated with frequent customer queries and updates about the product. You can access the Help Center at the following link – [Rezolve.ai|help-center](https://www.rezolve.ai/help-center) \n\n</br></br> If you have any other questions or need further assistance, please don’t hesitate to reach out to your dedicated customer service manager. We are committed to ensuring your success. </br></br> Sincerely, </br> Team [Rezolve.ai](https://www.rezolve.ai)`;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getQuickHelp = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${config.tsUrl}/botmgmt-service/HelpContent?$filter=appname eq 'agent'`,
        {
          headers: {
            isSupportRequest: 'true',
          },
        }
      );
      setLoading(false);
      if (response.data) {
        return {
          error: null,
          response: response.data,
        };
      }
    } catch (error) {
      return { error: 'something went wrong', response: null };
    }
    return { error: 'something went wrong', response: null };
  };

  useEffect(() => {
    getQuickHelp().then((res) => {
      if (res.response) {
        setData(res.response || []);
      }
    });
  }, []);

  // Split the pathname and get the filter value
  const pathNameParts = window.location.pathname.split('/');
  const filterBy =
    pathNameParts.length > 2 ? pathNameParts[2] : 'template-cardlist';

  // Memoize quickHelpContent for optimization
  const quickHelpContent = useMemo(() => {
    if (filterBy && Array.isArray(data) && data.length > 0) {
      const filterVal = data.find(
        (x) => x?.applocation?.toLowerCase() === filterBy.toLowerCase()
      );
      return filterVal?.content || defaultHelpContent;
    }
    return defaultHelpContent;
  }, [filterBy, data]);

  if (loading) return null;

  return (
    <div
      className="notesForDark"
      dangerouslySetInnerHTML={{ __html: quickHelpContent }}
    />
  );
};

export default QuickHelp;

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import RestrictionsCustomDrawer from '../RestrictionsCustomDrawer';
import { DATE_VALIDATION_LABELS } from '../../../../../utils/enums/labels';
import DateAddRuleAbsolute from './DateAddRuleAbsolute';
import DateAddRuleRelative from './DateRuleRelative';
import { FUNCTION_TYPES, RULE_TYPE_LABELS, RULE_TYPES, TIMEFRAME_TYPES } from './utils/dateRestrictions.constants';
import { getRuleObject } from './utils/dateRestrictions.helper';

const {
  ADD_RULE_MODAL_TITLE,
  EDIT_RULE_MODAL_TITLE,
  ADD_RULE_BUTTON,
  SAVE_RULE_BUTTON,
  CANCEL,
} = DATE_VALIDATION_LABELS;

const DateAddRuleDrawer = ({
  rule,
  isEditMode,
  open,
  onModalClose,
  onSaveRule,
  allowTime,
  templateFields,
}) => {
  const [currentRule, setCurrentRule] = useState(rule || getRuleObject());
  const {
    type,
    start,
    end,
    offset,
    timeframe,
    reference,
    functionName,
  } = currentRule;

  const onChangeType = (value) => {
    setCurrentRule({
      ...currentRule,
      type: value,
    });
  };

  const onChangeAbsoluteRule = (value) => {
    const {
      start: startDate,
      end: endDate
    } = value;

    setCurrentRule({
      ...currentRule,
      start: startDate,
      end: endDate,
    });
  };

  const onChangeRelativeRule = (value) => {
    const {
      timeframe: timeframeValue,
      offset: offsetValue,
      reference: referenceValue,
      functionName: functionNameValue,
    } = value;
    setCurrentRule({
      ...currentRule,
      timeframe: timeframeValue,
      offset: offsetValue,
      reference: referenceValue,
      functionName: functionNameValue,
    });
  };

  return (
    <RestrictionsCustomDrawer
      open={open}
      onCancelOrClose={onModalClose}
      onSave={() => {
        // cleanup
        if (currentRule.type === RULE_TYPES.ABSOLUTE) {
          delete currentRule.offset;
          delete currentRule.timeframe;
          delete currentRule.reference;
          delete currentRule.functionName;
        } else {
          delete currentRule.start;
          delete currentRule.end;
        }

        // validate
        onSaveRule(currentRule);
      }}
      title={isEditMode ? EDIT_RULE_MODAL_TITLE : ADD_RULE_MODAL_TITLE}
      closeLabel={CANCEL}
      saveLabel={isEditMode ? SAVE_RULE_BUTTON : ADD_RULE_BUTTON}
      errorMessage=""
    >
      <RadioGroup onChange={onChangeType} value={type}>
        <Stack direction="row">
          <Radio value={RULE_TYPES.RELATIVE}>{RULE_TYPE_LABELS[RULE_TYPES.RELATIVE]}</Radio>
          <Radio value={RULE_TYPES.ABSOLUTE}>{RULE_TYPE_LABELS[RULE_TYPES.ABSOLUTE]}</Radio>
        </Stack>
      </RadioGroup>
      {
        type === RULE_TYPES.ABSOLUTE && (
          <DateAddRuleAbsolute
            start={start}
            end={end}
            onChangeAbsoluteRule={onChangeAbsoluteRule}
            allowTime={allowTime}
          />
        )
      }
      {
        type === RULE_TYPES.RELATIVE && (
          <DateAddRuleRelative
            offset={offset}
            timeframe={timeframe}
            reference={reference}
            functionName={functionName}
            onChangeRelativeRule={onChangeRelativeRule}
            templateFields={templateFields}
          />
        )
      }
    </RestrictionsCustomDrawer>

  );
};

DateAddRuleDrawer.propTypes = {
  rule: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      RULE_TYPES.RELATIVE,
      RULE_TYPES.ABSOLUTE
    ]).isRequired,
    offset: PropTypes.number,
    timeframe: PropTypes.oneOf([
      TIMEFRAME_TYPES.LESS_THAN,
      TIMEFRAME_TYPES.GREATER_THAN,
      TIMEFRAME_TYPES.RANGE
    ]),
    reference: PropTypes.string,
    functionName: PropTypes.oneOf([
      FUNCTION_TYPES.CURRENT,
      FUNCTION_TYPES.START_OF_DAY,
      FUNCTION_TYPES.END_OF_DAY,
      FUNCTION_TYPES.START_OF_WEEK,
      FUNCTION_TYPES.END_OF_WEEK,
      FUNCTION_TYPES.START_OF_MONTH,
      FUNCTION_TYPES.END_OF_MONTH,
      FUNCTION_TYPES.START_OF_YEAR,
      FUNCTION_TYPES.END_OF_YEAR,
    ]),
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }),
  isEditMode: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSaveRule: PropTypes.func.isRequired,
  templateFields: PropTypes.array.isRequired,
  allowTime: PropTypes.bool,
};

DateAddRuleDrawer.defaultProps = {
  rule: null,
  allowTime: false,
};

export default DateAddRuleDrawer;

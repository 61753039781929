import axios from 'axios';

export function isValidImageUrl(url) {
  const regexhttps = /^https?:\/\//i;
  if (regexhttps.test(url)) {
    const regex = /\.(jpg|jpeg|png|gif|bmp|svg|ico|webp|tif|tiff)$/i;
    return regex.test(url);
  }
  return false;
}
export function isValidVideoUrl(url) {
  const regexhttps = /^https:\/\//i;
  if (regexhttps.test(url)) {
    const regex = /^(https?\:\/\/)?(www\.)?.+\.(mp4|avi|mov|wmv|flv|mpg|mpeg|mkv|webm)$/;
    return regex.test(url);
  }
  return false;
}

export const uploadImageGetUrl = async (caseId, fieldId, data) => {
  try {
    const response = await axios.post(`http://localhost:3004/ticket/${caseId}/attachments`, data);
    return response?.data;
  } catch (error) {
    const message =
			(error.response && error.response.data && error.response.data.message) ||
			error.message ||
			error.toString();
    return message;
  }
};
export const convertDocToString = (inputArray) =>
  inputArray.map((item) => {
    if (item.doc && item.doc.type === 'Buffer' && Array.isArray(item.doc.data)) {
      const uint8Array = new Uint8Array(item.doc.data);
      const stringBuffer = String.fromCharCode.apply(null, uint8Array);
      item.doc = stringBuffer;
    }
    return item;
  });
export const addedUrlToIdentifier = (obj) => {
  const copyObj = { ...obj };
  if (obj.filename && obj.mediaType && obj.identifier) {
    copyObj.identifier = `https://nonprodcdn.blob.core.windows.net/${obj.identifier}`;
  }
  return copyObj;
};

export const getParentClassList = (event) => {
  const parentElement = event.target.parentNode;

  if (parentElement) {
    const parentClassList = parentElement.classList;
    if (
      parentClassList &&
			parentClassList.length > 0 &&
			parentClassList?.value?.toLowerCase() === 'ql-editor'
    ) {
      return true;
    }
    const secondParent = parentElement.parentNode;
    const upperParentClassList = secondParent.classList;
    if (
      upperParentClassList &&
			upperParentClassList?.length > 0 &&
			upperParentClassList?.value.toLowerCase() === 'ql-editor'
    ) {
      return true;
    }
    return false;
  }
  return false;
};

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
// import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
// import { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ArrowBackIcon, CloseIcon, ViewIcon } from '@chakra-ui/icons';
import { isEmpty, filter, cloneDeep, get, truncate } from 'lodash';
import DragAndResizeLayout from './DragAndResizeLayout';
import AddEditTemplateLayoutModal from './AddEditTemplateLayoutModal';
import AddEditLayoutSectionModal from './AddEditLayoutSectionModal';
import TemplateDeleteModal from '../TemplateEditor/TemplateDeleteModal';
import {
  addOrEditTemplateLayoutApi,
  getAllLayoutApi,
  isEmtpty,
  removePropertiesForLayout,
} from '../utils/helper';
import { LABELS } from '../../../utils/enums/labels';
import PreviewLayout from './PreviewLayout';
import SpecialFieldsModal from './SpecialFieldsModal';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { checkForV2 } from '../../../utils/helper';
import { LAYOUT_COLUMN, checkForEditedFields, specialFieldsDeferred  } from './utils/specialFieldsJson';
import { layoutViewTypes } from '../../../utils/enums/selectOption';
import SpecialFieldsTransitionModal from './SpecialFieldTransitionModal';
import SpecialFieldsInboxModal from './SecialFieldInboxModal';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import CustomToast from '../../../components/Notification/Notification';
import SpecialFieldsDeferred from './SpecialFieldDeferred';

const { addToast } = CustomToast();
const ConfigureTemplateViewContainer = () => {
  const [modalState, setModalState] = useState({
    show: false,
    rowData: null,
  });
  const { id } = useParams();
  const [isOpenLayoutModal, setIsOpenLayoutModal] = useState(false);
  const [isOpenLayoutEditModal, setIsOpenLayoutEditModal] = useState(false);
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [openSpecialFieldsModal, setOpenSpecialFieldsModal] = useState(false);
  const [allLayouts, setAllLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState({});
  const [itemList, setItemList] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const location = useLocation();
  const { name } = queryString.parse(location.search);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allFields, setAllFields] = useState([]);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const disabledBtn = isEmtpty(selectedLayout);

  const getAllLayouts = async (val) => {
    setLoader(true);
    const response = await getAllLayoutApi(val);
    const { fields = [], layouts = [] } = response;
    setAllFields(fields);
    setAllLayouts(layouts || []);
    setLoader(false);
  };

  useEffect(() => {
    getAllLayouts(id);
    return () => {
      // setSelectedLayout({});
      // setItemList([]);
    };
  }, [selectedTemplate]);

  // const copyLayout = async () => {
  //   delete selectedLayout.label;
  //   delete selectedLayout.value;
  //   const copiedLayout = {
  //     ...selectedLayout,
  //     name: `${selectedLayout?.name}-copy`,
  //     id: uuidv4(),
  //   };
  //   const res = await addOrEditTemplateLayoutApi({
  //     id,
  //     data: [...allLayouts, copiedLayout],
  //     defaultErrorMessage: 'Failed to clone layout',
  //     successMessage: 'Successfully cloned layout',
  //   });

  //   if (res.error) {
  //     return;
  //   }

  //   dispatch(
  //     updateViewState({
  //       stateKey: 'selectedTemplate',
  //       value: res?.response?.data,
  //     })
  //   );
  //   const data = {
  //     ...copiedLayout,
  //     label: copiedLayout?.name,
  //     value: copiedLayout?.id,
  //   };
  //   setAllLayouts([data, ...allLayouts]);
  //   setSelectedLayout(data);
  // };

  const deleteLayout = async () => {
    const isSelectedLayoutUsed = [
      ...(selectedTemplate.view_layout_map || []),
    ].some((item) => item.layout_id === selectedLayout?.id);
    if (isSelectedLayoutUsed) {
      addToast({
        title: '',
        message: 'The layout is already linked to a view',
        type: 'error',
      });
      return;
    }
    delete selectedLayout.label;
    delete selectedLayout.value;
    const updatedList = allLayouts?.filter((i) => i?.id !== selectedLayout?.id);

    let newSelectedObj = {
      ...updatedList[0],
      label: updatedList[0]?.name,
      value: updatedList[0]?.id,
    };
    if (updatedList?.length === 0) {
      newSelectedObj = {};
    }
    const response = await addOrEditTemplateLayoutApi({
      id,
      data: {
        path: 'layouts',
        operation: 'delete',
        id: selectedLayout?.id,
      },
      defaultErrorMessage: 'Failed to delete layout',
      successMessage: 'Successfully deleted layout',
    });

    if (response.error) {
      return;
    }
    setAllLayouts(updatedList);
    setSelectedLayout(newSelectedObj);
    setItemList(newSelectedObj?.schema || []);
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: response?.response?.data,
      })
    );
  };

  const addSectionInLayout = (val) => {
    const duplicate = itemList?.some(
      (ele) => ele?.sectionName?.toLowerCase() === val?.name?.toLowerCase()
    );
    if (duplicate) {
      addToast({
        title: '',
        message: `Section "${val.name}" already exists`,
        type: 'error',
      });
      return;
    }
    const sec = {
      id: `${val.name}-id`,
      type: 'component',
      sectionName: val?.name,
      sectionType: val?.type,
      hideSectionLabel: val?.hideSectionLabel,
      width: val?.width || 12,
      subItems: [],
      permissions: val?.permissions,
    };

    setItemList([...itemList, sec]);
    setOpenSectionModal(false);
  };

  const hardCodedSections = [
    {
      id: `otherInfo-id`,
      type: 'component',
      sectionName: 'Other Info',
      sectionType: 'Plain',
      hideSectionLabel: false,
      width: 12,
      subItems: [],
    },
    {
      id: `general-info-id`,
      type: 'component',
      sectionName: 'General Info',
      sectionType: 'Plain',
      hideSectionLabel: false,
      width: 12,
      subItems: [],
    },
    {
      id: `Status-id`,
      type: 'component',
      sectionName: 'Status',
      sectionType: 'Plain',
      hideSectionLabel: false,
      width: 12,
      subItems: [],
    },
  ];

  const addSpecialFieldsInLayout = (val) => {
    setItemList([...itemList, ...val]);
  };

  const generateSchema = (layoutType, viewType) => {
    if (viewType === 'INBOX') {
      const staticFields = [
        {
          id: 'ticket-num',
          label: 'Ticket Number',
          width: 6,
          height: 1,
          isStatic: true,
          type: 'SF_TICKETNUMBER',
        },
        {
          id: 'date',
          label: 'Created at',
          width: 6,
          height: 1,
          isStatic: true,
          type: 'SF_CREATEDAT',
        },
      ];

      const inbox = allFields?.length
        ? [
            ...staticFields,
            ...allFields
              ?.filter(
                (field) => field.type !== 'MEDIA' || field.type !== 'RICH_TEXT'
              )
              ?.map((i) => {
                delete i.type_based_attributes;
                delete i.permissions;
                delete i.tenant_id;
                return {
                  ...i,
                  width: 6,
                  height: 1,
                  isBadge: i.type !== 'TEXT' || i.type !== 'DATE',
                };
              }),
          ]
        : [...staticFields];
      return inbox;
    }
    if (viewType === layoutViewTypes.GRID) {
      return allFields?.length
        ? filter(
            allFields,
            (field) => field.scope === 'TICKET' || isEmpty(field.scope)
          )?.map((i) => {
            delete i.type_based_attributes;
            delete i.permissions;
            delete i.tenant_id;

            return {
              ...i,
              width: layoutType === 'MOBILE' || viewType === 'TABLE' ? 12 : 6,
              height: 1,
            };
          })
        : [];
    }
    if (viewType === layoutViewTypes.TRANSITION) {
      return allFields?.length
        ? filter(allFields, (field) => field.scope === 'WORKFLOW')?.map(
            (i) => ({
              ...i,
              width: layoutType === 'MOBILE' || viewType === 'TABLE' ? 12 : 6,
              height: 1,
            })
          )
        : [];
    }
    if (viewType === layoutViewTypes.DEFERRED) {
      return specialFieldsDeferred
    }
    return allFields?.length
      ? allFields?.map((i) => {
          delete i.type_based_attributes;
          delete i.permissions;
          delete i.tenant_id;
          return {
            ...i,
            width: layoutType === 'MOBILE' || viewType === 'TABLE' ? 12 : 6,
            height: 1,
          };
        })
      : [];
  };

  const showModal = useCallback(({ rowData }) => {
    setModalState({
      show: true,
      rowData,
      data:{}
    });
    setItemList([]);
    setSelectedLayout(rowData);
    if (rowData?.viewType === layoutViewTypes.INBOX) {
      setItemList(
        checkForEditedFields(
          filter(
            rowData?.schema,
            (field) => field.type !== 'MEDIA' && field.type !== 'RICH_TEXT'
          ),
          selectedTemplate
        ) || []
      );
    } else {
      setItemList(
        checkForEditedFields(rowData?.schema, selectedTemplate) || []
      );
    }
  }, []);

  const addOrEditLayout = async (val) => {
    const data = {
      ...val,
    };
    let response;

    if (isOpenLayoutEditModal) {
      const updatedList = allLayouts?.map((i) => {
        if (i.id === data?.id) {
          delete data.value;
          delete data.label;
          i = { ...data };
          return i;
        }
        return i;
      });
      delete data.value;
      delete data.label;
      response = await addOrEditTemplateLayoutApi({
        id,
        data: {
          path: 'layouts',
          operation: 'update',
          id: data.id,
          data: { ...data },
        },
        successMessage: 'Successfully updated layout',
        defaultErrorMessage: 'Failed to update layout',
      });
      if (response.error) {
        return;
      }
      setAllLayouts(updatedList);
      setSelectedLayout({ ...data, value: data?.id, label: data?.name });
      // setModalState(() => ({ rowData: null, show: false }));
    } else {
      const newObj = {
        ...val,
        id: uuidv4(),
        schema: checkForV2()
          ? generateSchema(val?.type, val?.viewType)
          : [...generateSchema(val?.type), ...hardCodedSections],
      };
      response = await addOrEditTemplateLayoutApi({
        id,
        data: {
          path: 'layouts',
          operation: 'create',
          data: {
            ...newObj,
          },
        },
        successMessage: 'Successfully added a new layout',
        defaultErrorMessage: 'Failed to add a new layout',
      });
      if (response.error) {
        return;
      }

      setAllLayouts([newObj, ...allLayouts]);
      setSelectedLayout({
        ...newObj,
        value: newObj?.id,
        label: newObj?.name,
      });
      setItemList(newObj?.schema);
      showModal({ rowData: { ...newObj, value: newObj?.id } });
    }
    setIsOpenLayoutEditModal(false);
    setIsOpenLayoutModal(false);
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: response?.response?.data,
      })
    );
  };

  const saveLayout = async () => {
    setLoader(true);
    const dataItemList = removePropertiesForLayout(itemList, [
      'permissions',
      'type_based_attributes',
      'tenant_id',
    ]);
    const data = { ...selectedLayout, schema: dataItemList };
    const updatedList = allLayouts?.map((i) => {
      if (i.id === data?.id) {
        delete data.value;
        delete data.label;
        i = { ...data };
        return i;
      }
      return i;
    });
    delete data.value;
    delete data.label;
    const response = await addOrEditTemplateLayoutApi({
      id,
      data: {
        path: 'layouts',
        operation: 'update',
        id: data.id,
        data: { ...data },
      },
      defaultErrorMessage: 'Failed to save layout',
      successMessage: 'Layout saved sucessfully',
    });

    if (response.error) {
      setLoader(false);
      return;
    }
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: response?.response?.data,
      })
    );
    setAllLayouts(updatedList);
    setLoader(false);
    setModalState(() => ({ rowData: null, data: {}, show: false }));
  };

  const options = allLayouts?.map((i) => ({
    ...i,
    value: i.id,
    label: i.name,
  }));

  const genericCopy = async (value) => {
    const communicationList = cloneDeep(selectedTemplate?.layouts || []);
    const rowIndex = communicationList.findIndex((e) => e.id === value.id);
    const newName = get(communicationList, `[${rowIndex}].name`, '');
    const toastSuccessMsg = `Failed to clone layout`;
    const toastFailedMsg = `Successfully cloned the layout`;
    dispatch(setActiveModal({ targetModalId: 'crudTemplateCloneModal' }));
    dispatch(
      updateViewState({
        stateKey: 'crudTemplateCloneModal',
        value: {
          id: selectedTemplate.id,
          patchData: {
            data: value,
            toastSuccessMsg,
            toastFailedMsg,
          },
          title: newName,
          masterType: `layout`,
          listKey: 'selectedTemplate',
          targetUrl: `/templates/updateWithPath?$filter=id eq ${selectedTemplate.id}`,
          method: 'patch',
        },
      })
    );
  };

  const onClone = (rowData) => {
    genericCopy(rowData);
  };
  const onDelete = (rowData) => {
    setSelectedLayout(rowData);
    setIsDeleteModal(true);
  };

  return (
    <Box mt="15px" id="layout-tab-box">
      {!modalState.show && (
        <TableViewWithSearch
          placeholder="Search for layout"
          buttonText="Create new layout"
          isTableLoading={loader}
          buttonVariant="solid"
          onCreateNew={() => setIsOpenLayoutModal(true)}
          columns={LAYOUT_COLUMN(showModal, onClone, onDelete)}
          tableData={options}
          title="Layouts"
        />
      )}
      {modalState.show && (
        <Box
          width="100%"
          flex="1"
          height="calc(100vh - 15.5rem)"
          overflow="auto"
          data-testid="ConfigureTemplateViewContainerBox"
        >
          <Box
            display="block"
            width="100%"
            flex="1"
            data-testid="ConfigureTemplateViewContainerInnerBox"
          >
            <Flex
              pt="10px"
              pb="10px"
              flex="1"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              justifyContent="space-between"
              flexWrap="wrap"
              data-testid="ConfigureTemplateViewContainerInnerFlex"
            >
              <Box>
              <Flex
                role="group"
                flex="1"
                data-testid="ConfigureTemplateViewContainerConfigureFlex"
              >
                <div
                  className="flex items-center gap-[5px]"
                  data-testid="DynamicRendererEditContainerIcon"
                >
                  <ArrowBackIcon
                    className="cursor-pointer border border-[#E2E8F0] p-1rounded-[5px]"
                    h="32px"
                    w="32px"
                    onClick={() => {
                      setModalState(() => ({ rowData:null, data: {}, isShow: false, show: false }));
                      setPreviewMode(false);
                    }} 
                  />
                  <p
                    className="font-semibold text-[16px]"
                    data-testid="DynamicRendererEditEventSettingsTextP"
                  >
                Configure Layout
                  </p>
                </div>
              </Flex>
              <p className="font-semibold text-[14px]" style={{ paddingLeft: '38px', maxWidth: '300px' }}>
                  {`${truncate(selectedLayout?.name, { length: 50, omission: '...' })} (${selectedLayout?.viewType})`}
              </p>
              </Box>
              <Box className="flex gap-3" data-testid="ConfigureTemplateViewContainerPrevBox">
                {!previewMode && !isEmpty(selectedLayout.value) ? (
                  <Flex gap={3}>
                    <SecondaryCustomButton
                      id="edit-template-section-buttonAddbtn"
                      onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        !disabledBtn && setIsOpenLayoutEditModal(true);
                      }}
                      buttonText="Edit Layout"
                      isDisabled={isEmpty(selectedLayout)}
                    />
                    {checkForV2() && (
                      <SecondaryCustomButton
                        id="edit-template-section-buttonAddbtn"
                        onClick={() => {
                          setOpenSpecialFieldsModal(true);
                        }}
                        buttonText="Add Special Fields"
                        isDisabled={disabledBtn}
                      />
                    )}
                    {checkForV2() &&
                      (selectedLayout?.viewType === layoutViewTypes.GRID ||
                        !selectedLayout?.viewType) && (
                        <SecondaryCustomButton
                          id="edit-template-section-buttonCreateBtn"
                          onClick={() => {
                            setOpenSectionModal(true);
                          }}
                          buttonText="Create New Section"
                          isDisabled={disabledBtn}
                        />
                      )}
                  </Flex>
                ) : null}
                {!previewMode ? (
                  <SecondaryCustomButton
                    leftIcon={<ViewIcon />}
                    id="viewjson-edit-templateCBtn"
                    onClick={() => {
                      setPreviewMode(true);
                    }}
                    buttonText="Preview"
                    isDisabled={disabledBtn}
                  />
                ) : (
                  <SecondaryCustomButton
                    leftIcon={<CloseIcon />}
                    id="viewjson-preview-templateCBtn"
                    onClick={() => {
                      setPreviewMode(false);
                    }}
                    buttonText="Exit Preview"
                  />
                )}
                {!previewMode && (
                  <PrimaryCustomButton
                    id="button-edit-template-saveTextBtn"
                    onClick={saveLayout}
                    buttonText="Save"
                    isDisabled={disabledBtn}
                    isLoading={loader}
                  />
                )}
              </Box>
            </Flex>
            {/* 2nd Row */}
            <Flex
              flex="1"
              // p="4"
              // mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              justifyContent="space-between"
              flexWrap="wrap"
              data-testid="ConfigureTemplateViewContainerFlex"
            >
              <Flex
                role="group"
                flex="1"
                alignItems="center"
                data-testid="ConfigureTemplateViewContainerIneerFlex"
              />
            </Flex>
          </Box>
          {/* Resize code  */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            data-testid="ConfigureTemplateViewContainerDragResizeBox"
          >
            {previewMode ? (
              <PreviewLayout
                items={itemList}
                layoutType={selectedLayout?.type}
                viewType={selectedLayout?.viewType || layoutViewTypes.GRID}
              />
            ) : (
              !disabledBtn && (
                <DragAndResizeLayout
                  itemsStateList={itemList}
                  setItemsStateList={setItemList}
                  layoutType={selectedLayout?.type}
                  viewType={selectedLayout?.viewType || layoutViewTypes.GRID}
                  key={selectedLayout?.id}
                />
              )
            )}
          </Box>
          {isOpenLayoutModal && (
            <AddEditTemplateLayoutModal
              setIsOpenLayoutModal={setIsOpenLayoutModal}
              isOpenLayoutModal={isOpenLayoutModal}
              onConfirmation={(val) => addOrEditLayout(val)}
              onCancel={() => setIsOpenLayoutModal(false)}
              channels={selectedTemplate?.properties?.channels || []}
            />
          )}
          {isOpenLayoutEditModal && (
            <AddEditTemplateLayoutModal
              setIsOpenLayoutModal={setIsOpenLayoutEditModal}
              isOpenLayoutModal={isOpenLayoutEditModal}
              onConfirmation={(val) => addOrEditLayout(val)}
              onCancel={() => setIsOpenLayoutEditModal(false)}
              isEdit
              layoutData={selectedLayout}
              channels={selectedTemplate?.properties?.channels || []}
            />
          )}
          {openSectionModal && (
            <AddEditLayoutSectionModal
              isOpen={openSectionModal}
              setIsOpen={setOpenSectionModal}
              isEdit={false}
              sectionData={{}}
              onCancel={() => setOpenSectionModal(false)}
              onConfirmation={(val) => {
                addSectionInLayout(val);
              }}
              selectedTemplateId={selectedTemplate.id}
            />
          )}
          {((openSpecialFieldsModal &&
            selectedLayout?.viewType === layoutViewTypes.GRID) ||
            !selectedLayout?.viewType) && (
            <SpecialFieldsModal
              isOpen={openSpecialFieldsModal}
              setIsOpen={setOpenSpecialFieldsModal}
              templateName={name}
              templateChannels={selectedTemplate?.properties?.channels || []}
              isEdit={false}
              layoutData={selectedLayout}
              itemList={itemList}
              onCancel={() => setOpenSpecialFieldsModal(false)}
              onConfirmation={(val) => {
                addSpecialFieldsInLayout(val);
              }}
            />
          )}

          {openSpecialFieldsModal &&
            selectedLayout?.viewType === layoutViewTypes.TRANSITION && (
              <SpecialFieldsTransitionModal
                isOpen={openSpecialFieldsModal}
                setIsOpen={setOpenSpecialFieldsModal}
                templateName={name}
                isEdit={false}
                layoutData={selectedLayout}
                itemList={itemList}
                onCancel={() => setOpenSpecialFieldsModal(false)}
                onConfirmation={(val) => {
                  addSpecialFieldsInLayout(val);
                }}
              />
            )}

          {openSpecialFieldsModal &&
            selectedLayout?.viewType === layoutViewTypes.DEFERRED && (
              <SpecialFieldsDeferred
                isOpen={openSpecialFieldsModal}
                setIsOpen={setOpenSpecialFieldsModal}
                templateName={name}
                isEdit={false}
                layoutData={selectedLayout}
                itemList={itemList}
                onCancel={() => setOpenSpecialFieldsModal(false)}
                onConfirmation={(val) => {
                  addSpecialFieldsInLayout(val);
                }}
              />
            )}  

          {openSpecialFieldsModal &&
            (selectedLayout?.viewType === layoutViewTypes.INBOX ||
              selectedLayout?.viewType === 'LIST') && (
              <SpecialFieldsInboxModal
                isOpen={openSpecialFieldsModal}
                setIsOpen={setOpenSpecialFieldsModal}
                templateName={name}
                isEdit={false}
                layoutData={selectedLayout}
                itemList={itemList}
                onCancel={() => setOpenSpecialFieldsModal(false)}
                onConfirmation={(val) => {
                  addSpecialFieldsInLayout(val);
                }}
              />
            )}
        </Box>
      )}
      {isOpenLayoutModal && (
        <AddEditTemplateLayoutModal
          setIsOpenLayoutModal={setIsOpenLayoutModal}
          isOpenLayoutModal={isOpenLayoutModal}
          onConfirmation={(val) => addOrEditLayout(val)}
          onCancel={() => setIsOpenLayoutModal(false)}
          channels={selectedTemplate?.properties?.channels || []}
        />
      )}
      {isDeleteModal && (
        <TemplateDeleteModal
          isDeleteModal={isDeleteModal}
          setIsDeleteModal={setIsDeleteModal}
          templateName={selectedLayout?.label}
          onConfirmation={() => {
            deleteLayout();
            setIsDeleteModal(false);
          }}
          titelText={LABELS.TITLE.DELETE_TEMPLATE_LAYOUT}
        />
      )}
    </Box>
  );
};

export default ConfigureTemplateViewContainer;

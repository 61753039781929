/* eslint-disable react/prop-types */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Td, Tr } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { fieldValueOption } from './relation.helper';
import { FieldTypes } from '../../../utils/enums/types';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomButton from '../../../components/Button/SimpleButton';
import { COLORS } from '../../../utils/enums/colors';
import { removeMatchingOption } from './relation.service';

const ShowValueMapForDropdownField = ({
  data,
  leftTemplate,
  rightTemplate,
  addValueMapInSimilarities,
  similarityIndex,
  handleChangeForSelectBox,
  details,
  deleteMapValueFromSimilarity,
  hierarchies,
}) => {
  const [leftFieldOption, setLeftFieldOption] = useState([]);
  const [rightFieldOption, setRightFieldOption] = useState([]);
  const [leftFieldType, setLeftFieldType] = useState('');
  const [rightFieldType, setRightFieldType] = useState('');

  useEffect(() => {
    if (!isEmpty(leftTemplate)) {
      const type =
        [...(leftTemplate?.fields || [])]?.find(
          (f) => f?.id === data.fieldA.value
        )?.type || '';
      const workFlowStates = (leftTemplate?.workflow?.workflow_status || [])
        .filter((state) => state.id !== 'start_1')
        .map((state) => ({ label: state.data.label, value: state.id }));

      const workflowData = {
        workFlowStates,
      };
      fieldValueOption(leftTemplate, workflowData, data.fieldA.value, hierarchies).then(
        (options) => {
          if (isEmpty(options)) setLeftFieldOption([]);
          else setLeftFieldOption(options);
        }
      );
      setLeftFieldType(type);
    }
    if (!isEmpty(rightTemplate)) {
      const type =
        [...(rightTemplate?.fields || [])]?.find(
          (f) => f?.id === data.fieldB.value
        )?.type || '';
      const workFlowStates = (rightTemplate?.workflow?.workflow_status || [])
        .filter((state) => state.id !== 'start_1')
        .map((state) => ({ label: state.data.label, value: state.id }));

      const workflowData = {
        workFlowStates,
      };
      fieldValueOption(rightTemplate, workflowData, data.fieldB.value, hierarchies).then(
        (options) => {
          if (isEmpty(options)) setRightFieldOption([]);
          else setRightFieldOption(options);
        }
      );
      setRightFieldType(type);
    }
  }, [leftTemplate, rightTemplate]);
  return (
    leftFieldType === FieldTypes.DROPDOWN &&
    rightFieldType === FieldTypes.DROPDOWN && (
      <>
        <Tr>
          <Td style={{ border: 'none' }}>
            <CustomSelectBox
              options={removeMatchingOption(leftFieldOption, [
                ...(data.mappings || []),
              ])}
              onChange={(e) => {
                handleChangeForSelectBox(`valueA-${similarityIndex}`, e);
              }}
              value={details[`valueA-${similarityIndex}`] || null}
              placeholder="Select value"
            />
          </Td>
          <Td style={{ border: 'none' }}>
            <CustomSelectBox
              options={rightFieldOption}
              onChange={(e) => {
                handleChangeForSelectBox(`valueB-${similarityIndex}`, e);
              }}
              value={details[`valueB-${similarityIndex}`] || null}
              placeholder="Select value"
            />
          </Td>
          <Td style={{ border: 'none' }}>
            <CustomButton
              // className={styles.addButton2}
              borderColor="#2563EB"
              colorScheme="blue"
              buttonText="Add"
              variant="outline"
              color={COLORS.LIGHT}
              onClick={() => {
                addValueMapInSimilarities(similarityIndex);
              }}
            />
          </Td>
        </Tr>
        {[...(data.mappings || [])].map((item, indexValue) => (
          <Tr>
            <Td
              style={
                data.mappings.length - 1 === indexValue
                  ? {}
                  : { border: 'none' }
              }
            >
              {item.left.label}
            </Td>
            <Td
              style={
                data.mappings.length - 1 === indexValue
                  ? {}
                  : { border: 'none' }
              }
            >
              ~
            </Td>
            <Td
              style={
                data.mappings.length - 1 === indexValue
                  ? {}
                  : { border: 'none' }
              }
            >
              {item.right.label}
            </Td>
            <Td
              style={
                data.mappings.length - 1 === indexValue
                  ? {}
                  : { border: 'none', width: '60px' }
              }
            >
              <DeleteIcon
                onClick={() => {
                  deleteMapValueFromSimilarity(similarityIndex, indexValue);
                }}
                color="red"
              />
            </Td>
          </Tr>
        ))}
      </>
    )
  );
};
export default ShowValueMapForDropdownField;

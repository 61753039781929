/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, SimpleGrid } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { FormControl, FormErrorMessage, FormLabel, Progress, Flex } from '@chakra-ui/react';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomInput from '../../../components/InputBox/Input';
import styles from '../styles/templatebuilder.module.scss';
import { DARK, LIGHT, BLACK, WHITE } from '../../../utils/enums/colors';
import SelectComponent from '../../../components/SelectBox/SelectComponent';
import {
  fieldColSpanOptions,
  sectionTypeOptions,
} from '../../../utils/enums/selectOption';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import { FIELD_PROPERTY_LABELS, LABELS } from '../../../utils/enums/labels';
import { CustomDrawer } from './AddEditTemplateLayoutModal';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';
import { TEMPLATE_ROLE_SETTINGS } from '../utils/constants';
import { isEmptyCondition } from '../../../utils/helper';
import { getTenantData } from '../../../helpers/tenantUrl';

const AddEditLayoutSectionModal = ({
  isOpen,
  setIsOpen,
  isEdit,
  sectionData,
  onCancel,
  onConfirmation,
  selectedTemplateId,
}) => {
  const [sectionState, setsectionState] = useState({
    name: sectionData?.sectionName || '',
    type: sectionData?.sectionType || '',
    width: sectionData?.width || '',
    hideSectionLabel: sectionData?.hideSectionLabel,
    permissions: sectionData?.permissions || {},
  });

  const [errorState, setErrorState] = useState(false);
  const onFinish = () => {
    if (!sectionState?.type) {
      setErrorState(true);
    } else {
      onConfirmation({
        id: sectionData?.id || '',
        name: sectionState?.name,
        type: sectionState?.type,
        width: sectionState?.width,
        hideSectionLabel: sectionState?.hideSectionLabel,
        permissions: sectionState?.permissions,
      });
    }
  };

  const [expLoad, setExpLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setExpLoad(false);
    }, 1500);
  }, []);

  const modalFooter = (
    <Box>
      <CustomButton
        color={useColorModeValue(BLACK, WHITE)}
        onClick={() => {
          onCancel();
        }}
        buttonText={LABELS.ACTIONS.CANCEL}
        variant="outline"
        className="mr-4"
        id="AddEditLayoutSectionModalNameCnclBtn"
      />
      <CustomButton
        color="white"
        onClick={() => {
          onFinish();
        }}
        buttonText={isEdit ? 'Update' : 'Create'}
        variant="solid"
        className={`${styles.createTemplateBtn} `}
        id="AddEditLayoutSectionModalNameEditBtn"
        bg={useColorModeValue(LIGHT, DARK)}
      />
    </Box>
  );

  return (
    <CustomDrawer
      id="delete-modal"
      modalTitle={`${isEdit ? 'Edit' : 'Create'} New Section`}
      drawerBody={
        <>
          <SimpleGrid columns={2} spacing={4} mt="4">
            <FormControl>
              <FormLabel data-testid="AddEditLayoutSectionModalNameFormLabel">
                Name
              </FormLabel>
              <CustomInput
                type="email"
                value={sectionState?.name || ''}
                onChange={(e) =>
                  setsectionState({ ...sectionState, name: e.target.value })
                }
                id="AddEditLayoutCIDivCInput"
              />
            </FormControl>
            <FormControl
              isRequired
              isInvalid={errorState}
              data-testid="AddEditLayoutSectionModalTypeFormControl"
            >
              <FormLabel>Type</FormLabel>
              <SelectComponent
                options={sectionTypeOptions}
                value={sectionState?.type}
                handleChange={(value) => {
                  setsectionState({ ...sectionState, type: value });
                  if (value) {
                    setErrorState(false);
                  }
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
              />
              {errorState && (
                <FormErrorMessage>Type is Required</FormErrorMessage>
              )}
            </FormControl>
            <FormControl data-testid="AddEditLayoutSectionModalColSpanFormControl">
              <FormLabel>ColSpan(s)</FormLabel>
              <SelectComponent
                options={fieldColSpanOptions}
                value={sectionState?.width}
                handleChange={(value) => {
                  setsectionState({ ...sectionState, width: value });
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
              />
            </FormControl>
            <FormControl data-testid="AddEditLayoutSectionModalSectionFormControl">
              <FormLabel> Section Label</FormLabel>
              <CustomCheckBox
                onChange={(e) =>
                  setsectionState({
                    ...sectionState,
                    hideSectionLabel: e.target.checked,
                  })
                }
                isChecked={sectionState?.hideSectionLabel}
                checkBoxText="Hide Label"
                isDisabled={false}
              />
            </FormControl>
          </SimpleGrid>
          <div height="20px" width="20px" />
          <Flex
                  justifyContent="flex-start"
                  marginLeft="26px"
                  marginTop="18px"
                />
                {expLoad && <Progress size="xs" isIndeterminate />}
          <ConditionsExpressions
            templateId={selectedTemplateId}
            value={sectionState?.permissions?.expression || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? {} : value;
              const updatedData = {
                [TEMPLATE_ROLE_SETTINGS.VIEWERS]: {
                  org: [getTenantData()?.tenantId],
                },
                [TEMPLATE_ROLE_SETTINGS.EDITORS]: {
                  org: [getTenantData()?.tenantId],
                },
                [TEMPLATE_ROLE_SETTINGS.EXPRESSION]: updatedValue,
              };
              setsectionState((prev) => ({
                ...prev,
                permissions: { ...prev.permissions, ...updatedData },
              }));
            }}
            label={FIELD_PROPERTY_LABELS.SHOW_ON_MATCH}
          />
          <ConditionsExpressions
            templateId={selectedTemplateId}
            value={sectionState?.permissions?.enableExpression || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? {} : value;
              const updatedData = {
                [TEMPLATE_ROLE_SETTINGS.VIEWERS]: {
                  org: [getTenantData()?.tenantId],
                },
                [TEMPLATE_ROLE_SETTINGS.EDITORS]: {
                  org: [getTenantData()?.tenantId],
                },
                [TEMPLATE_ROLE_SETTINGS.ENABLED_EXPRESSION]: updatedValue,
              };
              setsectionState((prev) => ({
                ...prev,
                permissions: { ...prev.permissions, ...updatedData },
              }));
            }}
            label={FIELD_PROPERTY_LABELS.ENABLE_ON_MATCH}
          />
          {/* <ConditionsExpressions
            templateId={selectedTemplateId}
            value={sectionState?.permissions?.requiredExpression || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? {} : value;
              const updatedData = {
                [TEMPLATE_ROLE_SETTINGS.VIEWERS]: {
                  org: [getTenantData()?.tenantId],
                },
                [TEMPLATE_ROLE_SETTINGS.EDITORS]: {
                  org: [getTenantData()?.tenantId],
                },
                [TEMPLATE_ROLE_SETTINGS.REQUIRED_EXPRESSION]: updatedValue,
              };
              setsectionState((prev) => ({
                ...prev,
                permissions: { ...prev.permissions, ...updatedData },
              }));
            }}
            label={FIELD_PROPERTY_LABELS.REQUIRED_ON_MATCH}
          /> */}
        </>
      }
      drawerFooter={modalFooter}
      openDrawer={isOpen}
      setOpenDrawer={setIsOpen}
    />
  );
};

AddEditLayoutSectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  sectionData: PropTypes.object,
  selectedTemplateId: PropTypes.string.isRequired,
};

AddEditLayoutSectionModal.defaultProps = {
  isEdit: false,
  sectionData: {},
};

export default AddEditLayoutSectionModal;

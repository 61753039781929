import Joi from 'joi';
import _capitalize from 'lodash/capitalize';

export const stringRequiredValidation = Joi.string().required().messages({
  'string.empty': 'This field is required',
  'any.required': 'This field is required.',
});
export const stringRequiredValidationMethod = (fieldName) => Joi.string().required().messages({
  'string.empty': `${fieldName} is required`,
  'any.required': `${fieldName} is required.`,
});
export const validationErrorMessage = (error) => {
  const validationErrors = {};

  // check if any one among moved_in_states and moved_out_states is present and update error array accordingly
  const errorDetail =filterMovedStatesErrors(error.details)
  errorDetail.forEach((detail) => {
    validationErrors[detail.path[0]] = detail.message;
  });
  return validationErrors;
};

// check and filter errors related to moved_in_states and moved_out_states
const filterMovedStatesErrors = (errors) => {
  const hasMovedIn = errors.some(err => err.path.includes("moved_in_states"));
  const hasMovedOut = errors.some(err => err.path.includes("moved_out_states"));

  // If both are present, no filtering is needed
  if (hasMovedIn && hasMovedOut) return errors;

  // Filter out errors if both are missing
  return errors.filter(err => !["moved_in_states", "moved_out_states"].includes(err.path[0]));
};
const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getErrorMessagesPath = (error, fieldMap = {}) => {
  const errors = [];
  error.details.forEach((e) => {
    let str = '';
    if (e.context.message) {
      str = e.context.message;
      if (e.context.key === 'user_conditions') {
        str = 'Missing user conditions';
      }
      // errors.push(str);
    } else if (e.message) {
      str = e.message;
    } else {
      str = 'Missing ';
      const reversedPaths = e.path.reverse();
      reversedPaths.forEach((p) => {
        if (typeof p === 'number') {
          str += `in ${getOrdinal(p + 1)} entry `;
        } else {
          str += `${fieldMap[p] || p} `;
        }
      });
    }
    errors.push(str);
  });

  return errors;
};

const formatErrorMessageString = (inputString, isStartAt) => {
  let transformedString = inputString
    .replace('"properties.', '')
    .replace('"', '');
  transformedString = transformedString
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (match) => match);
  if (isStartAt) {
    return _capitalize(transformedString).replace(
      'characters long',
      'running number'
    );
  }
  return _capitalize(transformedString);
};

export const validationErrorMessageForTemplate = (error) => {
  const validationErrors = {};
  error.details.forEach((detail) => {
    validationErrors[detail.context.key] = formatErrorMessageString(
      detail.message,
      detail.context.key === 'start_at'
    );
  });
  return validationErrors;
};

const LIFECYCLE_STATUS = {
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
  CANCELLED: 'CANCELLED',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING_REVIEW: 'PENDING_REVIEW',
};

const customValidationStartAt = (value, helpers) => {
  const runningNumber = helpers.state.ancestors[0].running_number;

  if (typeof runningNumber === 'number' && runningNumber > 0) {
    return value.length <= runningNumber ? value : helpers.error('string.max');
  }

  return value.length <= 10 ? value : helpers.error('string.max');
};
export const createTemplateSchema = Joi.object({
  version: Joi.string().required(),
  slug: Joi.string(),
  name: Joi.string().required(),
  lifecycle_status: Joi.string().valid(...Object.values(LIFECYCLE_STATUS)),
  is_default: Joi.boolean(),
  default_values: Joi.object(),
  tenant_id: Joi.string().guid({ version: 'uuidv4' }).required(),
  fields: Joi.array(),
  layouts: Joi.array(),
  events: Joi.object(),
  fieldsets: Joi.array(),
  workflow: Joi.object(),

  properties: Joi.object({
    runtime_version: Joi.string(),
    prefix: Joi.string().required(),
    suffix: Joi.string(),
    channels: Joi.array().required(),
    parent_id: Joi.string(),
    note_types: Joi.array().required(),

    cloned_from: Joi.string(),
    description: Joi.string(),
    permissions: Joi.object(),
    collaborators: Joi.string(),
    child_templates: Joi.array(),
    owners: Joi.alternatives().try(Joi.string(), Joi.array()),
    template_type: Joi.string(),
    running_number: Joi.number().min(1).max(10).required(),
    child_templates_list: Joi.array(),
    start_at: Joi.string()
      .allow('', null)
      .custom(customValidationStartAt)
      .trim(),
    doAutoMove: Joi.boolean(),
    diffBpmProcess4Children: Joi.boolean(),
  }),
  permissions: Joi.object(),
  metric: Joi.array(),
  slas: Joi.object(),
  actors: Joi.array(),
  view_layout_map: Joi.array(),
  communication_templates: Joi.array(),
  locales: Joi.array(),
  filters: Joi.array(),
  audience: Joi.array(),
});

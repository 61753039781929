import React from 'react';

import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Spinner,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import Upload from 'rc-upload';
import classNames from 'classnames';

import CustomButton from '../Button/SimpleButton';

import { COLORS } from '../../utils/enums/colors';

import styles from './Attachment.module.scss';
import {
  getIcon,
  getIconForMimeType,
  isImageMimeType,
} from './utils';

const AttachmentPreView = ({
  attachment,
  onDelete,
  uploadProps,
  isLoading,
  isImageLoading,
  isDarkMode,
  maxNoOfFileAllow,
}) => (
  <Box>
    <Flex gap="8px" wrap="wrap">
      <Box>
        {isImageLoading ? (
          <div className={styles.image__loader__container}>
            <Spinner size="sm" />
          </div>
        ) : (
          <Flex gap="10px">
            {attachment?.map((item) => (
              <Flex
                direction="column"
                borderRadius="4px"
                alignItems="center"
                width="100%"
                border="thin dotted gray"
                height="150px"
                padding={3}
              >
                <Box
                  width="130px"
                  position="relative"
                  height="130px"
                  mb="10px"
                  border={`1px solid ${COLORS.LIGHT_BORDER}`}
                  className="img-box"
                >
                                    <InputGroup>
                                    <InputRightElement>
                                 <CustomButton
                  colorScheme="red"
                  onClick={() => onDelete(item.blobName)}
                  buttonText=""
                  variant="outline"
                  rightIcon={<DeleteIcon />}
                  className={`${styles.emptyBtnWithIcon} attach-del-icon`}
                  isLoading={isLoading}
                />
                 </InputRightElement>
                 </InputGroup>
                  {isImageMimeType(item.fileMimeType) ? (
                    <Image src={item.fileSrc} className="!w-full !h-full" />
                  ) : (
                    <Icon
                      className="cursor-pointer"
                      as={getIcon(getIconForMimeType(item.fileMimeType))}
                      fontSize="4.5rem"
                      onClick={() => {
                        const downloadLink = document.createElement('a');
                        downloadLink.target = '_blank';
                        downloadLink.href = item.fileSrc;
                        downloadLink.download = item.blobName;
                        downloadLink.click();
                      }}
                    />
                  )}
                </Box>
                <Tooltip label={item?.blobName || ''}>
                <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" width="130px">{item?.blobName || ''}</Text>
                </Tooltip>
              </Flex>
            ))}
{' '}
          </Flex>
        )}
      </Box>

      <Flex alignItems="end">
        {attachment.length < maxNoOfFileAllow ? (
          <Upload {...uploadProps}>
            <Button
              colorScheme="blackAlpha"
              height="32px"
              isDisabled={!isLoading}
              isLoading={isLoading}
              className={classNames(styles.browse__files__button, {
                [styles.browse__files__button__dark]: isDarkMode,
              })}
            >
              Upload File
            </Button>
          </Upload>
        ) : null}
      </Flex>
    </Flex>
  </Box>
);

AttachmentPreView.propTypes = {
  attachment: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  uploadProps: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isImageLoading: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  maxNoOfFileAllow: PropTypes.number.isRequired,
};

AttachmentPreView.defaultProps = {
  isLoading: false,
  isImageLoading: false,
  isDarkMode: false,
};

export default AttachmentPreView;

import Joi from 'joi';
import { isEmpty } from 'lodash';

const nodeSchema = Joi.object({
  title: Joi.string().required().messages({
    'string.empty': 'Title is required',
    'any.required': 'Title is required',
  }),
  key: Joi.string().guid({ version: 'uuidv4' }).required(),
  position: Joi.number().default(0),
  children: Joi.array().items(Joi.link('#nodeSchema')).optional(),
  imageUrlInfo: Joi.object({
    imageUrl: Joi.string().allow('').optional(),
    blobName: Joi.string().allow('').optional()
  }).optional(),
  description: Joi.string().allow(null, '').optional(),
}).id('nodeSchema');

export const validateCreateIntentGroup = Joi.object({
  id: Joi.string().allow(''),
  intent_groups: Joi.array().items(nodeSchema).min(1).required()
    .messages({
      'array.min': 'Include at least one node',
      'any.required': 'Include at least one node',
    }),
});

const PREDEFINED_INTENT_GROUP = [
  {
    key: '0a5b17f2-7fb4-4cc2-8c8e-7d9e02ecb73d',
    title: 'Incidents',
    children: [],
    imageUrlInfo: {
      blobName: '',
      imageUrl: ''
    },
    description: '',
    position: 0
  },
  {
    key: 'e312f7fd-15cf-4dd0-ba31-4904171ab9c1',
    title: 'Requests',
    children: [],
    imageUrlInfo: {
      blobName: '',
      imageUrl: ''
    },
    description: '',
    position: 0
  },
  {
    key: '92a70d1a-d913-4a12-b575-06434e4b6b0a',
    title: 'Problems',
    children: [],
    imageUrlInfo: {
      blobName: '',
      imageUrl: ''
    },
    description: '',
    position: 0
  },
  {
    key: '6dc5b3a6-85bc-4a97-ba27-eea69dd0a49e',
    title: 'Changes',
    children: [],
    imageUrlInfo: {
      blobName: '',
      imageUrl: ''
    },
    description: '',
    position: 0
  }
];
export const emptyIntentGroup = {
  id: '',
  intent_groups: [...PREDEFINED_INTENT_GROUP],
};
export const updatedDataForIntentGroup = (data) => {
  if (isEmpty(data)) {
    return emptyIntentGroup;
  }
  const updatedTreeList = [];
  for (let i = 0; i < PREDEFINED_INTENT_GROUP.length; i++) {
    const predefinedKey = PREDEFINED_INTENT_GROUP[i].key;
    const found = [...(data?.intent_groups || [])].some((group) => group.key === predefinedKey);
    if (!found) {
      updatedTreeList.push(PREDEFINED_INTENT_GROUP[i]);
    }
  }
  return {
    id: data.id || '',
    intent_groups: [...data.intent_groups, ...updatedTreeList],
  };
};
export const PREDEFINED_INTENT_GROUP_KEY_LIST =
[...PREDEFINED_INTENT_GROUP].map((item) => item.key);

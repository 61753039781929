import axios from 'axios';
import { config } from './config';

const sessionStorageKey = 'tenant';

export const getHostName = () => config.domain;

export const getTenantInformation = async () => {
  const hostname = getHostName();
  let response;
  try {
    response = await axios.get(
      `${config.tsUrl}/tenant?${new URLSearchParams({
        domain: hostname,
        ts: Date.now(),
      })}`
    );

    localStorage.setItem(sessionStorageKey, JSON.stringify(response?.data));
  } catch (err) {
    localStorage.setItem(sessionStorageKey, JSON.stringify(null));
  }
  return response?.data;
};

export const getTenantData = () =>
  JSON.parse(localStorage.getItem(sessionStorageKey));

export const getDomain = () => {
  const tenantInformation = getTenantData();

  return `https://${tenantInformation?.domain}`;
};

export const removeTenantInformation = () => {
  localStorage.removeItem(sessionStorageKey);
};

export const getTenantConfig = async () => {
  const tenantInformation = getTenantData();
  const tenantId = tenantInformation?.id;

  if (!tenantId) {
    return {
      response: null,
      error: 'Cannot get tenantConfig',
    };
  }

  const url = `${config.tsUrl}/tenantConfig`;

  try {
    const res = await axios.get(url, {
      headers: {
        'x-tenantid': tenantId,
      },
    });

    return {
      response: res?.data,
      error: null,
    };
  } catch (err) {
    return {
      response: null,
      error: err.toString(),
    };
  }
};

export const getAgentDomain = () => {
  let baseURL = '';
  const tenantInformation = getTenantData();
  if (tenantInformation?.apiUrl && tenantInformation?.basePath) {
    baseURL = `${tenantInformation.apiUrl + tenantInformation.basePath}`;
  }
  return baseURL;
};

const getApplicationConfigBaseUrl = () => {
  const baseUrl = getAgentDomain();
  if (baseUrl) {
    return `${baseUrl}/dashboardV2`;
  }

  return '';
};

export const getTenantServiceUrl = async () => {
  const applicationConfigBaseURL = getApplicationConfigBaseUrl();

  if (applicationConfigBaseURL) {
    return applicationConfigBaseURL;
  }

  await getTenantInformation();

  return getApplicationConfigBaseUrl();
};

export const getUserInfoUrl = () => {
  const baseUrl = getAgentDomain();
  return `${baseUrl}/dashboardV2/userinfo`;
};

export const getUserInfoUsingQueue = (queueId) => {
  const baseUrl = getAgentDomain();
  return `${baseUrl}/catalog/queues/users/${queueId}`;
};

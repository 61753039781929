import React, { useState, useEffect } from 'react';
import { Input, Box, InputGroup, InputRightElement, Spinner, Text } from '@chakra-ui/react';
import { IoSearch } from 'react-icons/io5';
import CustomTable from '../../../DynamicRenderer/customTable';

const TriggersTableViewWithSearch = ({ columns, tableData, isFetching }) => {
  const [filter, setFilter] = useState(tableData);
  useEffect(() => {
    setFilter(tableData);
  }, [tableData]);
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const filteredData = tableData.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilter(filteredData.length > 0 ? filteredData : []);
  };
  // if (isFetching) {
  //   return (
  //   <Box height="calc(100vh - 15rem)" alignItems="center" flexDirection="column" display="flex" justifyContent="center">
  //       <Box className="search-section">
  //         <InputGroup w="500px">
  //           <Input placeholder="Search" onChange={handleChange} />
  //           <InputRightElement><IoSearch /></InputRightElement>
  //         </InputGroup>
  //     </Box>
  //     <Spinner size="md" />
  //     <Text>Fetching data</Text>
  //   </Box>
  //   );
  // }
  return (
    <Box className='triggers-section' >
      <Box className="search-section">
        <InputGroup w="500px">
          <Input placeholder="Search" onChange={handleChange} />
          <InputRightElement><IoSearch /></InputRightElement>
        </InputGroup>
      </Box>
      <Box mt="20px" className="triggers-table">
      <CustomTable
        height="calc(100vh - 18rem)"
        columns={columns}
        tableData={filter}
        onRowClick={() => {}}
        isFetching={isFetching}
      />
      </Box>
    </Box>
  );
};

export default TriggersTableViewWithSearch;

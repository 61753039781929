import { Box, Flex, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AddIcon } from '@chakra-ui/icons';
import { MarkerType, useStoreApi } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomButton from '../../../components/Button/SimpleButton';
import { BLACK, COLORS, WHITE } from '../../../utils/enums/colors';
import RoleBlack from '../../../assets/icons/RuleBlack.svg';
import { isTemplateApproved, mapToJsonArray } from '../helper';
import {
  addNewStateWorkFlowWithKeyValue,
  changeSelectedRuleWithKey,
  deleteEdgeFromWorkFlow,
  initSelectedRule,
  removeRuleFromSelectedWorkFlowState,
  removeStateFromWorkFlowWithKeyValue,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';

import {
  LABELS,
  ROLE_TITLE,
  RULE_MODAL_TYPE,
  WORK_FLOW_ACTION_LABEL,
} from '../../../utils/enums/labels';
import RuleCard from './RuleCard';
// eslint-disable-next-line import/no-cycle
import RestricMoveRoleModal from '../RestrictMoveRole';
import RestrictSpecificStatusModal from '../RestrictSpecificStatusModal';
import RestrictSpecificValueModal from '../RestrictSpecificValueModal';
import RuleDeleteModal from './RuleDeleteModal';
import { isEmtpty } from '../../TicketTemplateBuilder/utils/helper';
import AddNewRoleModal from '../AddNewRoleModal';
// import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import { checkForV2 } from '../../../utils/helper';
import { LifecycleStatusTypes } from '../../../utils/enums/lifecycleStatus';
import {
  checkPermissionForTemplatesWithData,
  showToastMessage,
} from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { checkIsTransitionReferenced as getTransitionReferences } from './helper';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import RestrictLinkedTicketSpecificStatusModal from '../RestrictLinkedTicketSpecificStatusModal';

const ShowEditEdge = ({ closeSideBar }) => {
  // setIsRestricMoveRoleModalOutEdit
  const [
    isRestricMoveRoleModalWithOutEdit,
    setIsRestricMoveRoleModalWithOutEdit,
  ] = useState(false);
  const [
    isRestrictSpecificStatusModalWithOutEdit,
    setIsRestrictSpecificStatusModalWithOutEdit,
  ] = useState(false);
  const [
    isRestrictSpecificValueWithOutEdit,
    setIsRestrictSpecificValueWithOutEdit,
  ] = useState(false);
  const [
    isRestricLinkedTicketSpecificStatusValue,
    setIsRestricLinkedTicketSpecificStatusValue,
  ] = useState(false);
  const [
    isRestricLinkedTicketSpecificStatusValueWithOutEdit,
    setIsRestricLinkedTicketSpecificStatusValueWithOutEdit,
  ] = useState(false);
  const [isAddRole, setIsAddRole] = useState(false);
  const [isDeleteModalForEdge, setIsDeleteModalForEdge] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isRestricMoveRoleModal, setIsRestricMoveRoleModal] = useState(false);
  const [transitionReference, setTransitionReference] = useState('');
  const [isRestrictSpecificStatusModal, setIsRestrictSpecificStatusModal] =
    useState(false);
  const [isRestrictSpecificValue, setIsRestrictSpecificValue] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorCommonFlow, setErrorCommonFlow] = useState('');
  const dispatch = useDispatch();
  const {
    selectedWorkFlowTransition,
    toTransitionState,
    fromTransitionState,
    selectedWorkFlow,
    isEdgeSave,
    selectedWorkFlowRule,
    entityRoute,
    templateLifeCycle,
  } = useSelector((state) => state.workFlowMain);
  const { selectedTemplate } = useSelector((state) => state.viewState || {});

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sourceRoute = searchParams.get('sourceRoute');

  useEffect(() => {
    setTransitionReference(
      getTransitionReferences(selectedWorkFlowTransition, selectedTemplate)
    );
  }, [selectedWorkFlowTransition, selectedTemplate]);

  const flowStore = useStoreApi();
  const { nodeInternals, edges } = flowStore.getState();
  const nodeOption = mapToJsonArray(nodeInternals)
    .filter((i) => i.id !== 'start_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
    }));
  const handleInput = (e) => {
    if (errorName) {
      setErrorName(false);
    }
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'selectedWorkFlowTransition',
        value: {
          ...selectedWorkFlowTransition,
          data: { label: e.target.value },
        },
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'isEdgeSave',
        value: true,
      })
    );
  };
  // eslint-disable-next-line no-unused-vars
  const handleAutoMove = (e) => {
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'selectedWorkFlowTransition',
        value: {
          ...selectedWorkFlowTransition,
          isAutoMoveAllowed: e.target.checked,
        },
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'isEdgeSave',
        value: true,
      })
    );
  };
  const handleSaveEdge = () => {
    /**
      avoid permission validation when we navigate from workflow-list
      only validate when it navigate form template.
   */
    if (sourceRoute !== 'workflow-list') {
      const hasAccess = checkPermissionForTemplatesWithData({
        data: selectedTemplate,
        key: 'editors',
        displayToastMessage: false,
      });
      if (hasAccess?.error) {
        showToastMessage({
          title: '',
          description: `You don't have access to edit the template`,
          status: 'error',
        });
        return;
      }
    }
    // const newEdge = innerEdges.filter((ed) => ed?.id !== selectedEdge?.id);
    // setInnerEdges(newEdge);
    dispatch(
      removeStateFromWorkFlowWithKeyValue({
        keyValue: 'workflow_transitions',
        value: selectedWorkFlowTransition?.id,
      })
    );
    // const connectionList = toTransitionState.map((item) => ({
    //   source: item.value,
    //   sourceHandle: selectedWorkFlowTransition.sourceHandle,
    //   target: selectedWorkFlowTransition.target,
    //   targetHandle: selectedWorkFlowTransition.targetHandle,
    //   markerEnd: {
    //     type: MarkerType.ArrowClosed,
    //   },
    //   type: WORK_FLOW_ACTION_LABEL.CUSTOM_EDGES,
    //   data: { label: selectedWorkFlowTransition?.data?.label },
    //   id: selectedWorkFlowTransition?.id,
    // }));
    const connectionList = toTransitionState.map((item) => {
      const newId =
        item.value === selectedWorkFlowTransition.source ||
        toTransitionState.length === 1
          ? selectedWorkFlowTransition?.id
          : uuidv4();

      return {
        source: item.value,
        sourceHandle: selectedWorkFlowTransition.sourceHandle,
        target: fromTransitionState?.value,
        targetHandle: selectedWorkFlowTransition.targetHandle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        type: WORK_FLOW_ACTION_LABEL.CUSTOM_EDGES,
        data: { label: selectedWorkFlowTransition?.data?.label },
        isAutoMoveAllowed: selectedWorkFlowTransition?.isAutoMoveAllowed,
        id: newId,
      };
    });

    dispatch(
      addNewStateWorkFlowWithKeyValue({
        keyValue: 'workflow_transitions',
        value: connectionList,
        editTranstion: true,
        dispatch,
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'selectedWorkFlowTransition',
        value: {},
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'isEdgeSave',
        value: false,
      })
    );
  };
  const handleCheckNameIsExit = () => {
    const index = edges
      .filter((item) => item.id !== selectedWorkFlowTransition.id)
      .findIndex((element) => {
        const transitionStateAlreadyExit = toTransitionState.find(
          ({ value }) => value === element.source
        );
        const isFlowAlreadyExist =
          transitionStateAlreadyExit &&
          element.target === fromTransitionState.value;
        const isNameAlreadyExist =
          element.data.label.replace(/ /g, '').toLowerCase() ===
          selectedWorkFlowTransition.data.label.replace(/ /g, '').toLowerCase();

        // set Error if flow is duplicate
        if (isFlowAlreadyExist) {
          setErrorCommonFlow(
            `${transitionStateAlreadyExit.label} to ${fromTransitionState.label} transition flow is already in use on the canvas.`
          );
        }

        // set Error if name is duplicate
        if (isNameAlreadyExist) {
          setErrorName(true);
        }

        return isNameAlreadyExist || isFlowAlreadyExist;
      });
    if (index === -1) {
      handleSaveEdge();
      closeSideBar(false);
    }
  };
  const openModalAsPerModalType = (value) => {
    if (value.ruleType === RULE_MODAL_TYPE.MOVE_ISSUE) {
      dispatch(
        updateStateOfWorkFlowWithKey({
          keyValue: 'selectedWorkFlowRule',
          value,
        })
      );
      setIsRestricMoveRoleModal(true);
    }
    if (value.ruleType === RULE_MODAL_TYPE.SPECIFIC_STATUS) {
      dispatch(
        updateStateOfWorkFlowWithKey({
          keyValue: 'selectedWorkFlowRule',
          value,
        })
      );
      setIsRestrictSpecificStatusModal(true);
    }
    if (value.ruleType === RULE_MODAL_TYPE.SPECIFIC_VALUE) {
      dispatch(
        updateStateOfWorkFlowWithKey({
          keyValue: 'selectedWorkFlowRule',
          value,
        })
      );
      setIsRestrictSpecificValue(true);
    }
    if (value.ruleType === RULE_MODAL_TYPE.RESTRICT_LINKED_TICKET) {
      dispatch(
        updateStateOfWorkFlowWithKey({
          keyValue: 'selectedWorkFlowRule',
          value,
        })
      );
      setIsRestricLinkedTicketSpecificStatusValue(true);
    }
  };
  const onDeleteBtnClickFromRuleCard = (value) => {
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'selectedWorkFlowRule',
        value,
      })
    );
    setIsDeleteModal(true);
  };
  const handleRuleModalOpen = (fromId) => {
    if (fromId === 'moveIssue') {
      setIsAddRole(false);
      dispatch(
        changeSelectedRuleWithKey({
          keyValue: 'ruleType',
          value: 'moveIssue',
        })
      );
      setIsRestricMoveRoleModalWithOutEdit(true);
    }
    if (fromId === 'specificStatus') {
      setIsAddRole(false);
      setIsRestrictSpecificStatusModalWithOutEdit(true);
    }
    if (fromId === 'specificValue') {
      setIsAddRole(false);
      dispatch(
        changeSelectedRuleWithKey({
          keyValue: 'ruleType',
          value: 'specificValue',
        })
      );
      setIsRestrictSpecificValueWithOutEdit(true);
    }
    if (fromId === RULE_MODAL_TYPE.RESTRICT_LINKED_TICKET) {
      setIsAddRole(false);
      dispatch(
        changeSelectedRuleWithKey({
          keyValue: 'ruleType',
          value: 'restrictLinkedTicket',
        })
      );
      setIsRestricLinkedTicketSpecificStatusValueWithOutEdit(true);
    }
  };
  const findTitle = (value) => {
    if (value === RULE_MODAL_TYPE.MOVE_ISSUE) {
      return ROLE_TITLE.MOVE_ISSUE;
    }
    if (value === RULE_MODAL_TYPE.SPECIFIC_STATUS) {
      return ROLE_TITLE.SPECIFIC_STATUS;
    }
    if (value === RULE_MODAL_TYPE.SPECIFIC_VALUE) {
      return ROLE_TITLE.SPECIFIC_VALUE;
    }
    if (value === RULE_MODAL_TYPE.RESTRICT_LINKED_TICKET) {
      return ROLE_TITLE.RESTRICT_LINKED_TICKET;
    }
    return '';
  };

  const filteredRules = useMemo(
    () =>
      (selectedWorkFlow?.workflow_rules?.list || []).filter(
        (item) => item.transitionId === selectedWorkFlowTransition?.id
      ),
    [selectedWorkFlowTransition, selectedWorkFlow]
  );

  const addedRules = useMemo(
    () =>
      filteredRules.reduce((prev, curr) => {
        prev[curr?.ruleType] = true;
        return prev;
      }, {}),
    [filteredRules]
  );

  const disableAddRuleButton = () => {
    if (checkForV2()) {
      return entityRoute === 'workflow-list';
    }
    return (
      entityRoute === 'workflow-list' ||
      filteredRules?.length === 2 ||
      isTemplateApproved(templateLifeCycle)
    );
  };
  const showToolTipForAddRule = () => {
    if (entityRoute === 'workflow-list') {
      return `We can't apply rule because workflow is not associated with template`;
    }
    if (isTemplateApproved(templateLifeCycle)) {
      return `Rule can't be added as it was approved`;
    }
    return '';
  };
  const showToolTipForDelete = () => {
    if (isTemplateApproved(templateLifeCycle)) {
      return `Transition can't be deleted as it was approved`;
    }

    if (transitionReference) {
      return `Transition can't be deleted as it is referenced in: ${transitionReference}`;
    }

    return '';
  };
  return (
    <Box height="100%" data-testid="ShowEditEdgeBox">
      <Box
        height="calc(100% - 70px)"
        overflowY="auto"
        data-testid="ShowEditEdgeTransBox"
      >
        <Box data-testid="ShowEditEdgeNameBox">
          <Text
            className="text-[14px]  mb-[4px] "
            data-testid="ShowEditEdgeNameText"
          >
            Name
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </Text>
          <CustomInput
            onChange={handleInput}
            value={selectedWorkFlowTransition?.data?.label}
            placeholder=""
            helperText=" "
            errorMessage="The transition name is already in use on the canvas."
            isError={errorName}
            id="ShowEditnode_name"
            isReadOnly={isTemplateApproved(templateLifeCycle)}
          />
        </Box>
        {/* Path Box */}
        <Box data-testid="ShowEditEdgePathBox">
          <h3
            className="text-[14px] mt-[20px]"
            data-testid="ShowEditEdgePathH3"
          >
            Path
          </h3>
          {selectedWorkFlowTransition?.id !== 'edges_1' && (
            <Box data-testid="ShowEditEdgeStatusBox">
              <Text
                mt="20px"
                className="text-[14px]"
                data-testid="ShowEditEdgeStatusText"
              >
                From Status
                <span className="text-red-600 text-sm pt-[10px] pl-[3px]">
                  *
                </span>
              </Text>
              <CustomSelectBox
                value={toTransitionState}
                isMulti
                name="from"
                options={nodeOption}
                isError={!!errorCommonFlow}
                onChange={(e) => {
                  dispatch(
                    updateStateOfWorkFlowWithKey({
                      keyValue: 'isEdgeSave',
                      value: true,
                    })
                  );
                  dispatch(
                    updateStateOfWorkFlowWithKey({
                      keyValue: 'toTransitionState',
                      value: e,
                    })
                  );
                }}
                isDisabled={isTemplateApproved(templateLifeCycle)}
                id="ShowEditEdgeSelectBox"
              />
            </Box>
          )}
          <Box data-testid="ShowEditEdgeToStatusBox">
            <Text
              mt="20px"
              className="text-[14px]"
              data-testid="ShowEditEdgeToStatusText"
            >
              To Status
            </Text>
            <CustomSelectBox
              value={fromTransitionState}
              name="to status"
              options={nodeOption}
              isError={!!errorCommonFlow}
              onChange={(e) => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'isEdgeSave',
                    value: true,
                  })
                );
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'fromTransitionState',
                    value: e,
                  })
                );
              }}
              isDisabled={isTemplateApproved(templateLifeCycle)}
              id="ShowEditEdgeZSelectBox"
            />
          </Box>
        </Box>
        {errorCommonFlow && (
          <span className="field-error">{errorCommonFlow}</span>
        )}
        {/* currently v2 runtime doesn’t support this right now */}
        {/* <Box mt="20px" data-testid="ShowEditEdgeAutoBox">
          <CustomCheckBox
            checkBoxText="Auto move"
            isChecked={!!selectedWorkFlowTransition?.isAutoMoveAllowed}
            onChange={(e) => {
              handleAutoMove(e);
            }}
            isDisabled={isTemplateApproved(templateLifeCycle)}
          >
            <span className="text-[12px] font-semibold block">AUTO MOVE</span>
          </CustomCheckBox>
        </Box> */}

        {/* rule */}
        {entityRoute !== 'workflow-list' && (
          <Box
            display="flex"
            justifyContent="space-between"
            mt="20px"
            data-testid="ShowEditEdgeRuleBox"
          >
            <Flex
              justify="center"
              align="center"
              data-testid="ShowEditEdgeRileFlex"
            >
              {' '}
              <ReactSVG
                src={RoleBlack}
                alt="image"
                data-testid="ShowEditEdgeRuleReacrSvg"
              />
              <p data-testid="ShowEditEdgeRuleP">Rules</p>
            </Flex>
            <Tooltip
              hasArrow
              label={showToolTipForAddRule()}
              data-testid="ShowEditEdgeRuleTooltip"
            >
              <Box data-testid="ShowEditEdgeRuleCButtonBox">
                <CustomButton
                  isDisabled={disableAddRuleButton()}
                  color={useColorModeValue(BLACK, WHITE)}
                  onClick={() => {
                    setIsAddRole(true);
                    dispatch(
                      changeSelectedRuleWithKey({
                        keyValue: 'transitionId',
                        value: selectedWorkFlowTransition?.id,
                      })
                    );
                  }}
                  buttonText={LABELS.ACTIONS.ADD_RULE}
                  variant="outline"
                  leftIcon={<AddIcon />}
                  id="ShowEditEdgeAddRuleBtn"
                />
              </Box>
            </Tooltip>
          </Box>
        )}
        {entityRoute !== 'workflow-list' && (
          <Box
            className="text-[12px]"
            mt="5px"
            data-testid="ShowEditEdgeRuleTextBox"
          >
            Rules help you save time when moving an issue.
            <br />
            {!checkForV2() && 'You can choose only one rule at a time.'}
          </Box>
        )}
        <Box data-testid="ShowEditEdgeRuleCardBox">
          {filteredRules.map((item) => (
            <RuleCard
              ruleInfo={item}
              key={uuidv4()}
              openModalAsPerModalType={openModalAsPerModalType}
              onDeleteBtnClickFromRuleCard={onDeleteBtnClickFromRuleCard}
            />
          ))}
        </Box>
      </Box>
      <Box
        position="fixed"
        bottom="10px"
        data-testid="ShowEditEdgeButtonBox"
        width="90%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          data-testid="ShowEditEdgeApprovedBox"
        >
          <Tooltip
            hasArrow
            label={showToolTipForDelete()}
            data-testid="ShowEditEdgeApprovedToolTip"
          >
            <Box position="relative" data-testid="ShowEditEdgeApprovedInnerBox">
              <CustomButton
                variant="solid"
                pl="5px"
                pr="5px"
                buttonColor="red"
                classname="calculatedHeight"
                isDisabled={
                  selectedWorkFlowTransition?.id === 'edges_1' ||
                  isTemplateApproved(templateLifeCycle) ||
                  isEdgeSave ||
                  transitionReference
                }
                buttonText="Delete"
                onClick={() => {
                  setIsDeleteModalForEdge(true);
                }}
                _hover={{
                  backgroundColor: 'red',
                  color: 'white',
                }}
                size="lg"
                minWidth="115px"
                id="ShowEditEdgezdeleteBtn"
              />
            </Box>
          </Tooltip>
          <Box
            ml="25px"
            gap={3}
            display="flex"
            data-testid="ShowEditEdgeCancelBox"
          >
            <SecondaryCustomButton
              buttonText={LABELS.ACTIONS.CANCEL}
              onClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowTransition',
                    value: {},
                  })
                );
                closeSideBar(false);
              }}
              id="ShowEditEdgeRulePCanceltextBtn"
            />
            <Tooltip
              placement="top-start"
              hasArrow
              label={
                isTemplateApproved(templateLifeCycle)
                  ? `Transition can't be changed as it was approved`
                  : ''
              }
              data-testid="ShowEditEdgeCancelTooltip"
            >
              <Box position="relative" data-testid="ShowEditEdgeSaveBox">
                <PrimaryCustomButton
                  isDisabled={
                    !(
                      isEdgeSave &&
                      selectedWorkFlowTransition?.data?.label &&
                      fromTransitionState &&
                      !isEmtpty(toTransitionState)
                    )
                  }
                  buttonText={LABELS.ACTIONS.SAVE}
                  onClick={handleCheckNameIsExit}
                  id="ShowEditEdgeRuleSaveTextbtn"
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <>
        <RestricMoveRoleModal
          isRestricMoveRole={isRestricMoveRoleModal}
          setIsRestricMoveRole={setIsRestricMoveRoleModal}
          edit
        />
        <RestrictSpecificStatusModal
          isRestricMoveRole={isRestrictSpecificStatusModal}
          setIsRestricMoveRole={setIsRestrictSpecificStatusModal}
          edit
        />
        <RestrictSpecificValueModal
          isRestrictSpecificValue={isRestrictSpecificValue}
          setIsRestrictSpecificValue={setIsRestrictSpecificValue}
          edit
        />
        <RestrictLinkedTicketSpecificStatusModal
          setIsRestrictLinkedTicketSpecificStatusValue={
            setIsRestricLinkedTicketSpecificStatusValue
          }
          isRestrictLinkedTicketSpecificStatusValue={
            isRestricLinkedTicketSpecificStatusValue
          }
          edit
        />
        <RuleDeleteModal
          isDeleteModal={isDeleteModal}
          setIsDeleteModal={setIsDeleteModal}
          onConfirmation={async () => {
            dispatch(removeRuleFromSelectedWorkFlowState({ dispatch }));
            setIsDeleteModal(false);
            // closeSideBar(false);
          }}
          onDeleteCancelClick={() => {
            dispatch(
              updateStateOfWorkFlowWithKey({
                keyValue: 'selectedWorkFlowRule',
                value: initSelectedRule,
              })
            );
            setIsDeleteModal(false);
          }}
          title={LABELS.TITLE.DELETE_RULE_WARNING}
          subTitle={findTitle(selectedWorkFlowRule.ruleType)}
        />
        <RuleDeleteModal
          isDeleteModal={isDeleteModalForEdge}
          setIsDeleteModal={setIsDeleteModalForEdge}
          onConfirmation={() => {
            dispatch(deleteEdgeFromWorkFlow());
            setIsDeleteModalForEdge(false);
            closeSideBar(false);
          }}
          onDeleteCancelClick={() => {
            setIsDeleteModalForEdge(false);
          }}
          title={LABELS.TITLE.DELETE_EDGE_WARNING}
          subTitle={selectedWorkFlowTransition?.data?.label}
        />
        <AddNewRoleModal
          isAddRole={isAddRole}
          setIsAddRole={setIsAddRole}
          handleRuleModalOpen={handleRuleModalOpen}
          addedRules={addedRules}
        />

        <RestricMoveRoleModal
          isRestricMoveRole={isRestricMoveRoleModalWithOutEdit}
          setIsRestricMoveRole={setIsRestricMoveRoleModalWithOutEdit}
        />
        <RestrictSpecificStatusModal
          isRestricMoveRole={isRestrictSpecificStatusModalWithOutEdit}
          setIsRestricMoveRole={setIsRestrictSpecificStatusModalWithOutEdit}
        />
        <RestrictSpecificValueModal
          isRestrictSpecificValue={isRestrictSpecificValueWithOutEdit}
          setIsRestrictSpecificValue={setIsRestrictSpecificValueWithOutEdit}
        />
        <RestrictLinkedTicketSpecificStatusModal
          setIsRestrictLinkedTicketSpecificStatusValue={
            setIsRestricLinkedTicketSpecificStatusValueWithOutEdit
          }
          isRestrictLinkedTicketSpecificStatusValue={
            isRestricLinkedTicketSpecificStatusValueWithOutEdit
          }
        />
      </>
    </Box>
  );
};
ShowEditEdge.propTypes = {
  closeSideBar: PropTypes.func,
};
ShowEditEdge.defaultProps = {
  closeSideBar: () => {},
};
export default ShowEditEdge;

import React from 'react';
import { Box } from '@chakra-ui/react';
import CustomMention from '../../../components/Mention';
import CustomSelectBox from '../../../components/SelectBox/Select';

const renderTicketAutomationForm = (
  actionToPerform,
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionErrorData,
  silentFlows
) => (
  <>
    <Box marginTop="10px" data-testid="DynamicRendererFlowNameBox">
      <CustomSelectBox
        label="Flow Name"
        placeholder="Select a option"
        options={silentFlows || []}
        value={actionToPerform.flowName}
        onChange={(val) => {
          handleChange(
            val,
            `action_to_perform[${actionToPerformSelectedIndex}].flowName`
          );
          handleActionErrorData('flowName');
        }}
      />
    </Box>
    <Box marginTop="10px" data-testid="DynamicRendererPayloadBox">
    <CustomMention
      placeholder="Enter Payload"
      value={actionToPerform.payload || ''}
      label="Payload"
      onChange={(value) => {
        handleChange(
          value,
          `action_to_perform[${actionToPerformSelectedIndex}].payload`
        );
        handleActionErrorData('payload');
      }}
      isError={!!actionErrorData?.template}
      errorMessage={actionErrorData?.template || ''}
      isMulti
      isMandatory
      multiLine
    />
    </Box>
  </>
);

export default renderTicketAutomationForm;

import React from 'react';

import PropTypes from 'prop-types';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { DATE_VALIDATION_LABELS } from '../../../../../utils/enums/labels';
import CustomDatePicker from '../../../../../components/Datepicker/AntdDatePicker';

const {
  FROM_DATE,
  FROM_DATE_TOOLTIP,
  TO_DATE,
  TO_DATE_TOOLTIP,
} = DATE_VALIDATION_LABELS;

const DateAddRuleAbsolute = ({
  start,
  end,
  allowTime,
  onChangeAbsoluteRule
}) => {
  const onChangeStartDate = (value) => {
    onChangeAbsoluteRule({ start: value, end });
  };

  const onChangeEndDate = (value) => {
    onChangeAbsoluteRule({ start, end: value });
  };

  return (
    <Flex marginTop="10px" gap="20px">
      <Stack flex={1}>
        <Tooltip label={FROM_DATE_TOOLTIP} placement="top" hasArrow>
          <Flex direction="row" alignItems="center" gap="5px">
            <Text fontWeight={500}>{FROM_DATE}</Text>
            <AiOutlineInfoCircle />
          </Flex>
        </Tooltip>
        <CustomDatePicker
          showTime={allowTime}
          value={start}
          onChange={onChangeStartDate}
          dateFormat={allowTime ? 'MMM DD, YYYY HH:mm' : 'MMM DD, YYYY'}
          popupStyle={{
            zIndex: 9999,
          }}
          style={{
            height: 37,
          }}
        />
      </Stack>

      <Stack flex={1}>
        <Tooltip label={TO_DATE_TOOLTIP} placement="top" hasArrow>
          <Flex direction="row" alignItems="center" gap="5px">
            <Text fontWeight={500}>{TO_DATE}</Text>
            <AiOutlineInfoCircle />
          </Flex>
        </Tooltip>
        <CustomDatePicker
          showTime={allowTime}
          value={end}
          onChange={onChangeEndDate}
          dateFormat={allowTime ? 'MMM DD, YYYY HH:mm' : 'MMM DD, YYYY'}
          popupStyle={{
            zIndex: 9999,
          }}
          style={{
            height: 37,
          }}
        />
      </Stack>
    </Flex>
  );
};

DateAddRuleAbsolute.propTypes = {
  allowTime: PropTypes.bool,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  onChangeAbsoluteRule: PropTypes.func.isRequired,
};

DateAddRuleAbsolute.defaultProps = {
  allowTime: false,
};

export default DateAddRuleAbsolute;

import { createData, deleteData, getData, updateData, bulkUpdateData } from '../../utils/crud';

export const getFieldDefaults = async () => {
  const res = await getData({
    endpoint: 'fieldDefaults/list?$select=*',
  });

  return res;
};

export const createFieldDefault = async (payload) => {
  const res = await createData({
    endpoint: 'fieldDefaults/create',
    payload,
    successMessage: 'Offer created successfully',
    errorMessage: 'Failed to create a new offer',
  });

  return res;
};

export const updateFieldDefault = async (payload, id) => {
  const res = await updateData({
    payload,
    endpoint: `fieldDefaults/update?$filter=id eq ${id}`,
    successMessage: 'Offer updated successfully',
    errorMessage: 'Failed to update the offer',
  });

  return res;
};

export const bulkUpdateOffer = async (payload) => {
  const res = await bulkUpdateData({
    payload,
    endpoint: `fieldDefaults/bulkUpdate`,
    successMessage: 'Offer updated successfully',
    errorMessage: 'Failed to update the offer',
  });

  return res;
};



export const deleteFieldDefault = async (id) => {
  const res = await deleteData({
    endpoint: `fieldDefaults/delete?$filter=id eq ${id}`,
    successMessage: 'Offer deleted successfully',
    errorMessage: 'Failed to delete the offer',
  });

  return res;
};

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Checkbox,
  useDisclosure,
} from '@chakra-ui/react';
import { components } from 'react-select';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import CustomSelectBox from '../../components/SelectBox/Select';
import {
  EMPTY_PERMISSION_ITEM,
  PERMISSION_KEY_MAP,
  PERMISSION__MAP_WITH_LABEL_KEY_LIST,
  removeMatchingOptionForPermission,
  createActionPermissionValidation,
  MAP_PERMISSION_WITH_ROLE,
  ALL_PERMISSION_DENIED,
} from './util';
import useKeycloak from '../../utils/hooks/useKeyCloak';
import {
  getStatusObject,
  processErrors,
} from '../DynamicRenderer/AddEditSignatureDrawer/utils';
import { patchDataForTemplate } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import allCompositeService from '../../redux/TicketTemplate/AllCompositeReducer/AllCompositeService';
import { getTenantPermission } from '../TenantPermission/tenantPermission.service';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import WarningTriggerModel from '../DynamicRenderer/WarningTriggerModel';
import { deleteTriggerConfirmation, deleteTriggersFromWarningModel } from '../TemplateCardListView/helpers';

// eslint-disable-next-line no-unused-vars
const TemplatePermissionMap = ({ label }) => {
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [keycloak] = useKeycloak();
  const [permissionList, setPermissionList] = useState([
    ...(selectedTemplate ? selectedTemplate[PERMISSION_KEY_MAP.ACTION_PERMISSIONS] : []),
  ]);
  const [errorKeyLIst, setErrorKeyList] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tenantPermissionList, setTenantPermissionList] = useState([]);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  const { id: templateId } = useParams();

  const addActionPermission = async () => {
    setIsLoading(true);
    const newData = [...(permissionList || [])];

    const res = await patchDataForTemplate({
      id: templateId,
      data: newData,
      key: [PERMISSION_KEY_MAP.ACTION_PERMISSIONS],
      defaultErrorMessage: 'Failed to save a action permission in template',
      successMessage: 'Successfully saved action permission template',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
    setIsLoading(false);
  };

  const getRoleList = async () => {
    try {
      const responseList = await allCompositeService.getRoleListApi();
      setRoleList(responseList.map((role) => role.name));
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line max-len
    setPermissionList(selectedTemplate ? selectedTemplate[PERMISSION_KEY_MAP.ACTION_PERMISSIONS] : []);
  }, [selectedTemplate]);
  
  useEffect(() => {
    let data = permissionList || [];
    data = Array.isArray(data) ? [...data] : [];
    // get role list
    getRoleList();

    if (!data.length) {
      data.push(EMPTY_PERMISSION_ITEM);
    }
    setPermissionList(data);
    setLoader(false);
  }, []);
  useEffect(() => {
    if (isEmpty(tenantPermissionList)) {
      getTenantPermission().then((res) => {
        if (res) {
          setTenantPermissionList(res?.action_permissions || []);
        }
      });
    }
  }, [tenantPermissionList]);

  const [triggersLoader,setTriggersLoader]=useState(false) 
  const [botUserRole,setBotUserRole] = useState(false)
  const [deleteModelState,setDeleteModelState]=useState({isDeleted:false,model:false,value:null})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isBotUserDeleted,setIsBotUserDeleted] = useState(false)

  useEffect(()=>{
    if(selectedTemplate){
      let getBotUserRole = selectedTemplate?.action_permissions?.filter((data)=>{return data.user_id === "Bot User"}) ?? false
      if(getBotUserRole.length !== 0 ){
        setBotUserRole(getBotUserRole[0]?.permission_mapping?.TICKET_CREATE ?? false)
      }
    }
  },[selectedTemplate])

  useEffect(() => {
    if (deleteModelState.value && deleteModelState.isDeleted) {
      setIsBotUserDeleted(true)
      if(deleteModelState.value.collectedData)deleteTriggerConfirmation(deleteModelState.value.collectedData)
      deleteModelState?.value?.key ? handleOnChangeCheckBox(deleteModelState.value.checked, deleteModelState.value.key, deleteModelState.value.permissionIndex) : handleSelectAllCheckbox(deleteModelState.value.checked, deleteModelState.value.permissionIndex) 
    }
  }, [deleteModelState]);

  const onCloseModel = () =>{
    setDeleteModelState({isDeleted:false,model:false,value:null}) 
    setIsBotUserDeleted(false)
    setTriggersLoader(false)
    onClose
  }

  const handleDeleteModel = () =>{
    setDeleteModelState({value:deleteModelState.value,isDeleted:true,model:true})
  }


  const ROLE_OPTION_LIST = useMemo(() => {
    // in permission tab role should not be convertable hence its breaking on ticketing
    const roles =
      roleList?.map((item) => ({
        value: item,
        label: item,
        type: 'ROLE',
      })) || [];
    const uniqueRoles = uniqBy([...roles], 'value');
    return uniqueRoles;
  }, [roleList]);

  const { Option } = components;
  // eslint-disable-next-line react/no-unstable-nested-components
  const IconOption = (props) => {
    const { data } = props;

    const { label: newLabel, type } = data;
    return (
      <Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{newLabel}</div>
          <div
            style={{ marginLeft: '10px', color: '#ccc', fontStyle: 'italic' }}
          >
            {type}
          </div>
        </div>
      </Option>
    );
  };
  const getPermissionMap = (roleType) => {
    const tenantPermission = tenantPermissionList.find(
      (item) =>
        _startCase(_camelCase(item.user_id)) ===
        _startCase(_camelCase(roleType))
    );
    if (!isEmpty(tenantPermission)) {
      return tenantPermission.permission_mapping;
    }
    if (MAP_PERMISSION_WITH_ROLE[roleType]) {
      return MAP_PERMISSION_WITH_ROLE[roleType];
    }
    return {
      ...ALL_PERMISSION_DENIED,
    };
  };
  const handleSelectAllCheckbox = async (checked, permissionIndex) => {
     let processedData = permissionList[permissionIndex].user_id === "Bot User"
     if(permissionList[permissionIndex]?.permission_mapping?.TICKET_CREATE && !checked && processedData && !deleteModelState.isDeleted && !isBotUserDeleted && botUserRole){
      setTriggersLoader(true)
      const collectedData = await deleteTriggersFromWarningModel(selectedTemplate.id)
      setTriggersLoader(false)
      if(collectedData && collectedData?.length !== 0){
        setDeleteModelState({isDeleted:false,model:true , value:{checked,key:null, permissionIndex , collectedData}})
        return
      }
    }else{
      setDeleteModelState({isDeleted:false,model:false,value:null}) 
    }
    if(!setDeleteModelState.model){
      setPermissionList((prevList) =>
        prevList.map((item, index) =>
          index === permissionIndex
            ? {
              ...item,
              [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: {
                ...item[PERMISSION_KEY_MAP.PERMISSION_MAPPING],
                ...PERMISSION__MAP_WITH_LABEL_KEY_LIST.reduce((acc, curr) => {
                  acc[curr.key] = checked;
                  return acc;
                }, {}),
              },
            }
            : item
        )
      );
    }
  };
  const onAddPermissionInPermissionList = () => {
    const IS_VALID_FIELD_LIST = permissionList.every(
      (item) =>
        item[PERMISSION_KEY_MAP.USER_ID] && item[PERMISSION_KEY_MAP.USER_TYPE]
    );
    // if (!IS_VALID_FIELD_LIST) {
    //   return;
    // }

    const tempData = cloneDeep([...permissionList, EMPTY_PERMISSION_ITEM]);
    setPermissionList(tempData);
  };
  const handleOnChangeSelectBox = (value, permissionIndex) => {
    setErrorKeyList({});
    setPermissionList((prevList) =>
      prevList.map((item, index) =>
        index === permissionIndex
          ? {
            ...item,
            [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: getPermissionMap(
              value.value
            ),
            [PERMISSION_KEY_MAP.USER_ID]: value.value,
            [PERMISSION_KEY_MAP.USER_TYPE]: value.type,
          }
          : item
      )
    );
  };
  const handleOnChangeCheckBox = async (checked, key, permissionIndex) => {
    let processedData = permissionList[permissionIndex].user_id === "Bot User"
    if(key === 'TICKET_CREATE' && !checked && processedData && !deleteModelState.isDeleted && !isBotUserDeleted && botUserRole){
      setTriggersLoader(true)
      const collectedData = await deleteTriggersFromWarningModel(selectedTemplate.id)
      setTriggersLoader(false)
      if(collectedData && collectedData?.length !== 0){
        setDeleteModelState({isDeleted:false,model:true ,value:{checked, key, permissionIndex,collectedData}})
        return
      }
    }else{
      setDeleteModelState({isDeleted:false,model:false,value:null}) 
    }
    if(!setDeleteModelState.model){
      setPermissionList((prevList) =>
        prevList.map((item, index) =>
          index === permissionIndex
            ? {
              ...item,
              [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: {
                ...item[PERMISSION_KEY_MAP.PERMISSION_MAPPING],
                [key]: checked,
              },
            }
            : item
        )
      );
    }
  };
 
  const onSavePermission = () => {
    setIsLoading(true);
    const { error } = createActionPermissionValidation.validate(
      permissionList,
      {
        abortEarly: false,
      }
    );
    if (!isEmpty(error)) {
      const tempError = processErrors(error.details, 1);
      setErrorKeyList(tempError);
      setIsLoading(false);
    } else {
      addActionPermission();
    }
  };
  const onDeleteSelectedPermissionFromList = (index) => {
    let newStaticValue = [...permissionList];
    if (newStaticValue.length === 1) {
      newStaticValue = [{ ...EMPTY_PERMISSION_ITEM }];
    } else {
      newStaticValue.splice(index, 1);
    }
    setPermissionList(newStaticValue);
  };
  const isCheckAllPermission = useCallback(
    (permissionIndex) =>
      Object.values(
        permissionList[permissionIndex][PERMISSION_KEY_MAP.PERMISSION_MAPPING]
      ).every((value) => value === true),
    [permissionList]
  );

  if (loader) {
    return <Loader />;
  }

  return (
    <Box p="15px" height="calc(100vh - 18rem)" overflow="auto">
      <Flex align="center" justify="space-between">
        <Text fontWeight="500" />
      </Flex>
      <Box maxH="600px" overflowX="scroll">
      <div className="table-wrp block !z-10 overflow-x-scroll list-scroll">
           <table className="w-full z-10">
           <thead className="bg-white sticky top-0 !z-10">
              <tr>
                <th />
                <th />
                {PERMISSION__MAP_WITH_LABEL_KEY_LIST.map((label1) => (
                  <th className="w-[400px] !z-10  whitespace-nowrap !px-2 !text-center">
                    <Text fontWeight="500" fontSize="15px">
                      {label1.label}
                    </Text>
                  </th>
                ))}
                <th />
              </tr>
            </thead>
            <tbody className="overflow-y-auto !z-9">
              {permissionList.map((permission, permissionIndex) => (
                <tr>
                  <td style={{ paddingLeft: '40px' }}>
                    <Checkbox
                      onChange={(e) => {
                        handleSelectAllCheckbox(
                          e.target.checked,
                          permissionIndex
                        );
                      }}
                      isChecked={isCheckAllPermission(permissionIndex)}
                      isDisabled={!permission[PERMISSION_KEY_MAP.USER_ID]}
                    />
                  </td>
                  <td className="!text-center !px-2">
                    <Box width="100%" height="40px" marginTop="10px" mx="10px">
                      <CustomSelectBox
                        options={removeMatchingOptionForPermission(
                          ROLE_OPTION_LIST,
                          permissionList
                        )}
                        components={{ Option: IconOption }}
                        value={getStatusObject(
                          permission[PERMISSION_KEY_MAP.USER_ID],
                          ROLE_OPTION_LIST
                        )}
                        onChange={(e) => {
                          handleOnChangeSelectBox(e, permissionIndex);
                        }}
                        isError={
                          errorKeyLIst[PERMISSION_KEY_MAP.USER_ID] &&
                          !permission[PERMISSION_KEY_MAP.USER_ID]
                        }
                        errorMessage="Please select the user role"
                      />
                    </Box>
                  </td>
                  {PERMISSION__MAP_WITH_LABEL_KEY_LIST.map((item) => (
                    <td style={{ paddingLeft: '40px' }}>
                      <Checkbox
                        isChecked={
                          permission[PERMISSION_KEY_MAP.PERMISSION_MAPPING][
                            item.key
                          ]
                        }
                        onChange={(e) => {
                          handleOnChangeCheckBox(
                            e.target.checked,
                            item.key,
                            permissionIndex
                          );
                        }}
                        isDisabled={!permission[PERMISSION_KEY_MAP.USER_ID]}
                      />
                    </td>
                  ))}
                  <td>
                    <DeleteIcon
                      mx="4"
                      color="red"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onDeleteSelectedPermissionFromList(permissionIndex);
                      }}
                      data-testid="permissionListDeleteIcon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
      <Flex
        justify="end"
        marginTop="20px"
        gap="10px"
        position="fixed"
        right="10"
        // top={window.innerHeight - 130}
        bottom="80px"
        zIndex="10"
      >
        <CustomButton
          buttonText="Add"
          variant="outline"
          style={{ height: '32px' }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={() => {
            onAddPermissionInPermissionList();
          }}
          leftIcon={<AddIcon mr="4px"/>}
        />
        <CustomButton
          buttonText="Save"
          variant="outline"
          style={{ height: '32px' }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={() => {
            onSavePermission();
          }}
          isLoading={isLoading}
        />
      </Flex>
      <WarningTriggerModel  isOpen={triggersLoader || deleteModelState.model } triggersLoader={triggersLoader}  onClose={onCloseModel} handleDeleteModel={handleDeleteModel}/>
    </Box>
  );
};

TemplatePermissionMap.propTypes = {
  label: PropTypes.string,
};

TemplatePermissionMap.defaultProps = {
  label: '',
};

export default TemplatePermissionMap;

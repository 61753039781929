/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ArrowBackIcon, DeleteIcon  } from '@chakra-ui/icons';
import LayoutTableView from './LayoutTableView';
import { columns } from './utils';
import { LAYOUT_VIEW_LIST, filterArrayByViewId, updateArray } from './helper';
// import CustomModal from '../../../components/Modal/Modal';
import CustomModalFooter from '../../TicketTemplateWorkflow/CreateEditFlow/CustomModalFooter';
import { COLORS, RULE_CARD_BORDER } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { checkForV2 } from '../../../utils/helper';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import {
  patchDataForTemplate,
  showToastMessage,
} from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { SCOPE_MAP, layoutViewTypes } from '../../../utils/enums/selectOption';
import { TITLE_LABELS } from '../../../utils/enums/labels';

const LayoutViewAndTransition = () => {
  const [tableDataState, setTableDataState] = useState([]);
  const [ViewLayoutMap, setViewLayoutMap] = useState([]);
  const [workflowTranstion, setWorkflowTranstion] = useState([]);
  const [layoutsState, setLayoutsState] = useState([]);
  const [layoutOption, setLayoutOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(true);
  const dispatch = useDispatch();
  const [editLayoutModal, setEditLayoutModal] = useState({
    isOpen: false,
    viewName: '',
    layoutId: '',
    rowData: {},
  });
  const [isLoadingForApiCall, setIsLoadingForApiCall] = useState(false);

  const viewState = useSelector((state) => state.viewState || {});
  const actors = useSelector((state) => state?.viewState?.selectedTemplate?.actors || '');
  const transitions = useSelector((state) => state?.viewState?.selectedTemplate?.workflow?.workflow_status);
  const defLayout = useSelector((state) => state?.viewState?.selectedTemplate?.view_layout_map);
  const filteredData = defLayout?.filter((item) => item.view_id === "deferred" && item.type === "DEFERRED");
  const [error, setError] = useState(false);
  const [deferredLayouts, setDeferredLayouts] = useState([
    {
      id: uuidv4(),
      deferredLinkLayout: [
        {
          actor: [],
          transition: [],
          additionalTransition: false,
          transitionLabel: '',
          linked_layout: ''
        }]
    }
  ]);
  const [linkLayouts, setLinkLayouts] = useState([]);
  const selectedTemplate = viewState?.selectedTemplate;
  /* Changing state to set the show modal */
  const showModal = useCallback(
    ({ viewName = '', layoutId = '', rowData = {} }) => {
      setEditLayoutModal({
        isOpen: true,
        viewName,
        layoutId,
        rowData,
      });
    },
    []
  );

  /* Changing state to close modal */
  const closeModal = useCallback(() => {
    setEditLayoutModal({
      isOpen: false,
      viewName: '',
      layoutId: '',
      rowData: {},
    });
  }, []);
  const { id } = useParams();

  useEffect(() => {
    if (selectedTemplate) {
      if (!isEmpty(selectedTemplate?.view_layout_map)) {
        setViewLayoutMap(selectedTemplate?.view_layout_map);
        setLoadingNew(false)
      }
      if (!isEmpty(selectedTemplate?.workflow?.workflow_transitions)) {
        if (checkForV2()) {
          const newArray = selectedTemplate?.workflow?.workflow_transitions
            ?.filter((item, index) => index > 0)
            .map((item) => ({
              id: item.id,
              type: 'TRANSITION',
              label: item.data.label,
              linked_layout: null,
            }));
          setWorkflowTranstion(newArray);
        }
        setLoadingNew(false)
      }
      if (!isEmpty(selectedTemplate?.layouts)) {
        const newOption = selectedTemplate?.layouts?.map((item) => ({
          value: item.id,
          label: item.name,
          viewType: item.viewType,
        }));
        setLayoutsState(selectedTemplate?.layouts);
        setLayoutOption(newOption);
        setLinkLayouts(newOption?.filter((item) => item.viewType === 'DEFERRED'));
        setLoadingNew(false)
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (isEmpty(workflowTranstion) && isEmpty(ViewLayoutMap)) {
      setTableDataState([...LAYOUT_VIEW_LIST]);
    } else if (isEmpty(ViewLayoutMap)) {
      const updatedTableData = [...LAYOUT_VIEW_LIST, ...workflowTranstion];
      setTableDataState(updatedTableData);
    } else {
      const updatedTableData = updateArray(
        [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...LAYOUT_VIEW_LIST?.map((i) => ({ ...i, linked_layout: null })),
          ...workflowTranstion,
        ],
        ViewLayoutMap
      );
      const newMap = filterArrayByViewId(ViewLayoutMap, updatedTableData);
      setViewLayoutMap(newMap);
      setTableDataState(updatedTableData);
    }
  }, [workflowTranstion, layoutsState]);

  const validateLayoutLinking = (sourceLayout, targetLayout) => {
    for (const sourceItem of sourceLayout.schema) {
      if (sourceItem.type === 'component') {
        const sourceFields = new Set(sourceItem.subItems.map((i) => i.id));

        for (const targetItem of targetLayout.schema) {
          if (
            targetItem.type === 'component' &&
            sourceItem.id !== targetItem.id
          ) {
            const targetFields = new Set(targetItem.subItems.map((i) => i.id));

            for (const field of sourceFields) {
              if (targetFields.has(field)) {
                return false; // Found a common value, return false
              }
            }
          }
        }
      }
    }
    return true; // No common value found, return true
  };

  const DeferredLinkLayout = async (newObj) => {
    let deferredLayoutsData = deferredLayouts[0].deferredLinkLayout.map((layout) => {
      return {
        actor: layout.actor.map(item => item),
        transition: layout.transition.map(item => item),
        additionalTransition: layout.additionalTransition,
        transitionLabel: layout.transitionLabel,
        linked_layout: layout.linked_layout,
      };
    });
    const updatedDeferredData = {
      id: uuidv4(),
      type: 'DEFERRED',
      deferredLinkLayout: deferredLayoutsData,
      view_id: 'deferred',
    };
    if (newObj.view_id === 'deferred') {
      Object.assign(newObj, updatedDeferredData);
    }
  };

  const handleLinkLayoutToView = async () => {
    setLoading(true);
    const tempData = [...ViewLayoutMap];
    const tempTableData = [...tableDataState].map((item) => {
      if (item.id === editLayoutModal.rowData.id) {
        return editLayoutModal.rowData;
      }
      return item;
    });
    const index = tempData.findIndex(
      (data) => data.view_id === editLayoutModal.rowData.id
    );
    const newObj = {
      id: uuidv4(),
      type: editLayoutModal.rowData.type,
      view_id: editLayoutModal.rowData.id,
      layout_id: editLayoutModal.rowData.linked_layout,
    };
    if (newObj.view_id === 'deferred') {
      await DeferredLinkLayout(newObj);
    }
    if (index === -1) {
      tempData.push(newObj);
    } else {
      tempData[index] = newObj;
    }

    const createLayout = tempData?.filter((i) => i?.view_id === 'create')?.[0];

    if (createLayout && checkForV2()) {
      const checkValidationForCreate = (layouts, fields, layoutId) => {
        // Helper function to flatten subItems recursively
        const flattenSubItems = (item) => {
          if (item.type === 'component' && item.subItems) {
            return [item, ...item.subItems.flatMap(flattenSubItems)];
          }
          return [item];
        };

        const currentlayFields = layouts
          ?.filter((i) => i?.id === layoutId)[0]
          ?.schema?.flatMap((i) => flattenSubItems(i))
          ?.map((i) => i)
          ?.sort();

        const reqFields = fields
          ?.filter(
            (i) =>
              i?.type_based_attributes?.required &&
              i?.scope !== SCOPE_MAP.WORKFLOW &&
              i?.scope !== SCOPE_MAP.RELATION
          )
          ?.map((i) => i)
          ?.sort();

        const areAllRequiredFieldsPresent = (normalFields, requiredFields) => {
          // Check if all required fields are present in the normal array and no special fields
          const hasSpecialField = normalFields.some((field) =>
            field?.type?.startsWith('SF_')
          );
          const rf = requiredFields?.map((i) => i?.id);
          const nf = normalFields?.map((i) => i?.id);

          return !hasSpecialField && rf.every((field) => nf.includes(field));
        };
        return areAllRequiredFieldsPresent(currentlayFields, reqFields);
      };

      const allFieldsPresent = checkValidationForCreate(
        selectedTemplate?.layouts,
        selectedTemplate?.fields,
        createLayout?.layout_id
      );

      if (!allFieldsPresent) {
        showToastMessage({
          title: 'Validation Error',
          description:
            'Layout linked to Create view should have all required fields and cannot have any Special Fields.',
          status: 'error',
        });
        setLoading(false);
        setIsLoadingForApiCall(false);
        return;
      }
    }

    // Code For Versioning
    const isValidationRequired = !!ViewLayoutMap?.length;

    if (isValidationRequired && !checkForV2()) {
      const targetLayout = layoutsState?.filter(
        (ins) => ins?.id === editLayoutModal?.layoutId
      )[0];

      for (const viewLayout of ViewLayoutMap) {
        const sourceLayout = layoutsState?.filter(
          (ins) => ins.id === viewLayout.layout_id
        )[0];

        const validation = validateLayoutLinking(sourceLayout, targetLayout);

        if (!validation) {
          // Validation failed for a pair, show error message and return from function
          showToastMessage({
            title: 'Validation Error',
            description:
              'Fields from the one section cannot be placed in other sections of the linked layout.',
            status: 'error',
          });
          setLoading(false);
          setIsLoadingForApiCall(false);
          return; // Stop further processing
        }
      }
    }

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'view_layout_map',
      defaultErrorMessage:
        index === -1
          ? 'Failed to link layout to View'
          : 'Failed to edit layout',
      successMessage:
        index === -1
          ? 'Successfully linked the layout to view'
          : 'Successfully edited the layout',
    });
    setLoading(false);
    setIsLoadingForApiCall(false);

    if (res.error) {
      closeModal();
    } else {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
      setTableDataState([...tempTableData]);
      setViewLayoutMap([...tempData]);
      closeModal();
    }
  };
  const onDelete = async (rowData) => {
    const tempData = [...ViewLayoutMap];
    const selectedRow = [...tableDataState].map((item) => {
      if (item.id === rowData.id) {
        return { ...item, linked_layout: null };
      }
      return item;
    });
    const indexViewLayout = tempData.findIndex(
      (data) => data.view_id === rowData.id
    );
    if (indexViewLayout !== -1) {
      tempData.splice(indexViewLayout, 1);
    }
    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'view_layout_map',
      defaultErrorMessage: 'Failed to unlink layout',
      successMessage: 'Successfully unlinked the layout',
    });
    if (!res.error) {
      setViewLayoutMap(tempData);
      setTableDataState(selectedRow);
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  const filterArrayForLayout = (layoutsList, selectedView) => {
    if (selectedView?.type === 'TRANSITION') {
      return layoutsList.filter(
        (item) => item.viewType === layoutViewTypes.TRANSITION
      );
    }

    if (selectedView?.type === 'VIEW') {
      if (selectedView?.id === 'card') {
        return layoutsList.filter(
          (item) => item.viewType === layoutViewTypes.INBOX
        );
      }
      return layoutsList.filter(
        (item) => item.viewType === layoutViewTypes.GRID || !item.viewType
      );
    }

    return [];
  };

  const updatedActors = [...actors];
  const deffActors = useSelector((state) => state?.viewState?.selectedTemplate?.special_fields_role_map);
  if (deffActors?.some(item => item.type === 'SF_SUBMITTED_BY')) {
    updatedActors.push({ value: 'Submitted By', name: 'Submitted By' });
  }



  useEffect(() => {
    if (filteredData?.length > 0) {
      const transformedData = filteredData.map((item) => ({
        id: item.id || uuidv4(),
        deferredLinkLayout: item.deferredLinkLayout.map((d, index) => ({
          actor: d.actor || [],
          transition: d.transition || [],
          additionalTransition: d.additionalTransition || false,
          transitionLabel: d.transitionLabel || '',
          linked_layout: d.linked_layout || '',
          index
        })),
      }));
      setDeferredLayouts(transformedData);
    }
  }, []);
  const addDeferredLayout = () => {
    setDeferredLayouts(prevDeferredLayouts => {
      const newData = {
        actor: [],
        transition: [],
        additionalTransition: false,
        transitionLabel: '',
        linked_layout: ''
      };
      const updatedDeferredLayouts = [...prevDeferredLayouts];
      updatedDeferredLayouts[0]?.deferredLinkLayout.push(newData);
      return updatedDeferredLayouts;
    });
  };
  useEffect(() => {
  }, [deferredLayouts])
  const handleActorChange = useCallback((selectedOptions, itemIndex, layoutIndex) => {
    const selectedActors = selectedOptions.map((option) => option.value);
    const duplicateActor = deferredLayouts.reduce((foundActor, layout, i) => {
      if (foundActor) return foundActor;
      return layout.deferredLinkLayout.reduce((actorFound, item, j) => {
        if (actorFound) return actorFound;
        if (i !== layoutIndex || j !== itemIndex) {
          const duplicate = selectedActors.find((actor) => item.actor?.includes(actor));
          return duplicate || actorFound;
        }
        return null;
      }, null);
    }, null);
    if (duplicateActor) {
      setError((prevErrors) => ({
        ...prevErrors,
        [`actorSelect_${layoutIndex}_${itemIndex}`]: `"${duplicateActor}" cannot be added since it is associated with another layout.`,
      }));
      return;
    }
    setError((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`actorSelect_${layoutIndex}_${itemIndex}`];
      return newErrors;
    });
    let updatedLayouts = deferredLayouts.map((layout, i) =>
      i === layoutIndex
        ? {
          ...layout,
          deferredLinkLayout: layout.deferredLinkLayout.map((item, j) =>
            j === itemIndex
              ? { ...item, actor: selectedActors }
              : item
          ),
        }
        : layout
    );
    setDeferredLayouts(updatedLayouts);
  }, [deferredLayouts, setDeferredLayouts]);
  const handleTransitionChange = useCallback((selectedOptions, itemIndex, layoutIndex) => {
    const updatedLayouts = deferredLayouts.map((layout, i) =>
      i === layoutIndex
        ? {
          ...layout,
          deferredLinkLayout: layout.deferredLinkLayout.map((item, j) =>
            j === itemIndex
              ? { ...item, transition: selectedOptions.map((option) => option.value) }
              : item
          )
        }
        : layout
    );
    setDeferredLayouts(updatedLayouts);
  }, [deferredLayouts, setDeferredLayouts]);
  const handleLinkedLayoutChange = useCallback((e, itemIndex, layoutIndex) => {
    const updatedLayouts = deferredLayouts.map((layout, i) =>
      i === layoutIndex
        ? {
          ...layout,
          deferredLinkLayout: layout.deferredLinkLayout.map((item, j) =>
            j === itemIndex
              ? { ...item, linked_layout: e.value }
              : item
          )
        }
        : layout
    );
    setDeferredLayouts(updatedLayouts);
  }, [deferredLayouts, setDeferredLayouts]);
  const handleAdditionalTransitionChange = (e, itemIndex, layoutIndex) => {
    const updatedLayouts = [...deferredLayouts];
    updatedLayouts[layoutIndex].deferredLinkLayout[itemIndex].additionalTransition = e.target.checked;
    setDeferredLayouts(updatedLayouts);
  };
  const handleTransitionLabelChange = (value, itemIndex, layoutIndex) => {
    const updatedLayouts = [...deferredLayouts];
    updatedLayouts[layoutIndex].deferredLinkLayout[itemIndex].transitionLabel = value;
    setDeferredLayouts(updatedLayouts);
  };
  const handleDeleteLastItem = (layoutIndex) => {
    setDeferredLayouts((prevLayouts) => {
      const updatedLayouts = [...prevLayouts];
      updatedLayouts[layoutIndex].deferredLinkLayout.pop();
      return updatedLayouts;
    });
  };
  const isFormDataComplete = () => {
    const lastItem = deferredLayouts[0]?.deferredLinkLayout[deferredLayouts[0].deferredLinkLayout.length - 1]; // Get the last item in the data array
    if (!lastItem) {
      return false;
    }
    const isComplete = (
      lastItem.actor && lastItem.actor.length > 0 &&
      lastItem.transition && lastItem.transition.length > 0 &&
      lastItem.linked_layout &&
      lastItem.transitionLabel
    );
    return isComplete;
  };

  if (!selectedTemplate?.layouts || !selectedTemplate?.layouts.length) {
    return (
      <Flex>
        <Alert status="info" mt="8" borderRadius="4px" alignItems="center">
          <AlertIcon />
          {TITLE_LABELS.NO_LAYOUTS}
        </Alert>
      </Flex>
    );
  }

  return (
    <Box
      data-testid="LayoutViewAndTransitionContainerBox"
      height="calc(100vh - 15rem)"
    >
      <LayoutTableView
        placeholder="Search for a view"
        isLoading={!tableDataState?.length}
        columns={columns(showModal, onDelete, layoutsState)}
        tableData={tableDataState}
        title="Linked layout"
      />

      <Drawer
        size="lg"
        placement="right"
        onClose={() => {
          if (editLayoutModal.isOpen) {
            closeModal();
          }
        }}
        isOpen={editLayoutModal.isOpen}
        data-testid="AddOrEditEmailTemplateDrawerEmail"
        closeOnOverlayClick={false}
      >
        <DrawerContent>
          <DrawerHeader>
            <Flex
              alignItems="center"
              gap="10px"
              pb="10px"
              borderBottom={`1px solid ${RULE_CARD_BORDER}`}
            >
              <IconButton
                colorScheme="blue"
                aria-label="Close Drawer"
                size="sm"
                borderRadius="50%"
                minW="1.5rem"
                height="1.5rem"
                variant="outline"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  if (editLayoutModal.isOpen) {
                    closeModal();
                  }
                }}
              />
              <Box className="font-semibold text-[16px]">
              Link Layout
              </Box>
              <Text fontSize="16px">
              <Box className="font-semibold text-[16px]">
              {editLayoutModal?.viewName}
                </Box>
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Grid
              templateColumns="repeat(12, 1fr)"
              data-testid="LayoutViewAndTransitionGrid"
            >
              <GridItem
                colSpan={12}
                data-testid="LayoutViewAndTransitionGridItem"
              >
                {editLayoutModal?.viewName === 'Deferred' ? (
                  <>
                    {deferredLayouts.map((layout, layoutIndex) => (
                      <div key={layout.id}>
                        {layout.deferredLinkLayout.map((formData, itemIndex) => (
                          <Box p='10px' border="1px solid #ccc" mb='20px' borderRadius={"5px"} key={itemIndex}>
                            {/* Delete Icon in the corner */}
                            {layout.deferredLinkLayout.length > 1 && itemIndex === layout.deferredLinkLayout.length - 1 && (
                              <Box display={'flex'} justifyContent={'flex-end'} onClick={() => handleDeleteLastItem(layoutIndex)}>
                                <DeleteIcon color="red.500" />
                              </Box>
                            )}
                            <CustomSelectBox
                              width="100%"
                              name={`actorSelect_${layoutIndex}_${itemIndex}`}
                              value={formData.actor?.map((actor) => ({
                                value: actor,
                                label: actor,
                              }))}
                              options={updatedActors
                                ?.filter((actor) => !formData.actor?.includes(actor.name))
                                ?.map((actor) => ({
                                  value: actor.name,
                                  label: actor.name,
                                }))}
                              onChange={(selectedOptions) =>
                                handleActorChange(selectedOptions, itemIndex, layoutIndex)
                              }
                              isMulti={true}
                              label="Applicable For Actors"
                            />
                            {error[`actorSelect_${layoutIndex}_${itemIndex}`] && (
                              <div style={{ color: 'red', marginTop: '5px' }}>
                                {error[`actorSelect_${layoutIndex}_${itemIndex}`]}
                              </div>
                            )}
                            <CustomSelectBox
                              width="100%"
                              name={`transitionSelect_${layoutIndex}_${itemIndex}`}
                              value={formData.transition.map((transition) => ({
                                value: transition,
                                label: transition,
                              }))}
                              options={transitions
                                // ?.filter((transition) => transition.type === "toDoneNode")
                                ?.filter((transition) => !formData.transition.includes(transition.data.label)) // Exclude selected values
                                .map((transition) => ({
                                  value: transition.data.label,
                                  label: transition.data.label,
                                }))
                              }
                              onChange={(selectedOptions) => handleTransitionChange(selectedOptions, itemIndex, layoutIndex)}
                              isMulti={true}
                              label="Applicable For Transition"
                            />
                            <CustomSelectBox
                              width="100%"
                              name={`linkedLayoutSelect_${layoutIndex}_${itemIndex}`}
                              value={{ value: formData.linked_layout, label: formData.linked_layout }}
                              options={linkLayouts.map((layout) => ({ value: layout.label, label: layout.label }))}
                              onChange={(e) => handleLinkedLayoutChange(e, itemIndex, layoutIndex)}
                              label="Link Layout"
                            />
                            <Box display={'flex'} gap='20px' my='10px'><Text className='SCLabel'>Add In Workflow As Additional Transition </Text>
                              <Switch
                                ms='10px'
                                isChecked={formData.additionalTransition}
                                onChange={(e) => handleAdditionalTransitionChange(e, itemIndex, layoutIndex)}
                              />
                            </Box>
                            <Box gap='20px'><Text mb='8px' className='SCLabel'>Transition Label </Text>
                              <Input
                                type="text"
                                value={formData.transitionLabel}
                                onChange={(e) => handleTransitionLabelChange(e.target.value, itemIndex, layoutIndex)}
                              />
                            </Box>
                          </Box>
                        ))}
                      </div>
                    ))}
                    {/* Button to add another deferred layout */}
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Button variant={'outline'} colorScheme='blue' onClick={addDeferredLayout} isDisabled={!isFormDataComplete()}>Add Another Layout</Button>
                    </Box>
                  </>
                ) :
                  <CustomSelectBox
                    width="100%"
                    styles={customStyleFromRuleForSelect}
                    name=""
                    value={getWholeOption(editLayoutModal.layoutId, layoutOption)}
                    options={filterArrayForLayout(
                      layoutOption,
                      editLayoutModal?.rowData
                    )}
                    onChange={(e) => {
                      setEditLayoutModal((pre) => ({
                        ...pre,
                        layoutId: e.value,
                        rowData: { ...pre.rowData, linked_layout: e.value },
                      }));
                    }}
                    id="LayoutViewAndTransitionSelectViewBox"
                    label="Layout"
                    isMandatory
                  />
                }
              </GridItem>
            </Grid>
          </DrawerBody>
          <DrawerFooter>
            <CustomModalFooter
              onCancelClick={() => {
                closeModal();
              }}
              onAddClick={() => {
                setIsLoadingForApiCall(true);
                handleLinkLayoutToView();
              }}
              btnLabel="Link"
              isDisabled={(!editLayoutModal.layoutId || loading) && editLayoutModal.viewName !== 'Deferred'}
              isLoading={isLoadingForApiCall}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default LayoutViewAndTransition;

import React from 'react';

import { EditIcon } from '@chakra-ui/icons';
import Joi from 'joi';

import TableColumnText from '../../components/Text/TableColumnText';

import { validationErrorMessage } from '../../utils/validationSchemas/validationSchemas';
import { deleteRenderer } from '../DynamicRenderer/MetricsAndSLA/Metrics/utils';
import {
  convertRolesToArray,
  renderCriteria,
} from '../DynamicRenderer/Audience/AudienceEditModal/audience.utls';

export const FORM_MAP = {
  NAME: 'name',
  DESCRIPTION: 'description',
  INTENT_GROUPS: 'intent_groups',
  INTENT_GROUP_ID: 'intent_group_id',
  ROLE: 'role',
};

const validateForCreateAudience = Joi.object({
  [FORM_MAP.NAME]: Joi.string().required().messages({
    'string.empty': 'Name is required',
    'any.required': 'Name is required',
  }),
  [FORM_MAP.INTENT_GROUPS]: Joi.array()
    .required()
    .items(
      Joi.object({
        [FORM_MAP.INTENT_GROUP_ID]: Joi.string().required(),
      })
    )
    .min(1)
    .messages({
      'array.min': 'Intent Group is required',
      'any.required': 'Intent Group is required',
    }),
  [FORM_MAP.ROLE]: Joi.object()
    .keys({
      org: Joi.array().items(Joi.string()),
      roles: Joi.array().items(Joi.string()),
      teams: Joi.array().items(Joi.string()),
      users: Joi.array().items(Joi.string()),
      actors: Joi.array().items(Joi.string()),
      groups: Joi.array().items(Joi.string()),
      queues: Joi.array().items(Joi.string()),
    })
    .custom((value, helpers) => {
      // Check if at least one key in the object has a value
      const hasValue = Object.values(value).some((val) => val.length > 0);
      if (!hasValue) {
        return helpers.error('object.empty', {
          message: 'At least one key must have a value',
        });
      }
      return value;
    })
    .messages({
      'object.empty': 'Add atleast one role'
    })
    .unknown(),
}).unknown();

export const createPayloadForAudience = (data) => {
  const payload = {
    id: data?.id || '',
    [FORM_MAP.NAME]: data?.[FORM_MAP.NAME]?.replace(/\s+/g, ' ').trim() || '',
    [FORM_MAP.INTENT_GROUPS]: data?.[FORM_MAP.INTENT_GROUPS]?.map((g) => ({
      [FORM_MAP.INTENT_GROUP_ID]: g,
    })),
    [FORM_MAP.ROLE]: data?.[FORM_MAP.ROLE] || {},
    [FORM_MAP.DESCRIPTION]: data?.[FORM_MAP.DESCRIPTION] || '',
  };

  return payload;
};

export const enrichDataForEdit = (data) => {
  const payload = {
    id: data?.id || '',
    [FORM_MAP.NAME]: data?.[FORM_MAP.NAME]?.replace(/\s+/g, ' ').trim() || '',
    [FORM_MAP.ROLE]: data?.[FORM_MAP.ROLE] || {},
    [FORM_MAP.DESCRIPTION]: data?.[FORM_MAP.DESCRIPTION] || '',
    [FORM_MAP.INTENT_GROUPS]: data?.[FORM_MAP.INTENT_GROUPS]
      ?.map((g) => g?.[FORM_MAP.INTENT_GROUP_ID])
      ?.filter((g) => g),
  };

  return payload;
};

export const validatePayload = (data, isNameExist) => {
  const payload = createPayloadForAudience(data);

  const { error } = validateForCreateAudience.validate(payload, {
    abortEarly: false,
  });

  if (isNameExist) {
    return {
      success: false,
      message: { name: 'Audience name already exist' }
    };
  }
  if (error) {
    const message = validationErrorMessage(error);
    return {
      success: false,
      message,
    };
  }

  return {
    success: true,
    payload,
  };
};

export const columns = ({ onEditClick, onDelete, optionsMap, configObj }) => {
  const cols = [
    {
      name: FORM_MAP.NAME,
      col_name: FORM_MAP.NAME,
    },
    {
      name: FORM_MAP.INTENT_GROUPS,
      col_name: FORM_MAP.INTENT_GROUPS,
      cellRenderer: ({ rowData }) => {
        const intentGroups = rowData?.[FORM_MAP.INTENT_GROUPS];
        if (!Array.isArray(intentGroups)) {
          return null;
        }

        const columnText = intentGroups
          .map((a) => optionsMap[a?.[FORM_MAP.INTENT_GROUP_ID]] || a?.[FORM_MAP.INTENT_GROUP_ID])
          .join(', ');

        return <TableColumnText text={columnText} />;
      },
    },
    {
      name: FORM_MAP.ROLE,
      col_name: 'Roles',
      cellRenderer: ({ rowData }) => {
        const roles = rowData?.[FORM_MAP.ROLE] || {};
        if (Object.keys(roles).length > 0) {
          let serializeRoles=roles;
          if (roles?.actors && configObj?.actors) {
            const mappedActors = roles.actors.map((actorId) => {
              const actor = configObj.actors.find((item) => item.id === actorId);
              return actor?.name || actorId;
            });
            serializeRoles = {
              ...roles,
              actors: mappedActors
            };
          }
          if (roles?.queues && configObj?.queues) {
            const mappedQueues = roles.queues.map((queueId) => {
              const queue = configObj.queues.find((item) => item.value === queueId);
              return queue?.label || queueId;
            });
            serializeRoles = {
              ...roles,
              queues: mappedQueues
            };
          }
          const data = convertRolesToArray(serializeRoles);
          return renderCriteria(data);
        }

        return null;
      },
    },
    {
      name: FORM_MAP.DESCRIPTION,
      col_name: FORM_MAP.DESCRIPTION,
    },
    {
      col_name: 'Actions',
      cellRenderer: ({ rowData }) => (
        <div className="flex gap-[10px]">
          <EditIcon
            className="cursor-pointer"
            data-testid={`audience-${rowData.name}-edit`}
            onClick={() => {
              onEditClick(rowData);
            }}
          />
          {deleteRenderer({
            title: 'Delete this audience',
            description: 'Are you sure you want to delete this audience?',
            dataTestId:`audience-${rowData.name}-delete`,
            onConfirm: () => {
              onDelete(rowData);
            },
          })}
        </div>
      ),
    },
  ];

  return cols;
};

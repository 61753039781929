/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactWindowTable from './ReactWindowTable';
import Loader from './Dashboard/Loader';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import axiosInstance from '../../utils/axios/axios';
import CustomToast from '../../components/Notification/Notification';

const TableView = ({
  name,
  columns,
  actions,
  title,
  apiReq,
  onRowClick,
  calculatedHight,
  filteredName,
}) => {
  const { endPoint, reqCols, orderBy, isSingle, filter, filterValue } = apiReq || {};
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [itemId, setItemId] = useState('');
  const top = 25;
  const [skip, setSkip] = useState(0);
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);
  const { addToast } = CustomToast();
  useEffect(() => {
    if (
      // items?.length === 0 &&
      viewState[name]?.refresh &&
      !viewState[name]?.search
    ) {
      setRefreshKey((prev) => {
        if (prev) {
          return 0;
        }
        return 1;
      });
    }
    dispatch(
      updateViewState({
        stateKey: name,
        value: { filter: '', data: [] },
      })
    );
    setItemId(id);
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setSkip(0);

    return () => {
      if (filteredName) {
        dispatch(
          updateViewState({
            stateKey: filteredName,
            value: { filter: '', data: [] },
          })
        );
      }
      setItems([]);
    };
  }, [id, viewState[name]?.refresh]);

  useEffect(() => {
    setItems(viewState?.[filteredName || name]?.data || []);
  }, [viewState?.[filteredName || name]?.data]);

  let filterString = `&$filter=${filter || ''} eq ${filter ? filterValue || id : ''}`;
// Removes null from the filter string (e.g., "&$filter=template_id eq null") to avoid bad responses.
// Works correctly for valid filter strings (e.g., "&$filter=template_id eq someFilter").
if (filterString.includes('null')) {
  filterString = '' ;
}
  const loadNextPage = () => {
    if (viewState?.[name]?.search) {
      return;
    }

    setIsNextPageLoading(true);
    setHasNextPage(false);
    setIsLoading(true)
    axiosInstance
      .get(
        `${endPoint}?$top=${top}&$skip=${skip}&$select=${reqCols?.toString()}&$orderby=${orderBy}${filterString}`
      )
      .then((response) => {
        const newData = [
          ...((isSingle
            ? response?.data?.rows[0]?.[reqCols]
            : response?.data?.rows) || []),
        ];

        const data = [...(viewState?.[name]?.data || []), ...newData];

        dispatch(
          updateViewState({
            stateKey: name,
            value: {
              filter: '',
              data,
              count: response?.data?.count,
            },
          })
        );

        // setItems((data || []).filter((item) => item?.id));
        // setHasNextPage(viewState?.[name]?.data.length < response?.data?.count);
        setIsLoading(false)
        setHasNextPage(!!newData?.length);
        setIsNextPageLoading(false);
        setSkip((prev) => top + prev);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        addToast({
          title: 'Something went wrong',
          message: error?.response?.data?.message || '',
          type: 'error',
        });
        setIsLoading(false)
        setIsNextPageLoading(false);
      });
  };

  return (isNextPageLoading && skip === 0)|| isLoading ? (
    <Loader height="50vh" />
  ) : (
    <Box key={refreshKey} data-testid="TableViewBox">
      <ReactWindowTable
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        sectionName={name}
        items={items}
        title = {title}
        calculatedHight = {calculatedHight}
        loadNextPage={loadNextPage}
        columns={columns}
        height={200}
        actions={actions}
        key={itemId}
        id={itemId}
        onRowClick={onRowClick}
      />
    </Box>
  );
};

TableView.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  actions: PropTypes.object.isRequired,
  apiReq: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  filteredName: PropTypes.string,
};

TableView.defaultProps = {
  onRowClick: undefined,
  filteredName: '',
  title: '',
};

export default TableView;

import axiosInstance from '../../../utils/axios/axios';
import { getData } from '../../../utils/crud';

export const getEmailSettings = async () => {
  const res = await getData({
    endpoint: '/emailSettings/list?$select=*',
  });

  return res?.[0] || null;
};

export const createOrUpdateEmailSettings = async ({ data }) => {
  const res = await await axiosInstance.put('emailSettings/createOrUpdate', data);

  if (!res) {
    return null;
  }

  return res;
};

import Joi from 'joi';

import { stringRequiredValidation } from '../validationSchemas';
import { FORM_MAP } from '../../../pages/TenantFilters/filters.constants';

export const FILTER_VALIDATIONS = Joi.object({
  [FORM_MAP.NAME]: Joi.string()
  .required()
  .trim()
  .messages({
    'string.empty': 'Filter name is required',
    'any.required': 'Filter name is required',
  }),
[FORM_MAP.CONDITIONS]: Joi.array().required().min(1), [FORM_MAP.CONDITIONS]: Joi.array()
.min(1)
.required()
.messages({
  'array.min': 'At least one condition is required',
  'array.empty': 'At least one condition is required',
  'any.required': 'At least one condition is required',
}),
}).unknown();

export const FILTER_ROLE_MAPPING_VALIDATIONS = Joi.object({
  [FORM_MAP.ROLES]: Joi.string()
  .required()
  .trim()
  .messages({
    'string.empty': 'Roles is required',
    'any.required': 'Roles is required',
  }),
[FORM_MAP.FILTERS]: Joi.array()
  .required()
  .min(1)
  .messages({
    'array.min': 'At least one filter is required',
    'array.empty': 'Filters is required',
    'any.required': 'Filters is required',
  }),
[FORM_MAP.DEFAULT_FILTER]: Joi.string()
  .required()
  .trim()
  .messages({
    'string.empty': 'Default filter is required',
    'any.required': 'Default filter is required',
  }),
}).unknown();

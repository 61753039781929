import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';

const SortableTable = ({ data, config, handleScrollDuringDrag, children }) => {
  const [state, setState] = useState([]);

  const dSort = useMemo(() => data.map((item) => ({ ...item })), [data]);

  useEffect(() => {
    setState(dSort);
  }, [dSort]);

  const handleListChange = (newList) => {
    const updatedList = newList.map((item, index) => ({
      ...item,
      position: index,
    }));
    setState(updatedList);

    config?.onTableOrderChange(updatedList);
    config?.onOrderChange(updatedList);
  };

  const onMoveHandler = (evt) => {
    handleScrollDuringDrag(evt);
    const { related } = evt;
    if (related instanceof HTMLElement && related.dataset.index !== undefined) {
      const relatedIndex = parseInt(related.dataset.index, 10);
      if (state[relatedIndex]?.disabled) {
        return false;
      }
    }
    return true;
  };

  if (config?.isSortable && data.length > 0) {
    return (
      <ReactSortable
        list={state}
        setList={handleListChange}
        tag="tbody"
        filter=".disabled-item"
        preventOnFilter
        onMove={onMoveHandler}
        autoScroll
      >
        {children}
      </ReactSortable>
    );
  }

  return children;
};

SortableTable.propTypes = {
  data: PropTypes.array.isRequired,
  config: PropTypes.shape({
    isSortable: PropTypes.bool.isRequired,
    onToggleSortable: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onOrderSave: PropTypes.func.isRequired,
    onTableOrderChange: PropTypes.func.isRequired,
    btnTitle: PropTypes.shape({
      toggleOff: PropTypes.string,
      toggleOn: PropTypes.string,
    }),
  }).isRequired,
  handleScrollDuringDrag: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SortableTable;

import React, { useCallback, useState, useEffect } from 'react';

import { useToast } from '@chakra-ui/react';
import Offers from './Offers';
import axiosInstance from '../../utils/axios/axios';
import { columns } from './helper';

const LocalesWrapper = () => {
  const toast = useToast();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const getAllOffers = async () => {
    try {
      const response = await axiosInstance.get(`fieldDefaults/list?$select=*&$filter=offer_type ne 'email'&$orderby=id asc`);
      if (response?.data?.rows) setTableData(response.data.rows);
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(() => {
    getAllOffers();
  }, []);
  const bulkOfferSave = async (data) => {
    try {
      const response = await axiosInstance.put('fieldDefaults/bulkupdate', {
        data,
      });
      if (response?.status === 200 || response?.status === 201) {
        toast({
          title: 'Offers successfully saved',
          status: 'success',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
        setIsloading(false);
        getAllOffers();
      } else {
        toast({
          title: 'Failed to save Offers',
          status: 'error',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to save Offers',
        status: 'error',
        duration: 3000,
        position: 'bottom-left',
        isClosable: true,
      });
      throw new Error(error);
    }
  };
  // sortable drag & drop configs start
  const [isFilterSortable, setIsFilterSortable] = useState(false);
  const [reOrderedList, setReOrderList] = useState([]);
  const onOffersOrderSave = useCallback(() => {
    setIsloading(true);
    const payload = reOrderedList.map((item) => {
      const { position, properties, ...rest } = item;
      return {
        ...rest,
        properties: {
          ...properties,
          position,
        }
      };
    });
    bulkOfferSave(payload);
    //  API Call required to put the updated index in respective filter table
  }, [reOrderedList]);
  const sortableConfig = {
    isSortable: isFilterSortable,
    showReorder: Array.isArray(tableData) && tableData?.length > 0,
    onToggleSortable: setIsFilterSortable,
    onOrderChange: setReOrderList,
    onOrderSave: onOffersOrderSave,
    btnTitle: { toggleOff: 'Reorder', toggleOn: 'Save Order', isLoading },
  };
  // sortable drag & drop configs end
  return (
    <Offers
      columns={columns()}
      tableData={tableData}
      searchKey="name"
      sortableConfig={sortableConfig}
    />
  );
};

export default LocalesWrapper;

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import styles from './restrictions.module.scss';

const RestrictionPreviewPill = ({
  id,
  onEdit,
  onDelete,
  previewObjects,
  previewObjectJoiner,
}) => {
  const previewObjectsMemo = useMemo(() => previewObjects.map((previewObject, index) => {
    const { left, middle, right } = previewObject;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={styles.rppObjectBlock}>
        <span className={styles.rppLeft}>{left}</span>
        <span className={styles.rppMiddle}>{middle}</span>
        <span className={styles.rppRight}>{right}</span>
        {
          index < previewObjects.length - 1 && (
            <span className={styles.rppJoiner}>{previewObjectJoiner}</span>
          )
        }
      </div>
    );
  }), [previewObjects, previewObjectJoiner]);

  return (
    <div className={styles.restrictionPreviewPill}>
      { previewObjectsMemo }
      { onDelete && typeof onDelete === 'function' && (
        <IconButton
          colorScheme="blue"
          aria-label="Delete Rule"
          size="sm"
          className={styles.rppIconButton}
          variant="unstyled"
          icon={<CloseIcon />}
          onClick={() => onDelete(id)}
        />
      )}
      { onEdit && typeof onEdit === 'function' && (
        <IconButton
          colorScheme="blue"
          aria-label="Edit Rule"
          size="sm"
          className={styles.rppIconButton}
          variant="unstyled"
          icon={<EditIcon />}
          onClick={() => onEdit(id)}
        />
      )}
    </div>
  );
};

RestrictionPreviewPill.propTypes = {
  id: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  previewObjects: PropTypes.arrayOf(PropTypes.shape({
    left: PropTypes.string,
    middle: PropTypes.string,
    right: PropTypes.string,
  })).isRequired,
  previewObjectJoiner: PropTypes.string.isRequired,
};

RestrictionPreviewPill.defaultProps = {
  onEdit: null,
  onDelete: null,
};

export default RestrictionPreviewPill;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MetaFieldsService from './MetaFieldsService';

const initialState = {
  metaFieldList: [],
  metaTableAttribute: {},
  metaColumnAttributes: [],
  allMetaColumnAttributes: [],
  loading: false,
  error: null,
};

export const getMetaFieldList = createAsyncThunk(
  'metaFields/getMetaFieldList',
  async (defaultErrorMessage, thunkAPI) => {
    try {
      const TEMP_DATA = await MetaFieldsService.getMetaFieldList(
        defaultErrorMessage
      );
      return TEMP_DATA;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteMetaFields = createAsyncThunk(
  'metaFields/deleteMetaFields',
  async (metaFieldId, thunkAPI) => {
    try {
      const res = await MetaFieldsService.deleteMetaFields(metaFieldId);
      return res;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const metaFields = createSlice({
  name: 'metaFields',
  initialState,
  reducers: {
    updateMetaFieldList: (state, action) => {
      state.metaFieldList = [...action.payload];
    },
    resetError: (state) => {
      state.error = null;
    },
    updateMultipleStatesOfMetaFieldList: (state, action) => {
      action.payload.forEach((act) => {
        const { stateKey, value } = act;
        state[stateKey] = value;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetaFieldList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMetaFieldList.fulfilled, (state, action) => {
        const {
          metaFieldList,
          error,
          tableAttributes,
          columnAttributes,
          allColumnAttributes,
        } = action.payload;

        state.loading = false;
        state.metaFieldList = metaFieldList;
        state.metaTableAttribute = tableAttributes;
        state.metaColumnAttributes = columnAttributes;
        state.allMetaColumnAttributes = allColumnAttributes;
        state.error = error;
      })
      .addCase(getMetaFieldList.rejected, (state, action) => {
        const {
          metaFieldList,
          error,
          columnAttributes,
          tableAttributes,
          allColumnAttributes,
        } = action.payload;
        state.loading = false;
        state.metaFieldList = metaFieldList;
        state.metaColumnAttributes = columnAttributes;
        state.metaTableAttribute = tableAttributes;
        state.allMetaColumnAttributes = allColumnAttributes;
        state.error = error;
      })
      .addCase(deleteMetaFields.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMetaFields.fulfilled, (state, action) => {
        const metaFieldIdToDelete = action.payload;
        state.loading = false;
        state.metaFieldList = state.metaFieldList.filter(
          (metaField) => metaField.id !== metaFieldIdToDelete.response.id
        );
        state.metaColumnAttributes = state.metaColumnAttributes.filter(
          (metaField) => metaField.meta_field_id !== metaFieldIdToDelete.response.meta_field_id
        );
        state.allMetaColumnAttributes = state.allMetaColumnAttributes.filter(
          (metaField) => metaField.meta_field_id !== metaFieldIdToDelete.response.meta_field_id
        );
      })
      .addCase(deleteMetaFields.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  updateMetaFieldList,
  resetError,
  updateMultipleStatesOfMetaFieldList,
} = metaFields.actions;
export default metaFields.reducer;

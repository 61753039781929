/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Input, Textarea, Select, Tooltip } from '@chakra-ui/react';
import { deleteRenderer } from '../../../DynamicRenderer/MetricsAndSLA/Metrics/utils';

export const BOT_IDENTICATION_FIELDS_TRIGGERS = ({
  onEditDescription,
  onDelete,
  onChangeInformationRetrievalPreference,

}) => [
  {
    name: 'Field',
    label: 'Field',
    type: 'string',
    cellRenderer: ({ rowData }) => <Tooltip label={rowData?.label}><Input value={rowData?.label} disabled={true} /></Tooltip> ,
  },
  {
    name: 'Type',
    label: 'Type',
    type: 'string',
    cellRenderer: ({ rowData }) => <Input value={rowData?.type} disabled={true} />,
  },
  {
    name: 'Category',
    label: 'Category',
    type: 'string',
    cellRenderer: ({ rowData }) => (
        <Input value={rowData?.type_based_attributes?.required ? "Required" : "Optional"} disabled={true} />
    ),
    },
    {
        name: 'Information Retrieval Preference',
        label: 'Information Retrieval Preference',
        type: 'string',
        cellRenderer: ({ rowData }) => {
            const handleChange = (event) => {
                onChangeInformationRetrievalPreference(rowData,event.target.value)
            }
            return(
                <Select defaultValue={rowData?.informationRetrievalPreference || "May Ask Leading Questions"} onChange={handleChange} >
                    <option value='May Ask Leading Questions' >May Ask Leading Questions</option>
                    <option value='Fetch From Conversation' >Fetch From Conversation</option>
                </Select>
            )
        },
  },
  {
    name: 'Description for Bot',
    label: 'Description for Bot',
    type: 'string',
    cellRenderer: ({ rowData }) =>{
      const handleBlur = (event) => {
        const updatedDescription = event.target.value;
        if (updatedDescription !== rowData.botDescription) {
          onEditDescription(rowData, updatedDescription);
        }
      };
      let descriptionData;
      if (rowData && rowData?.botDescription) {
        descriptionData = rowData?.botDescription;
      }
      return (
             <Textarea resize="none" overflowY="auto" placeholder="Enter description" defaultValue={descriptionData || ''} onBlur={handleBlur} />
      );
    },
  },
  {
    name: 'Action',
    label: 'Action',
    type: 'string',
    cellRenderer: ({ rowData }) =>(
      !rowData?.type_based_attributes?.required ?
        <div>
          {deleteRenderer(
            // @ts-ignore
            {
              title: 'Delete this field',
              description: 'Are you sure you want to delete this field?',
              onConfirm: () => {
                onDelete(rowData);
              },
              dataTestId: `Fields-${rowData.name}-delete`,
            }
          )}
        </div>
            : 
            <div>--</div>
      ),
  },
];

/* eslint-disable no-restricted-syntax */
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../utils/axios/axios';

export const makeApiCallForHierarchyList = async () => {
  try {
    const response = await axiosInstance.get('/hierarchies/list', {
      params: {
        $select: 'id,name,hierarchy,is_actor_hierarchy',
      },
    });
    return response?.data?.rows;
  } catch (error) {
    const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const calculateDepth = (data) => {
  let maxDepth = 0;

  function calculateDepthRecursive(node, depth) {
    if (!node.children || node.children.length === 0) {
      maxDepth = Math.max(maxDepth, depth);
    } else {
      for (const child of node.children) {
        calculateDepthRecursive(child, depth + 1);
      }
    }
  }

  for (const item of data) {
    calculateDepthRecursive(item, 1);
  }

  return maxDepth;
};

export const getDefaultFieldOption = () => ({
  showAllValues: false,
  selectedValue: null,
});

export const getDefaultFieldMapping = () => ({
  id: uuidv4(),
  heirachyListId: '',
  map: [''],
  fieldOptions: {
    '': {
      ...getDefaultFieldOption(),
      showAllValues: true,
    },
  },
});

const getValueIdsByLevel = (node, level = 0, result = []) => {
  if (!result[level]) {
    result[level] = [];
  }
  result[level].push({ value: node.key, label: node.title });

  if (node.children) {
    node.children.forEach((child) => {
      getValueIdsByLevel(child, level + 1, result);
    });
  }

  return result;
};

export const transformHierarchyToArray = (hierarchy) => {
  let result = [];
  hierarchy.forEach((item) => {
    result = getValueIdsByLevel(item, 0, result);
  });
  return result;
};

const findParentNode = (key, node) => {
  if (node.key === key) {
    return null;
  }

  if (!node.children || node.children.length === 0) {
    return null;
  }

  if (node.children) {
    for (const child of node.children) {
      if (child.key === key) {
        return node;
      }

      const parent = findParentNode(key, child);
      if (parent) {
        return parent;
      }
    }
  }

  return null;
};

export const getParentValueInHierarchy = (childValue, hierarchies) => {
  if (!childValue) {
    return null;
  }

  let parentValue = null;
  hierarchies.forEach((item) => {
    const parentNode = findParentNode(childValue.value, item);
    if (parentNode) {
      parentValue = { value: parentNode.key, label: parentNode.title };
    }
  });

  if (parentValue) {
    return parentValue;
  }

  return null;
};

export const FLEX_WIDTHS = [
  '0.2',
  '0.15',
  '0.3',
  '0.3',
  '0.1',
];

export const LABELS_AND_FLEX_WIDTH_MAP = [
  {
    label: 'Levels',
    flex: FLEX_WIDTHS[0],
  },
  {
    label: 'Show All Values',
    flex: FLEX_WIDTHS[1],
  },
  {
    label: 'Mapped Fields',
    flex: FLEX_WIDTHS[2],
  },
  {
    label: 'Selected Value',
    flex: FLEX_WIDTHS[3],
  },
  {
    label: 'Actions',
    flex: FLEX_WIDTHS[4],
  },
];

export const getHeaderPosition = (index) => {
  if (index === 0) {
    return 'flex-start';
  }
  if (index === LABELS_AND_FLEX_WIDTH_MAP.length - 1) {
    return 'flex-end';
  }

  return 'center';
};

export const getComponentPosition = (index) => {
  if (index === 0) {
    return 'flex-start';
  }
  if (index === FLEX_WIDTHS.length - 1) {
    return 'flex-end';
  }

  return 'center';
};

/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  GridItem,
  Flex,
  useColorModeValue,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { DARK, LIGHT } from '../../utils/enums/colors';
import CustomSelectBox from '../../components/SelectBox/Select';

import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';

const ActionEventConfigRow = ({
  index,
  actionState,
  handleChange,
  eventsByTemplateId,
  filteredRelatedTemplateOptions,
  selectedTemplate,
  onAddEventConfigRow,
  onRemoveEventConfigRow,
  relatedOffers,
  errorKeyListWithMessage,
}) => {
  const eventConfig = actionState.eventConfig[index] || {};
  const [eventOptions, setEventOptions] = useState([]);
  const [linkedItemSelected, setLinkedItemSelected] = useState(false);
  const [hideLinkedOffers, setHideLinkedOffers] = useState('');

  const offerOptionsMemo = useMemo(() => {
    const linkedItemOffers = relatedOffers.filter(
      (offer) => offer.item_type === eventConfig.linked_item_type
    );

    const offerOptionsArray = [{
      value: '',
      label: 'None'
    }];
    linkedItemOffers.forEach((offer) => {
      offerOptionsArray.push({
        value: offer.id,
        label: offer.name,
      });
    });
    return offerOptionsArray;
  }, [relatedOffers, eventConfig.linked_item_type]);

  useEffect(() => {
    if (eventConfig.linked_item_type) {
      const selectedOption = getWholeOption(eventConfig.linked_item_type, filteredRelatedTemplateOptions || []);
      setHideLinkedOffers(selectedOption.label);
    } else {
      setHideLinkedOffers('');
    }
  }, [eventConfig.linked_item_type, filteredRelatedTemplateOptions]);

  useEffect(() => {
    if (!eventConfig.linked_item_type) {
      return;
    }

    if (Object.keys(eventsByTemplateId).length === 0) {
      return;
    }

    const events = eventsByTemplateId[eventConfig.linked_item_type];

    const eventOptionsArray = (events || []).map((event) => ({
      value: event.id,
      label: event.event_name,
    }));
    setEventOptions(eventOptionsArray);
  }, [
    eventConfig.linked_item_type,
    eventsByTemplateId,
    selectedTemplate
  ]);

  return (
    <Box display="flex" marginTop="20px" alignItems="end" gap={1} justifyContent="flex-start" flexWrap="wrap">
      <GridItem colSpan={3}>
        <Box height={70}>
          <CustomSelectBox
            options={filteredRelatedTemplateOptions || []}
            placeholder="Select item type"
            value={getWholeOption(
              eventConfig.linked_item_type,
              filteredRelatedTemplateOptions || []
            )}
            onChange={(value) => {
              setHideLinkedOffers(value.label);
              handleChange(value.value, `eventConfig[${index}].linked_item_type`);
              setLinkedItemSelected(true);
            }}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            styles={customStyleFromRuleForSelect}
            id="DynamicRendererSelectCustomBoxItemType"
            label="Linked Item Type"
            isMandatory
            isError={!linkedItemSelected && (errorKeyListWithMessage?.eventConfig || false)}
            errorMessage="Linked Item Type is required"
          />
        </Box>
      </GridItem>
      {hideLinkedOffers ==='Self' ? null : (
      <GridItem colSpan={3}>
        <Box>
          <CustomSelectBox
            options={offerOptionsMemo || []}
            placeholder="Select offer"
            value={getWholeOption(
              eventConfig.linked_offer_id,
              offerOptionsMemo || []
            )}
            onChange={(value) => {
              handleChange(value.value, `eventConfig[${index}].linked_offer_id`);
            }}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            styles={customStyleFromRuleForSelect}
            id="DynamicRendererSelectCustomBoxItemType"
            label="Linked Offer"
          />
        </Box>
      </GridItem>
      )}
      <GridItem colSpan={3}>
        <Box height={70}>
          <CustomSelectBox
            options={eventOptions}
            placeholder="Select Event"
            value={getWholeOption(eventConfig.listened_event, eventOptions)}
            onChange={(value) => {
              handleChange(value.value, `eventConfig[${index}].listened_event`);
            }}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            styles={customStyleFromRuleForSelect}
            id="DynamicRendererEditTriggerListingEventSelectCustomBox"
            label="Listened Event"
            isMandatory
            isDisabled={!eventConfig.linked_item_type}
          />
        </Box>
      </GridItem>
      <GridItem colSpan={3}>
        <Flex alignItems="end" height="100%">
          <IconButton
            variant="outline"
            aria-label="Add"
            icon={<AddIcon />}
            onClick={onAddEventConfigRow}
            style={{ marginRight: '8px' }}
          />
          <IconButton
            variant="outline"
            aria-label="Delete"
            color="red"
            icon={<DeleteIcon />}
            onClick={onRemoveEventConfigRow}
            isDisabled={actionState.eventConfig.length === 1}
          />
        </Flex>
      </GridItem>
    </Box>
  );
};

export default ActionEventConfigRow;

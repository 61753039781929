/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import _uppercase from 'lodash/upperCase';
import _flattenDeep from 'lodash/flattenDeep';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import {
  FormLabel,
  Wrap,
  WrapItem,
  IconButton,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import { Group } from 'antd/es/avatar';
import CustomButton from '../Button/SimpleButton';
import CustomSelect from '../SelectBox/Select';
import styles from './dynamicSelectWithInputTags.module.scss';
import {
  COLORS,
  DARK,
  LIGHT,
  TEXT_DARK,
  TEXT_LIGHT,
} from '../../utils/enums/colors';
import { LABELS } from '../../utils/enums/labels';
// eslint-disable-next-line import/no-cycle
import {
  // getActorsByTemplateId,
  ROLE_SETTING_TYPE_VS_INPUT_TYPE,
  ROLE_SETTING_TYPE_VS_OPTIONS,
  parseComponentValuesToAPIData,
  parseAPIDataToComponentValues,
  getActorsByTemplateId,
  getAppusers,
  getTenantRolesAndTeams,
  getUserAttributes,
  getGroup,
} from './dynamicSelectWithInputTags.helper';
import CustomField from '../dynamicExpressionWithTags/CustomField';
import {
  roleSettingSelectOpt,
  roleMapping,
  userAttribute,
} from '../../utils/enums/selectOption';
import useKeycloak from '../../utils/hooks/useKeyCloak';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';
import { getQueueList } from '../../pages/CreateMailServer/utils';
import { SecondaryCustomButton } from '../Button/PrimarySecondaryButton';
import { stripEmptyArraysFromObject } from '../dynamicSelectWithTags/dynamicSelectWithTags.helper';
import WarningTriggerModel from '../../pages/DynamicRenderer/WarningTriggerModel';
import { deleteTriggerConfirmation, deleteTriggersFromWarningModel } from '../../pages/TemplateCardListView/helpers';

const DynamicMutliSelectWithInputTags = ({
  label,
  selectOptions,
  mapping,
  onChange,
  roleSettingTypeVsInputType,
  roleSettingTypeVsOptions,
  values,
  templateId,
  isMandatory,
  disableAddButton,
  isReadOnly,
  isAddedOrganization,
  includeUserAttributes,
  showOnlyUserAttributes,
  showQueueMapField,
  actorOption,
  layoutState
}) => {
  const [options, setOptions] = useState([]);
  const [componentState, setComponentState] = useState({
    value: '',
    type: selectOptions[0],
  });
  const [attributeOptions, setAttributeOption] = useState([]);
  const [leftOptionList, setLeftOptionList] = useState([]);
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const [keycloak] = useKeycloak();
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [attributesLoaded, setAttributesLoaded] = useState(false);
  const addKeyValue = ({ key, value }) => {
    let valueByKey = _get(keyValuePairs, `[${key}]`, []) || [];
    if (key === 'queues' && !Array.isArray(value)) return;
    if (value?.label) {
      if (valueByKey.findIndex((vbk) => vbk.value === value.value) > -1) return;
    } else if (valueByKey.findIndex((vbk) => vbk === value) > -1) return;
    if (Array.isArray(value)) {
      valueByKey = [...valueByKey, ...value];
    } else {
      valueByKey.push(value);
    }
    setKeyValuePairs((prevState) => {
      const newState = {
        ...prevState,
        [key]: valueByKey,
      };
      const apiReadyData = parseComponentValuesToAPIData(newState);
      onChange(stripEmptyArraysFromObject(apiReadyData));
      return newState;
    });
  };

  const [triggersLoader,setTriggersLoader]=useState(false) 
  const [deleteModelState,setDeleteModelState]=useState({isDeleted:false,model:false,key:null,value:null})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isBotUserDeleted,setIsBotUserDeleted] = useState(false)
  const onCloseModel = () =>{
    setDeleteModelState({isDeleted:false,model:false,key:null,value:null}) 
    setIsBotUserDeleted(false)
    setTriggersLoader(false)
    onClose
  }
  const handleDeleteModel = () =>{
    setDeleteModelState({key: deleteModelState.key,value:deleteModelState.value ,isDeleted:true,model:true})
  }
  useEffect(() => {
    if (deleteModelState.key && deleteModelState.value && deleteModelState.isDeleted) {
      setIsBotUserDeleted(true)
      if(deleteModelState.value.collectedData)deleteTriggerConfirmation(deleteModelState.value.collectedData)
      removeKeyValue({key: deleteModelState.key,value:deleteModelState.value.value})
    }
  }, [deleteModelState]);

  const [getLayoutData ,setLayoutData] = useState([])
  useEffect(()=>{
    setLayoutData(layoutState)
  },[]) 

 
  const removeKeyValue = async ({ key, value }) => {
    if(value.value === 'bot_user' && value.label==='Bot User' && !deleteModelState.isDeleted && getLayoutData?.viewers?.roles?.includes("bot_user") && !isBotUserDeleted){
      setTriggersLoader(true)
      const collectedData = await deleteTriggersFromWarningModel(layoutState.id)
      setTriggersLoader(false)
      if(collectedData && collectedData?.length !== 0){
        setDeleteModelState({isDeleted:false,model:true ,key,value:{value,collectedData}})
        return
      }
    }else{
      setDeleteModelState({isDeleted:false,model:false,key:null,value:null}) 
    }
    if(!setDeleteModelState.model){
      const valueByKey = _cloneDeep(keyValuePairs[key]);
      _remove(valueByKey, (vbk) => {
        if (vbk.value && vbk.value === value.value) {
          return true;
        }
        return vbk === value;
      });
      setKeyValuePairs((prevState) => {
        // Create a shallow copy of prevState
        const newState = { ...prevState };
    
        // Check if valueByKey is empty and delete the key if true
        if (Array.isArray(valueByKey) && valueByKey.length === 0) {
          delete newState[key];
        } else {
        // Otherwise, update the state with the new value
          newState[key] = valueByKey;
        }
        const apiReadyData = parseComponentValuesToAPIData(newState);
        onChange(stripEmptyArraysFromObject(apiReadyData));
        return newState;
      });   
    }
  };

  useEffect(() => {
    if (!_isEmpty(keyValuePairs)) {
      const apiReadyData = parseComponentValuesToAPIData(keyValuePairs);
      onChange(stripEmptyArraysFromObject(apiReadyData));
    }
  }, [keyValuePairs]);

  useEffect(() => {
    if (!_isEmpty(values) && !_isEmpty(options)) {
      const renderableDataFromValues = parseAPIDataToComponentValues(
        values,
        options
      );
      setKeyValuePairs(renderableDataFromValues);
      const apiReadyData = parseComponentValuesToAPIData(keyValuePairs);
      onChange(stripEmptyArraysFromObject(apiReadyData));
    }
  }, [options]);

  useEffect(() => {
    /**
     * api call for get info
     */
    getTenantRolesAndTeams().then((res) => {
      let roles = res?.defaultProducts?.map((item) =>
        _map(item?.permissions, (perm) => ({
          value: perm,
          label: _startCase(_camelCase(perm)),
        }))
      );
      roles = _.uniqBy(_.flattenDeep(roles), 'value') || _flattenDeep(roles);
      setOptions((prevState) => ({
        ...prevState,
        roles,
      }));
      const teams = res?.team?.team?.map((item) => ({
        value: item,
        label: _startCase(_capitalize(item)),
      }));
      setOptions((prevState) => ({
        ...prevState,
        teams,
      }));
      const org = res?.domain?.split('.');
      if (!_isEmpty(org)) {
        setOptions((prevState) => ({
          ...prevState,
          org: org[0],
        }));
        setComponentState((prevState) => ({
          ...prevState,
          value: org[0],
        }));
      }
    });
  }, [keycloak]);

  useEffect(() => {
    if (componentState?.type?.value === 'queues') {
      if (showQueueMapField) {
        const queueFieldList = [...(selectedTemplate?.fields || [])].filter((item) => item.type === 'DROPDOWN' && _get(item, 'type_based_attributes.collection.collection_type') === 'SPECIAL_FIELD' && _get(
          item,
          'type_based_attributes.collection.specialField'
        ) === 'queue');
        const option = queueFieldList.map((item) => ({ value: item.id, label: item.label }));
        if (option.length) {
          setOptions((prevState) => ({
            ...prevState,
            queues: option,
          }));
        }
      } else {
        getQueueList().then((qList) => {
          const users = qList.response ?? [];
          setOptions((prevState) => ({
            ...prevState,
            queues: users,
          }));
        });
      }
    }
    if (componentState?.type?.value === 'users') {
      // getAppusers().then((res) => {
      //   const users = res?.map((item) => ({
      //     value: item?.email ? item?.email : item.username,
      //     label: item?.email ? item?.email : item.username,
      //   }));
      //   setOptions((prevState) => ({
      //     ...prevState,
      //     users,
      //   }));
      // });
      const users = availableUsersListInTenantLevel();
      setOptions((prevState) => ({
        ...prevState,
        users,
      }));
    }
  }, [selectedTemplate, leftOptionList]);

  useEffect(() => {
    const actors = _map(selectedTemplate?.actors || actorOption || [], (actor) => ({
      value: actor.id,
      label: _startCase(_camelCase(actor.name)),
    }));
    if (actors.length) {
      setOptions((prevState) => ({
        ...prevState,
        actors, // Update actors
      }));
    }
  }, [selectedTemplate, actorOption]);

  // const fetchActors = async () => {
  //   const template = await getActorsByTemplateId(templateId);
  //   const actors = template?.actors?.map((item) => ({
  //     value: item.id,
  //     label: item.name,
  //   }));
  //   setOptions((prevState) => ({
  //     ...prevState,
  //     actors,
  //   }));
  // };
  useEffect(() => {
    if (isEmpty(keyValuePairs) && isAddedOrganization) {
      getTenantRolesAndTeams().then((res) => {
        const org = res?.domain?.split('.');
        if (!_isEmpty(org)) {
          addKeyValue({
            key: 'org',
            value: org[0],
          });
        }
      });
    }
  }, [keyValuePairs]);

  useEffect(() => {
    if (showQueueMapField) {
      const queueFieldList = [...(selectedTemplate?.fields || [])].filter((item) => item.type === 'DROPDOWN' && _get(
        item,
        'type_based_attributes.collection.specialField'
      ) === 'queue' &&
       _get(item, 'type_based_attributes.collection.collection_type') === 'SPECIAL_FIELD'
      );
      const option = queueFieldList.map((item) => ({ value: item.id, label: item.label }));
      if (option.length) {
        setOptions((prevState) => ({
          ...prevState,
          queues: option,
        }));
      }
    } else {
      getQueueList().then((qList) => {
        const users = qList.response ?? [];
        setOptions((prevState) => ({
          ...prevState,
          queues: users,
        }));
      });
    }

    getGroup().then((groupListResp) => {
      const groupList = groupListResp?.response?.rows ?? [];
      setOptions((prevState) => ({
        ...prevState,
        groups: groupList.map(({ groupText }) => ({ label: groupText, value: groupText })),
      }));
    });
    const users = availableUsersListInTenantLevel();
    setOptions((prevState) => ({
      ...prevState,
      users,
    }));
    const actors = _map(selectedTemplate?.actors || actorOption || [], (actor) => ({
      value: actor.id,
      label: _startCase(_camelCase(actor.name)),
    }));
    if (actors.length) {
      setOptions((prevState) => ({
        ...prevState,
        actors, // Update actors
      }));
    }
    if (
      includeUserAttributes
        || showOnlyUserAttributes
        || selectOptions?.find((item) => item.value === userAttribute)
    ) {
      getUserAttributes().then((res) => {
        const option = selectOptions.filter((item) => item.value !== 'teams');
        const apiOption = res.response.rows
          .map((item) => {
            const localOption = item.valueLabels
              ?.map((data) => ({ value: data, label: _startCase(_camelCase(data)) }))
              || [];
            setOptions((prevState) => ({
              ...prevState,
              [item.key]: localOption
            }));

            return { value: item.key,
              label: _startCase(item.label),
              rightOptionType: item.validationRule.field };
          });
        setAttributeOption(apiOption);
        const userAttributeOption = option.find(({ value }) => value === userAttribute);

        if (!userAttributeOption) options.push({ value: userAttribute, label: 'User Attribute', icon: Group });

        if (showOnlyUserAttributes) {
          setLeftOptionList(userAttributeOption ? [userAttributeOption] : []);
          setAttributesLoaded(true);
          setComponentState((prevState) => ({
            ...prevState,
            typeAttribute: roleSettingSelectOpt.find(({ value }) => value === userAttribute),
            type: apiOption[0],
            value: '',
          }));
        } else {
          setLeftOptionList([...option]);
        }
      }).catch(() => {
        if (showOnlyUserAttributes) {
          setLeftOptionList([]);
          setComponentState({
            value: '',
            type: null,
          });
          setAttributesLoaded(true);
        } else {
          setLeftOptionList([...selectOptions]);
        }
      });
    } else {
      setLeftOptionList([...selectOptions]);
    }
  }, []);

  const getTypeForSelectedOption = useCallback((type) => {
    if (type?.rightOptionType === 'Predefined List') {
      return 'multi-select';
    }
    if (type?.rightOptionType === 'Free Text') {
      return 'input';
    }
    if (type?.value === 'org') {
      return 'input-disabled';
    }
    if (type?.value === 'groups') {
      return 'multi-select';
    }
    return 'multi-select';
  }, [componentState]);

  const getLabelForType = useCallback((key) => {
    if (key === 'org') {
      return 'Organization: ';
    }
    return `${_startCase(key)}: `;
  }, []);

  if (showOnlyUserAttributes && !attributesLoaded) {
    return null;
  }

  // eslint-disable-next-line arrow-body-style
  const getValueOptions = () => {
    const inputType = getTypeForSelectedOption(componentState?.type);

    if (inputType.includes('select')) {
      return options[componentState?.type?.value]
        ?.filter(
          ({ value }) => !keyValuePairs[componentState?.type?.value]
            ?.some((item) => value === item.value)
        );
    }

    return options[componentState?.type?.value];
  };

  return (
    <Box
      mb="2px"
      className={styles.dynamicSelectWithMutliInputTags}
      data-testid="DynamicSelectWithMutliInputTagsBox"
    >
      <FormLabel
        className="SCLabel"
        marginBottom="10px"
        color={useColorModeValue(TEXT_LIGHT, TEXT_DARK)}
        data-testid="DynamicSelectWithMutliInputTagsFormLabel"
      >
        {label}
        {isMandatory ? (
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        ) : null}
      </FormLabel>
      <Wrap
    spacing="10px"
    align="center"
    data-testid="DynamicSelectWithMutliInputTagsWrap"
    className="dynamic__select__wrap__container"
  >
    <WrapItem>
      <CustomSelect
        options={leftOptionList}
        value={componentState?.typeAttribute || componentState?.type || leftOptionList[0]}
        onChange={(val) => {
          if (userAttribute === val?.value && attributeOptions?.length) {
            setComponentState((prevState) => ({
              ...prevState,
              typeAttribute: val,
              type: attributeOptions[0],
              value: '',
            }));
            return;
          }
          setComponentState((prevState) => {
            const newState = { ...prevState };
            delete newState.typeAttribute;
            return {
              ...newState,
              type: val,
              value: val?.value === 'org' ? options?.org : '',
            };
          });
        }}
        placeholder="select option"
        getOptionLabel={(e) => (
          <Flex align="center" className="text-[14px]">
            {e.icon && <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />}
            <span className="ml-1">{e.label}</span>
          </Flex>
        )}
        id="DynamicSelectWithMutliInputTagsSelect"
        isDisabled={isReadOnly}
      />
    </WrapItem>
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      gap="10px"  // Adjust the spacing as needed
      wrap="nowrap"  // Prevents wrapping to keep everything in a single line
      data-testid="DynamicSelectWithMutliInputTagsFlex"
      className="dynamic__select__flex__container"
     >
       {componentState?.typeAttribute && (
    <>
      <Box
        flex={2}
        display={componentState?.type?.value === 'org' ? 'flex' : 'none'}
        justifyContent="center"
        alignItems="center"
        mt="7px"
        className="org__label__container"
      >
        <FormLabel className={styles.roleTitleText}>
          {LABELS.TITLE.LOGGED_FROM}
        </FormLabel>
      </Box>
      <CustomSelect
        options={attributeOptions}
        value={componentState?.typeAttribute ? componentState?.type : attributeOptions[0]}
        onChange={(val) => {
          setComponentState((prevState) => ({
            ...prevState,
            type: val,
            value: val?.value === 'org' ? options?.org : '',
          }));
        }}
        placeholder="select option"
        getOptionLabel={(e) => (
          <Flex align="center" className="text-[14px]">
            {e.icon && <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />}
            <span className="ml-1">{e.label}</span>
          </Flex>
        )}
        id="DynamicSelectWithMutliInputTagsSelect"
        isDisabled={isReadOnly}
      />
    </>
)}
<CustomField
 value={componentState?.value}
 type={getTypeForSelectedOption(componentState?.type)}
 onChange={(value) => {
   setComponentState((prevState) => ({
     ...prevState,
     value,
   }));
 }}
 options={getValueOptions()}
/>
   <SecondaryCustomButton
     id="button-addSelectInputWithTag-role"
     buttonText={LABELS.ACTIONS.ADD}
     variant="outline"
     _hover={{ bg: 'blue.500', color: 'white' }}
     isDisabled={disableAddButton || isReadOnly || (componentState?.type?.value === 'org' && keyValuePairs?.org)}
     onClick={() => {
       if (componentState.value) {
         addKeyValue({
           key: componentState?.type?.value,
           value: componentState.value,
         });
       }
       if (componentState?.type?.value !== 'org') {
            setComponentState((prevState) => ({
              ...prevState,
              value: '',
            }));
          }
        }}
      />
    </Flex>
  </Wrap>
      {keyValuePairs && (
        <Wrap
          spacing="15px"
          mt="22px"
          data-testid="DynamicSelectWithMutliInputTagsWrap"
        >
          {Object.entries(keyValuePairs)?.map(([keyItem, valueArray]) => (
            <Wrap
              spacing={2}
              pl="10px"
              pt="3px"
              pb="3px"
              maxW="380px"
              display={valueArray?.length > 0 ? '' : 'none'}
              border={useColorModeValue(
                `1px solid ${LIGHT}`,
                `1px solid ${DARK}`
              )}
              borderRadius="4px"
              key={uuidv4()}
              data-testid="DynamicSelectWithMutliInputTagsInnerWrap"
              height="fit-content"
            >
              <WrapItem data-testid="DynamicSelectWithMutliInputTagsWItem">
                <Wrap data-testid="DynamicSelectWithMutliInputTagsBoxWrap">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    data-testid="DynamicSelectWithMutliInputTagsMApBpx"
                  >
                    {/* {`${mapping ? mapping[keyItem] : keyItem} : `} */}
                    {getLabelForType(keyItem)}
                  </Box>
                  {valueArray?.length > 0 &&
                    valueArray.map((value) => (
                      <Flex
                        key={value}
                        justifyContent="center"
                        alignItems="center"
                        bg={useColorModeValue(
                          COLORS.WRAP_OPERATOR_BG,
                          COLORS.WRAP_OPERATOR_BG_DARK
                        )}
                        borderRadius="4px"
                        fontWeight={500}
                        height="28px"
                        maxW="350px"
                        paddingLeft="1rem"
                        data-testid="DynamicSelectWithMutliInputTagsBtnFlex"
                      >
                        <Box data-testid="DynamicSelectWithMutliInputTagsBtnBox">
                          {value?.label ? value?.label : value}
                        </Box>
                        <IconButton
                          background="ffffff"
                          _hover={{
                            background: 'unset'
                          }}
                          icon={<SmallCloseIcon />}
                          onClick={() => {
                            removeKeyValue({
                              key: keyItem,
                              value,
                            });
                            setComponentState((prevState) => ({
                              ...prevState,
                              value: keyItem === 'org' ? value : prevState.value,
                            }));
                          }
                          }
                          data-testid="DynamicSelectWithMutliInputTagsIconBtn"
                          isDisabled={isReadOnly}
                        />
                      </Flex>
                    ))}
                </Wrap>
              </WrapItem>
            </Wrap>
          ))}
        </Wrap>
      )}
      <WarningTriggerModel isOpen={triggersLoader || deleteModelState.model} triggersLoader={triggersLoader}   onClose={onCloseModel} handleDeleteModel={handleDeleteModel}/>
    </Box>
  );
};

DynamicMutliSelectWithInputTags.propTypes = {
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.array,
  mapping: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  templateId: PropTypes.number.isRequired,
  roleSettingTypeVsInputType: PropTypes.object,
  roleSettingTypeVsOptions: PropTypes.object,
  isMandatory: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isAddedOrganization: PropTypes.bool,
  includeUserAttributes: PropTypes.bool,
  showOnlyUserAttributes: PropTypes.bool,
  showQueueMapField: PropTypes.bool,
  actorOption: PropTypes.array,
};

DynamicMutliSelectWithInputTags.defaultProps = {
  selectOptions: roleSettingSelectOpt,
  roleSettingTypeVsInputType: ROLE_SETTING_TYPE_VS_INPUT_TYPE,
  roleSettingTypeVsOptions: ROLE_SETTING_TYPE_VS_OPTIONS,
  mapping: roleMapping,
  isMandatory: false,
  disableAddButton: false,
  isReadOnly: false,
  isAddedOrganization: false,
  includeUserAttributes: false,
  showOnlyUserAttributes: false,
  showQueueMapField: true,
  actorOption: []
};

export default DynamicMutliSelectWithInputTags;

import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { CURRENT_DATE_PLACEHOLDER, CURRENT_DATE_PLACEHOLDER_LABEL, FUNCTION_LABELS, FUNCTION_TYPES, RULE_TYPES, TIMEFRAME_LABELS, TIMEFRAME_TYPES } from './dateRestrictions.constants';

const DATE_FORMAT = 'MM/DD/YYYY';
const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';

const getConditionalValidationObject = (length) => ({
  id: uuid(),
  order: length + 1,
  condition: {},
  rules: [],
});

const getRuleObject = () => ({
  id: uuid(),
  type: RULE_TYPES.RELATIVE,
  offset: 0,
  timeframe: TIMEFRAME_TYPES.RANGE,
  reference: CURRENT_DATE_PLACEHOLDER,
  functionName: '',
});

const getDateString = (date, showTime) => {
  if (!date) {
    return '';
  }

  return showTime ? moment(date).format(DATE_TIME_FORMAT) : moment(date).format(DATE_FORMAT);
};

const getAbsoluteRulePreviewObject = (rule, showTime) => {
  const { start, end } = rule;

  if (start && end) {
    return [{
      left: `Between ${getDateString(start, showTime)}`,
      middle: 'and',
      right: getDateString(end, showTime),
    }];
  }

  if (start) {
    return [{
      left: 'Any date',
      middle: 'After',
      right: getDateString(start, showTime),
    }];
  }

  if (end) {
    return [{
      left: 'Any date',
      middle: 'Before',
      right: getDateString(end, showTime),
    }];
  }

  return [{
    left: 'Any date',
  }];
};

const getReferenceLabel = (reference, templateFields) => {
  if (reference === CURRENT_DATE_PLACEHOLDER) {
    return CURRENT_DATE_PLACEHOLDER_LABEL;
  }

  const field = templateFields.find((templateField) => templateField.id === reference);

  return field ? field.label : reference;
};

const getRelativeRulePreviewObject = (rule, templateFields) => {
  const { offset, timeframe, reference, functionName } = rule;

  const referenceLabel = getReferenceLabel(reference, templateFields);
  const functionNameLabel = FUNCTION_LABELS[functionName];
  const offsetLabel = `${offset} day${offset < 1 ? '' : 's'}`;
  const timeframeLabel = TIMEFRAME_LABELS[timeframe];

  return [{
    left: functionNameLabel ? `${offsetLabel} and ${functionNameLabel}` : offsetLabel,
    middle: timeframeLabel,
    right: referenceLabel,
  }];

  // return [{
  //   left: 'Any date',
  // }];
};

const getRulePreviewObject = (rule, templateFields, showTime) => {
  if (rule.type === RULE_TYPES.ABSOLUTE) {
    return getAbsoluteRulePreviewObject(rule, showTime);
  }

  return getRelativeRulePreviewObject(rule, templateFields);
};

const cleanRules = (rules) => (rules || [])
  .map((rule) => {
    if (rule.type === RULE_TYPES.ABSOLUTE) {
      rule.start = rule.start ? moment(rule.start).toDate() : null;
      rule.end = rule.end ? moment(rule.end).toDate() : null;
    }

    if (rule.type === RULE_TYPES.RELATIVE) {
      rule.offset = rule.offset || 0;
      rule.timeframe = rule.timeframe || TIMEFRAME_TYPES.RANGE;
      rule.reference = rule.reference || CURRENT_DATE_PLACEHOLDER;
      rule.functionName = rule.functionName || FUNCTION_TYPES.CURRENT;
    }

    return rule;
  });

const getDateValidationsObject = (validations) => {
  const validationObject = validations ? cloneDeep(validations) : {
    defaultValidations: { rules: [] },
    conditionalValidations: [],
  };

  validationObject.defaultValidations = validationObject.defaultValidations || { rules: [] };

  validationObject.defaultValidations.rules = cleanRules(
    validationObject.defaultValidations.rules
  );

  validationObject.conditionalValidations = validationObject.conditionalValidations?.length
    ? validationObject.conditionalValidations.map((cv) => ({
      ...cv,
      condition: cv.condition || {},
      rules: cleanRules(cv.rules),
    }))
    : [];

  return validationObject;
};

export {
  getRuleObject,
  getRulePreviewObject,
  getDateValidationsObject,
  getConditionalValidationObject,
};

/* eslint-disable react/prop-types */
import React from 'react';
import { Box, FormLabel } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import FieldMappingSection from './FieldMappingSection';
import SelectFieldFromTemplateWithDelete from '../../components/selectFieldFromTemplateWithDelete';
import { MAIL_CONFIG } from '../../utils/enums/labels';

const defaultFieldMapping = {
  emailMapping: [],
  attachmentFieldId: '',
  optionalMappings: [],
  requiredMappings: [],
  emailAttachmentFieldId: '',
  isEmailAttachmentShow: false,
  whiteLabelLisiting: []
};

const TicketMappingPage = ({
  isEditMode,
  mailServerState,
  handleInputWithPath,
  handleInputWithPaths,
  templateOptionList,
  errorListWithKey,
  hierarchies,
  templateAndRelatedData,
  setErrorListWithKey,
}) => {
  const { itemTypeId, offerId } = mailServerState;
  const {
    template,
    offersList,
    fieldHierarchyMap,
  } = templateAndRelatedData;

  const offersOptionList = itemTypeId
    ? [
      {
        label: 'None',
        value: 'NONE',
      },
      ...offersList.map((offer) => ({
        label: offer.name,
        value: offer.id,
      })),
    ]
    : [];

  const requiredFields = (template?.fields || [])?.filter((field) => {
    if (!field?.scope || field?.scope === 'TICKET') {
      return field?.type_based_attributes?.required;
    }
    return false;
  });

  const handleOfferIdChange = (newOfferId) => {
    const selectedOffer = offersList.find((offer) => offer.id === newOfferId);

    const getRequiredFieldValueFromSelectedOffer = (fieldId) => {
      if (!selectedOffer) {
        return undefined;
      }

      return (
        (selectedOffer.field_attributes || []).find(
          (attributeObject) => attributeObject?.field?.value === fieldId
        )?.value || undefined
      );
    };

    const fieldDefaultValues = (requiredFields || []).map((field) => ({
      field: { label: field.label, value: field.id },
      value: getRequiredFieldValueFromSelectedOffer(field.id),
    }));

    const fieldMapClone = cloneDeep(
      mailServerState?.fieldMapping || defaultFieldMapping
    );

    fieldMapClone.requiredMappings = fieldDefaultValues;
    handleInputWithPaths({
      offerId: newOfferId,
      fieldMapping: fieldMapClone,
    });
  };
  return (
    <Box mt="16px">
      <FormLabel className="SCLabel" w="630px" mb="16px">
        Resolver for ticket type, field values, and queue derived based on
        fields and ticket type. Canned form mapping by ticket type
      </FormLabel>
      <Box>
        <CustomSelectBox
          label={`Template ${isEditMode ? '(Cannot be changed)' : ''}`}
          options={templateOptionList}
          onChange={(e) => {
            handleInputWithPath(e.value, 'itemTypeId');
          }}
          value={getWholeOption(itemTypeId, templateOptionList)}
          isDisabled={isEditMode && !!itemTypeId}
          isError={errorListWithKey?.itemTypeId || false}
          errorMessage={errorListWithKey?.itemTypeId || ''}
        />
      </Box>
      <Box mt="16px">
          <CustomSelectBox
          label={'Whitelist Ticket types'}
          options={templateOptionList}
          onChange={(e) => {
            const whiteLabelClone = cloneDeep(
              mailServerState?.fieldMapping || defaultFieldMapping
            );
            whiteLabelClone.whiteLabelLisiting = e;
            handleInputWithPath(whiteLabelClone, 'fieldMapping');
          }}
          value={mailServerState?.fieldMapping?.whiteLabelLisiting || []}
          isMulti={true}
        />
      </Box>
      <FormLabel mt="24px" className="SCLabel">
        {MAIL_CONFIG.REQUIRED_MAPPING_LABEL}
      </FormLabel>
      <Box w="350px">
        <CustomSelectBox
          key={template?.id}
          mt="16px"
          label="Use from offer"
          options={offersOptionList}
          onChange={(e) => {
            if (e.value !== offerId) {
              handleOfferIdChange(e.value);
            }
          }}
          value={getWholeOption(offerId, offersOptionList)}
        />
      </Box>
      <Box w="630px">
        <SelectFieldFromTemplateWithDelete
          key={`${itemTypeId}-${offerId}`}
          fieldList={requiredFields}
          value={mailServerState?.fieldMapping?.requiredMappings || []}
          workFlowStatusList={
            template?.workflow?.workflow_status
              ?.filter((item) => item.id !== 'start_1')
              .map((ele) => ({ value: ele.id, label: ele.data.label })) || []
          }
          onChange={(e) => {
            const fieldMapClone = cloneDeep(
              mailServerState?.fieldMapping || defaultFieldMapping
            );
            fieldMapClone.requiredMappings = e;
            handleInputWithPath(fieldMapClone, 'fieldMapping');
            setErrorListWithKey((pre) => ({ ...pre, requiredMappings: '' }));
          }}
          isRequired
          preventFieldRepeat
          hierarchies={hierarchies}
          fieldHierarchyMap={fieldHierarchyMap}
          actorAssociationMap={template?.actors || []}
        />
      </Box>
      {errorListWithKey?.requiredMappings && (
        <FormLabel className="SCLabel" color="red.500" mb="16px">
          {errorListWithKey?.requiredMappings}
        </FormLabel>
      )}
      <FieldMappingSection
        key={itemTypeId}
        value={mailServerState.fieldMapping || defaultFieldMapping}
        onChange={(value) => {
          handleInputWithPath(value, 'fieldMapping');
        }}
        selectedTemplate={template}
        hierarchies={hierarchies}
        fieldHierarchyMap={fieldHierarchyMap}
        errorListWithKey={errorListWithKey}
        setErrorListWithKey={setErrorListWithKey}
      />
      {errorListWithKey?.optionalMappings && (
        <FormLabel className="SCLabel" color="red.500" mb="16px">
          {errorListWithKey?.optionalMappings}
        </FormLabel>
      )}
    </Box>
  );
};

export default TicketMappingPage;

import React, { useState } from 'react';

import { Box } from '@chakra-ui/react';

import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import CustomInput from '../../../components/InputBox/Input';

import {
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';

const EditRelationTag = ({
  relations,
  onChange,
  label,
}) => {
  const [relationPlaceholder, setRelationPlaceholder] = useState('');

  return (
    <Box>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'flex-end',
        }}
      >
        <CustomInput
          type="text"
          label={label}
          value={relationPlaceholder}
          onChange={(e) => setRelationPlaceholder(e.target.value)}
          style={{ flex: 1 }}
          isMandatory
        />
        <SecondaryCustomButton
          buttonText="Add"
          onClick={() => {
            onChange([...relations, relationPlaceholder]);
            setRelationPlaceholder('');
          }}
          isDisabled={!relationPlaceholder}
          width="100px"
        />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        marginTop: '10px',
        marginBottom: '10px',
      }}
      >
        {
          relations?.map((relLeft, index) => (
            <div style={{
              border: '1px solid blue',
              padding: '5px',
              cursor: 'pointer',
              borderRadius: '5px',
              display: 'inline-flex',
              gap: '10px',
            }}
            >
              {relLeft}
              <div
                style={{
                  color: 'blue',
                }}
                onClick={() => {
                  const tempData = cloneDeep(relations);
                  tempData.splice(index, 1);
                  onChange(tempData);
                }}
              >
              X
              </div>
            </div>
          ))
        }
      </div>
    </Box>
  );
};

EditRelationTag.propTypes = {
  relations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

EditRelationTag.defaultProps = {};

export default EditRelationTag;

/* eslint-disable indent */
import CustomToast from '../../components/Notification/Notification';

import { getTenantData } from '../../helpers/tenantUrl';
import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../utils/axios/axios';

const { addToast } = CustomToast();

const showToastMessage = ({ status = 'success', description }) => {
  addToast({
    title: status === 'success' ? 'Success' : 'Something went wrong',
    message: description,
    type: status,
  });
};

export const cloneTemplate = async (id, name) => {
  try {
    const res = await axiosInstance.post('/templates/clone', {
      data: { id, name },
    });
    if (res.status < 300) {
      showToastMessage({
        description: 'Successfully cloned the template',
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    showToastMessage({
      status: 'error',
      description:
        err?.response?.data?.message || 'Failed to clone the template',
    });
    return {
      response: null,
      error: err?.response?.data?.message || 'Failed to clone the template',
    };
  }
};

export const deleteTemplate = async (id) => {
  try {
    const res = await axiosInstance.delete(
      `templates/delete?$filter=id eq ${id}`
    );
    if (res.status < 300) {
      showToastMessage({
        description: 'Successfully deleted the template',
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    showToastMessage({
      status: 'error',
      description:
        err?.response?.data?.message || 'Failed to delete the template',
    });
    return {
      response: null,
      error: err?.response?.data?.message || 'Failed to delete the template',
    };
  }
};

export const toggleFavoriteForTemplate = async (id, isFavorite) => {
  try {
    const requestBody = {
      data: {
        template_id: id,
        tenant_id: getTenantData()?.id,
      },
    };

    const endpoint = isFavorite ? '/favourites/remove' : 'favourites/add';

    const res = await axiosInstance.post(endpoint, requestBody);

    if (res.status < 300) {
      showToastMessage({
        description: isFavorite
          ? 'Removed from favourites'
          : 'Added to favourites',
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    const defaultErrorMessage = isFavorite
      ? 'Failed to remove from favourites'
      : 'Failed to add to favourites';

    showToastMessage({
      status: 'error',
      description: err?.response?.data?.message || defaultErrorMessage,
    });
    return {
      response: null,
      error: err.toString(),
    };
  }
};

export const getCreatedByInfo = (cardData) => {
  const owners = cardData?.properties?.owners;
  const createdBy = cardData?.created_by;
  if (createdBy) {
    return createdBy;
  }
  if (typeof owners === 'string' && owners.trim() !== '') {
    return owners.trim();
  }
  if (createdBy === null || createdBy === '') {
    return 'No Info';
  }
  return 'No Info';
};

export const getChildren = (allTemplates, currentTemplate) => {
  if (currentTemplate.properties?.child_templates_list.length) {
    const {
      properties: { child_templates_list: childTemplateIds },
    } = currentTemplate;
    const childIdStrings = childTemplateIds
      .filter((id) => id)
      .map((id) => id.toString());
    const children = allTemplates.filter((template) =>
      childIdStrings.includes(template.id)
    );
    return children;
  }

  return [];
};

export const getTreeItem = (allTemplates, template, children) => ({
  id: template.id,
  name: template.name,
  parent_id: template.properties?.parent_id,
  properties: {
    description: template?.properties?.description,
    parent_id: template.properties?.parent_id,
    owners: template.properties?.owners,
    prefix: template.properties?.prefix,
    defaultTicket: template.properties?.defaultTicket || false,
  },
  lifecycle_status: template.lifecycle_status,
  created_by: template.created_by,
  updated_on: template.updated_on,
  children: children.length
    ? children.map((child) =>
        getTreeItem(allTemplates, child, getChildren(allTemplates, child))
      )
    : [],
});

export const getTree = (allTemplates) => {
  const rootTemplates = allTemplates?.filter(
    (template) => !template?.properties?.parent_id
  );

  const tree = rootTemplates?.map((rootLevelTemplate) =>
    getTreeItem(
      allTemplates,
      rootLevelTemplate,
      getChildren(allTemplates, rootLevelTemplate)
    )
  );

  return tree;
};


export const deleteTriggersFromWarningModel = async (id) => {
  if(!id) return false 
  let getTemplateOffers = [];
  let getTemplateTriggers = [];
  await axiosInstance.get(`fieldDefaults/list?$select=*&$filter=item_type eq '${id}'`).then((res)=>{
    getTemplateOffers = res?.data?.rows ?? [] ;
  })
  await axiosInstanceWithoutBaseUrl.get(`botmgmt-service/qna/qnaRecords?limit=2000&sortName=1&sortOrder=desc&type=flow&answer=NA&botIntegration=True`).then((res)=>{
    getTemplateTriggers = res?.data?.data ?? [];
  })
  let manipulatedData = getTemplateOffers?.map((item) => {
    let getOfferidParent = getTemplateTriggers?.filter((data) => 
      data?.metadata?.filter((d1) => 
        d1?.name === "offers" && 
        d1?.value?.some((d2) => d2?.name === item?.name)
      ).length > 0  
    );
  
    return { 
      id: item?.id, 
      isPresented: getOfferidParent?.length > 0 ?? false, 
      getOfferidParent
    };
  }) ?? [];
  let collectData = manipulatedData.filter((data)=>data.isPresented).map((data)=> {
    let collectTransformedData = []
    if(data?.getOfferidParent){
      for (let offers of data?.getOfferidParent){
        let flowName = offers.metadata?.find((data)=>data.name === "flow").value+"-"+data.id
        collectTransformedData.push({id:offers.id ,flow:flowName })
      }
    }
    return collectTransformedData
  })
  return collectData.flat(Infinity)
}

export const deleteTriggersForOffers = async (offer) => {
  let getTemplateTriggers = []
  await axiosInstanceWithoutBaseUrl.get(`botmgmt-service/qna/qnaRecords?limit=2000&sortName=1&sortOrder=desc&type=flow&answer=NA&botIntegration=True`).then((res)=>{
    getTemplateTriggers = res?.data?.data ?? [];
  })
  let getData = getTemplateTriggers?.filter((data) => 
    data?.metadata?.filter((d1) => 
      d1?.name === "offers" && 
      d1?.value?.some((d2) => d2?.name === offer?.offerName && d2?.offerId === offer?.offerId)
    ).length > 0  
  ).map((singleOffer)=>{
    let flowName = singleOffer.metadata?.find((data)=>data.name === "flow").value+"-"+offer?.offerId
    return {id:singleOffer.id ,flow:flowName }
  })
  return getData
}

export const deleteTriggerConfirmation = async (data) =>{
  if(data && data?.length !== 0 ){
    await axiosInstanceWithoutBaseUrl.post(`botmgmt-service/qna/rollbackQnapair`,{data}).then((res)=>{
      return true;
    })
  } 
}
export const MOCK_EVENTS_TABLE_DATA = [
  {
    event_name: 'send',
    description: 'desc',
    trigger_type: { value: 'manual', label: 'Manual' },
    trigger: { value: 'field_change', label: 'Field Change' },
    permissions: { org: ['abc', 'bca'] },
    expressions: {},
    action_to_perform: [
      {
        action_type: { label: 'email', value: 'email' },
        to: 'abc@g.com',
        subject: 'subject',
        email_template: null,
        update_info: 'Admin',
        update_date: '19-06-2023',
        signature: 'actor',
      },
    ],
  },
];

export const INITIAL_STATE = {
  temp: true,
};

export const RECORD = {
  field: '',
  value: '',
};

export const NOTIFY_ON_TYPES_VALUE = {
  TEAMS: 'Teams',
  SLACK: 'Slack',
};

export const NOTIFY_ON_TYPES = [
  { value: NOTIFY_ON_TYPES_VALUE.TEAMS, label: 'Teams' },
  { value: NOTIFY_ON_TYPES_VALUE.SLACK, label: 'Slack' },
];

export const TRIGGER_TYPES = [
  { value: 'manual', label: 'Manual' },
  { value: 'time_triggers', label: 'Time Triggers' },
];

export const TRIGGER_TYPES_V1 = [{ value: 'manual', label: 'Manual' }];

export const MANUAL_TRIGGER_TYPES = {
  FIELD_CHANGE: 'field_change',
  ISSUE_ATTACHMENT_ADDED_REMOVED: 'Issue_atachment_added/removed',
  ISSUE_TRANSITIONED: 'issue_transitioned',
  ISSUE_MOVED: 'issue_moved',
  ISSUE_NOTE_ADDED: 'issue_note_added',
  ISSUE_LINKED: 'issue_linked',
  APPROVAL_DONE: 'approval_done',
  APPROVAL_FAILED: 'approval_failed',
  ACTOR_ASSIGNED: 'actor_assigned',
  TICKET_CREATED: 'ticket_created',
  TICKET_PRE_CREATION: 'ticket_pre_creation',
};

export const MANUAL_TRIGGERS = [
  { value: MANUAL_TRIGGER_TYPES.FIELD_CHANGE, label: 'Field Update' },
  {
    value: MANUAL_TRIGGER_TYPES.ISSUE_ATTACHMENT_ADDED_REMOVED,
    label: 'Issue Attachment Added/Removed',
  },
  { value: MANUAL_TRIGGER_TYPES.ISSUE_TRANSITIONED, label: 'Issue Transitioned' },
  { value: MANUAL_TRIGGER_TYPES.ISSUE_MOVED, label: 'Issue Moved' },
  { value: MANUAL_TRIGGER_TYPES.ISSUE_NOTE_ADDED, label: 'Issue Note Added' },
  { value: MANUAL_TRIGGER_TYPES.ISSUE_LINKED, label: 'Issue Linked' },
  // { value: MANUAL_TRIGGER_TYPES.APPROVAL_DONE, label: 'Approval Done' },
  // { value: MANUAL_TRIGGER_TYPES.APPROVAL_FAILED, label: 'Approval Failed' },
  { value: MANUAL_TRIGGER_TYPES.ACTOR_ASSIGNED, label: 'Actor Assigned' },
  { value: MANUAL_TRIGGER_TYPES.TICKET_CREATED, label: 'Ticket Created' },
  { value: MANUAL_TRIGGER_TYPES.TICKET_PRE_CREATION, label: 'Ticket Pre-Creation' },
];

export const MANUAL_TRIGGERS_V1 = [
  { value: MANUAL_TRIGGER_TYPES.ISSUE_TRANSITIONED, label: 'Issue Transitioned' },
];

export const TIME_TRIGGER_TYPES = {
  RELATIVE_ITEM: 'relative_item',
  SPECIFIC_HOURS_OF_DAY: 'specific_hours_of_day',
  BOD: 'bod',
  EOD: 'eod',
  CRON: 'cron',
  SLA: 'sla',
  SLA_RELATIVE_TIME: 'sla_relative_time',
  // TIME_OF_DAY: 'time_of_day',
};

export const TIME_TRIGGERS = [
  { value: TIME_TRIGGER_TYPES.RELATIVE_ITEM, label: 'Relative Item' },
  { value: TIME_TRIGGER_TYPES.SPECIFIC_HOURS_OF_DAY, label: 'Specific Hour of Day ' },
  { value: TIME_TRIGGER_TYPES.BOD, label: 'BOD' },
  { value: TIME_TRIGGER_TYPES.EOD, label: 'EOD' },
  { value: TIME_TRIGGER_TYPES.CRON, label: 'Cron' },
  { value: TIME_TRIGGER_TYPES.SLA, label: 'SLA' },
  { value: TIME_TRIGGER_TYPES.SLA_RELATIVE_TIME, label: 'SLA Relative Time' },
  // { value: 'time_of_day', label: 'Time of Day' },
];

export const ACTION_TYPE_VALUE = {
  SEND_EMAIL: 'send_email',
  UPDATE_RECORDS: 'update_records',
  NOTIFY_ON_TEAM_OR_SLACK: 'bot_notification',
  // LOG_METRICS: 'log_metrics',
  CREATE_ISSUES: 'create_issues',
  RUN_SCRIPT: 'run_script',
  TRIGGER_WEBHOOK: 'trigger_webhook',
  QUEUE_ASSIGNMENT: 'queue_assignment',
  ACTOR_ASSIGNMENT: 'actor_assignment',
  INITIATE_APPROVAL_FLOW: 'initiate_approval_flow',
  STATE_TRANSITION: 'state_transition',
  ADD_NOTE: 'add_note',
  ADD_WATCHERS: 'add_watchers',
  REMOVE_WATCHERS: 'remove_watchers',
  ADD_ATTACHMENT: 'add_attachment',
  REMOVE_ATTACHMENT: 'remove_attachment',
  TICKET_AUTOMATION: 'ticket_automation',
  ADD_TO_KNOWLEDGE: 'add_to_knowledge',
};

export const ACTION_TYPE_V1 = [
  { value: ACTION_TYPE_VALUE.SEND_EMAIL, label: 'Send Email' },
];

export const ACTION_TYPE = [
  { value: ACTION_TYPE_VALUE.SEND_EMAIL, label: 'Send Email' },
  { value: ACTION_TYPE_VALUE.UPDATE_RECORDS, label: 'Update Record(s)' },
  { value: ACTION_TYPE_VALUE.CREATE_ISSUES, label: 'Create Issue' },
  { value: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK, label: 'Bot Notification' },
  // { value: ACTION_TYPE_VALUE.LOG_METRICS, label: 'Log Metrics' },
  // { value: ACTION_TYPE_VALUE.RUN_SCRIPT, label: 'Run Script' }, // TODO: Enable post drop 2
  { value: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK, label: 'Trigger Webhook' },
  { value: ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT, label: 'Queue Assignment' },
  { value: ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT, label: 'Actor Assignment' },
  { value: ACTION_TYPE_VALUE.ADD_TO_KNOWLEDGE, label: 'Add to Knowledge' },
  // {
  //   value: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
  //   label: 'Initiate Approval Flow',
  // },
  {
    value: ACTION_TYPE_VALUE.STATE_TRANSITION,
    label: 'State Transition',
  },
  {
    value: ACTION_TYPE_VALUE.ADD_NOTE,
    label: 'Add Note',
  },
  {
    value: ACTION_TYPE_VALUE.ADD_WATCHERS,
    label: 'Add Watcher'
  },
  {
    value: ACTION_TYPE_VALUE.REMOVE_WATCHERS,
    label: 'Remove Watcher',
  },
  {
    value: ACTION_TYPE_VALUE.ADD_ATTACHMENT,
    label: 'Add Attachment',
  },
  {
    value: ACTION_TYPE_VALUE.REMOVE_ATTACHMENT,
    label: 'Remove Attachment'
  },
  {
    value: ACTION_TYPE_VALUE.TICKET_AUTOMATION,
    label: 'Ticket Automation'
  }
];

export const SIGNATURE_TYPE = [{ value: 'actor', label: 'Actor' }];

export const SCRIPT_TYPE = [{ value: 'bash', label: 'Bash Script' }];

export const STATUS_TYPE = [
  { value: 'ON', label: 'ON' },
  { value: 'OFF', label: 'OFF' },
];

export const TRANSITION_NAMES = [
  { value: 'any', label: 'Any' },
  { value: 'work_assigned', label: 'Work Assigned' },
  { value: 'work_done', label: 'Work Done' },
];

export const ENCODING_TYPE = {
  JSON: 'JSON',
  XML: 'XML',
};

export const fieldNameLabelMap = {
  event_name: 'Name',
  trigger_type: 'Trigger Type',
  trigger: 'Trigger Type',
  field: 'Field',
  transition_name: 'Transition Name',
  event: 'Event',
  amount_of_time: 'Amount Of Time',
  start_time: 'start time',
  to: 'To',
  subject: 'Subject',
  url: 'URL',
  request_type: 'Request Type',
  script_type: 'Script Type',
  notify_on: 'Notify On',
  team_id: 'Team Id',
  channel_id: 'channel Id',
  status: 'Status',
  template: 'Email Template',
  insert_data: 'Insert Data',
  script_to_run: 'Script To Run',
  notification_message: 'Notification Message',
  update_record: 'Update Records',
  create_issue: 'Create Issue',
  action_to_perform: '"Action to Perform"',
  events: 'Events',
  when_to_run: 'When to run',
  action_to_performs: 'Action to perform',
  number_of_approvals_required: 'Number of Approvals Required',
  type_of_approval: 'Type of Approval',
  flat_permitted_roles: 'Permitted Roles',
  heirarchical_permitted_roles: 'Permitted Roles',
  levels: 'Levels',
  action_name: 'Action name',
  listened_event: 'Listened event',
  linked_item_type: 'Linked item type',
  listening_conditions: 'listing conditions',
  event_conditions: 'Event conditions',
  user_conditions: 'User conditions'
};

export const signatureLabelOption = [
  { value: 'actors', label: 'Actor' },
  { value: 'teams', label: 'Team' },
  { value: 'queues', label: 'Queue' },
];

export const roleOptions = [{ value: 'roles', label: 'Role', icon: 'Role' }];

export const approvalOptionsValues = {
  flat: 'FLAT',
  heirarchical: 'HEIRARCHICAL',
};

export const approvalOptionsLabels = {
  flat: 'Flat',
  heirarchical: 'Heirarchical',
};

export const approvalOptions = [
  { value: approvalOptionsValues.flat, label: approvalOptionsLabels.flat },
  {
    value: approvalOptionsValues.heirarchical,
    label: approvalOptionsLabels.heirarchical,
  },
];

export const ASSIGNMENT_STRATEGY = [
  { value: 'ROUND_ROBIN', label: 'Round Robin' },
  { value: 'RANDOM', label: 'Random' },
  { value: 'LEAST_ISSUES', label: 'Least Issues' },
  // { value: 'MOST_AVAILABLE', label: 'Most Available' }
];

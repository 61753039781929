import moment from 'moment';

const ConfigProcessor = {};

ConfigProcessor.setTicketFormat = (props, tenantName, userProfile) => {
  // Define dynamic keys to be used
  const dynamicKeys = [
    'subject',
    'status',
    'priority',
    'created_at',
    'submittedby',
    'requestor',
  ];

  // Destructure the necessary properties from props
  const { list, itemTypes } = props;
  const pUser =
    userProfile?.profile?.username || userProfile?.userInfo?.preferred_username;
  const roles = userProfile?.realmAccess?.roles || [];
  if (!pUser && !roles) return [];
  // Initialize the array to store the results
  const getItemType = [];

  // Ensure the list and tenantName are defined
  if (!list || !tenantName) {
    return [];
  }

  // Filter the list based on the tenant name
  const filteredList = list.filter(
    (x) =>
      x?.summary?.toLowerCase() === tenantName.toLowerCase() ||
      x?.data?.customer === tenantName
  );

  // Check if the filtered list has any items
  if (filteredList.length === 0) {
    return [];
  }

  // Iterate over the filtered list
  filteredList.forEach((item) => {
    let serializeField = {
      caseId: item?.object_id || item?.data?.object_id || '',
      submittedOn: item?.created_at || item?.updated_at || '',
      toWatchers: item?.data?.toWatchers || [],
      ccWatchers: item?.data?.ccWatchers || [],
      submittedby: '',
      requestor: '',
      submittedByUserName: ''
    };
    serializeField.submittedOn = serializeField?.submittedOn && moment(serializeField.submittedOn).local().format('MMMM-DD, YYYY hh:mm A');

    // Ensure item.lookup_props and itemTypeId are defined
    const itemTypeId = item?.lookup_props?.itemTypeId;
    if (!itemTypeId || !itemTypes[itemTypeId]) {
      getItemType.push(serializeField);
      return;
    }

    const itemTypeDef = itemTypes[itemTypeId]?.definition;
    if (!itemTypeDef) {
      getItemType.push(serializeField);
      return;
    }
    // Iterate over the fields of the item type definition
    itemTypeDef.fields.forEach((elem) => {
      if (dynamicKeys.includes(elem?.label?.toLowerCase())) {
        if (elem?.label?.toLowerCase() === 'status') {
          const {
            workflow: { workflow_status },
          } = itemTypeDef;
          const fndStatus = workflow_status?.find(
            (x) => x?.id === item?.data[elem.id]
          );
          serializeField = {
            ...serializeField,
            [elem?.label?.toLowerCase()]:
              fndStatus?.data?.label || item?.data[elem.id] || null,
          };
        } else {
          serializeField = {
            ...serializeField,
            [elem?.label?.toLowerCase()]: item?.data[elem.id] || null,
          };
        }
      }
    });

    // Ensure submittedBy is defined and has the necessary properties
    if (item?.data?.submittedBy) {
      const sBy = item.data.submittedBy;
      if (sBy?.firstName && sBy?.lastName) {
        serializeField = {
          ...serializeField,
          submittedby: `${sBy.firstName} ${sBy.lastName}`,
          submittedByUserName: sBy?.userName || '',
          requestor: item?.data?.chatSummary || item?.data?.requestedFor || null
        };
      }
    }

    if (roles?.includes('ticket_admin')) {
      getItemType.push(serializeField);
    } else if (
      serializeField?.requestor === pUser ||
      serializeField?.submittedByUserName === pUser ||
      serializeField?.toWatchers.includes(pUser) ||
      serializeField?.ccWatchers.includes(pUser)
    ) {
      getItemType.push(serializeField);
    }
  });
  return getItemType;
};

export default ConfigProcessor;

import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const SupportBot = () => {
  const [data, setData] = useState({
    support: '',
    iframe: '',
  });

  const toast = useToast();

  async function fetchData() {
    const tenant = localStorage.getItem('tenant')
      ? JSON.parse(localStorage.getItem('tenant') || '')
      : {};
    const userEmail = localStorage.getItem('userEmail');
    console.log(76576567, { tenant, userEmail });
    const requestOptionsSupportBot = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ['Authorization']: `Bearer ${localStorage.getItem('kc_token')}`,
        ['x-tenantId']: tenant?.id,
        usecase: 'james',
      },
      url: `${tenant?.apiUrl}/api/v1/BotConfig/findWithParentAndCountAll?$filter=configType eq 'defaultSetting' and fullName eq 'supportBotUrl' and status eq 'Published'`,
    };

    try {
      const resSupportBot = await axios(requestOptionsSupportBot);

      if (resSupportBot.data && resSupportBot.data.length > 0) {
        const support = resSupportBot.data[0];
        setData((prev) => ({
          ...prev,
          support: resSupportBot.data[0],
          iframe: `<iframe src="${support?.properties?.url}?isSupportBot[0]=${tenant?.id}&isSupportBot[1]=${userEmail}" scrolling="no" width="100%" height="100%" style="min-height: 100%" frameBorder="0" ></iframe>`,
        }));
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {data.iframe && (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 180px)',
            position: 'unset',
          }}
          dangerouslySetInnerHTML={{ __html: data.iframe }}
        />
      )}
      </>
  );
};
export default SupportBot;

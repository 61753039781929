import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Flex, Stack } from '@chakra-ui/react';
import { MdAddCircleOutline } from 'react-icons/md';
import { cloneDeep } from 'lodash';
import RestrictionsCustomDrawer from '../RestrictionsCustomDrawer';
import DateDefaultRestrictions from './DateDefaultRestrictions';
import { DATE_VALIDATION_LABELS } from '../../../../../utils/enums/labels';
import { SUPPORTED_FIELD_TYPES } from '../../../../TenantFilters/filters.constants';
import DateConditionalRestrictions from './DateConditionalRestrictions';
import { getConditionalValidationObject, getDateValidationsObject } from './utils/dateRestrictions.helper';

const MOVE_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

const {
  RESTRICTION_DRAWER_TITLE,
  CANCEL,
  SAVE,
  ADD_VALIDATION_CONDITION,
} = DATE_VALIDATION_LABELS;

const DateRestrictionsDrawer = ({
  open,
  onModalClose,
  fieldData,
  fieldDataInDB,
  setFieldData,
  templateFields,
}) => {
  const {
    validations,
    isAllowTimeField,
  } = fieldData;

  const [currentValidations, setValidations] = useState(getDateValidationsObject(validations));

  const {
    defaultValidations,
    conditionalValidations,
  } = currentValidations;

  // const orderedConditionalValidations = conditionalValidations?.length
  //   ? conditionalValidations.sort((a, b) => a.order - b.order)
  //   : [];

  const filteredTemplateFields = useMemo(() => {
    if (!templateFields?.length) return [];

    if (!fieldDataInDB?.id) {
      return templateFields.filter((field) => field.type === SUPPORTED_FIELD_TYPES.DATE);
    }

    return templateFields.filter((field) => {
      const { id, type } = field;

      return id !== fieldDataInDB.id && type === SUPPORTED_FIELD_TYPES.DATE;
    });
  }, [templateFields, fieldDataInDB]);

  const onChangeDefaultRestrictions = (data) => {
    setValidations((prev) => ({
      ...prev,
      defaultValidations: data,
    }));
  };

  const onAddConditionalRestriction = () => {
    setValidations((prev) => ({
      ...prev,
      conditionalValidations: [
        ...prev.conditionalValidations,
        getConditionalValidationObject(prev.conditionalValidations.length),
      ],
    }));
  };

  const onDeleteConditionalValidation = (conditionId) => {
    const curIndex = conditionalValidations.findIndex((c) => c.id === conditionId);
    const curCV = conditionalValidations[curIndex];

    const clonedCVs = cloneDeep(conditionalValidations);

    const updatedCVArray = clonedCVs
      .filter((cv) => cv.id !== conditionId)
      .map((cv) => {
        if (cv.order > curCV.order) {
          cv.order -= 1;
        }

        return cv;
      });

    setValidations((prev) => ({
      ...prev,
      conditionalValidations: updatedCVArray,
    }));
  };

  const onChangeConditionalValidation = (conditionId, data) => {
    setValidations((prev) => ({
      ...prev,
      conditionalValidations: prev
        .conditionalValidations
        .map((c) => (c.id === conditionId ? data : c)),
    }));
  };

  const onMoveConditionalValidation = (conditionId, direction) => {
    const curIndex = conditionalValidations.findIndex((c) => c.id === conditionId);
    const curCV = conditionalValidations[curIndex];

    if (direction === MOVE_DIRECTION.UP && curCV.order <= 1) {
      return;
    }

    if (direction === MOVE_DIRECTION.DOWN && curCV.order >= conditionalValidations.length) {
      return;
    }

    const targetIndex = conditionalValidations.findIndex(
      (c) => {
        const targetOrder = direction === MOVE_DIRECTION.UP ? curCV.order - 1 : curCV.order + 1;
        return c.order === targetOrder;
      },
    );
    const targetCV = conditionalValidations[targetIndex];

    if (!targetCV) {
      return;
    }

    const updatedConditionalValidations = cloneDeep(conditionalValidations);

    updatedConditionalValidations[targetIndex].order = curCV.order;
    updatedConditionalValidations[curIndex].order = targetCV.order;

    setValidations((prev) => ({
      ...prev,
      conditionalValidations: updatedConditionalValidations,
    }));
  };

  const orderedCVS = (conditionalValidations?.length ? conditionalValidations : [])
    .sort((cv1, cv2) => cv1.order - cv2.order);

  return (
      <RestrictionsCustomDrawer
        open={open}
        onCancelOrClose={onModalClose}
        onSave={() => {
          // Validate and save the data
          console.log(fieldData);
          console.log(currentValidations);
          setFieldData((prev) => ({
            ...prev,
            validations: currentValidations,
          }));
          onModalClose();
        }}
        title={RESTRICTION_DRAWER_TITLE}
        closeLabel={CANCEL}
        saveLabel={SAVE}
        errorMessage=""
      >
        <DateDefaultRestrictions
          defaultValidations={defaultValidations}
          onChangeDefaultRestrictions={onChangeDefaultRestrictions}
          templateFields={filteredTemplateFields}
          allowTime={isAllowTimeField}
        />

        {
          orderedCVS?.length ? (
            <Stack style={{ marginTop: '20px' }} gap="20px">
              {
                orderedCVS.map((cV, index) => (
                  <DateConditionalRestrictions
                    key={`${cV.id}-${cV.order}`}
                    conditionalValidation={cV}
                    templateFields={filteredTemplateFields}
                    allowTime={isAllowTimeField}
                    index={index}
                    maxLength={orderedCVS.length}
                    onChangeConditionalValidation={onChangeConditionalValidation}
                    onDeleteConditionalValidation={onDeleteConditionalValidation}
                    onMoveConditionalValidationUp={
                      (id) => onMoveConditionalValidation(id, MOVE_DIRECTION.UP)
                    }
                    onMoveConditionalValidationDown={
                      (id) => onMoveConditionalValidation(id, MOVE_DIRECTION.DOWN)
                    }
                  />
                ))
              }
            </Stack>
          ) : null
        }

        <Flex justifyContent="flex-end" marginTop="20px">
          <Button
            leftIcon={<MdAddCircleOutline />}
            variant="link"
            size="sm"
            colorScheme="blue"
            onClick={onAddConditionalRestriction}
          >
            {ADD_VALIDATION_CONDITION}
          </Button>
        </Flex>
      </RestrictionsCustomDrawer>
  );
};

DateRestrictionsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  fieldData: PropTypes.object.isRequired,
  fieldDataInDB: PropTypes.object.isRequired,
  setFieldData: PropTypes.func.isRequired,
  templateFields: PropTypes.array.isRequired,
};

DateRestrictionsDrawer.defaultProps = {
};

export default DateRestrictionsDrawer;

/* eslint-disable quotes */
import { AddIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStoreApi } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { IN_PROGRESS_STATUS } from '../../../utils/enums/colors';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
// import CustomButton from '../../../components/Button/SimpleButton';
import TransitionCard from './TransitionCard';
import {
  deleteNodeFromWorkFlow,
  updateSelectedWorkFlowStatus,
  updateSelectedWorkFlowWithKeyValue,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { isTemplateApproved, mapToJsonArray } from '../helper';
import { LABELS } from '../../../utils/enums/labels';
import { checkForV2 } from '../../../utils/helper';
// import { LifecycleStatusTypes } from '../../../utils/enums/lifecycleStatus';
import { checkIsStatusReferenced, checkRankInStatus } from './helper';
import {
  DangerCustomButton,
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';

const wasStatusApproved = (status) => {
  if (checkForV2()) {
    return false;
  }

  return status?.data?.wasApproved;
};

const ShowEditStatus = ({ closeSideBar, openTransitionModalInEditStatus }) => {
  const [isEdited, setIsEdited] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [statusReference, setStatusReference] = useState('');
  const [errorRankMsg, setErrorRankMsg] = useState('');

  // workflow_category
  const dispatch = useDispatch();
  const { selectedWorkFlow, selectedWorkFlowStatus, templateLifeCycle } =
    useSelector((state) => state.workFlowMain);
  const { selectedTemplate } = useSelector((state) => state.viewState || {});

  useEffect(() => {
    setStatusReference(
      checkIsStatusReferenced(selectedWorkFlowStatus, selectedTemplate)
    );
  }, [selectedWorkFlowStatus, selectedTemplate]);
  const StatusCategoryOption = selectedWorkFlow.workflow_category.map(
    (item) => ({
      label: item?.name,
      value: item?.id,
      type: item?.color?.value,
    })
  );
  const flowStore = useStoreApi();
  const { edges, nodeInternals } = flowStore.getState();
  const handleUpdatedNode = () => {
    const index = mapToJsonArray(nodeInternals).findIndex(
      (element) => element.id === selectedWorkFlowStatus.id
    );
    if (index === -1) {
      throw new Error(
        `Element with id '${selectedWorkFlowStatus.id}' not found in array.`
      );
    }
    dispatch(
      updateSelectedWorkFlowWithKeyValue({
        keyValue: 'workflow_status',
        value: selectedWorkFlowStatus,
        dispatch,
      })
    );
    dispatch(updateSelectedWorkFlowStatus(null));
  };
  const handleCheckNameIsExit = () => {
    const nodeList = mapToJsonArray(
      Array.from(nodeInternals.values()).filter((node) => node?.id !== selectedWorkFlowStatus?.id)
    );
        if (!selectedWorkFlowStatus?.data?.rank) {
      setErrorRankMsg('Rank is required');
      return; // Prevent further execution if rank is invalid
    }
    if (
      selectedWorkFlowStatus?.data?.rank <= 0 ||
      selectedWorkFlowStatus?.data?.rank > nodeList.length
    ) {
      setErrorRankMsg(
        'The rank must be a positive integer and not bigger than the no of statuses'
      );
      return;
    }
    if (
      checkRankInStatus(nodeList, selectedWorkFlowStatus?.data?.rank).isPresent
    ) {
      setErrorRankMsg('The rank must be a unique');
      return;
    }
    const index = mapToJsonArray(nodeInternals)
      ?.filter((item) => item.id !== selectedWorkFlowStatus.id)
      .findIndex(
        (element) =>
          element.data.label.replace(/ /g, '').toLowerCase() ===
          selectedWorkFlowStatus.data.label.replace(/ /g, '').toLowerCase()
      );
    if (index === -1) {
      handleUpdatedNode();
      closeSideBar(false);
    } else {
      setErrorName(true);
    }
  };
  const handleInput = (e) => {
    if (errorName) {
      setErrorName(false);
      setIsEdited(false);
    }
    dispatch(
      updateSelectedWorkFlowStatus({
        ...selectedWorkFlowStatus,
        data: { ...selectedWorkFlowStatus.data, label: e.target.value },
      })
    );
    setIsEdited(true);
  };
  const handleRankInput = (e) => {
    if (errorRankMsg) {
      setErrorRankMsg('');
      setIsEdited(false);
    }
    dispatch(
      updateSelectedWorkFlowStatus({
        ...selectedWorkFlowStatus,
        data: {
          ...selectedWorkFlowStatus.data,
          rank: parseInt(e.target.value, 10),
        },
      })
    );
    setIsEdited(true);
  };
  const handleSelect = (e) => {
    dispatch(
      updateSelectedWorkFlowStatus({
        ...selectedWorkFlowStatus,
        type: e.type,
        data: { ...selectedWorkFlowStatus.data, name: e.label, categoryId: e.value },
      })
    );
    setIsEdited(true);
  };
  const selectedNodeEdgeList = useCallback(
    () =>
      edges.filter(
        (edge) =>
          edge.source === selectedWorkFlowStatus?.id ||
          edge.target === selectedWorkFlowStatus?.id
      ),
    [edges, selectedWorkFlowStatus?.id]
  );
  const getTooltipLabel = () => {
    if (selectedNodeEdgeList()?.length) {
      return "Status can't be deleted as it has associated transitions";
    }
    if (
      isTemplateApproved(templateLifeCycle) ||
      wasStatusApproved(selectedWorkFlowStatus)
    ) {
      return "Status can't be deleted as it was approved";
    }

    if (statusReference) {
      return `Status can't be deleted as it is referenced in: ${statusReference}`;
    }

    return '';
  };
  const getToolTipForTransitions = () => {
    if (isTemplateApproved(templateLifeCycle)) {
      return "Transitions can't be added as it was approved";
    }
    return '';
  };
  return (
    <Box height="100%" data-testid="ShowEditStatusBox">
      <Box
        height="calc(100% - 70px)"
        overflow="auto"
        data-testid="ShowEditStatusBodyBox"
      >
        <Box data-testid="ShowEditStatusStatusBox">
          <Box
            display="flex"
            p="8px"
            bgColor={IN_PROGRESS_STATUS}
            mt="8px"
            data-testid="ShowEditStatusWarningBox"
          >
            <WarningIcon color="white" />
            <p
              className="text-[11px] pl-[5px] text-white"
              data-testid="ShowEditStatusWarningP"
            >
              {LABELS.TITLE.STATUS_MUTLIPLE_WORKFLOW}
            </p>
          </Box>
          <Box data-testid="ShowEditStatusDetailBox">
            <Text
              className="text-[12px] mt-[20px] mb-[4px] font-semibold"
              data-testid="ShowEditStatusNameText"
            >
              {LABELS.TITLE.NAME}
              <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
            </Text>
            <CustomInput
              onChange={handleInput}
              value={selectedWorkFlowStatus?.data?.label}
              placeholder=""
              helperText=" "
              isError={errorName}
              errorMessage="The status name is already in use on the canvas."
              id="ShowStatusnode_name"
              isReadOnly={
                isTemplateApproved(templateLifeCycle) ||
                wasStatusApproved(selectedWorkFlowStatus)
              }
            />
          </Box>
          <Box data-testid="ShowEditStatusDetailBox">
            <Text
              className="text-[12px] mt-[20px] mb-[4px] font-semibold"
              data-testid="ShowEditStatusNameText"
            >
              {LABELS.TITLE.RANK}
              <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
            </Text>
            <CustomInput
              type="number"
              onChange={handleRankInput}
              value={selectedWorkFlowStatus?.data?.rank}
              placeholder=""
              helperText=" "
              isMandatory
              isError={!isEmpty(errorRankMsg)}
              errorMessage={errorRankMsg || ''}
              id="ShowStatusnode_name"
            />
          </Box>
        </Box>

        {/*  second part */}
        <Box data-testid="ShowEditStatusSecondBox">
          {selectedWorkFlowStatus?.id !== 'start_1' && (
            <Box data-testid="ShowEditStatusCapitalBox">
              <Text
                mt="20px"
                className="text-[12px] font-semibold"
                data-testid="ShowEditStatusCapitalText"
              >
                {LABELS.TITLE.CATEGORY_CAPITAL}
              </Text>
              <p
                className="text-[11px] pb-[4px]"
                data-testid="ShowEditStatusGroupP"
              >
                {LABELS.TITLE.CATEGORY_GROUP}
              </p>

              <CustomSelectBox
                value={{
                  label: selectedWorkFlowStatus?.data?.name,
                  value: selectedWorkFlowStatus?.data?.categoryId,
                }}
                name="from"
                options={StatusCategoryOption}
                onChange={handleSelect}
                isDisabled={
                  isTemplateApproved(templateLifeCycle) ||
                  wasStatusApproved(selectedWorkFlowStatus)
                }
                id="ShowEditBoxSelectBox"
              />
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            mt="20px"
            data-testid="ShowEditStatusTransBox"
          >
            <Text
              className="text-[12px] font-semibold"
              data-testid="ShowEditStatusTransTitle"
            >
              {LABELS.TITLE.TRANSITIONS}
            </Text>
            <Tooltip
              hasArrow
              label={getToolTipForTransitions()}
              placement="bottom-start"
              data-testid="ShowEditStatusApprovedToolTip"
            >
              <Box position="relative" data-testid="ShowEditStatusApprovedBox">
                <AddIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (isTemplateApproved(templateLifeCycle)) {
                      return;
                    }
                    openTransitionModalInEditStatus();
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box
            className="text-[11px] pb-[4px] pt-[4px]"
            data-testid="ShowEditStatusConnectBox"
          >
            {LABELS.TITLE.TRANSITION_CONNECT}
          </Box>
          {/* transition list */}
          <Box data-testid="ShowEditStatusCardBox">
            {selectedNodeEdgeList().map((item) => (
              <TransitionCard key={`CardIten${item.id}`} edge={item} />
            ))}
          </Box>
          {/* transtion end */}
        </Box>
      </Box>

      {/* Button Container */}
      <Box
        position="fixed"
        bottom="10px"
        width="-webkit-fill-available"
        marginRight="16px"
        data-testid="ShowEditStatusButtonBox"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          data-testid="ShowEditStatusInnerButtonBox"
        >
          <Tooltip
            hasArrow
            label={getTooltipLabel()}
            data-testid="ShowEditStatusDeleteTooltip"
          >
            <Box position="relative" data-testid="ShowEditStatusDeleteBox">
              <DangerCustomButton
                buttonText="Delete"
                isDisabled={
                  selectedNodeEdgeList().length ||
                  wasStatusApproved(selectedWorkFlowStatus) ||
                  isTemplateApproved(templateLifeCycle) ||
                  isEdited ||
                  statusReference
                }
                onClick={() => {
                  dispatch(
                    deleteNodeFromWorkFlow({
                      nodeId: selectedWorkFlowStatus?.id,
                      dispatch,
                    })
                  ).then(() => {
                    dispatch(updateSelectedWorkFlowStatus(null));
                  });
                  closeSideBar(false);
                }}
                id="ShowEditStatusDeleteBoxDeleteBtn"
              />
            </Box>
          </Tooltip>
          <Box display="flex" gap={3} data-testid="ShowEditStatusCancel">
            <SecondaryCustomButton
              buttonText={LABELS.ACTIONS.CANCEL}
              onClick={() => {
                dispatch(updateSelectedWorkFlowStatus(null));
                closeSideBar(false);
              }}
              id="ShowEditStatusDeleteBoxCancelBtn"
            />
            <Tooltip
              hasArrow
              label={
                (wasStatusApproved(selectedWorkFlowStatus) && !isEdited) ||
                isTemplateApproved(templateLifeCycle)
                  ? `Status can't be changed as it was approved`
                  : ''
              }
              placement="top-start"
              data-testid="ShowEditStatusApprovedTooltip"
            >
              <Box
                position="relative"
                data-testid="ShowEditStatusApprovedSaveBox"
              >
                <PrimaryCustomButton
                  isDisabled={
                    !(selectedWorkFlowStatus?.data?.label.trim() && isEdited) ||
                    isTemplateApproved(templateLifeCycle) ||
                    !!errorRankMsg                  }
                  buttonText={LABELS.ACTIONS.SAVE}
                  onClick={handleCheckNameIsExit}
                  id="ShowEditStatusDeleteBoxSaveBtn"
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ShowEditStatus.propTypes = {
  closeSideBar: PropTypes.func,
  openTransitionModalInEditStatus: PropTypes.func,
};
ShowEditStatus.defaultProps = {
  closeSideBar: () => {},
  openTransitionModalInEditStatus: () => {},
};
export default ShowEditStatus;

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS, DARK, LIGHT } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { LOCALES_TYPE } from '../helper';
import CustomInput from '../../../components/InputBox/Input';

const LocaleDrawer = ({
  openDrawer,
  setCloseDrawer,
  title,
  setLocales,
  code,
}) => {
  const { selectedLanguages } = useSelector((state) => state.locales);
  const { metaFieldList } = useSelector((state) => state.metaFields);
  const [localeType, setLocaleType] = useState(LOCALES_TYPE[0]);
  const [selectedLocales, setSelectedLocales] = useState();
  const [ errMsg, setErrMsg ] = useState('')
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    if (!_isEmpty(selectedLanguages) && code) {
      const selectedLanguage = selectedLanguages.find(
        (item) => item.locale_code === code
      );
      let updatedTranslations = selectedLanguage?.meta_field_translations || [];
      const otherTranslations = selectedLanguage?.other_translations || [];
      if (selectedLanguage?.meta_field_translations) {
        updatedTranslations = [...selectedLanguage.meta_field_translations];
      }

      metaFieldList?.forEach((metaField) => {
        const exists = updatedTranslations?.some(
          (translation) => translation.label === metaField.name
        );

        if (!exists) {
          updatedTranslations.push({ label: metaField.name, value: '' });
        }
      });

      setSelectedLocales({
        meta_field_translations: updatedTranslations,
        other_translations: otherTranslations,
      });
    }
  }, [selectedLanguages, metaFieldList, localeType, code]);

  const handleInputChange = useCallback((index, value) => {
    setSelectedLocales((prevState) => {
      const newState = { ...prevState };
      const metaFieldTranslations = _cloneDeep(
        newState.meta_field_translations
      );
      metaFieldTranslations[index].value = value;
      newState.meta_field_translations = metaFieldTranslations;
      return newState;
    });
  }, []);

  const handleOtherTranslationInputChange = useCallback((index, field, value) => {
    setSelectedLocales((prevState) => {
      const newState = { ...prevState };
      const otherTranslations = _cloneDeep(newState.other_translations);
      otherTranslations[index][field] = value;
      newState.other_translations = otherTranslations;
      setErrors(prevErrors => ({
              ...prevErrors,
              [`${field}${index}`]: value.trim() === ''
            }));
      return newState;
    });
  },[]);

  const handleAddTranslation = () => {
    if (selectedLocales?.other_translations?.length > 0) {
      const lastTranslation = selectedLocales.other_translations[selectedLocales.other_translations.length - 1];
      let errorMessages = [];
      if (!lastTranslation.label) {
        errorMessages.push("Label");
      }
      if (!lastTranslation.value) {
        errorMessages.push("Translation");
      }
      if (errorMessages.length > 0) {
        setErrMsg(`${errorMessages.join(" and ")} is required`);
        return; // Stop adding a new translation if there are errors
      }
    }

    setSelectedLocales((prevState) => {
      const newState = { ...prevState };
      const otherTranslations = [...newState.other_translations];
      otherTranslations.push({ label: '', value: '' });
      newState.other_translations = otherTranslations;
      return newState;
    });
    setErrMsg('');
  };

  const isLastTranslationIncomplete = () => {
    if (!selectedLocales?.other_translations?.length) return false;
  
    const lastTranslation = selectedLocales?.other_translations[selectedLocales?.other_translations.length - 1];
    return !lastTranslation.label || !lastTranslation.value;
  };  

  const handleRemoveTranslation = (index) => {
    setErrMsg('')
    setSelectedLocales((prevState) => {
      const newState = { ...prevState };
      const otherTranslations = [...newState.other_translations];
      otherTranslations.splice(index, 1);
      newState.other_translations = otherTranslations;
      return newState;
    });
  };

  const validateInputs = () => {
    if(localeType?.value === LOCALES_TYPE[1].value) {
      const newErrors = {};
      let labelMissing = false;
      let translationMissing = false;

      selectedLocales.other_translations.forEach((item, index) => {
        if (item.label.trim() === '') {
          newErrors[`label${index}`] = true;
          labelMissing = true;
        }
        if (item.value.trim() === '') {
          newErrors[`value${index}`] = true;
          translationMissing = true;
        }
      });

      setErrors(newErrors);
      setErrMsg(labelMissing && translationMissing
        ? "Label and Translation are required"
        : labelMissing
        ? "Label is required"
        : translationMissing
        ? "Translation is required"
        : '');

      return Object.keys(newErrors).length === 0;
    }
    return true
  };
  
  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setCloseDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={setCloseDrawer}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Box mb="20px">
            <CustomSelectBox
              options={LOCALES_TYPE}
              value={localeType}
              onChange={(e) => {
                setLocaleType(e);
                setErrMsg('')
                setErrors({})
                // setErrorKeyList((pre) => ({
                //   ...pre,
                //   [META_FIELD_KEY.FIELD_DATA]: false,
                // }));
              }}
              label="Locale Type"
              isError={false}
              errorMessage="Locale type is required"
              aria-label="LocaleType"
              // isDisabled={getDisabledForColumnType()}
            />
          </Box>
          {localeType.value === LOCALES_TYPE[0].value && (
            <Grid templateColumns="repeat(2, 1fr)" gap="30px">
              {selectedLocales?.meta_field_translations?.map((item, index) => (
                <>
                  <GridItem>
                    <Text>{item.label}</Text>
                  </GridItem>
                  <GridItem>
                    <CustomInput
                      label="Translation"
                      value={item.value}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                  </GridItem>
                </>
              ))}
            </Grid>
          )}

          {localeType.value === LOCALES_TYPE[1].value && (
            <Grid templateColumns="repeat(12, 1fr)" gap="10px">
              {selectedLocales?.other_translations?.map((item, index) => (
                <React.Fragment key={index}>
                  <GridItem colSpan={5}>
                    <CustomInput
                      label="Label"
                      value={item.label}
                      onChange={(e) => {
                          handleOtherTranslationInputChange(
                            index,
                            'label',
                            e.target.value
                          )
                          setErrMsg('')
                        }  
                      }
                      isError={errors[`label${index}`]}
                      isMandatory
                    />
                  </GridItem>
                  <GridItem colSpan={6}>
                    <CustomInput
                      label="Translation"
                      value={item.value}
                      onChange={(e) => {
                          handleOtherTranslationInputChange(
                            index,
                            'value',
                            e.target.value
                          )
                          setErrMsg('')
                        } 
                      }
                      isError={errors[`value${index}`]}
                      isMandatory
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <DeleteIcon
                      color="red"
                      style={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        marginTop: '34px',
                      }}
                      onClick={() => {
                        handleRemoveTranslation(index);
                      }}
                      data-testid="delete-other-translation"
                    />
                  </GridItem>
                </React.Fragment>
              ))}
              {errMsg && (
                <GridItem colSpan={12}>
                  <span className="field-error">{errMsg}</span>
                </GridItem>
              )}
              <CustomButton
                onClick={handleAddTranslation}
                buttonText="Add Translation"
                color={useColorModeValue(LIGHT, DARK)}
                id="button-add-other-transalation"
                variant="outline"
                height="32px"
                mt="0px"
                width="130px"
                padding="0px 30px"
                isDisabled={errMsg || isLastTranslationIncomplete()}
              />
            </Grid>
          )}
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: '1px solid #d1d5db',
              // background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={setCloseDrawer}
            size="lg"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.UPDATE}
            onClick={() => {
              if (validateInputs()) {
                setLocales(selectedLocales, localeType);
                setCloseDrawer(false);
              }
            }}
            size="lg"
            isLoading={false}
            data-testid="LocaleButtonAdd"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

LocaleDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setCloseDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setLocales: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
};

export default LocaleDrawer;

import React, { useEffect, useState } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from '../pages/DynamicRenderer/Dashboard/Loader';
import { getTenantConfig } from '../helpers/tenantUrl';
import { isTemplateDetailsScreen } from '../utils/helper';
import styles from './styles.module.scss';
import useLocalStorage from '../utils/hooks/useLocalStorage';
import FooterContent from './navigation/FooterContent';
import HeaderContent from './navigation/HeaderContent';
import SidebarContents from './navigation/SidebarContents';

const AppLayout = ({ keycloak }) => {
  const { pathname } = useLocation();
  const selectedMenu = pathname.split('/')[1];
    // to open sidebar as default after refresh or login  
  const [openMenu , setOpenMenu] = useState(true);
  const [uiFeatureData, setUIFeatureData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const colorType = useColorModeValue('light', 'dark');

  const getUIFeatureData = (data) => {
    const mappedData = data.reduce((result, dataItem) => {
      if (
        dataItem.configType === 'uiFeature' &&
        dataItem?.properties &&
        dataItem?.properties?.name
      ) {
        const key = dataItem.properties.name;
        const value = dataItem.properties;
        result[key] = value;
      }
      return result;
    }, {});

    return mappedData;
  };

  const getTenantInfo = async () => {
    const res = await getTenantConfig();
    if (!res.error) {
      const uiFeature = getUIFeatureData(res.response || []);
      setUIFeatureData({ ...uiFeature });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getTenantInfo();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HeaderContent
        keycloak={keycloak}
        openMenu={openMenu}
        globalBg={colorType}
      />
      <Box
        display="flex"
        height="calc(100vh - 90px)"
        bg={useColorModeValue('gray.100', 'gray.900')}
      >
        <Box
          className={
            openMenu
              ? styles.side_bar_content_parent
              : styles.side_bar_content_collapsed
          }
          bg={useColorModeValue('white', 'rgb(0, 0, 0)')}
        >
          <SidebarContents
            onClose={() => setOpenMenu(false)}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            globalBg={colorType}
            selectedMenu={`/${selectedMenu}`}
            keycloak={keycloak}
            uiFeatureData={uiFeatureData || []}
          />
        </Box>

        <Box className={styles.mainContainer} bg={useColorModeValue('#f1f5fe', '#3b3b3b')}>
          <Box
            bg={useColorModeValue('white', 'rgb(0, 0, 0)')}
            overflow={isTemplateDetailsScreen(pathname) ? 'hidden' : 'auto'}
            className={styles.mainLayoutContainer}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
      <FooterContent />
    </>
  );
};

AppLayout.propTypes = {
  keycloak: PropTypes.object.isRequired,
};
export default AppLayout;

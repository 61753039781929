import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { DATE_VALIDATION_LABELS } from '../../../../../utils/enums/labels';
import { CURRENT_DATE_PLACEHOLDER, CURRENT_DATE_PLACEHOLDER_LABEL, FUNCTION_OPTIONS, FUNCTION_TYPES, TIMEFRAME_OPTIONS, TIMEFRAME_TYPES } from './utils/dateRestrictions.constants';
import CustomInput from '../../../../../components/InputBox/Input';
import CustomSelectBox from '../../../../../components/SelectBox/Select';

const {
  NUMBER_OF_DAYS,
  TIMEFRAME,
  REFERENCE_DATE,
  NUMBER_OF_DAYS_TOOLTIP,
  FUNCTION,
} = DATE_VALIDATION_LABELS;

const getFunctionOptions = (referenceField) => {
  if (referenceField !== CURRENT_DATE_PLACEHOLDER) {
    return FUNCTION_OPTIONS.filter((option) => option.value !== FUNCTION_TYPES.CURRENT);
  }

  return FUNCTION_OPTIONS;
};

const DateAddRuleRelative = ({
  offset,
  timeframe,
  reference,
  functionName,
  templateFields,
  onChangeRelativeRule,
}) => {
  const REFERENCE_OPTIONS_MEMO = useMemo(() => {
    const options = [
      {
        label: CURRENT_DATE_PLACEHOLDER_LABEL,
        value: CURRENT_DATE_PLACEHOLDER,
      }
    ];

    if (!templateFields?.length) {
      return options;
    }

    return options.concat(templateFields.map((field) => ({
      label: field.label,
      value: field.id,
    })));
  }, [templateFields]);

  const functionOptions = getFunctionOptions(reference);

  const onChangeOffset = (value) => {
    onChangeRelativeRule({
      offset: parseInt(value, 10),
      timeframe,
      reference,
      functionName,
    });
  };

  const onChangeTimeframe = ({ value }) => {
    onChangeRelativeRule({
      offset,
      timeframe: value,
      reference,
      functionName: value === TIMEFRAME_TYPES.RANGE ? '' : functionName,
    });
  };

  const onChangeReference = ({ value }) => {
    onChangeRelativeRule({
      offset,
      timeframe,
      reference: value,
      functionName: value !== CURRENT_DATE_PLACEHOLDER && functionName === FUNCTION_TYPES.CURRENT ? '' : functionName,
    });
  };

  const onChangeFunctionName = ({ value }) => {
    onChangeRelativeRule({
      offset,
      timeframe,
      reference,
      functionName: value,
    });
  };

  return (
    <Stack>
      <Flex marginTop="10px" gap="20px">
        <Stack flex={1}>
          <Tooltip label={NUMBER_OF_DAYS_TOOLTIP} placement="top" hasArrow>
            <Flex direction="row" alignItems="center" gap="5px">
              <Text fontWeight={500}>{NUMBER_OF_DAYS}</Text>
              <AiOutlineInfoCircle />
            </Flex>
          </Tooltip>
          <CustomInput
            type="number"
            value={`${offset}`}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (Number.isNaN(value)) {
                return;
              }

              if (value < 0) {
                return;
              }
              onChangeOffset(Number(e.target.value));
            }}
          />
        </Stack>

        <Stack flex={1}>
          <Text fontWeight={500}>{TIMEFRAME}</Text>
          <CustomSelectBox
            value={TIMEFRAME_OPTIONS.find((option) => option.value === timeframe)}
            onChange={onChangeTimeframe}
            options={TIMEFRAME_OPTIONS}
          />
        </Stack>
      </Flex>
      <Flex marginTop="10px" gap="20px">
        <Stack flex={1}>
          <Text fontWeight={500}>{REFERENCE_DATE}</Text>
          <CustomSelectBox
            value={REFERENCE_OPTIONS_MEMO.find((option) => option.value === reference)}
            onChange={onChangeReference}
            options={REFERENCE_OPTIONS_MEMO}
          />
        </Stack>

        <Stack flex={1}>
        {timeframe !== TIMEFRAME_TYPES.RANGE && (
          <>
            <Text fontWeight={500}>{FUNCTION}</Text>
            <CustomSelectBox
              value={
                functionOptions.find((option) => option.value === functionName) || null
              }
              onChange={onChangeFunctionName}
              options={functionOptions}
            />
          </>
        )}
        </Stack>
      </Flex>
    </Stack>
  );
};

DateAddRuleRelative.propTypes = {
  offset: PropTypes.number.isRequired,
  timeframe: PropTypes.oneOf([
    TIMEFRAME_TYPES.LESS_THAN,
    TIMEFRAME_TYPES.GREATER_THAN,
    TIMEFRAME_TYPES.RANGE,
  ]).isRequired,
  reference: PropTypes.string.isRequired,
  functionName: PropTypes.oneOf([
    FUNCTION_TYPES.CURRENT,
    FUNCTION_TYPES.START_OF_DAY,
    FUNCTION_TYPES.END_OF_DAY,
    FUNCTION_TYPES.START_OF_WEEK,
    FUNCTION_TYPES.END_OF_WEEK,
    FUNCTION_TYPES.START_OF_MONTH,
    FUNCTION_TYPES.END_OF_MONTH,
    FUNCTION_TYPES.START_OF_YEAR,
    FUNCTION_TYPES.END_OF_YEAR,
  ]).isRequired,
  onChangeRelativeRule: PropTypes.func.isRequired,
  templateFields: PropTypes.array,
};

DateAddRuleRelative.defaultProps = {
  templateFields: [],
};

export default DateAddRuleRelative;

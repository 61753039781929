import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import { Box } from '@chakra-ui/react';
import { debounce, isEmpty } from 'lodash';
import CustomTabs from '../../components/Tabs/Tabs';
import {
  actionColumns,
  createPayloadAction,
  eventActionTabList,
  eventColumns,
  getRelatedTemplateIds,
  getTemplatesById,
} from './helper';
import CreateAndEditEvent from './CreateAndEditEvent';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import CreateAndEditAction from './CreateAndEditAction';
import { getRelations } from '../DynamicRenderer/Relation/relation.service';
import { ActionHeader } from '../CommunicationTemplates/HeaderAction';
import { patchDataForTemplateWithPath, showToastMessage } from '../TicketTemplateBuilder/utils/templateAPIUtils';

const TemplateNewEventAction = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showEventPage, setShowEventPage] = useState({
    isShow: false,
    data: {},
  });
  const dispatch = useDispatch();
  const [showActionPage, setActionPage] = useState({
    isShow: false,
    data: {},
  });
  let selectedTabType = '';
  let searchKey = '';
  if (tabValue === 0) {
    selectedTabType = 'EVENT';
    searchKey = 'event_name';
  } else {
    searchKey = 'action_name';
    selectedTabType = 'ACTION';
  }

  const [relations, setRelations] = useState([]);
  const [relatedTemplates, setRelatedTemplates] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const { selectedTemplate } = useSelector(
    (reduxState) => reduxState.viewState
  );
  const updateState = (res) => {
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      getRelations(selectedTemplate.id).then((res) => {
        setRelations(res);
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (isEmpty(relations) || isEmpty(selectedTemplate)) {
      return;
    }

    const relatedIds = getRelatedTemplateIds(relations, selectedTemplate.id);
    getTemplatesById(relatedIds).then((templates) => {
      setRelatedTemplates(templates.rows || [...selectedTemplate]);
    });
  }, [relations, selectedTemplate]);

  const switchStatus = async (value, action) => {
    const newLocalAction = _cloneDeep(action);
    _set(newLocalAction, 'enabled', value);
    const payload = createPayloadAction(newLocalAction, selectedTemplate);
    const res = await patchDataForTemplateWithPath({
      data: {
        path: 'events.actions',
        operation: 'update',
        data: payload,
        id: payload.id,
      },
      id: selectedTemplate?.id,
      defaultErrorMessage: 'Failed to switch status',
      successMessage: value
        ? 'Successfully action enabled'
        : 'Successfully action disabled',
    });
    updateState(res);
    if (res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: { ...selectedTemplate },
        })
      );
    }
  };
  const getDataAccordingToTab = useMemo(
    () => (tab) => {
      if (tab === 'EVENT') {
        return [...(selectedTemplate?.events?.events || [])];
      }
      if (tab === 'ACTION') {
        return [...(selectedTemplate?.events?.actions || [])];
      }
      return [];
    },
    [selectedTemplate]
  );

  useEffect(() => {
    setFilteredData([...getDataAccordingToTab(selectedTabType)]);
  }, [selectedTabType, selectedTemplate]);

  const onEdit = (val, tabType) => {
    if (tabType === 'EVENT') {
      setShowEventPage({ isShow: true, data: val });
    }
    if (tabType === 'ACTION') {
      setActionPage({
        isShow: true,
        data: { ...val },
      });
    }
  };
  const onClone = (value, tabType) => {
    if (tabType === 'EVENT') {
      const eventSettingList = _cloneDeep(selectedTemplate?.events?.events);
      const actionList = _cloneDeep(selectedTemplate?.events?.actions || []);
      dispatch(setActiveModal({ targetModalId: 'crudTemplateCloneModal' }));
      dispatch(
        updateViewState({
          stateKey: 'crudTemplateCloneModal',
          value: {
            id: selectedTemplate.id,
            patchData: { eventSettingList, cloneItemId: value.id, actionList },
            title: value.event_name,
            masterType: 'event',
            listKey: 'selectedTemplate',
            targetUrl: `/templates/update?$filter=id eq ${selectedTemplate.id}`,
            method: 'patch',
          },
        })
      );
    }
    if (tabType === 'ACTION') {
      const actionList = _cloneDeep(selectedTemplate?.events?.events);
      const eventSettingList = _cloneDeep(
        selectedTemplate?.events?.actions || []
      );
      dispatch(setActiveModal({ targetModalId: 'crudTemplateCloneModal' }));
      dispatch(
        updateViewState({
          stateKey: 'crudTemplateCloneModal',
          value: {
            id: selectedTemplate.id,
            patchData: { actionList, cloneItemId: value.id, eventSettingList },
            title: value.action_name,
            masterType: 'action',
            listKey: 'selectedTemplate',
            targetUrl: `/templates/update?$filter=id eq ${selectedTemplate.id}`,
            method: 'patch',
          },
        })
      );
    }
  };
  const onDelete = (value, tabType) => {
    if (tabType === 'EVENT') {
      const actionList = _cloneDeep(selectedTemplate?.events?.actions || []);
      const linkedAction = actionList?.filter((action) => action.listened_event === value.id);
      if (linkedAction.length > 0) {
        showToastMessage({
          description: `Failed to delete ${value.event_name} event. ${linkedAction.length} actions have been linked to this event.`,
          status: 'error',
        });
        return;
      }
      dispatch(setActiveModal({ targetModalId: 'deleteModal' }));
      dispatch(
        updateViewState({
          stateKey: 'deleteModal',
          value: {
            ...value,
            labelKey: 'name',
            masterType: 'events',
            listKey: 'selectedTemplate',
            // eslint-disable-next-line no-template-curly-in-string
            targetUrl: '/templates/update?$filter=id eq ${id}',
            method: 'patch',
          },
        })
      );
    }
    if (tabType === 'ACTION') {
      dispatch(setActiveModal({ targetModalId: 'deleteModal' }));
      dispatch(
        updateViewState({
          stateKey: 'deleteModal',
          value: {
            ...value,
            labelKey: 'name',
            masterType: 'events',
            listKey: 'selectedTemplate',
            // eslint-disable-next-line no-template-curly-in-string
            targetUrl: '/templates/update?$filter=id eq ${id}',
            method: 'patch',
            linkedType: 'actions',
          },
        })
      );
    }
  };

  const showColumnWithTab = (tab) => {
    if (tab === 'EVENT') {
      return eventColumns({
        onEdit,
        onDelete,
        onClone,
        tabType: tab.tabType || 'EVENT',
      });
    }
    return actionColumns({
      onEdit,
      onDelete,
      onClone,
      tabType: tab.tabType || 'ACTION',
      onSwitchChange: switchStatus,
    });
  };
  const addButtonClick = (tabType) => {
    if (tabType === 'EVENT') {
      setShowEventPage((pre) => ({ ...pre, isShow: true }));
    }
    if (tabType === 'ACTION') {
      setActionPage((pre) => ({ ...pre, isShow: true }));
    }
  };
  const filterData = debounce((e) => {
    const filteredFields = getDataAccordingToTab(selectedTabType).filter(
      (field) =>
        field?.[searchKey]
          ?.toLowerCase()
          ?.includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);
  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...getDataAccordingToTab(selectedTabType)]);
  };

  return (
    <Box paddingTop="15px">
      {true && (
        <Box
          data-testid="CommunicationTemplateCommonTableContainerBoxFlex"
          justifyContent="space-between"
        >
          <CustomTabs
            smallTabs="true"
            tabsData={eventActionTabList.map((tab) => ({
              ...tab,
              content: React.cloneElement(tab.content, {
                columns: showColumnWithTab(tab.tabType),
                searchKey:
                  tab.tabType === 'EVENT' ? 'event_name' : 'action_name',
                tableData: getDataAccordingToTab(tab.tabType),
                filteredData,
                addButtonClick: () => {
                  if (tab.tabType === 'EVENT') {
                    setShowEventPage((pre) => ({ ...pre, isShow: true }));
                  }
                  if (tab.tabType === 'ACTION') {
                    setActionPage((pre) => ({ ...pre, isShow: true }));
                  }
                },
                tabType: tab.tabType,
                showNoRecord: true,
              }),
            }))}
            tabRadius="100px"
            onChange={(tabIndex) => {
              setTabValue(tabIndex);
            }}
            actionHeaderComponent={
              <ActionHeader
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                filterData={filterData}
                handleClearInput={handleClearInput}
                selectedTabType={selectedTabType}
                addButtonClick={addButtonClick}
                addButtonText={`Add New ${
                  selectedTabType === 'EVENT' ? 'Event' : 'Action'
                }`}
              />
            }
            specialTab
            variant="unstyled"
            defaultTabIndex={tabValue}
            index={tabValue}
          />
        </Box>
      )}

      {showEventPage.isShow && (
        <CreateAndEditEvent
          eventState={showEventPage.data}
          setEventState={setShowEventPage}
          key={showActionPage.data.id}
        />
      )}
      {showActionPage.isShow && (
        <CreateAndEditAction
          actionStateObject={showActionPage.data}
          setActionState={setActionPage}
          relations={relations}
          relatedTemplates={relatedTemplates}
        />
      )}
    </Box>
  );
};

export default TemplateNewEventAction;

/* eslint-disable import/first */
import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Box,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
  WrapItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Icon
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { BiEnvelope, BiHelpCircle } from 'react-icons/bi';
import PropTypes from 'prop-types';
import Notification from './Notification';
import FeedBackModal from './FeedBackModal';
// don't comment just do a "git submodule udpate --init"
import HelpSupport from '../../common/SupportComponent/catalog/HelpSupport';

const LogoMarkLight =
  'https://as-cdn.azureedge.net/cdn/Rezolve-Monogram-Light.png';
const LogoMarkDark =
  'https://as-cdn.azureedge.net/cdn/Rezolve-Monogram-Dark.png';
const Logo = 'https://as-cdn.azureedge.net/cdn/Rezolve-Logo-Light.png';
const DarkLogo = 'https://as-cdn.azureedge.net/cdn/Rezolve-Logo-Dark.png';

import CustomSwitch from './CustomSwitch';
import styles from './nav-layout.module.scss';
import { HEADER_TOOLTIP_LABELS } from '../../utils/enums/labels';
import { removeTenantInformation } from '../../helpers/tenantUrl';

const HeaderContent = ({
  keycloak,
  openMenu,
  globalBg,
}) => {
  const [openFeedBackModal, setOpenFeedBackModal] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const getLogo = () => {
    if (openMenu && globalBg === 'dark') return DarkLogo;
    if (openMenu && globalBg !== 'dark') return Logo;
    if (globalBg === 'dark') return LogoMarkDark;
    return LogoMarkLight;
  };

  return (
    <Box
      bg={useColorModeValue('white', 'rgb(0, 0, 0)')}
      className={styles.header}
    >
      <div
        className={
          openMenu ? styles.headerExpandedInner : styles.headerCollapsedInner
        }
      >
        <div
          className={
            openMenu ? styles.headerExpandedLogo : styles.headerCollapsedLogo
          }
        >
          <img
            src={getLogo()}
            alt="logo"
            className={
              openMenu
                ? styles.expandedRezolveLogo
                : styles.collapsedRezolveLogo
            }
          />
        </div>
      </div>
      <div
        className={
          openMenu
            ? styles.expandedHeaderSection
            : styles.collapsedHeaderSection
        }
      >
        <Tooltip
          label={HEADER_TOOLTIP_LABELS.SWITCH}
          placement="right-end"
          hasArrow
          data-testid="MobileNavTooltip"
        >
          <Box data-testid="MobileNavCSwitchBox">
            <CustomSwitch />
          </Box>
        </Tooltip>

        <Flex
          alignItems="center"
          justifyContent="center"
          data-testid="MobileNavNotifFlex"
          gap="0.1rem"
        >
          {/* <Tooltip
            label={HEADER_TOOLTIP_LABELS.FEEDBACK}
            placement="bottom"
            hasArrow
            data-testid="MobileNavFeedBackTooltip"
          >
            <div
              data-testid="feedbackZbutton"
              onClick={() => {
                setOpenFeedBackModal(true);
              }}
            >
              <Button
                fontFamily="Inter"
                fontWeight="normal"
                borderRadius="15px"
                size="xs"
                variant="outline"
                leftIcon={<BiEnvelope />}
                colorScheme="blue"
                data-testid="feedback"
              >
                Feedback
              </Button>
            </div>
          </Tooltip> */}

          <Notification />

          <Tooltip
            label={HEADER_TOOLTIP_LABELS.HELP}
            placement="bottom"
            hasArrow
            data-testid="MobileNavLinkZTooltip"
          >
          <Icon as={BiHelpCircle} color="#94A3B8" boxSize={6} data-testid="Bihelp" onClick={onOpen} cursor="pointer" />
          </Tooltip>
          <HelpSupport data-testid="slider" isOpen={isOpen} onOpen={onOpen} onClose={onClose} config={keycloak} />
          <Tooltip
            label={HEADER_TOOLTIP_LABELS.PROFILE}
            placement="bottom"
            hasArrow
            data-testid="MobileNavProfileTooltip"
          >
            <WrapItem>
              <Menu>
                <MenuButton transition="all 0.2s">
                  <Box
                    ml="1"
                    display="flex"
                    justifyContent="space-between"
                    fontSize="12px"
                  >
                    <Box>
                      <Avatar size="xs" name={keycloak.userInfo.name} />
                    </Box>
                    <Text
                      ml="1"
                      data-testid="MobileNavkeyText"
                      color={useColorModeValue('#94A3B8', '#EDF2F7')}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {keycloak.userInfo.name}
                    </Text>
                    <Box pt="3px">
                      <ChevronDownIcon boxSize={5} />
                    </Box>
                  </Box>
                </MenuButton>
                <MenuList
                  fontSize="14px"
                  onClick={() => {
                    keycloak.logout();
                    localStorage.setItem('isAuth', null);
                    localStorage.removeItem('userName');
                    removeTenantInformation();
                  }}
                  data-testid="MobileNavSFlex"
                >
                  <MenuItem>
                    <Text>Logout</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </WrapItem>
          </Tooltip>

          {openFeedBackModal && (
            <FeedBackModal
              openFeedBackModal={openFeedBackModal}
              setOpenFeedBackModal={setOpenFeedBackModal}
            />
          )}
        </Flex>
      </div>
    </Box>
  );
};

HeaderContent.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  globalBg: PropTypes.string,
  keycloak: PropTypes.object.isRequired,
};

HeaderContent.defaultProps = {
  globalBg: '',
};

export default HeaderContent;

/* eslint-disable indent */
import React from 'react';
import { Popconfirm } from 'antd';
import { EditIcon, DeleteIcon, AttachmentIcon } from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import { isEmpty } from 'lodash';
import { useColorModeValue } from '@chakra-ui/react';
import WorkflowWhite from '../../../assets/icons/TicketWorkflow.svg';
import workflowBlack from '../../../assets/icons/workflowBlack.svg';
import { findElementName } from './helper';

export const renderUpdatedInfo = (rowData) => (
  <div className="text-left inline-flex align-middle">
    <p className="text-[14px]">{rowData.label}</p>
    {rowData.type === 'TRANSITION' && (
      <span className="ml-[5px] mt-[2px]">
        <ReactSVG
          src={useColorModeValue(workflowBlack, WorkflowWhite)}
          width="13px"
          height="13px"
        />
      </span>
    )}
  </div>
);
export const renderLinkedLayout = (rowData, layoutsState) => (
  <div className="text-center inline-flex align-middle">
    <p className="text-[14px]">
      {findElementName(layoutsState, rowData.linked_layout, 'name')}
    </p>
  </div>
);
export const renderIsActiveLayout = (rowData, layoutsState) => (
  <div className="text-center inline-flex align-middle">
    <p className="text-[14px]">
      {findElementName(layoutsState, rowData.linked_layout, 'is_active')
        ? 'Active'
        : ''}
    </p>
  </div>
);
export const deleteRenderer = ({
  onConfirm,
  title = 'Unlink this',
  description = 'Are you sure you want to unlink ?',
  loading = false,
  dataTestId,
}) => (
  <Popconfirm
    title={title}
    description={description}
    onConfirm={onConfirm}
    okText="Yes"
    cancelText="No"
    arrow={{
      pointAtCenter: true,
    }}
    placement="top"
    okButtonProps={{
      loading,
      style: { backgroundColor: '#1677ff' },
    }}
  >
    <DeleteIcon data-testid={dataTestId} className="cursor-pointer" />
  </Popconfirm>
);
export const unlinkedRender = ({
  title = 'Unlink this',
  description = 'Are you sure you want to unlink ?',
}) => (
  <Popconfirm
    title={title}
    description={description}
    okText="Ok"
    arrow={{
      pointAtCenter: true,
    }}
    placement="top"
    okButtonProps={{
      style: { backgroundColor: '#1677ff', loading: false, disabled: true },
    }}
  >
    <DeleteIcon className="cursor-pointer" />
  </Popconfirm>
);

export const columns = (showModal, onDelete, layoutsState) => [
  {
    name: 'LINKED ACTION VIEW',
    col_name: 'Views',
    type: 'string',
    cellRenderer: ({ rowData }) => renderUpdatedInfo(rowData),
  },
  {
    name: 'layout name',
    col_name: 'linked_layout',
    type: 'string',
    cellRenderer: ({ rowData }) => renderLinkedLayout(rowData, layoutsState),
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData, rowIndex }) => (
      <div className="flex gap-[10px]">
        {(!isEmpty(rowData?.linked_layout) || rowData?.label === 'Deferred')  ? (
          <EditIcon
            className="cursor-pointer"
            data-testid={`linkedLayout-${rowData.label}-edit`}
            onClick={() =>
              showModal({
                viewName: rowData.label,
                layoutId: !isEmpty(rowData?.linked_layout)
                  ? rowData?.linked_layout
                  : '',
                rowData,
              })
            }
          />
        ) : (
          <AttachmentIcon
            className="cursor-pointer"
            color="blue"
            data-testid={`linkedLayout-${rowData.label}-edit`}
            onClick={() =>
              showModal({
                viewName: rowData.label,
                layoutId: !isEmpty(rowData?.linked_layout)
                  ? rowData?.linked_layout
                  : '',
                rowData,
              })
            }
          />
        )}
        {(!isEmpty(rowData?.linked_layout) || rowData?.label === 'Deferred')
           && deleteRenderer({
              title: 'Unlink the layout from this view',
              description: 'Are you sure you want to unlink this layout?',
              onConfirm: () => onDelete(rowData, rowIndex),
              dataTestId:`linkedLayout-${rowData.label}-delete`
            })
          // : unlinkedRender({
          //     title: `Can't unlink layout this view`,
          //     description: 'Linked the view with layout',
          //   })
            }
        {/* <CopyIcon
            className="cursor-pointer"
            onClick={() => onClone(rowData, rowIndex)}
          /> */}
      </div>
    ),
  },
];
